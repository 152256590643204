import React from "react";
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonModal,
    IonRow,
    IonSpinner,
    IonText,
    IonTitle,
    IonToggle,
    IonBadge,
    IonToolbar,
    IonChip,
    IonSelect,
    IonSelectOption,
    IonPopover
} from "@ionic/react";
import { Device, Scheduler, SchedulerDate, SchedulerMode, SchedulerOutput, SchedulerTemplate } from "../service/API";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import Utils from "../service/Utils";
import { add, arrowForwardSharp, ellipsisHorizontal, pencilOutline, repeatOutline } from "ionicons/icons";
import EventBox from "../service/EventBox";
import { Capacitor } from "@capacitor/core";
import Lang from "../service/Lang";
import moment from "moment";

export type DeviceSchedulersProps = {
    device: Device,
    store?: Store,
    lang?: Lang,

    routerOutlet: HTMLIonRouterOutletElement,
}
export type DeviceSchedulersState = {
    outputs: SchedulerOutput[],
    schedulersTemplates: SchedulerTemplate[],
    edit: boolean,
    output: SchedulerOutput,
    state: boolean,
    loading: boolean,
    loadingList: boolean,
    scheduler?: Scheduler,
    viewList: boolean,
    pickerOutput: boolean,
}
@inject("store", "lang")
export default class DeviceSchedulers extends React.Component<DeviceSchedulersProps, DeviceSchedulersState> {

    private events?: EventBox;

    componentDidMount(): void {
        this.loadSchedulers();
        this.events = new EventBox(this.props.store?.events!);
        this.events.on('schedulers', () => this.loadSchedulers());

        this.setState({ viewList: Utils.toBoolean(this.props.store?.getItem("schedulerList")!) })
    }

    componentWillUnmount(): void {
        this.events?.off();
    }

    componentDidUpdate(prevProps: Readonly<DeviceSchedulersProps>, prevState: Readonly<DeviceSchedulersState>, snapshot?: any) {
        if (prevProps.device !== this.props.device) {
            this.loadSchedulers();
        }
    }

    filterSchedulers() {
        return (a: Scheduler) => a.id_scheduler_template === this.props.device.id_scheduler_template;
    }

    loadSchedulers() {
        let id = this.props.device.id;
        if (!this.state || !this.state.loadingList) {
            this.setState({ loadingList: true });
            return this.props.store!.api.schedulers(id).then(value => {
                if (value.success) {
                    let items = value.data.sort((a, b) => a.oindex - b.oindex).filter(a => a.visible);
                    this.setState({ outputs: items, loadingList: false });
                    this.loadSchedulersTemplates();
                }
            });
        }
    }

    loadSchedulersTemplates() {
        let id = this.props.device.id;
        this.props.store?.api.schedulersTemplates(id).then(res => {
            if (res.success) {
                this.setState({ schedulersTemplates: res.data })
            }
        })

    }

    createScheduler(output_id = 0, state = true): Scheduler {
        return {

            id: 0,
            running: true,
            time: '00:00',
            output_id: output_id,
            device_id: this.props.device.id,
            state: state ? "1" : "0",
            transport: 'api',
            week: '0000000',
            mode: 'time',
            date: new Date().toISOString(),
            dates: [],
            timer_repeat: 0,
            timer_interval: 0,
            id_scheduler_template: this.props.device.id_scheduler_template
        };
    }

    createSchedulerTemplate() {
        return {
            id: 0,
            device_id: this.props.device.id,
            title: '',

        } as SchedulerTemplate
    }

    addScheduler = (output?: SchedulerOutput, state: boolean = true) => {
        this.setState({ edit: true, scheduler: this.createScheduler(output ? output.id : 0, state) });
    };
    editScheduler = (output: SchedulerOutput, scheduler: Scheduler) => (ev: any) => {
        // debugger;
        if (ev.target.tagName !== "ION-TOGGLE") {
            this.setState({ edit: true, output: output, scheduler: scheduler });
            ev.stopPropagation();
        } else {
            ev.stopPropagation();
        }
    };

    isWeek(index: number) {
        return this.state.scheduler?.week.substring(index, index + 1);
    }

    handleEditScheduler = async (ev: any) => {
        // eslint-disable-next-line no-restricted-globals
        if (this.state.scheduler?.output_id! > 0) {
            this.setState({ loading: true });
            let result = await this.props.store?.api.schedulerAdd(this.props.device.id, this.state.scheduler!);
            this.setState({ loading: false });
            if (result && result!.success) {
                this.setState({ edit: false });
                this.loadSchedulers();
            }
        } else {
            this.setState({ pickerOutput: true });
        }

    };

    handleCheckChange = (name: string) => (ev: any) => {
        this.setState({ scheduler: { ...this.state.scheduler!, [name]: ev.currentTarget.checked } })
    }
    handleWeekChange = (index: number) => (ev: any) => {
        let value = (ev.currentTarget.checked ? "1" : "0");
        // @ts-ignore
        let week = this.state.scheduler?.week;
        if (week?.length !== 7) week = "0000000";
        let left = week?.substring(0, index);
        let right = week.substring(index + 1);
        this.state.scheduler!.week = left + (value) + right;

        this.setState({ scheduler: this.state.scheduler });
    }

    handleChange = (name: string) => (ev: any) => {
        this.setState({ scheduler: { ...this.state.scheduler!, [name]: ev.detail.value } })

    }

    handleModeChange = (mode: SchedulerMode) => (ev: any) => {

        if (ev.currentTarget.checked) {
            this.state.scheduler!.mode = mode;
            this.setState({ scheduler: this.state.scheduler })
        }
    }

    handleToggleScheduler = async (ev: any) => {

        this.props.device.enableScheduler = ev.currentTarget.checked ? 1 : 0;
        await this.props.store?.api.deviceScheduler(this.props.device.id, ev.currentTarget.checked);

        this.setState({});

    }

    handleRemove = (scheduler: Scheduler) => async (ev: any) => {

        this.setState({ loading: true });
        let result = await this.props.store?.api.removeScheduler(scheduler.id);

        this.setState({ loading: false });

        if (result && result.success) {
            await this.loadSchedulers();

            this.setState({ edit: false });
        }
    }

    handleAddDates = (ev: any) => {
        this.state.scheduler?.dates.push({
            date: new Date().toISOString(),
        });
        this.setState({});
    }

    handleChangeDates = (item: SchedulerDate) => (ev: any) => {
        item.date = ev.currentTarget.value;
        this.setState({});

    }
    handleRemoveDates = (item: SchedulerDate) => (ev: any) => {
        let index = this.state.scheduler?.dates.indexOf(item);
        if (index !== undefined && index >= 0) {
            this.state.scheduler?.dates.splice(index, 1);
            this.setState({});
        }

    }

    handleChangeRunning = (item: Scheduler) => (ev: any) => {
        item.running = ev.currentTarget.checked;
        this.setState({ loading: true });
        this.props.store?.api.schedulerAdd(this.props.device.id, item).then(value => {
            this.setState({ loading: false });
        });
    };

    handleToggleView = (list: boolean) => (ev: any) => {
        this.props.store?.setItem("schedulerList", list);
        this.setState({ viewList: list });
    }

    getDates() {
        let dates = [];
        let s = this.state.scheduler!;
        let t = s.time.split(":");
        if (s.timer_repeat > 0 && s.timer_interval > 0 && t.length === 2) {
            for (let i = 0; i < s.timer_repeat; i++) {
                let date = moment();
                date.hours(parseInt(t[0])).minute(parseInt(t[1]));
                date.add(i * s.timer_interval, 'minutes');

                dates.push(date);
            }
        }
        return dates;
    }

    handleSetOutput = (ev: any) => {
        this.setState({
            scheduler: {
                ...this.state.scheduler!,
                output_id: ev.detail.value
            }
        });
    }
    handleSetOutputState = (ev: any) => {
        this.setState({
            scheduler: {
                ...this.state.scheduler!,
                state: ev.detail.value
            }
        });
    }

    handleAllOn = async (scheduler: SchedulerOutput, state: boolean) => {
        let item = scheduler;

        if (item) {
            this.setState({ loading: true });

            let r = item.schedulers.filter(a => Utils.toBoolean(a.state) === (state)).map(async a => {
                a.running = true;
                await this.props.store?.api.schedulerAdd(this.props.device.id, a);
            });
            await Promise.all(r);

            this.setState({ loading: false });
        }

    }
    handleAllOff = async (scheduler: SchedulerOutput, state: boolean) => {

        let item = scheduler;
        if (item) {
            this.setState({ loading: true });


            let r = item.schedulers.filter(a => Utils.toBoolean(a.state) === (state)).map(async a => {
                a.running = false;
                await this.props.store?.api.schedulerAdd(this.props.device.id, a);
            });
            await Promise.all(r);

            this.setState({ loading: false });
        }

    }

    handleEnableTemplate = (id_template: number | null) => {
        this.props.device.id_scheduler_template = id_template;
        this.props.store?.api.deviceSchedulerTemplate(this.props.device.id, id_template);

        this.setState({});

    }

    handleRemoveSchedulerTemplate = (ev: any) => {

        this.props.store?.api.schedulerTemplateDelete(this.props.device.id_scheduler_template!).then(res => {
            if (res.success) {
                this.handleEnableTemplate(null);
            }
        })
    }

    handleAddSchedulerTemplate = (ev: any) => {
        let st = this.createSchedulerTemplate();
        let t = prompt("Título de la plantilla");
        if (t) {
            st.title = t;
            this.props.store?.api.schedulerTemplateAdd(this.props.device.id, st).then(res => {
                if (res.success) {
                    this.handleEnableTemplate(res.data.id);
                }
            })
        }
    }

    renderEditor() {
        if (!this.state.scheduler) return;
        const { lang } = this.props;
        const { scheduler } = this.state;
        const { mode } = scheduler;
        // const { state } = scheduler;
        let output = Utils.getOutputById(this.props.device.outputs, this.state.scheduler.output_id);

        let options = [], values = [];

        for (let i = 0; i < this.props.device.outputs.length; i++) {
            let item = this.props.device.outputs[i];
            options.push({ text: item.name, value: item.id });
        }
        if (output?.type === 'toggle') {
            values.push({ text: output.on_name, value: String(output.on_value) });
            values.push({ text: output.off_name, value: String(output.off_value) });
        } else if (output?.type === 'select') {
            for (let i = 0; i < output.values!.length; i++) {
                const element = output.values![i];
                values.push({ text: element.title, value: element.value });
            }
        }
        return <>
            <IonItem>
                <IonSelect label={lang?.l.main.scheduler_output()} labelPlacement="floating" value={output?.id} placeholder="Seleccionar salida" onIonChange={this.handleSetOutput}>
                    {this.props.device.outputs.sort(Utils.sortOutputs()).map((value, index) => <IonSelectOption value={value.id}>{value.name}</IonSelectOption>)}
                </IonSelect>

            </IonItem>
            {output && (output.type === "toggle" || output.type === "select") && <IonItem>


                <IonSelect label={lang?.l.main.map_value()} labelPlacement="start" value={this.state.scheduler.state} placeholder="Seleccionar estado" onIonChange={this.handleSetOutputState}>
                    {values.map((value, index) => <IonSelectOption value={value.value}>{value.text}</IonSelectOption>)}
                </IonSelect>
            </IonItem>}
            {output && output.type === "value" && <IonItem>
                <IonInput label={lang?.l.main.map_value()} labelPlacement="start" style={{ textAlign: 'right' }} slot={"end"} type={"number"} onIonInput={this.handleChange('state')} value={this.state.scheduler.state}></IonInput>

            </IonItem>}
            <IonItem>
                <IonToggle enableOnOffLabels={true} labelPlacement="start" checked={this.state.scheduler?.running} onIonChange={this.handleCheckChange('running')}
                    color="primary">
                    {lang?.l.main.enable()}
                </IonToggle>
            </IonItem>
            <IonItem>
                <IonInput
                    labelPlacement="start"
                    label={lang?.l.main.scheduler_time()}
                    type="time" onIonChange={this.handleChange('time')} value={this.state.scheduler.time}></IonInput>
                {/* <IonButton>Cambiar Hora</IonButton> */}
                <IonDatetime presentation="time" locale="es-ES" onIonChange={this.handleChange('time')} value={this.state.scheduler.time} />
            </IonItem>
            <IonItem>
                <IonInput labelPlacement="start" label="Repetir (Nº Veces)" max={"100"} type={"number"}
                    onIonInput={this.handleChange('timer_repeat')} value={this.state.scheduler.timer_repeat}></IonInput>
                {/*<IonDatetime displayFormat="HH:mm" onIonChange={this.handleChange('time')}*/}
                {/*             value={this.state.scheduler.time}></IonDatetime>*/}
            </IonItem>
            <IonItem hidden={this.state.scheduler.timer_repeat <= 0}>
                <IonInput label="Intervalo (min.)" labelPlacement="start" style={{ textAlign: 'right' }} type={"number"} onIonInput={this.handleChange('timer_interval')} value={this.state.scheduler.timer_interval}></IonInput>
                {/*<IonDatetime displayFormat="HH:mm" onIonChange={this.handleChange('time')}*/}
                {/*             value={this.state.scheduler.time}></IonDatetime>*/}
            </IonItem>
            <IonItem hidden={this.state.scheduler.timer_repeat <= 0} style={{ 'overflowX': 'auto' }}>
                {this.getDates().map((value, key) => {
                    return <p><IonChip key={key}>
                        <IonLabel>{value.format("HH:mm")}</IonLabel></IonChip></p>
                })}
            </IonItem>
            <IonItem>
                <IonCheckbox labelPlacement="start" checked={this.state.scheduler?.mode === 'time'}
                    onIonChange={this.handleModeChange('time')}>
                    {lang?.l.main.scheduler_repeat_week()}
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "time"}>
                <IonCheckbox labelPlacement="end" checked={this.isWeek(0) === "1"} onIonChange={this.handleWeekChange(0)}>
                    {lang?.l.main.monday()}
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "time"}>
                <IonCheckbox labelPlacement="end" checked={this.isWeek(1) === "1"} onIonChange={this.handleWeekChange(1)}>
                    {lang?.l.main.tuesday()}
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "time"}>
                <IonCheckbox labelPlacement="end" checked={this.isWeek(2) === "1"} onIonChange={this.handleWeekChange(2)} >
                    {lang?.l.main.wednesday()}
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "time"}>
                <IonCheckbox labelPlacement="end" checked={this.isWeek(3) === "1"} onIonChange={this.handleWeekChange(3)} >
                    {lang?.l.main.thursday()}
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "time"}>
                <IonCheckbox labelPlacement="end" checked={this.isWeek(4) === "1"} onIonChange={this.handleWeekChange(4)}>
                    {lang?.l.main.friday()}
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "time"}>
                <IonCheckbox labelPlacement="end" checked={this.isWeek(5) === "1"} onIonChange={this.handleWeekChange(5)}>
                    {lang?.l.main.saturday()}
                </IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "time"}>
                <IonCheckbox labelPlacement="end" checked={this.isWeek(6) === "1"} onIonChange={this.handleWeekChange(6)}>
                    {lang?.l.main.sunday()}
                </IonCheckbox>
            </IonItem>

            <IonItem>
                <IonCheckbox labelPlacement="start" checked={this.state.scheduler?.mode === 'date'}
                    onIonChange={this.handleModeChange('date')}>{lang?.l.main.scheduler_fixed_date()}</IonCheckbox>
            </IonItem>
            <IonItem hidden={mode !== "date"}>
                <IonLabel>{lang?.l.main.date()}</IonLabel>
                <IonDatetime firstDayOfWeek={1} locale="es-ES" max={"2099-12-31"} onIonChange={(ev: any) => this.setState({ scheduler: { ...this.state.scheduler!, date: ev.detail.value.substring(0, 10) } })}
                    value={this.state.scheduler?.date} presentation="date" />
            </IonItem>

            <IonItem>
                <IonCheckbox labelPlacement="start" checked={this.state.scheduler?.mode === 'multi-date'}
                    onIonChange={this.handleModeChange('multi-date')}>{lang?.l.main.scheduler_multiple_dates()}</IonCheckbox>
            </IonItem>
            <div hidden={mode !== "multi-date"}>
                {this.state.scheduler?.dates.map((value, index) => {
                    return <IonItemSliding key={index}>
                        <IonItem>

                            <IonLabel>{lang?.l.main.date()}</IonLabel>
                            <IonDatetime firstDayOfWeek={1} presentation="date" locale="es-ES" max={"2099-12-31"} onIonChange={this.handleChangeDates(value)}
                                value={value.date} />
                        </IonItem>

                        <IonItemOptions side="end">
                            <IonItemOption color="danger" expandable
                                onClick={this.handleRemoveDates(value)}>{lang?.l.main.remove()}</IonItemOption>
                        </IonItemOptions>
                    </IonItemSliding>
                })}
                <IonItem button onClick={this.handleAddDates}>
                    <IonLabel><IonIcon icon={add} /> {lang?.l.main.add()}</IonLabel>
                </IonItem>
            </div>
            <IonItem button onClick={this.handleRemove(this.state.scheduler)} color={"danger"}>
                <IonLabel>
                    {lang?.l.main.remove()}
                </IonLabel>
            </IonItem>
        </>
    }

    renderSchedulerText(value: Scheduler) {
        if (value.mode === "time") {
            let week = Utils.translateWeek(value.week);
            return [value.time, week];
        }
        if (value.mode === 'date') {
            return [value.time, value.date];
        }
        return [value.time, value.dates.length + " dia(s)"];

    }

    renderSchedulerItem(output: SchedulerOutput, scheduler: Scheduler) {
        let date = this.renderSchedulerText(scheduler);
        return <>
            <IonToggle slot={"start"} onClick={this.handleChangeRunning(scheduler)}
                checked={scheduler.running} /><br />


            <IonText>{scheduler.timer_repeat > 0 && <div><IonIcon icon={repeatOutline} /> {scheduler.timer_repeat} <IonIcon icon={arrowForwardSharp} /> {scheduler.timer_interval} m.</div>}</IonText>
            <IonText
                className={(scheduler.running ? "" : "scheduler-not-running")}>{[date[0] + " " + date[1]]}</IonText>

        </>
    }

    renderScheduler(output: SchedulerOutput, scheduler: Scheduler, state?: boolean) {
        if (state === undefined || Utils.toBoolean(scheduler.state) === state) {
            let date = this.renderSchedulerText(scheduler);
            if (Capacitor.getPlatform() === "web") {
                return <IonItem color={scheduler.running ? "" : "danger"} button detail detailIcon={pencilOutline}
                onClick={this.editScheduler(output, scheduler)}>
                    <IonToggle slot={"start"} onClick={this.handleChangeRunning(scheduler)}
                        checked={scheduler.running} /><br />
                    <IonText
                        className={(scheduler.running ? "" : "scheduler-not-running")}>{[date[0] + " " + date[1]]}
                        {scheduler.timer_repeat > 0 && <div><IonIcon icon={repeatOutline} /> {scheduler.timer_repeat} <IonIcon icon={arrowForwardSharp} /> {scheduler.timer_interval} m.</div>}

                        {output.type === "value" && <div><IonBadge>{scheduler.state}</IonBadge></div>}
                        {output.type === "select" && <div><IonBadge>{Utils.getOutputTitleByValue(output, scheduler.state)}</IonBadge></div>}


                    </IonText>
                </IonItem>
            } else {
                return <IonItem color={scheduler.running ? "" : "danger"} button detail detailIcon={pencilOutline} onClick={this.editScheduler(output, scheduler)}  >
                    <IonToggle slot="start" labelPlacement="end" onClick={this.handleChangeRunning(scheduler)}
                        checked={scheduler.running}></IonToggle><br />
                        <small className={(scheduler.running ? "" : "scheduler-not-running")}>{[date[0],
                        <br />, date[1]]}</small>
                        {scheduler.timer_repeat > 0 && <div><IonIcon icon={repeatOutline} /> {scheduler.timer_repeat} <IonIcon icon={arrowForwardSharp} /> {scheduler.timer_interval} m.</div>}

                        {output.type === "value" && <div><IonBadge>{scheduler.state}</IonBadge></div>}
                        {output.type === "select" && <div><IonBadge>{scheduler.state}</IonBadge></div>}
                    
                </IonItem>
            }

        }
    }

    renderContextMenu(value: SchedulerOutput, state: boolean) {
        return <IonItem id={"popover-" + value.id + "-" + state} color="light" detail detailIcon={ellipsisHorizontal} button>
            <IonLabel>Acciones</IonLabel>
            <IonPopover trigger={"popover-" + value.id + "-" + state} dismissOnSelect={true}>
                <IonContent>
                    <IonList>
                        <IonItem button={true} detail={false} onClick={() => this.handleAllOn(value, state)}>
                            <IonToggle checked />
                            <IonLabel> Todos ON</IonLabel>
                        </IonItem>
                        <IonItem button={true} detail={false} onClick={() => this.handleAllOff(value, state)}>
                            <IonToggle checked={false} />
                            <IonLabel>Todos OFF</IonLabel>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonPopover>
        </IonItem>
    }
    renderGrid() {
        return this.state && this.state.outputs && this.state.outputs.map((value, index) => {
            if (value.type === "value" || value.type === "select" || value.type === "process" || value.type === 'extension') {
                return <IonRow key={index}>
                    <IonCol size="12">

                        <IonItem color="dark" detail detailIcon={add} button onClick={() => this.addScheduler(value, true)}>
                            <IonLabel>{value.name}</IonLabel>
                        </IonItem>
                        <IonList>
                            {value.schedulers.filter(this.filterSchedulers()).map((value1, index1) => (
                                <div key={index1}>{this.renderScheduler(value, value1, undefined)}</div>))}

                        </IonList>
                    </IonCol>
                </IonRow>
            } else if (value.type !== "input") {
                let onSchedulers = value.schedulers.filter(this.filterSchedulers()).filter(value1 => Utils.toBoolean(value1.state));
                let offSchedulers = value.schedulers.filter(this.filterSchedulers()).filter(value1 => !Utils.toBoolean(value1.state));
                return <IonRow key={index}>
                    <IonCol size="6">
                        <IonItem color="dark" detail detailIcon={add} button onClick={() => this.addScheduler(value, true)}>
                            <IonText>{value.name} {value.on_name}</IonText>
                        </IonItem>

                        <IonList lines="none">
                            {onSchedulers.map((value1, index1) => (
                                <div key={index1}>{this.renderScheduler(value, value1, true)}</div>))}
                        </IonList>

                        {onSchedulers.length > 0 && this.renderContextMenu(value, true)}
                    </IonCol>
                    <IonCol size="6">

                        <IonItem color="dark" detail detailIcon={add} button onClick={() => this.addScheduler(value, false)}>
                            <IonText>{value.name} {value.off_name}</IonText>
                        </IonItem>
                        <IonList lines="none">
                            {offSchedulers.map((value1, index1) => <div
                                key={index1}>{this.renderScheduler(value, value1, false)}</div>)}
                        </IonList>

                        {offSchedulers.length > 0 && this.renderContextMenu(value, false)}
                    </IonCol>
                </IonRow>
            }
            return "";
        })
    }

    renderList() {
        return this.state && this.state.outputs && this.state.outputs.map((value, index) => {
            return value.schedulers.map((value1, index1) => {
                return <IonItem color={value1.running ? "" : "danger"} button detail
                    onClick={this.editScheduler(value, value1)}>
                    <IonLabel>
                        {value.name}

                        {value.type === "toggle" && (Utils.toBoolean(value1.state) ? " ON " : " OFF ")}
                        {value.type === "value" && <div> <IonBadge>{value1.state}</IonBadge></div>}
                        {value.type === "select" && <div> <IonBadge>{value1.state}</IonBadge></div>}


                    </IonLabel> {this.renderSchedulerItem(value, value1)}
                </IonItem>
            })
        })
    }

    renderMainContextMenu() {
        if (!this.state) return "";
        let templateName = "";
        if (this.state.schedulersTemplates) {
            let item = this.state.schedulersTemplates.find(a => a.id === this.props.device.id_scheduler_template);
            if (item) {
                templateName = item.title;
            }

        }
        return <IonItem id={"popover-menu"} color="light" detail detailIcon={ellipsisHorizontal} button>
            <IonLabel>Plantilla "{templateName ? templateName : "(Por defecto)"}"</IonLabel>
            <IonPopover trigger={"popover-menu"} dismissOnSelect={true}>
                <IonContent>
                    <IonList>
                        <IonItem button={true} detail={true} detailIcon={add} onClick={this.handleAddSchedulerTemplate}>
                            <IonLabel> Nueva plantilla vacía</IonLabel>
                        </IonItem>
                        {this.state.schedulersTemplates && this.state.schedulersTemplates.reverse().map((value, index) => {
                            return <IonItem color={this.props.device.id_scheduler_template === value.id ? "primary" : ""} button={true} detail={false} onClick={() => this.handleEnableTemplate(value.id)}>
                                <IonLabel> {this.props.device.id_scheduler_template === value.id ? "" : "Activar"} Plantilla "{value.title}"</IonLabel>
                            </IonItem>
                        })}
                        <IonItem color={this.props.device.id_scheduler_template ? "" : "primary"} button={true} detail={false} onClick={() => this.handleEnableTemplate(null)}>
                            <IonLabel> {this.props.device.id_scheduler_template ? "Activar" : ""}  Plantilla Por defecto</IonLabel>
                        </IonItem>

                        {this.props.device.id_scheduler_template && <IonItem button={true} color="danger" detail={false} onClick={this.handleRemoveSchedulerTemplate}>
                            <IonLabel> Eliminar "{templateName}"</IonLabel>
                        </IonItem>}
                    </IonList>
                </IonContent>
            </IonPopover>
        </IonItem>

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!this.state) return "";
        const { lang } = this.props;
        return <IonGrid className={this.props.device.enableScheduler ? "" : "overlayStriped"}>
            <IonRow>
                <IonCol size="12">
                    <IonItem>
                        <IonToggle labelPlacement="start" value="1" checked={!!this.props.device.enableScheduler}
                            onIonChange={this.handleToggleScheduler}>
                            {lang?.l.main.scheduler_enable()}
                        </IonToggle>


                        {/* <IonButtons slot={"end"}>
                            <IonButton fill={"clear"} color={this.state.viewList ? "primary" : "medium"}
                                onClick={this.handleToggleView(true)}>
                                <IonIcon icon={list} />
                            </IonButton>
                            <IonButton fill={"clear"} color={!this.state.viewList ? "primary" : "medium"}
                                onClick={this.handleToggleView(false)}>
                                <IonIcon icon={grid} />
                            </IonButton>
                        </IonButtons> */}
                    </IonItem>
                    <IonItem hidden={!!this.props.device.enableScheduler}>
                        <IonText color={"danger"}>
                            {lang?.l.main.scheduler_disable_warning()}
                        </IonText>
                    </IonItem>
                    {this.renderMainContextMenu()}

                </IonCol>
            </IonRow>
            {/* {this.state.viewList && this.renderList()} */}
            {this.renderGrid()}
            {/* {this.renderList()} */}

            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state.edit ? true : false} onDidDismiss={() => this.setState({ edit: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{this.state.scheduler && this.state.scheduler.id > 0 ? lang?.l.main.edit() : lang?.l.main.add()} {lang?.l.main.scheduler_title_modal()}</IonTitle>

                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({ edit: false })}>{lang?.l.main.cancel()}</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleEditScheduler}><IonSpinner
                                hidden={!this.state.loading} /> {lang?.l.main.save()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>


        </IonGrid>;
    }

}
