import React, { RefObject } from "react";
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonFab,
    IonFabButton,
    IonFabList,
    IonGrid,
    IonHeader,
    IonIcon,
    IonImg,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonMenuButton,
    IonModal,
    IonPage,
    IonRow,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { ClientDevices, Device, HMIPanel, Panel, PanelControl, PanelData, User, NewDashboard } from "../service/API";
import { add, bugOutline, checkmarkOutline, cloud, cloudyOutline, ellipsisVertical, hardwareChip, hardwareChipOutline, laptopOutline, mailOutline, newspaper, notifications, thermometerOutline } from "ionicons/icons";
import { RouteComponentProps, withRouter } from "react-router";
import AddPanel from "../components/AddPanel";
import DeviceStatus from "./Device/DeviceStatus";
import Lang from "../service/Lang";
import HMIDisplay from "../components/HMIDisplay";
import ForecastCard, { Forecast } from "../components/ForecastCard";
import Utils from "../service/Utils";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import '@ionic/react/css/ionic-swiper.css';

export interface DashboardProps extends RouteComponentProps {
    store?: Store,
    lang?: Lang,
    routerOutlet?: HTMLIonRouterOutletElement,
}
export type DashboardState = {
    // devices?:SimpleDevice[],
    clients?: ClientDevices[],
    user?: User,

    loading: boolean,
    loadingDashboard: boolean,
    loadingSend: boolean,
    editPanel: number,
    sent: boolean,
    panels?: PanelData[],
    hmiPanels?: HMIPanel[],
    news?: NewDashboard[],
    error?: any,
    dashboards?: any[],

}

export type DashboardType = {
    id: string,
    icon?: string
    title?: string
    header?: boolean
    ref?: RefObject<any>,
}
const DEFAULT_DATA = { order: 0, hidden: false };

@inject("store", "lang")
class Dashboard extends React.Component<DashboardProps, DashboardState> {


    token: RefObject<any>;
    refDevices: RefObject<any> = React.createRef();
    refHMI: RefObject<any> = React.createRef();
    refForecast: RefObject<any> = React.createRef();
    refPanels: RefObject<any> = React.createRef();
    refNews: RefObject<any> = React.createRef();
    refTools: RefObject<any> = React.createRef();
    state: DashboardState = { loading: false, loadingDashboard: false, loadingSend: false, sent: false, editPanel: 0, dashboards: [] };

    dashboard: DashboardType[] = [
        {
            id: 'welcome',

        },
        {
            id: 'news',
            icon: newspaper,
            title: 'Novedades',
            ref: this.refNews,
            header: true,
        },
        {
            id: 'forecast',
            icon: cloudyOutline,
            title: 'Tiempo',
            ref: this.refForecast,
            header: true,
        },
        {
            id: 'devices',
            icon: hardwareChipOutline,
            title: 'Dispositivos',
            ref: this.refDevices,
            header: true,
        },
        {
            id: 'hmi',
            icon: thermometerOutline,
            title: 'HMI',
            ref: this.refHMI,
            header: true,
        },
        {
            id: 'panels',
            icon: laptopOutline,
            title: 'Paneles',
            ref: this.refPanels,
            header: true,
        },
        // {
        //     id: 'campo',
        //     icon: book,
        //     title: 'Libro Campo',
        //     header: true,
        // },
        // {
        //     id: 'tools',
        //     icon: build,
        //     title: 'Herramientas',
        //     ref: this.refTools,
        //     header: true,
        // },

    ];
    constructor(props: DashboardProps, context: any) {
        super(props, context);
        this.token = React.createRef();
        this.refHMI = React.createRef();
    }

    loadDashboardData(id: string) {
        let str = this.props.store?.getItem("dashboards." + id);
        try {
            return JSON.parse(str!) || DEFAULT_DATA;
        } catch (e) {
            return DEFAULT_DATA;
        }
    }


    saveDashboardData(id: string, data: any) {
        this.props.store?.setItem("dashboards." + id, JSON.stringify(data));
    }

    getDashboardData(id: string) {
        let item = this.state.dashboards?.find(a => a.id === id);
        if (item) {
            return { ...DEFAULT_DATA, ...item.data };
        }
        return DEFAULT_DATA;
    }

    setDashboardData(id: string, data: any) {
        let item = this.getDashboardData(id);
        if (item) {
            item = { ...item, ...data };
            let d = this.state.dashboards?.find(a => a.id === id);
            if (d) {
                d.data = item;
            }
            this.saveDashboardData(id, item);
            this.setState({});
        }

        //this.state.dashboards?.sort((a, b) => a.data.order - b.data.order);
    }

    loadDashboard() {
        let dashboards = [];
        for (let index = 0; index < this.dashboard.length; index++) {
            const element = this.dashboard[index];
            let data = this.loadDashboardData(element.id);
            dashboards.push({ id: element.id, data: data });
        }
        //dashboards.sort((a, b) => a.data.order - b.data.order);
        this.setState({ dashboards: dashboards });

        this.setState({ loadingDashboard: true, error: undefined });
        this.loadUser()?.then(() => {
            this.props.store?.api.getDevicesClients().then(value => {
                this.setState({ loadingDashboard: false });
                if (value.success) {
                    this.setState({ clients: value.data.clients });
                } else {
                    this.setState({ error: value.error })
                }
            });

        });
        this.props.store?.api.getHMIPanels().then(res => {
            this.setState({ hmiPanels: res.data });
        })
        this.props.store?.api.getNews().then(res => {
            if (res.success) {       
                this.setState({ news: res.data });
            }
        })

        this.props.store?.api.getPanels().then(async value => {
            let api = this.props.store?.cache;
            if (value.success) {
                let result = [];
                for (let i = 0; i < value.data.length; i++) {
                    let panelData = await this.props.store?.api.getPanelData(value.data[i].id);
                    if (panelData?.success) {
                        await panelData.data.controls.map(async e => {
                            let res = await api?.getDevice(e.input!.group!.device_id);
                            if (res && res.success) {
                                e.input!.group!.device = res.data
                                return e;
                            }
                            return e;
                        })
                        result.push(panelData.data)
                    }
                }
                this.setState({ panels: result });
            }
        })

    }

    loadUser(update: boolean = false) {
        return this.props.store?.getUser(update).then(value => {
            this.setState({ user: value });
        });
    }

    componentDidMount(): void {
        this.loadDashboard();

        this.props.store?.events.on('verify', () => {
            this.loadUser(true);
        });

        this.props.store!.push.check(this.props);
    }

    componentWillUnmount(): void {
        this.props.store?.events.off('verify');
        this.setState({ editPanel: 0 });
    }

    handleVerify = (ev: any) => {
        this.setState({ loading: true });
        this.props.store?.api.verification(this.token.current.value!).then((res) => {
            if (res.success) {
                this.loadUser(true)!.then(() => this.setState({ loading: false }));
            } else {
                this.setState({ loading: false });
            }
        });
    }

    handleResend = (ev: any) => {
        this.setState({ loadingSend: true });
        this.props.store?.api.verificationResend().then(() => this.setState({ loadingSend: false, sent: true }));
    }

    handleUnderstood = (ev: any) => {
        this.props.store?.setItem("understood", "true");
        this.setState({});
    }

    handleCloseDashboard = (id: string) => (ev: any) => {
        this.setDashboardData(id, { hidden: true });
    }
    handleToggleDashboad = (id: string) => (ev: any) => {
        let visible = this.isDashboardVisible(id);
        let d = this.dashboard.find(a => a.id === id);
        this.setDashboardData(id, { hidden: this.isDashboardVisible(id), order: this.state.dashboards?.filter(a => !a.data.hidden).length });

        if (!visible) {
            if (d && d.ref && d.ref.current) {
                setTimeout(() => d!.ref!.current.scrollIntoView({ behavior: "smooth" }), 200);
            }
        }
    }

    handleShowAllDashboards = (ev: any) => {
        let list = this.dashboard.filter(a => a.header);
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            this.setDashboardData(element.id, { hidden: false, order: index });
        }
    }

    handleClickCloud = () => {
        // alert("ERROR");
        // throw new Error("Error tochoo!");

    }

    getPanelSize(panel: Panel, factor: number) {
        return String(Math.min(12, panel.size * factor));
    }

    isDashboardVisible(id: string) {
        return !this.getDashboardData(id).hidden

    }
    isAllDashboardVisible() {
        let list = this.dashboard.filter(a => a.header);
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            if (!this.isDashboardVisible(element.id)) return false;
        }
        return true;
    }
    isAllDashboardHidden() {
        let list = this.dashboard.filter(a => a.header);
        for (let index = 0; index < list.length; index++) {
            const element = list[index];
            if (this.isDashboardVisible(element.id)) return false;
        }
        return true;
    }

    handleForecast = (item: Forecast) => {
        if (item.list.length > 0) {
            let id = item.list[0].weather[0].id;
            let icon = Utils.getIconById(id, true);
            this.dashboard.find(a => a.id === 'forecast')!.icon = icon;
            this.setState({});
            console.log(id);
        }
    }

    handleNewsClose = (item: NewDashboard) => () => {
        let {hiddenList} = this.getDashboardData('news') ;
        if (!hiddenList) {
            hiddenList = [];
        }
        hiddenList.push(item.id);

        this.setDashboardData('news', { hiddenList });
        this.setState({})
    }

    handleClickShowAllNews = () => {
        let hiddenList : any[] = [];
        this.setDashboardData('news', { hiddenList });
        this.setState({})
    }

    lastDevice?: Device;
    renderPanelControl = (control: PanelControl) => {
        if (!this.state.clients) return;
        if (control.type === "input") {
            // let name = Utils.getSubInputName([control.input!], control.input?.iindex!, control.input_index);
            let append = null;
            if (!control.input || !control.input.group || !control.input.group.device) return;
            if (!this.lastDevice || this.lastDevice!.id !== control.input.group.device.id) {
                this.lastDevice = control.input.group.device;
                append = <IonItem><b>{control.input?.group?.device?.name}</b></IonItem>
            }
            return <>{append}<IonItem>
                <IonLabel>{DeviceStatus.renderItemTitle(control.input.group.device!, control.input)}</IonLabel>
                <IonText>{DeviceStatus.renderItemContent(this.props.lang!, control.input.group.device!, control.input)}</IonText>
            </IonItem></>
        }
    }

    renderPanel = (panel: PanelData, index: number) => {
        this.lastDevice = undefined;
        return <IonCol sizeSm={this.getPanelSize(panel, 6)} sizeMd={this.getPanelSize(panel, 4)} key={index}>
            <IonCard className="welcome-card">
                <IonCardHeader>
                    <IonCardTitle>
                        <IonIcon icon={ellipsisVertical} onClick={() => this.setState({ editPanel: panel.id })} />
                        {panel.title}
                    </IonCardTitle>


                </IonCardHeader>
                {panel.controls.map(this.renderPanelControl)}
            </IonCard>
        </IonCol>

    }
    renderHMIPanel = (panel: HMIPanel, index: number) => {
        return <IonCol sizeSm={"12"} key={index}>
            <IonCard className="welcome-card">
                <IonCardHeader>
                    <IonCardTitle>
                        {panel.name}
                    </IonCardTitle>


                </IonCardHeader>
                <HMIDisplay panel={panel} />
            </IonCard>
        </IonCol>

    }

    renderVerification() {
        const { lang } = this.props;
        if (!this.state) return "";
        if (!this.state.user) return "";
        return <IonModal isOpen={this.state && this.state.user! && !this.state.user!.enabled} canDismiss={false} backdropDismiss={false} keyboardClose={false} onDidDismiss={(ev: any) => ev.preventDefault()}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{lang?.l.main.dashboard_verify_title()}</IonTitle>

                    <IonButtons slot={"primary"}>
                        <IonButton onClick={this.handleVerify}><IonSpinner hidden={!this.state.loading} /> {lang?.l.main.dashboard_button()}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                <IonListHeader>
                    <IonLabel>{lang?.l.main.dashboard_content()}</IonLabel>
                </IonListHeader>
                <IonList>
                    <IonItem>
                        <IonText><div dangerouslySetInnerHTML={{ __html: String(lang?.l.main.dashboard_email_sent({ email: this.state.user!.email })) }} /></IonText>
                    </IonItem>
                    <IonItem>
                        <IonInput label={lang?.l.main.dashboard_code_input()} ref={this.token}></IonInput>
                    </IonItem>
                    <IonItem detail={false} button color={"primary"} onClick={this.handleResend}>
                        <IonLabel>{lang?.l.main.dashboard_resend()}</IonLabel>
                        {this.state.loadingSend && <IonSpinner />}
                        {this.state.sent && <IonIcon icon={checkmarkOutline} />}
                        {!this.state.sent && !this.state.loadingSend && <IonIcon icon={mailOutline} />}

                    </IonItem>
                    <IonItem detail={false} button href={"/logout"}>
                        <IonLabel>{lang?.l.main.menu_logout()}</IonLabel>


                    </IonItem>
                </IonList>
            </IonContent>

        </IonModal>
    }

    renderWelcomeCard() {
        const { lang } = this.props;
        // if (this.props.store?.getItem("understood")) return "";
        return <IonCard className="welcome-card">
            <img src="/assets/riego.jpg" style={{ maxWidth: '400px' }} alt="" />
            <IonCardHeader>
                <IonCardSubtitle>{lang?.l.main.dasbhoard_head()}</IonCardSubtitle>
                <IonCardTitle>{lang?.l.main.dashboard_welcome()}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <p>
                    {lang?.l.main.dashboard_welcome_message()}
                </p>
                <p>
                    Puedes personalizar esta vista seleccionando los elementos que deseas ver en la barra de arraiba.
                </p>
                <IonButton onClick={this.handleCloseDashboard('welcome')}>{lang?.l.main.dashboard_understood()}</IonButton>
            </IonCardContent>
        </IonCard>
    }

    renderForecast() {
        return <><IonList lines="none">
            <IonListHeader>
                <IonLabel>Pronóstico Meteorológico</IonLabel>
            </IonListHeader>
        </IonList>

            <ForecastCard onForecast={this.handleForecast} routerOutlet={this.props.routerOutlet} /></>
    }

    renderHMI() {
        // const { lang } = this.props;
        return this.state.hmiPanels && this.state.hmiPanels.length > 0 && <><IonList lines="none">
            <IonListHeader>
                <IonLabel><IonIcon icon={thermometerOutline} /> HMI</IonLabel>
                <IonButton routerLink="/hmi">Ver todo</IonButton>
            </IonListHeader>
        </IonList>
            <IonGrid>
                <IonRow>
                    {this.state.hmiPanels && this.state.hmiPanels.map(this.renderHMIPanel)}
                </IonRow>
            </IonGrid></>
    }

    renderDevices() {
        const { lang } = this.props;
        return <><IonList lines="none">
            <IonListHeader lines="inset">
                <IonLabel><IonIcon icon={hardwareChipOutline} /> {lang?.l.main.dashboard_devices()}</IonLabel>
                <IonButton routerLink="/devices">Ver todos ({this.state.clients ? this.state.clients.map(a => a.devices.length).reduce((a, b) => a + b, 0) : 0})</IonButton>
            </IonListHeader>
        </IonList>
            {this.state && this.state.clients && this.state.clients.map((client, index1) => {
                return <IonGrid key={index1}>
                    <IonRow>
                        <IonCol size={"12"}>
                            <IonListHeader>
                                <IonLabel>{client.user.fullname}</IonLabel>
                            </IonListHeader>
                        </IonCol>
                        {client.devices.map((value, index) => {
                            return <IonCol sizeSm={"6"} sizeMd={"4"} key={index}>
                                <IonCard className="welcome-card">
                                    <img src={value.image || value.model.image} alt="" />
                                    <IonCardHeader>
                                        <IonCardSubtitle>
                                            <IonBadge color={value.online ? "primary" : "danger"}>{value.online ? lang?.l.main.online() : lang?.l.main.offline()}</IonBadge></IonCardSubtitle>
                                        <IonCardTitle>{value.name}</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <p>
                                            <IonIcon icon={value.simulator ? bugOutline : hardwareChipOutline} />  {value.model.name} {value.protocol}
                                        </p>
                                        <IonButton routerLink={"/device/" + value.id + "/details"}>{lang?.l.main.dasbhoard_button_control()}</IonButton>
                                    </IonCardContent>
                                </IonCard>
                            </IonCol>
                        })}
                        {this.state.error && <IonCol sizeSm={"12"} sizeMd={"12"}>

                            <IonText>{lang?.l.main.dashboard_error()}</IonText><br />
                            <IonButton onClick={() => this.loadDashboard()}>{lang?.l.main.reload()}</IonButton>

                        </IonCol>}
                        {/* 
            <IonCol sizeSm={"6"} sizeMd={"4"}>
                <IonCard className="welcome-card">
                    <IonCardHeader>
                        <IonCardSubtitle>Añadir dispositivo</IonCardSubtitle>
                    </IonCardHeader>
                    <IonCardContent>

                        <IonButton routerLink={"/devices/add"}>Añadir dispositivo</IonButton>
                    </IonCardContent>
                </IonCard>
            </IonCol> */}
                    </IonRow>
                </IonGrid>
            })}</>
    }

    renderPanels() {
        const { lang } = this.props;
        return this.state.panels && this.state.panels.length > 0 && <><IonList lines="none">
            <IonListHeader>
                <IonLabel>{lang?.l.main.dashboard_panels()}</IonLabel>
            </IonListHeader>
        </IonList>
            <IonGrid>
                <IonRow>
                    {this.state.panels && this.state.panels.map(this.renderPanel)}
                </IonRow>
            </IonGrid></>
    }

    renderNews() {
        // const { lang } = this.props;
        let { hiddenList } = this.getDashboardData('news');
        if (!hiddenList) {
            hiddenList = [];
        }
        return <>
            <IonList lines="none">
                <IonListHeader>
                    <IonLabel>Novedades y Noticias</IonLabel>
                    <IonButton onClick={this.handleClickShowAllNews}>Ver todo</IonButton>
                </IonListHeader>
            </IonList>
            {/* <IonSlides pager={true} options={{ effect: 'cards', rewind: true, centeredSlides: false, }}>
                {this.state.news && this.state.news.map((v, k) => {
                    return <IonSlide key={k} hidden={hiddenList.indexOf(v.id) !== -1}>
                        {this.renderNewsItem(v)}
                    </IonSlide>
                })}
            </IonSlides> */}
        </>
    }

    renderNewsItem(news: NewDashboard) {
        return <IonCard className="action-card">
            {news.image !== "" && <div className="newsImageContainer" style={{}}>
                <IonImg alt="Background" className="newsImage" src={news.image} />
            </div>}
            
            
            <IonCardHeader>
                <IonCardSubtitle>{ news.subtitle }</IonCardSubtitle>
                <IonCardTitle>{news.title}</IonCardTitle>
            </IonCardHeader>

            <IonCardContent>
                {news.content}
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            {!!news.action_url && <IonButton href={news.action_url}>{ news.action_text }</IonButton>}
                        </IonCol>
                        <IonCol>
                            <IonButton fill="clear" onClick={this.handleNewsClose(news)}>Cerrar</IonButton>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    }
    renderDashboard(item: DashboardType) {
        let data = this.getDashboardData(item.id);
        if (data.hidden) return;

        if (item.id === 'welcome') {
            return this.renderWelcomeCard();
        }
        if (item.id === 'forecast') {
            return this.renderForecast();
        }
        if (item.id === 'hmi') {
            return this.renderHMI();
        }
        if (item.id === 'devices') {
            return this.renderDevices();
        }
        if (item.id === 'panels') {
            return this.renderPanels();
        }
        if (item.id === 'news') {
            return this.renderNews();
        }

    }

    renderSliders() {
        // @ts-ignore
        return <Swiper className="icons-pager" slidesPerView={Utils.isDesktop() ? 5 : 3}>
            {this.dashboard.filter(a => a.header).map((item, key) => {
                // return <IonSlides pager={false} options={{ width: 120, focusableElements: 'button', followFinger: false }} className="icons-pager">

                return <SwiperSlide>
                    <div className="slide-item">

                        <IonButton onClick={this.handleToggleDashboad(item.id)} size="large" color="primary" className="btn-round" fill={this.isDashboardVisible(item.id) ? "solid" : "outline"}><IonIcon icon={item.icon} /></IonButton>
                        <div className="slide-title">{item.title}</div>

                    </div>
                </SwiperSlide>
            })}
        </Swiper>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const { lang } = this.props;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot={"start"}><IonMenuButton /></IonButtons>
                    <IonButtons slot={"end"}>
                        <IonButton><IonIcon icon={notifications} /></IonButton>

                    </IonButtons>
                    <IonTitle>{lang?.l.main.dashboard_title()}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen={true}>
                <IonHeader collapse="fade">
                    <IonToolbar>
                        {this.renderSliders()}
                    </IonToolbar>
                </IonHeader>
                {this.state.dashboards!.map(a => <div ref={this.dashboard.find(b => b.id === a.id)?.ref}>{this.renderDashboard(a)}</div>)}

                {this.isAllDashboardHidden() && <IonButton onClick={this.handleShowAllDashboards}>Motrar todo</IonButton>}

            </IonContent>

            {/* {this.renderVerification()} */}

            <AddPanel routerOutlet={this.props.routerOutlet} open={this.state.editPanel > 0} id={this.state.editPanel} onClose={() => { this.setState({ editPanel: 0 }); this.loadDashboard(); }} />

            <IonLoading
                isOpen={this.state.loadingDashboard!}
                message={lang?.l.main.loading()}
                duration={5000}
            />

            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton>
                    <IonIcon icon={add} />
                </IonFabButton>
                <IonFabList side="top">
                    <IonFabButton routerLink={"/devices/add"}><IonIcon icon={hardwareChip} /> </IonFabButton>
                    <IonFabButton onClick={this.handleClickCloud}><IonIcon icon={cloud} /></IonFabButton>

                </IonFabList>
            </IonFab>
        </IonPage>
    }
}

export default withRouter(Dashboard);
