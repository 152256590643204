import React from 'react';
import styles from './styles';

const Button = ({onClick, ...props}:any) => {
  let _onClick = (e:any, ...args:any) => {
    e.preventDefault();
    onClick(...args);
  }
  return (
    <button style={styles.button} onClick={_onClick}>
      {props.children}
    </button>
  );
}

export default Button;
