import ModbusRequestBody from "./request/ModbusRequestBody";
import RequestFactory from "./request/RequestFactory";


const CRC = require('crc')
export default class ModbusRTURequest {
    private _address: number;
    private _body: ModbusRequestBody;
    private _corrupted: boolean;
    private _crc ? : number;
    /** Convert a buffer into a new Modbus RTU Request. Returns null if the buffer
     * does not contain enough data.
     * @param {Buffer} buffer
     * @return {ModbusRTURequest} A new Modbus RTU Request or null.
     */
    static fromBuffer (buffer:Buffer) {
        try {
            if (buffer.length < 1 /* address */ + 2 /* CRC */) {
                // debug('not enough data in the buffer yet')
                return null
            }

            const address = buffer.readUInt8(0)

            // debug(`rtu header complete, address, ${address}`)
            // debug('buffer', buffer)

            // NOTE: This is potentially more than the body; the body length isn't know at this point...
            const body = RequestFactory.fromBuffer(buffer.slice(1))

            if (!body) {
                return null
            }

            const payloadLength = 1 /* address */ + body.byteCount
            const expectedCrc = CRC.crc16modbus(buffer.slice(0, payloadLength))
            const actualCrc = buffer.readUInt16LE(payloadLength)
            const corrupted = (expectedCrc !== actualCrc)

            return new ModbusRTURequest(address, body, corrupted)
        } catch (e) {
            // debug('not enough data to create a rtu request', e)
            return null
        }
    }
    constructor (address:number, body:ModbusRequestBody, corrupted:boolean) {
        this._address = address
        this._body = body
        this._corrupted = corrupted
    }

    get address () {
        return this._address
    }

    get crc () {
        return this._crc
    }

    get body () {
        return this._body
    }

    get name () {
        return this._body!.name
    }

    get corrupted () {
        return this._corrupted;
    }

    createPayload () {
        const bodyPayload = this._body.createPayload()

        this._crc = CRC.crc16modbus(Buffer.concat([Buffer.from([this._address]), bodyPayload]))
        const crBu = Buffer.alloc(2)
        crBu.writeUInt16LE(this._crc!, 0);
        const idBuf = Buffer.from([this._address])
        const payload = Buffer.concat([idBuf, bodyPayload, crBu])

        return payload
    }

    get byteCount () {
        return this.body.byteCount + 3
    }
}
