import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {CardElement, IbanElement} from "@stripe/react-stripe-js";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import {Stripe} from "@stripe/stripe-js";
import Lang from "../service/Lang";


export type AddPaymentMethodProps = {
    isOpen: boolean,
    onAddPayment: (result: string | boolean) => void,
    store?: Store,
    lang?: Lang,
    stripe: Stripe | null,
    elements: any,
    device_id ? : string,
    routerOutlet:HTMLIonRouterOutletElement,
}
export type AddPaymentMethodState = {
    method: 'creditCard' | 'bankAccount' | 'googlePay',
    loading: boolean,
    error?: { message: string },
    cardComplete: boolean,
    titular: string
}

class AddPaymentMethod extends React.Component<AddPaymentMethodProps, AddPaymentMethodState> {
    state: AddPaymentMethodState = {
        method: 'creditCard',
        loading: false,
        cardComplete: false,
        titular: ''
    }

    componentDidMount(): void {
        this.setState({titular: ''})
    }
    async createSecret(type: string) {
        const { store } = this.props;
        if (this.props.device_id) {
            return await store?.api.paymentsDeviceIntent(this.props.device_id, type);
        } else {
            return await store?.api.paymentsIntent(type);
        }
    }

    async handleAddCard() {
        const { stripe, elements, lang } = this.props;
        
        let secret = await this.createSecret("card");
        if (secret && secret.success) {
            return stripe?.confirmCardSetup(secret.data.client_secret, {
                payment_method: {
                    card: elements.getElement(CardElement),
                    billing_details: {
                        name: this.state.titular,
                    },
                }
            });
        } else {
            this.setState({error: {message: lang?.l.main.add_payment_error()!}})
        }
    }

    async handleAddBank() {
        const {stripe, elements, lang} = this.props;
        let secret = await this.createSecret("sepa_debit");
        if (secret && secret.success) {
            return stripe?.confirmSepaDebitSetup(secret.data.client_secret, {
                payment_method: {
                    sepa_debit: elements.getElement(IbanElement),
                    billing_details: {
                        name: this.state.titular,
                        email: this.props.store?.user?.email!,
                    },
                }
            });
        } else {
            this.setState({error: {message: lang?.l.main.add_payment_error()!}})
        }
    }

    handleAdd = async (ev: any) => {
        const {store, lang} = this.props;
        this.setState({loading: true});
        let result;
        if (this.state.method === 'creditCard') {
            result = await this.handleAddCard();
        } else if (this.state.method === 'bankAccount') {
            result = await this.handleAddBank();
        } else {
            this.setState({loading: false, error: {message: lang?.l.main.add_payment_not_implemented()!}});
            return;
        }

        this.setState({loading: false});

        if(result) {
            if(!result.error) {
                let id = result.setupIntent!.payment_method!;
                if (this.props.device_id) {
                    await store?.api.paymentsDeviceCreate(this.props.device_id, String(id));
                } else {
                    await store?.api.paymentsCreate(String(id));
                }
                this.props.onAddPayment(String(id));
            } else {
                this.setState({error: {message: result.error!.message!}})

            }
        } else {
            this.setState({error: {message: lang?.l.main.add_payment_error_internet()!}})
        }
        // if (payload && !payload.error) {
        //     let response2 = await store?.api.paymentsCreate(String(payload.token?.id));
        //     this.setState({loading: false});
        //
        //     if (response2 && response2.success) {
        //         this.setState({error: undefined, loading: false});
        //         this.props.onAddPayment(String(payload.token?.card?.id));
        //     } else {
        //
        //         this.setState({error: {message: "Server error"}})
        //     }
        // } else if (payload && payload.error) {
        //     this.setState({error: {message: payload.error.message!}, loading: false});
        // } else {
        //     this.setState({error: {message: 'Método de pago no válido'}, loading: false});
        // }



    };

    handleChange = (ev: any) => {
        this.setState({method: ev.detail.value, error: undefined});
    }

    renderCreditCard() {
        const {lang} = this.props;

        return <>

            <IonItem>
                <IonLabel position={"stacked"}>{lang?.l.main.add_payment_holder_name()}</IonLabel>
                <IonInput required={true} value={this.state.titular} onIonInput={(ev: any) => {
                    this.setState({titular: ev.detail.value})
                }}/>
            </IonItem>
            <CardElement className={"ion-padding creditCard"} onChange={(event) => {
                this.setState({
                    error: event.error,
                    cardComplete: event.complete,
                });
            }}/>
            <IonItem>
                <IonText color="medium">{lang?.l.main.add_payment_credit_message()}</IonText>
            </IonItem>
        </>
    }

    renderSEPA() {
        const {lang} = this.props;
        return <>

            <IonItem>
                <IonLabel position={"stacked"}>{lang?.l.main.add_payment_sepa_holder()}</IonLabel>
                <IonInput required={true} value={this.state.titular} onIonInput={(ev: any) => {
                    this.setState({titular: ev.detail.value})
                }}/>
            </IonItem>
            <IbanElement className={"ion-padding creditCard"}
                              options={{
                                  supportedCountries: ['SEPA'],
                                  placeholderCountry: 'ES',
                              }}/>
                              <IonItem>
                              <IonText color="medium">
                                  {lang?.l.main.add_payment_sepa_message()}
                              </IonText>
                              </IonItem>
                              </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {lang} = this.props;
        return <IonModal presentingElement={this.props.routerOutlet} isOpen={this.props.isOpen ? true: false} canDismiss={true}
                         onDidDismiss={() => this.props.onAddPayment(false)} >

            <IonHeader>
                <IonToolbar>
                    <IonTitle>{lang?.l.main.add_payment_title()}</IonTitle>
                    <IonButtons slot={"secondary"}>

                        <IonButton onClick={() => this.props.onAddPayment(false)}>{lang?.l.main.cancel()}</IonButton>
                    </IonButtons>
                    <IonButtons slot={"primary"}>
                        <IonButton disabled={this.state.loading} onClick={this.handleAdd}> <IonSpinner
                            hidden={!this.state.loading}/> {lang?.l.main.ok()}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen>
                <IonList>
                    <IonListHeader>
                        <IonLabel>{lang?.l.main.add_payment_help_message()}</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonSelect label={lang?.l.main.add_payment_method()} value={this.state.method} onIonChange={this.handleChange}
                                   interface="popover" placeholder={lang?.l.main.add_device_payment_method()}>
                            <IonSelectOption value="creditCard">{lang?.l.main.add_payment_credit_card()}</IonSelectOption>
                            <IonSelectOption value="bankAccount">{lang?.l.main.add_payment_sepa()}</IonSelectOption>
                            {/*<IonSelectOption value="googlePay" disabled={true}>Google Pay</IonSelectOption>*/}
                            {/*<IonSelectOption value="applePay" disabled={true}>Apple Pay</IonSelectOption>*/}
                        </IonSelect>
                    </IonItem>

                    {this.state.method === "creditCard" && this.renderCreditCard()}

                    {this.state.method === "bankAccount" && this.renderSEPA()}

                    {this.state.error && <IonLabel color={"danger"}>{this.state.error.message}</IonLabel>}
                </IonList>
            </IonContent>
        </IonModal>;
    }
}

export default inject("store", "lang")(AddPaymentMethod);
