import {AutomationCondition} from "./API";

const Lexer = require('lex');

export type Resolver = (name:string) => {value: string, title: string} | undefined;

export default class Expressions {

    private lexer:any;
    private parser:any;
    private resolver:Resolver;

    constructor(resolver:Resolver) {
        this.resolver = resolver;

        this.lexer = new Lexer();

        this.lexer.addRule(/\s+/, function () {
            /* skip whitespace */
        });

        this.lexer.addRule(/sqrt|pow/, function (lexeme:any) {
            return lexeme; // functions
        });
        this.lexer.addRule(/\$\{.*?\}/, function (lexeme:any) {
            return lexeme; // symbols
        });

        this.lexer.addRule(/[(+\-*/)^,]/, function (lexeme:any) {
            return lexeme; // punctuation (i.e. "(", "+", "-", "*", "/", ")")
        });
        this.lexer.addRule(/[0-9.]+/, function (lexeme:any) {
            return lexeme; // numbers
        });

        this.lexer.addRule(/-[0-9.]+/, function (lexeme:any) {
            return lexeme; // numbers
        });


        var potencial = {
            precedence: 3,
            associativity: "left"
        };
        var factor = {
            precedence: 2,
            associativity: "left"
        };

        var term = {
            precedence: 1,
            associativity: "left"
        };

        this.parser = new Parser({
            "+": term,
            "-": term,
            "*": factor,
            "/": factor,
            "sqrt": potencial,
            "pow": potencial,
            "^": potencial,
        });
    }


    evaluate(condition: AutomationCondition, context: any) {

        let left = this.evalExpresion(condition.left, context);
        var right = condition.right; // this.getOperand(context, condition.right);
        switch (condition.op) {
            case '==':
                // eslint-disable-next-line eqeqeq
                return left == right;
            case '&&':
            case 'AND':
                return left && right;
            case '||':
            case 'OR':
                return left || right;
            case '>':
                return left > right;
            case '<':
                return left < right;
            case '>=':
                return left >= right;
            case '<=':
                return left <= right;
            case '!=':
                return left !== right;
        }
        return false;
    }
    private parse(exp:string) {
        this.lexer.setInput(exp);
        let tokens = [], token;
        while ((token = this.lexer.lex())) tokens.push(token);
        return this.parser.parse(tokens);
    }

    evalExpresion(exp:string, context: any) {
        let items = this.parse(exp);
        var stack:any[] = [];
        var funcions = {
            "+": function(a:any, b:any) { return a + b},
            "-": function(a:any, b:any) { return a - b},
            "*": function(a:any, b:any) { return a * b},
            "/": function(a:any, b:any) { return a / b},
            "^": function(a:any, b:any) { return a ^ b},
            "sqrt": function(a:any) { return Math.sqrt(a)},
            "pow": function(a:any, b:any) { return a ^ b},
        };
        items.forEach((c:string) => {
            switch (c) {
                case "+":
                case "-":
                case "*":
                case "^":
                case "/":
                    var b = stack.pop();
                    var a = stack.pop();
                    if(b === undefined || a === undefined) {
                        throw new Error("Invalid operation. No data on stack");
                    }
                    let val = funcions[c](a, b);
                    stack.push(val);
                    break;

                default:
                    let res1:any = false;
                    if((res1 = c.match(/\$\{([a-zA-Z0-9_[\]]+)\}/))) {
                        // eslint-disable-next-line no-eval
                        stack.push(parseFloat(eval("context." + res1[1])));
                    } else {
                        stack.push(parseFloat(c))
                    }
            }
        });


        var output = stack.pop();
        if(stack.length !== 0) {
            throw new Error("Invalid operation, stack not empty. " + output);
        }
        return output;
    }


    parseExpresionToString(exp:string, stack2 : any[] = []) {
        let items = this.parse(exp);
        var stack:any[] = [];

        // var operator = {
        //     "+": "Suma",
        //     "-": "Resta",
        //     "*": "Multiplica",
        //     "/": "Divide",
        //     "^": "Potencia",
        //     "sqrt": "Raiz",
        //     "pow": "Potencia",
        // };
        var funcions = {
            "+": function(a:any, b:any) { return a + b},
            "-": function(a:any, b:any) { return a - b},
            "*": function(a:any, b:any) { return a * b},
            "/": function(a:any, b:any) { return a / b},
            "^": function(a:any, b:any) { return Math.pow(a, b)},
            "sqrt": function(a:any) { return Math.sqrt(a)},
            "pow": function(a:any, b:any) { return Math.pow(a, b)},
        };

        items.forEach((c:string) => {
            switch (c) {
                case "+":
                case "-":
                case "*":
                case "^":
                case "/":
                    var b = stack.pop();
                    var a = stack.pop();
                    if(b === undefined || a === undefined) {
                        throw new Error("Invalid operation. No data on stack");
                    }

                    let na = stack2.pop();
                    let nb = stack2.pop();
                    let val = funcions[c](na, nb);

                    stack.push("(" + a + " " + c + " " + b + ")");
                    stack2.push(val);
                    break;

                default:
                    if(c.charAt(0) === "$") {
                        c = c.substr(2, c.length - 3);
                        let op = this.resolver(c);
                        stack2.push(parseFloat(op!.value));
                        c = op!.title
                    } else {

                        stack2.push(parseFloat(c))
                    }
                    stack.push(c);
            }
        });


        var output = stack.pop();
        /* eslint-disable @typescript-eslint/no-unused-vars */
        // var result = stack2.pop();
        if(stack.length !== 0) {
            throw new Error("Invalid operation, stack not empty. " + output);
        }
        return output; //  + " # " + result;

    }
}


export class Parser {
    table: any;

    constructor(table: any) {
        this.table = table;
    }
    parse(input:string) {
        var length = input.length,
            table = this.table,
            output = [],
            stack = [],
            index = 0;

        while (index < length) {
            let token = input[index++];

            switch (token) {
                case "(":
                    stack.unshift(token);
                    break;
                case ")":
                    while (stack.length) {
                        token = stack.shift()!;
                        if (token === "(") break;
                        else output.push(token);
                    }

                    if (token !== "(")
                        throw new Error("Mismatched parentheses.");
                    break;
                default:
                    if (table.hasOwnProperty(token)) {
                        while (stack.length) {
                            var punctuator = stack[0];

                            if (punctuator === "(") break;

                            var operator = table[token],
                                precedence = operator.precedence,
                                antecedence = table[punctuator].precedence;

                            if (!(precedence <= antecedence && !(precedence === antecedence &&
                                operator.associativity === "right"))) break;
                            else output.push(stack.shift());
                        }

                        stack.unshift(token);
                    } else output.push(token);
            }
        }

        while (stack.length) {
            var token = stack.shift();
            if (token !== "(") output.push(token);
            else throw new Error("Mismatched parentheses.");
        }

        return output;
    }
}
