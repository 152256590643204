import Registers from "../Registers";
import {MemoryAddress} from "./MemoryMapper";

export default class PeriodicTimer extends Registers {

    public timers : {
        enable: boolean,
        weekDay: number,
        hourMinute: number,
        interval: number,
        action: number,
    }[] = [];

    // constructor(rtu: ModbusRTUClient) {
    //     super(rtu);
    //     //
    //     // this.defineAddress(2, {
    //     //     Basic_Settings: Model.value(),
    //     //     Reporting_removal_settings: Model.value(),
    //     //     Reporting_DIN_settings: Model.value(),
    //     //     Reporting_AIN_settings: Model.value(),
    //     //     Reporting_DOUT_settings: Model.value(),
    //     //     ALM_SMS_removal_settings: Model.value(),
    //     //     ALM_SMS_DIN_settings: Model.value(),
    //     //     ALM_SMS_AIN_settings: Model.value(),
    //     //     ALM_SMS_DOUT_settings: Model.value(),
    //     //     Equipment_ID: Model.value(),
    //     //     Cloth_removal: Model.value(),
    //     //     Cloth_DISARM_TIME: Model.value(),
    //     //     _empty: Model.values(3),
    //     //     Equipment_DES: Model.byteString(60),
    //     // })
    //     // this.defineAddress(1496,{
    //     //     version_model: Model.byteString(5),
    //     // });
    //     // this.defineAddress(80,{
    //     //     HostTEL_Number: Model.byteString(21),
    //     // });
    // }

    async Read(): Promise<boolean> {
        let read = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.PERIODIC_TIMER), 40);
        if(read) {
            this.timers = [];
            for (let i = 0; i < 10; i++) {
                let item = {
                    enable: (read[i * 4] >> 8) === 1,
                    weekDay: (read[i * 4]),
                    hourMinute: read[i * 4 + 1],
                    interval: read[i * 4 + 2],
                    action: read[i * 4 + 3],
                }
                this.timers.push(item);
            }
            console.log(this.timers);
            return true;
        }
        return false;
    }

    async Write(): Promise<any> {
        let stack = [];

        for (let i = 0; i < 10; i++) {
            let item = this.timers[i];
            if(!item) continue;
            if(item.enable) {
                let week = 1 << 8 + (item.weekDay);
                stack.push(week);
                stack.push(item.hourMinute);
                stack.push(item.interval);
                stack.push(item.action);
            } else {
                stack.push(0);
                stack.push(0);
                stack.push(0);
                stack.push(0);
            }
        }

        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.PERIODIC_TIMER), stack);
        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.PERIODIC_TIMER_SAVE), [1]);

        await this.writeMultipleRegisters(79, [1]);

        return false;
    }
}
