import React, { RefObject } from "react";
import { inject } from "mobx-react";
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSegment,
    IonSegmentButton,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {
    arrowBack,
    document,
    flash,
    refreshOutline,
    saveOutline,
} from "ionicons/icons";
import { RouteComponentProps } from "react-router";
import { Store } from "../../service/Store";
import { Device, LogAlarm } from "../../service/API";
import Lang from "../../service/Lang";
import { Swiper as SwiperClass } from 'swiper/types'
import { Swiper, SwiperSlide } from 'swiper/react';
import DeviceLogActions from "../../components/DeviceLogActions";
import DeviceLogAlarms from "../../components/DeviceLogAlarms";


import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/keyboard';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import 'swiper/css/zoom';


export interface DeviceLogProp extends RouteComponentProps<{ id: string }> {
    store?: Store;
    lang?: Lang;
}
export enum DeviceOutputExecuteType {
    EXECUTE_TYPE_MANUAL,
    EXECUTE_TYPE_SCHEDULER,
    EXECUTE_TYPE_EVENT,
    EXECUTE_ONLINE,
    EXECUTE_OFFLINE,
    EXECUTE_AC_STATUS,
    EXECUTE_ERROR,
    EXECUTE_AUTOMATION,
    EXECUTE_TIMER,
    EXECUTE_ALARM,
}
export interface DeviceLogState {
    device?: Device,

    download?: boolean,
    detailsModal?: boolean,
    loading?: boolean,
    loadingDevice?: boolean,
    error?: string,
    segment: string,


}

@inject("store", "lang")
export default class DeviceLog extends React.Component<DeviceLogProp, DeviceLogState> {
    state: DeviceLogState = {
        segment: 'actions'

    }
    counter = 0;
    deviceLogActions: RefObject<DeviceLogActions>;
    deviceLogAlarms: RefObject<DeviceLogAlarms>;
    // slides: RefObject<any>;
    dateStart: RefObject<any>;
    dateEnd: RefObject<any>;
    search: RefObject<any>;
    segment: RefObject<any>;

    swiper?: SwiperClass;

    constructor(props: DeviceLogProp, context: any) {
        super(props, context);
        this.dateStart = React.createRef();
        this.dateEnd = React.createRef();
        this.search = React.createRef();
        this.segment = React.createRef();
        this.deviceLogActions = React.createRef();
        this.deviceLogAlarms = React.createRef();
        // this.slides = React.createRef();
    }

    componentDidMount(): void {
        this.setState({

            download: false,
            loading: false,
            loadingDevice: false
        }, () => {

            this.loadDevice();
        });
        this.handleTab();
    }

    componentDidUpdate(prevProps: Readonly<DeviceLogProp>, prevState: Readonly<DeviceLogState>, snapshot?: any): void {
        if (this.props.match?.params.id !== prevProps.match?.params.id) {
            this.loadDevice();
        }
        if (this.props.location.search !== prevProps.location.search) {
            this.handleTab();
        }
    }

    loadDevice() {
        const { lang } = this.props;
        this.setState({ loadingDevice: true, error: undefined });
        const id = this.props.match?.params.id;
        return this.props.store?.api.deviceGet(parseInt(id!)).then(value => {
            if (value.success) {
                this.setState({ device: value.data });
                // this.loadData()?.then(() => {

                // })
                this.setState({ loadingDevice: false });

            } else {
                this.setState({ loadingDevice: false, error: lang?.l.main.log_load_error() })
            }
            return value;
        })
    }



    handleSegment = (ev: any) => {
        this.setState({ segment: ev.detail.value });
        this.props.history.replace("?tab=" + ev.detail.value);
    };

    handleTab() {
        let tab = this.props.location.search;
        let i = tab.indexOf("tab=");
        if (i >= 0) {
            tab = tab.substring(i + 4);
            this.setSegment(tab);

        }
    }

    handleReload = () => {
        this.loadDevice()?.then(() => {
            if (this.state.segment === 'actions') {
                this.deviceLogActions.current!.handleReload();
            }
            if (this.state.segment === 'events') {
                this.deviceLogAlarms.current!.handleReload();
            }
        })
    }

    setSegment(p: string) {

        switch (p) {
            case 'actions':
                //this.slides.current?.slideTo(0);
                this.swiper!.slideTo(0);
                break;
            case 'events':
                //this.slides.current?.slideTo(1);
                this.swiper!.slideTo(1);
                break;
        }
        this.setState({ segment: p });
    }

    setSwiperInstance(swiper: SwiperClass) {
        this.swiper = swiper;
    }

    handleSlide = async (ev: any) => {
        let res = this.swiper!.activeIndex;
        // alert(res);
        switch (res) {
            case 0:
                this.setState({ segment: 'actions' });
                break;
            case 1:
                this.setState({ segment: 'events' });
                break;
        }
        this.props.history.replace("?tab=" + this.state.segment);
    };

    handleDownload = (duration: boolean) => {

        this.setState({ loading: true });
        if (this.swiper?.activeIndex === 0) {
            if (!duration) {

                this.deviceLogActions.current!.handleDownload(this.dateStart.current!.value!, this.dateEnd.current!.value)?.then(() => {
                    this.setState({ loading: false, download: false });
                })
            } else {

                this.deviceLogActions.current!.handleDownloadDuration(this.dateStart.current!.value!, this.dateEnd.current!.value)?.then(() => {
                    this.setState({ loading: false, download: false });
                })
            }
        }

    }

    handleSeenAlarm = (item: LogAlarm) => {
        if (this.state.device) {
            let found = this.state.device.alarms.find(a => a.id === item.id);
            if (found) {
                let i = this.state.device.alarms.indexOf(found);
                this.state.device.alarms.splice(i, 1);
                this.setState({});
            }
        }
    }

    handleSeenAlarms = () => {
        this.state.device!.alarms = [];
        this.setState({});

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        //const id = this.props.match?.params.id;
        if (!this.state) return "";
        const { lang } = this.props;
        const { id } = this.props.match!.params;

        // let filtered = this.state.items!.filter(this.filter);
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonButton routerDirection={"back"} routerLink={"/device/" + id + "/details"} ><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>{lang?.l.main.tab_log()}</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={this.handleReload}>
                            <IonIcon slot="icon-only" icon={refreshOutline} />
                        </IonButton>
                        <IonButton onClick={() => this.setState({ download: true })}>
                            <IonIcon slot="icon-only" icon={saveOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonToolbar color={"primary"}>
                    <IonSegment value={this.state.segment} onIonChange={this.handleSegment} ref={this.segment}>
                        <IonSegmentButton value="actions">
                            <IonIcon icon={document} />
                            <IonLabel>Registro</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="events">
                            <IonIcon icon={flash}>

                            </IonIcon>
                            <IonLabel>
                                Eventos
                                {this.state.device && this.state.device.alarms.length > 0 && <IonBadge color={"danger"}>{this.state.device.alarms.length}+</IonBadge>}


                            </IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </IonToolbar>
            </IonHeader>
            <IonContent>

                <Swiper onSwiper={(swiper: SwiperClass) => this.setSwiperInstance(swiper)}
                    onSlideChangeTransitionEnd={this.handleSlide}>

                    <SwiperSlide>
                        <div className={"slide"} style={{ width: '100%' }}>
                            <DeviceLogActions ref={this.deviceLogActions} {...this.props} device={this.state.device} />
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className={"slide"} style={{ width: '100%' }}>
                            <DeviceLogAlarms onSeenAlarms={this.handleSeenAlarms} onSeenAlarm={this.handleSeenAlarm} ref={this.deviceLogAlarms} {...this.props} device={this.state.device} />
                        </div>
                    </SwiperSlide>
                </Swiper>
            </IonContent>

            {/* <Swiper onSwiper={(swiper) => this.setSwiperInstance(swiper)}
                speed={400} noSwiping={true} initialSlide={0} onSlideChangeTransitionEnd={this.handleSlide}>
                <SwiperSlide>
                </SwiperSlide>
                <SwiperSlide>
                    <div className={"slide"}>
                    </div>
                </SwiperSlide>

            </Swiper> */}
            <IonModal isOpen={this.state.download! ? true : false} onDidDismiss={() => this.setState({ download: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{lang?.l.main.status_download_registers()}</IonTitle>
                        <IonButtons slot={"end"}>
                            <IonButton onClick={() => this.setState({ download: false })}>{lang?.l.main.close()}</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent >

                    <IonList>
                        <IonItem>
                            <IonInput label={lang?.l.main.start_date()} labelPlacement="stacked" ref={this.dateStart} defaultValue={"2020-01-01"} type={"date"} lang="es-ES"></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonInput label={lang?.l.main.end_date()} labelPlacement="stacked" ref={this.dateEnd} defaultValue={"2020-03-01"} type={"date"} lang="es-ES"></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonSpinner hidden={!this.state.loading} slot={"start"} />
                        </IonItem>

                        <IonItem button detail={false} color={"primary"} onClick={() => this.handleDownload(false)}>
                            <IonIcon icon={saveOutline} slot={"start"} />
                            <IonText slot={"start"}>{lang?.l.main.download()} Listado</IonText>
                        </IonItem>
                        <IonItem button detail={false} color={"primary"} onClick={() => this.handleDownload(true)}>
                            <IonIcon icon={saveOutline} slot={"start"} />
                            <IonText slot={"start"}>{lang?.l.main.download()} Duracion</IonText>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonModal>
            <IonLoading
                isOpen={this.state.loadingDevice!}
                message={lang?.l.main.loading()}
            />
        </IonPage>
    }
}
