import React from "react";
import {IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonTitle, IonToolbar, IonSearchbar, IonList, IonItem, IonLabel, IonSelect, IonSelectOption, IonInput, IonText, IonItemDivider} from "@ionic/react";
import {arrowBack, informationCircleOutline} from "ionicons/icons";
import Utils from "../service/Utils";
import { Store } from "../service/Store";
import { inject } from "mobx-react";

export type ReferenceIndex = {
    title: string,
    file: string,

};
export type ReferenceBook = {
    meta?: {
        title: string,
        addrFormat?: ReferenceAddrFormat[],
    },

    registers: ReferenceRegisters[],
    formats: ReferenceFormat[],

};
export type ReferenceAddrFormat = {
    offset: number, 
    size: number,

    base?: number,
    map?: {[key: string]: number},
}

export type ReferenceRegisters = {
    title: string,

    addr: string,
    writeFormat: number,
    readFormat: number,

    category? : true,
    registers? : ReferenceRegisters[],

}
export type ReferenceFormat = {
    id: number,
    title: string,
    variables: ReferenceFormatVariable[]
}
export type ReferenceFormatVariable = {
    title: string,
    name: string,
    type: 'integer' | 'decimal' | 'map' | 'function',
    bits: number,
    offset: number,
    map?: {[key: number]: string},

    fromDecimal?: string,
    toDecimal?:string,
}

export type ReferenceInterpretation = {
    name: string,
    value: any,
    format: ReferenceFormatVariable,
}


export interface ModbusExplorerProps {
    store?: Store,
    onClose: () => void,
    onAddrSelected: (addr:number) => void,
}

export type ModbusExplorerState = {
    loading: boolean,
    referenceIndex?: ReferenceIndex[],
    referenceData?: ReferenceBook,
    referenceCurrent?: ReferenceRegisters[],
    referenceParent?: ReferenceRegisters[],
    referenceFilter: string,
    referenceSelected?: ReferenceRegisters & any,

    referenceToInterpretateWrite: number,
    referenceToInterpretateRead: number,
}

@inject("store", "lang")
export default class ModbusExplorer extends React.Component<ModbusExplorerProps, ModbusExplorerState> {
    state : ModbusExplorerState  = {
        loading: false,
        referenceIndex: undefined,
        referenceData: undefined,
        referenceCurrent: undefined,
        referenceParent: undefined,
        referenceFilter: '',
        referenceSelected: undefined,
        referenceToInterpretateWrite: 0,
        referenceToInterpretateRead: 0,
    }

    interpretationWrite: ReferenceInterpretation[] = [];
    interpretationRead: ReferenceInterpretation[] = [];

    allRegisters:any[] = [];

    
    static reference = [
        {
            model: 'LS100',
            vendor: 'VMC',
            web: 'https://www.vmc.es/es/s100',
            manual: 'https://www.vmc.es/es/system/files/archivos/manual_s100_0.4-22kw_esp.pdf',
            registers: [
                {
                    title: 'Modelo de variador',
                    addr: 0x0000,
                    readOnly: true,
                    map: {
                        6:  'S100'
                    }
                },
                {
                    title: 'Capacidad del variador',
                    addr: 0x0001,
                    readOnly: true,
                    map: {
                        0:  '0,75kW',
                        1:  '1,5kW',
                        2:  '2,2kW',
                        3:  '3,7kW',
                        4:  '5,5kW',
                        5:  '7,5kW',
                        6:  '11kW',
                        7:  '15kW',
                        8:  '18,5kW',
                        9:  '22kW',
                        256:  '0,4kW',
                        257:  '1,1kW',
                        258:  '3,0kW',
                        259:  '4,0kW',
                    }

                },
                {
                    title: 'Tensión de entrada del variador',
                    addr: 0x0002,
                    readOnly: true,
                    map: {
                        0:  '220V',
                        1:  '440V',
                    }

                },
                {
                    title: 'Version',
                    addr: 0x0003,
                    readOnly: true,
                    map: {
                        read: function(v:number) {
                            return v * 0.01;
                        },
                        write: function(v:number) {
                            return v / 0.01;
                        }
                    }
                },
                {
                    title: 'Frecuencia comando',
                    addr: 0x0005,
                    readOnly: false,
                    map: {
                        read: function(v:number) {
                            return v * 0.01;
                        },
                        write: function(v:number) {
                            return v / 0.01;
                        }
                    }
                },
                {
                    title: 'Comando de operación',
                    addr: 0x0006,
                    readOnly: false,
                    format: 'bit',
                    map: {
                        read: function(v:number) {
                            return {

                            }
                        },
                        write: function(data:any) {

                            return ;
                        }
                    }
                }

            ]
        }
    ]


    componentDidMount() {
        this.loadReference();
    }

    loadReference() {
        this.setState({loading: true});
        this.props.store?.api.getFile("schemes/index.json").then(response => response.json()).then(json => {

            this.setState({ loading: false, referenceIndex: json });
        }).catch(() => {
            this.setState({ loading: false });

        });
        
    }


    handleLoadReference = (file: string) => (ev:any) => {

        this.setState({loading: true});
        this.props.store?.api.getFile(file).then((response:any) => response.json()).then((json:ReferenceBook) => {
            this.allRegisters = [];
            this.allRegisters = this.expandRegisters(json.registers);
            this.setState({ loading: false, referenceData: json, referenceCurrent: json.registers });
        });
    }

    handleGoReferenceIndex = (ev:any) => {
        this.setState({ loading: false, referenceCurrent: undefined, referenceFilter: '' });
    }

    handleReferenceInterpetate = (format: ReferenceFormat, mode: 'read' | 'write') => (ev:any) => {
        let value = parseInt(ev.detail.value) || 0;
        let result = Utils.interpretateFormat(value, format);
        if(mode === 'read') {
            this.interpretationRead = result;
            this.setState({referenceToInterpretateRead: value});
        } else if(mode === 'write') {
            this.interpretationWrite = result;
            this.setState({referenceToInterpretateWrite: value});
        }

        //this.interpretation = Utils.interpretateFormat(value, this.state.referenceSelected.formatJson);
        //this.setState({referenceToInterpretate: value});
    }

    expandRegisters(list:any[], output:any[] = []) {
        for (let i = 0; i < list.length; i++) {
            const element = list[i];
            if(element.category) {
                this.expandRegisters(element.registers, output);
            } else {
                output.push(element);
            }
        }
        return output;
    }
    


    handleReferenceClick = (el:ReferenceRegisters | ReferenceRegisters[]) => (ev:any) => {
        let register = el as ReferenceRegisters;
        if(register.category) {
            this.setState({referenceCurrent: register.registers, referenceParent: this.state.referenceCurrent});
        } else if(register.addr) {
            let reference = this.state.referenceData;
            let addr = register.addr;
            let addrDec = Utils.convertReference(addr, reference);
            let writeFormat = Utils.getFormatById(register.writeFormat, reference?.formats!);
            let readFormat = Utils.getFormatById(register.readFormat, reference?.formats!);
            this.interpretationWrite = Utils.interpretateFormat(this.state.referenceToInterpretateWrite, writeFormat);
            this.interpretationRead = Utils.interpretateFormat(this.state.referenceToInterpretateRead, readFormat);
            this.setState({referenceSelected: {...el, addrDec: addrDec, writeFormatJson: writeFormat, readFormatJson: readFormat}});
        } else {
            this.setState({referenceCurrent: el as ReferenceRegisters[], referenceParent: undefined});
        }

    }

    filterReference = (ref:any) => {
        if(this.state.referenceFilter) {
            return ref.title.toLowerCase().indexOf(this.state.referenceFilter.toLowerCase()) >= 0;
        }
        return true;
    }


    handleInterpretationClick = (ev:any) => {

    }
    handleChangeInterpretation(item: ReferenceInterpretation, value: any, mode: string) {
        item.value = value;
        if(mode === 'write') {
            this.setState({referenceToInterpretateWrite: Utils.formatFromInterpretation(this.interpretationWrite)});
        } else if(mode === 'read') {
            this.setState({referenceToInterpretateRead: Utils.formatFromInterpretation(this.interpretationRead)});

        }
    }
    renderInterpretation(interpretation: ReferenceInterpretation[], mode: 'read' | 'write') {
        if(!interpretation) return;
        
        return <>
            {interpretation.map((v, k) => {
                let label1 = v.format.title + " " + v.name;
                return  <IonItem>
                    {v.format.type === 'map' && <IonSelect label={label1} value={v.value} onIonChange={(ev:any) => this.handleChangeInterpretation(v, ev.detail.value, mode)}>
                        {Object.keys(v.format.map!).map((v2, k2) => {
                            let k3 = v.format.map![v2 as any];
                            return <IonSelectOption value={k3}>{k3}</IonSelectOption>
                        })}
                        </IonSelect>}
                        {v.format.type === 'integer' && <IonInput onIonInput={(ev:any) => this.handleChangeInterpretation(v, ev.detail.value, mode)} value={v.value}></IonInput>}
                        {v.format.type === 'function' && <IonInput onIonInput={(ev:any) => this.handleChangeInterpretation(v, ev.detail.value, mode)} value={v.value}></IonInput>}
                </IonItem>
            })}
            {/* <IonItem>
                <IonLabel>Formato</IonLabel>
                <IonText class="font-monospace">{JSON.stringify(interpretation, null, 4)}</IonText>
            </IonItem> */}
        </>

    }
    renderReference() {
        if(this.state.referenceSelected) {
            return <IonList>
            <IonItem button onClick={() => this.setState({referenceSelected: undefined})}>
                <IonLabel>&lt; Volver</IonLabel>
            </IonItem>
            <IonItem>
                <IonLabel>Título</IonLabel>
                <IonText>{this.state.referenceSelected.title}</IonText>
            </IonItem>
            <IonItem>
                <IonLabel>Detalles</IonLabel>
                <IonText>{this.state.referenceSelected.details}</IonText>
            </IonItem>
                <IonItemDivider>
                    <IonLabel>
                    Dirección
                    </IonLabel>
                </IonItemDivider>
            <IonItem>
                <IonLabel>Dirección</IonLabel>
                <IonText>{this.state.referenceSelected.addr}</IonText>
            </IonItem>
                <IonItem button onClick={() => this.props.onAddrSelected(this.state.referenceSelected.addrDec)}>
                    <IonLabel>Dirección Decimal</IonLabel>
                    <IonText>{this.state.referenceSelected.addrDec}</IonText>
                </IonItem>
                <IonItemDivider>
                    <IonLabel>
                    Formato Escritura
                    </IonLabel>
                </IonItemDivider>
                <IonItem>
                    <IonLabel>Formato</IonLabel>
                    <IonText>{this.state.referenceSelected.writeFormat} - {this.state.referenceSelected.writeFormatJson && this.state.referenceSelected.writeFormatJson.title}</IonText>
                </IonItem>
                <IonItem>
                    <IonLabel>Interpretar Valor</IonLabel>
                    <IonInput value={this.state.referenceToInterpretateWrite} onIonInput={this.handleReferenceInterpetate(this.state.referenceSelected.writeFormatJson, 'write')}></IonInput>
                </IonItem>

                {this.renderInterpretation(this.interpretationWrite, 'write')}
                <IonItemDivider>
                    <IonLabel>
                    Formato Lectura
                    </IonLabel>
                </IonItemDivider>
                <IonItem>
                    <IonLabel>Formato</IonLabel>
                    <IonText>{this.state.referenceSelected.readFormat} - {this.state.referenceSelected.readFormatJson && this.state.referenceSelected.readFormatJson.title}</IonText>
                </IonItem>
                <IonItem>
                    <IonLabel>Interpretar Valor</IonLabel>
                    <IonInput value={this.state.referenceToInterpretateRead} onIonInput={this.handleReferenceInterpetate(this.state.referenceSelected.readFormatJson, 'read')}></IonInput>
                </IonItem>

                {this.renderInterpretation(this.interpretationRead, 'read')}
            </IonList>

        } else if(!this.state.referenceCurrent) {
            return <>
                <IonList>
                    
                    {this.state.referenceIndex && this.state.referenceIndex.map((v:any, k:number) => {
                        return <IonItem onClick={this.handleLoadReference(v.file)} detail={true} button>
                            <IonLabel>{v.title}</IonLabel>
                        </IonItem>
                    })}
                </IonList>
            </>

        } else if(this.state.referenceFilter) {
            return <>
            <IonList>
                {this.allRegisters && this.allRegisters.filter(this.filterReference).map((v:any, k:number) => {
                    return <IonItem onClick={this.handleReferenceClick(v)}>
                        <IonLabel>{v.title}</IonLabel>
                    </IonItem>
                })}
            </IonList>
        </>
        } else {
            return <>
                <IonList>
                    {this.state.referenceParent && <IonItem button onClick={this.handleReferenceClick(this.state.referenceParent)}>
                            <IonLabel>Volver</IonLabel>
                        </IonItem>}
                    {!this.state.referenceParent && <IonItem button onClick={this.handleGoReferenceIndex}>
                            <IonLabel>Índice</IonLabel>
                        </IonItem>}
                    {this.state.referenceCurrent && this.state.referenceCurrent.map((v:any, k:number) => {
                        return <IonItem onClick={this.handleReferenceClick(v)} detail={true} button detailIcon={v.category ? undefined : informationCircleOutline}>
                            <IonLabel>{v.title}</IonLabel>
                        </IonItem>
                    })}
                </IonList>
            </>
        }
    }
    
    render() {
        return <>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonButton onClick={this.props.onClose}><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>Referencia Modbus</IonTitle>
                </IonToolbar>
                <IonToolbar color={"primary"}>
                    <IonSearchbar value={this.state.referenceFilter} onIonChange={e => this.setState({referenceFilter: e.detail.value!})}></IonSearchbar>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                {this.renderReference()}
            </IonContent>
        </>;
    }
}
