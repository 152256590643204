import React from 'react';
// import Icon from '../Icon';

import Vector from './Vector';
import {IonIcon} from "@ionic/react";
import {square} from "ionicons/icons";

export default class Rect extends Vector {
  static meta = {
    icon: <IonIcon icon={square} />,
    initial: {
      x: 10,
      y: 20,
      width: 5,
      height: 5,
      stroke: "",
      strokeWidth: 0,
      fill: "blue",
      radius: 0,
      blendMode: "normal",
      rotate: 0
    }
  };

  render() {
    let {object} = this.props;
    return (
      <rect style={this.getStyle()}
         {...this.getObjectAttributes()}
         rx={object.radius}
         width={object.width}
         height={object.height} />
    );
  }
}
