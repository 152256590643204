import React from "react";
import { Deploy } from "cordova-plugin-ionic";
import { IonAlert, IonLoading } from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import Utils from "../service/Utils";

@inject("store", "lang")
export default class Updater extends React.Component<any, any> {

    state = { updating: false, progress: 0, current: 0, update: false };
    async performManualUpdate() {
        this.setState({ updating: true });
        await Deploy.downloadUpdate((progress) => {
            Utils.log(progress);
            this.setState({ updating: true, progress: progress, current: 1 });
        });

        this.setState({ updating: true, progress: 100, current: 1 });
        // await Deploy.extractUpdate((progress) => {
        //     Utils.log(progress);
        //     this.setState({updating: true, progress: progress, current: 2});
        // });
        // this.setState({updating: true, progress: 100, current: 2});

        // await Deploy.reloadApp();
    }

    async downloadUpdate() {
        await Deploy.downloadUpdate((progress) => {
            Utils.log(progress);
            //this.setState({updating: true, progress: progress, current: 1});
        })
    }
    async applyUpdate() {
        await Deploy.extractUpdate((progress) => {
            Utils.log(progress);
            this.setState({ updating: true, progress: progress, current: 2 });
        });
        this.setState({ updating: true, progress: 100, current: 2 });
        await Deploy.reloadApp();

    }

    performUpdate() {
        let store: Store = this.props.store;
        let enable = true;
        if(enable) {
            // Deploy.deleteVersionById();
            Deploy.configure({ channel: store.updates, host: 'https://admin.cloudrtu.com' }).then(async () => {
                const update = await Deploy.checkForUpdate();
                if (update.available) {
                    await this.downloadUpdate();
                    this.setState({ update: true });
                }

                let versions = Deploy.getAvailableVersions();
                console.log("Versions ", versions);
            }).catch(e => {
                console.error(e);
                // alert("Actualizacion: " + e);
            });
        } else {
            Deploy.getCurrentVersion().then(ver => {
                if(ver) {
                    Deploy.deleteVersionById(ver.versionId).then(res => {
                        if(res) {
                            Deploy.reloadApp();
                        }
                    })
                }
            })
        }
    }
    componentDidMount() {
        // this.performUpdate();
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <>
            <IonAlert
                isOpen={this.state.update}
                onDidDismiss={() => this.setState({ update: false })}
                header={'Actualización'}
                subHeader={'Nueva versión de la aplicación'}
                message={'Hay una actualización disponible de la aplicación. ¿Deseas actualizar la aplicación ahora?'}
                buttons={[
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {

                        }
                    },
                    {
                        text: 'Actualizar',
                        handler: () => {
                            this.applyUpdate();
                        }
                    }
                ]}
            />
            <IonLoading
                isOpen={this.state.updating}
                message={'Actualizando... ' + this.state.progress + " % " + this.state.current + " de 2"}
            />

        </>

    }
}
