import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar,
    IonAlert,
    IonText,
    IonTextarea,
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device, DeviceTraffic } from "../../service/API";
import Utils, { DeviceFlags } from "../../service/Utils";
import Confirm from "../../components/Confirm";
import { RouteComponentProps } from "react-router-dom";
import Gallery from "../../components/Gallery";
import { FeatureGroup, LayersControl, MapContainer, Marker, TileLayer, Tooltip } from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
import { Map } from "leaflet";

const { BaseLayer } = LayersControl

export interface DeviceSettingsGeneralProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}
export type DeviceSettingsGeneralState = {
    loading?: boolean,
    showTransfer: boolean,
    device?: Device,
    warning: boolean,
    pickImage: boolean,
    trafficModal: boolean,
    mapModal: boolean,
    traffic?: DeviceTraffic,
    expert: boolean,
    ipModal: boolean,
    ips: any[],
    deviceStatus: any,
}

@inject("store", "lang")
export default class DeviceSettingsGeneral extends React.Component<DeviceSettingsGeneralProps, DeviceSettingsGeneralState> {
    private confirm: React.RefObject<Confirm>;

    state: DeviceSettingsGeneralState = { deviceStatus: undefined, ipModal: false, ips: [], expert: false, warning: false, pickImage: false, showTransfer: false, trafficModal: false, mapModal: false };
    handleShowPosition = (ev: any) => {

        this.setState({ mapModal: true });
        setTimeout(() => {

            window.dispatchEvent(new Event('resize'));
        }, 1000);
    };


    constructor(props: DeviceSettingsGeneralProps, context: any) {
        super(props, context);
        this.confirm = React.createRef();
    }

    componentDidMount(): void {
        this.loadDevice();

    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsGeneralProps>, prevState: Readonly<DeviceSettingsGeneralState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    loadDevice() {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if (value.success) {
                this.setState({ device: value.data });
            }
            this.props.store?.api.deviceTraffic(Number(id)).then(value1 => {
                if (value1.success) {
                    this.setState({ traffic: value1.data });
                }

            }).finally(() => this.setState({ loading: false }));

            this.props.store?.getUser(false).then(user => {
                if (user) {
                    this.setState({ expert: true })
                }
            })
        });
    }

    handleChange = (flag: number) => (ev: any) => {
        this.setState({ device: { ...this.state.device!, flags: Utils.setFlag(this.state.device?.flags!, flag, ev.currentTarget.checked) } });
    };

    handleChangeName = (ev: any) => {
        this.setState({ device: { ...this.state.device!, name: ev.detail.value } });
    };

    handleGalleryChange = (image: string) => {
        this.setState({ device: { ...this.state.device!, image: image } });

    }
    handleChangeDataInterval = (ev: any) => {
        this.setState({ device: { ...this.state.device!, dataInterval: ev.detail.value } });
    };

    handleChangeObservations = (ev: any) => {
        this.setState({ device: { ...this.state.device!, observations: ev.detail.value } });
    };



    handleMapClick = (ev: any) => {
        this.setState({ device: { ...this.state.device!, lat: Number(ev.latlng.lat), lng: Number(ev.latlng.lng) } })
    }

    handleIPClick = (ev: any) => {
        this.setState({ loading: true });
        this.props.store?.api.getDeviceSettingsIp(this.state.device!.id!).then(res => {
            if (res.success) {

                this.setState({ ipModal: true, ips: res.data.ips });
            }
            this.setState({ loading: false });
        });
    }

    handleIPChange = (ev: any) => {
        this.setState({ loading: true });

        this.props.store?.api.setDeviceSettingsIp(this.state.device?.id!, this.state.ips).then(res => {
            this.setState({ loading: false });
            if (res.success) {
                this.setState({ ipModal: false });
            }
        })

    }

    handleSave = (ev: any) => {
        this.setState({ loading: true });
        const { name, flags, image, lat, lng, dataInterval, observations } = this.state.device!;
        this.props.store?.api.devicePost(this.state.device!.id, { name, flags, image, lat, lng, dataInterval, observations })
            .then(value => {
                this.props.history.goBack();
            })
            .finally(() => this.setState({ loading: false }));
    };

    handleRemove = (ev: any) => {
        this.confirm.current!.confirm('Eliminar dispositivo', 'Esta acción es irreversible', '¿Realmente deseas eliminar el dispositivo?').then(value => {
            if (value) {
                let id = this.props.match?.params.id!;

                this.props.store?.api.deleteDevice(id).then(value1 => {
                    if (value1.success) {
                        this.props.history.replace({ pathname: '/devices' });
                    }
                });

            }
        })
    }

    handleSetMap = (map: Map | null) => {
        if(map == null) {
            return;
        }

        map.addEventListener('click', this.handleMapClick);
    } 

    private transferDevice(email: string) {
        this.setState({ loading: true });

        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceTransfer(parseInt(id), { recipient: email }).then(value => {
            if (value.success) {
                this.props.history.replace({ pathname: '/devices' });
            }
        });
    }

    handleTransfer = (ev: any) => {
        this.setState({ showTransfer: true });
    }
    handleDisconnect = (ev: any) => {
        this.setState({ loading: true });

        this.props.store?.api.deviceDisconnect(this.props.match.params.id).then(() => {
            this.setState({ loading: false });

        })
    }

    handleGetStatus = (ev: any) => {
        this.setState({ loading: true });
        let id = parseInt(this.props.match.params.id);
        this.props.store?.api.deviceInputData(id).then((data) => {
            this.setState({ loading: false, deviceStatus: data.data });
            

        })
        
    }
    handleArm = (arm: boolean) => (ev: any) => {
        this.setState({ loading: true });

        this.props.store?.api.deviceArmDissarm(this.props.match.params.id, arm).then(() => {
            this.setState({ loading: false });
        })
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;
        // let enabled = Utils.haveFlag(this.state.device?.flags!, DeviceFlags.NOTIFY_EVENTS);
        const position = {
            lat: 37.78517179004318,
            lng: -3.604101093980808
        };
        const zoom = 9;
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>General</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonInput label="Nombre Dispositivo" value={this.state.device?.name} onIonInput={this.handleChangeName}></IonInput>
                    </IonItem>
                    <IonItem button detail={true} onClick={() => this.setState({ pickImage: true })}>
                        <IonLabel>Imagen de Portada</IonLabel>
                    </IonItem>
                    <IonItem button detail={true} onClick={this.handleShowPosition}>
                        <IonLabel>Ubicación</IonLabel>
                    </IonItem>
                    <IonItem button detail={true} onClick={() => this.setState({ trafficModal: true })}>
                        <IonLabel>Tráfico de datos</IonLabel>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Identificador</IonLabel>
                        <IonText>{this.state.device?.imei}</IonText>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Propietario" readonly value={this.state.device?.user?.fullname}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonLabel>Almacenar eventos de conexión/desconexión al servidor</IonLabel>
                        <IonToggle checked={Utils.haveFlag(this.state.device?.flags!, DeviceFlags.LOG_CONNECTION_EVENTS)}
                            onIonChange={this.handleChange(DeviceFlags.LOG_CONNECTION_EVENTS)} slot="end" />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Almacenar fallos de alimentación en el registro</IonLabel>
                        <IonToggle checked={Utils.haveFlag(this.state.device?.flags!, DeviceFlags.LOG_AC_EVENTS)}
                            onIonChange={this.handleChange(DeviceFlags.LOG_AC_EVENTS)} slot="end" />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Almacenar errores en el registro</IonLabel>
                        <IonToggle checked={Utils.haveFlag(this.state.device?.flags!, DeviceFlags.LOG_ERRORS)}
                            onIonChange={this.handleChange(DeviceFlags.LOG_ERRORS)} slot="end" />
                    </IonItem>
                    <IonItem>
                        <IonLabel>Almacenar eventos de alarmas en el registro</IonLabel>
                        <IonToggle checked={Utils.haveFlag(this.state.device?.flags!, DeviceFlags.LOG_ALARMS)}
                            onIonChange={this.handleChange(DeviceFlags.LOG_ALARMS)} slot="end" />
                    </IonItem>


                    <IonItem button detail routerLink={"/device/" + id + "/settings/notifications"}>
                        <IonLabel>Notificaciones</IonLabel>
                    </IonItem>

                    <IonItem>
                        <IonTextarea placeholder="Observaciones, Notas instalación" autoGrow={true}
 value={this.state.device?.observations} onIonChange={this.handleChangeObservations} ></IonTextarea>
                        
                    </IonItem>
                    {this.state.expert && <>

                        <IonItemDivider>
                            <IonLabel>
                                Ajustes expertos
                            </IonLabel>
                        </IonItemDivider>
                        <IonItem detail={true} onClick={this.handleIPClick}>
                            <IonLabel>IP servidor</IonLabel>

                        </IonItem>

                    </>}
                    <IonItem color={"warning"} button detail={false} onClick={() => this.setState({ warning: !this.state.warning })}>
                        Mostrar Ajustes Peligrosos
                    </IonItem>
                    <div hidden={!this.state.warning}>

                        <IonItemDivider>
                            <IonLabel>
                                Estos ajustes pueden afectar directamente al funcionamiento del dispositivo
                            </IonLabel>
                        </IonItemDivider>
                        {/*<IonItem button detail={false}>*/}
                        {/*    Transferir dispositivo a otra cuenta*/}
                        {/*</IonItem>*/}
                        <IonItem button detail={false} onClick={this.handleArm(true)}>
                            Armar
                        </IonItem>
                        <IonItem button detail={false} onClick={this.handleArm(false)}>
                            Desarmar
                        </IonItem>
                        <IonItem>
                            <IonInput label="Intervalo de Datos (minutos)" value={this.state.device?.dataInterval} onIonInput={this.handleChangeDataInterval}></IonInput>
                        </IonItem>
                        <IonItem button detail={false} onClick={this.handleGetStatus}>
                            Obtener estado
                        </IonItem>
                        {this.state.deviceStatus && <IonItem>
                            <IonTextarea rows={5} value={ JSON.stringify(this.state.deviceStatus, null, 4)}></IonTextarea>
                        </IonItem>}
                        <IonItem button detail={false} onClick={this.handleDisconnect}>
                            Desconectar del servidor
                        </IonItem>
                        <IonItem color={"warning"} button detail={false} onClick={this.handleTransfer}>
                            Transferir dispositivo a otro usuario
                        </IonItem>
                        <IonItem color={"danger"} button detail={false} onClick={this.handleRemove}>
                            Eliminar dispositivo de mi cuenta
                        </IonItem>
                    </div>

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
                <Confirm ref={this.confirm} />

                <IonAlert
                    isOpen={this.state.showTransfer}
                    onDidDismiss={() => this.setState({ showTransfer: false })}
                    cssClass='my-custom-class'
                    header={'Transferir este dispositivo'}
                    message={'Al transferir el dispositivo perderás el control sobre él. Introduzca el email del usuario que va a recibir este dispositivo.'}
                    inputs={[
                        {
                            label: 'Email Usuario a transferir',
                            name: 'email',
                            type: 'email',
                            placeholder: 'Email usuario a transferir'
                        },
                    ]}
                    buttons={[
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                            cssClass: 'secondary',
                            handler: () => {
                                console.log('Confirm Cancel');
                            }
                        },
                        {
                            text: 'Ok',
                            handler: (data: any) => {
                                console.log('Confirm Ok');
                                console.log(data);
                                this.transferDevice(data.email);
                            }
                        }
                    ]}
                />
            </IonContent>


            <IonModal isOpen={this.state && this.state.pickImage!} onDidDismiss={() => this.setState({ pickImage: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Seleccionar Icono</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ pickImage: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <Gallery selection={this.state.device?.image} onChange={this.handleGalleryChange} />
                </IonContent>

            </IonModal>

            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state && this.state.trafficModal!} onDidDismiss={() => this.setState({ trafficModal: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Detalles del tráfico de datos</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ trafficModal: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonList>
                        {!!this.state.traffic || <IonItem color="warning">
                            <IonLabel>No se ha enviado ninguna información</IonLabel>
                        </IonItem>}
                        <IonItem>
                            <IonLabel>Fecha</IonLabel>
                            <IonLabel>Enviado</IonLabel>
                            <IonLabel>Recibido</IonLabel>
                        </IonItem>
                        {this.state.traffic && this.state.traffic.map((value, index) => {
                            return <IonItem key={index}>
                                <IonLabel>{value.date}</IonLabel>
                                <IonLabel>{value.sent}</IonLabel>
                                <IonLabel>{value.recv}</IonLabel>
                            </IonItem>
                        })}
                    </IonList>
                </IonContent>

            </IonModal>

            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state && this.state.ipModal!} onDidDismiss={() => this.setState({ ipModal: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>IP del dispositivo</IonTitle>
                        <IonButtons slot={"secondary"}>
                            <IonButton onClick={() => this.setState({ ipModal: false })}>Cancelar</IonButton>
                        </IonButtons>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={this.handleIPChange}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonList>
                        {this.state.ips.map((value, index) => {
                            return <IonItem>
                                <IonInput value={value.ip} onIonInput={(ev: any) => value.ip = ev.detail.value}></IonInput>
                                <IonInput value={value.port} onIonInput={(ev: any) => value.port = ev.detail.value}></IonInput>
                            </IonItem>
                        })}
                    </IonList>
                </IonContent>

            </IonModal>
            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state.mapModal! ? true : false} onDidDismiss={() => this.setState({ mapModal: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Establecer ubicación</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ mapModal: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>
                    <div style={{ width: '100%', height: '100%' }}>
                        <MapContainer
                            ref={this.handleSetMap}
                            center={position} zoom={zoom}
                            style={{ width: '100%', height: '100%' }}>
                            <LayersControl position="topright">

                                <BaseLayer checked name="Hibrido">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'hybrid'} />
                                </BaseLayer>
                                <BaseLayer name="Terreno">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'terrain'} />
                                </BaseLayer>
                                <BaseLayer name="Satélite">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'satellite'} />
                                </BaseLayer>
                                <BaseLayer name="Carretera">
                                    <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'roadmap'} />
                                </BaseLayer>
                                <BaseLayer name="OpenStreetMap.Mapnik">
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                    />
                                </BaseLayer>
                                <BaseLayer name="OpenStreetMap.BlackAndWhite">
                                    <TileLayer
                                        attribution='&amp;copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                                        url="https://tiles.wmflabs.org/bw-mapnik/{z}/{x}/{y}.png"
                                    />
                                </BaseLayer>

                                <FeatureGroup>
                                    {this.state.device && <Marker position={{ lat: this.state.device?.lat, lng: this.state.device?.lng }}

                                    >
                                        <Tooltip permanent={true} direction={"top"}>{this.state.device.name}</Tooltip>
                                    </Marker>}
                                </FeatureGroup>

                            </LayersControl>
                        </MapContainer>
                    </div>
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
