import ReadCoilsRequestBody from "./ReadCoilsRequestBody";
import ReadHoldingRegistersRequestBody from "./ReadHoldingRegistersRequestBody";
import WriteMultipleRegistersRequestBody from "./WriteMultipleRegistersRequestBody";

export default class RequestFactory {
    /** Create a Modbus Request Body from a buffer object. Depending on the function code
     * in the buffer the request body could by any function codes request body.
     * @param {Buffer} buffer The buffer to be parsed.
     * @returns {ModbusRequestBody} The actual request body or null if there is not enough data in the buffer.
     */
    static fromBuffer (buffer:Buffer) {
        /* TODO:detect non modbus requests and return a InvalidProtocolRequest. Requests
         * of this kind should lead to disconnecting the client. This way we can make sure that
         * unintendet messages do not harm the server */
        try {
            const fc = buffer.readUInt8(0)

            if (fc === 0x01) {
                return ReadCoilsRequestBody.fromBuffer(buffer)
            }
            // if (fc === 0x02) {
            //     const ReadDiscreteInputsRequest = require('./read-discrete-inputs.js')
            //     return ReadDiscreteInputsRequest.fromBuffer(buffer)
            // }
            if (fc === 0x03) {
                return ReadHoldingRegistersRequestBody.fromBuffer(buffer)
            }
            // if (fc === 0x04) {
            //     const ReadInputRegistersRequest = require('./read-input-registers.js')
            //     return ReadInputRegistersRequest.fromBuffer(buffer)
            // }
            // if (fc === 0x05) {
            //     const WriteSingleCoilRequest = require('./write-single-coil.js')
            //     return WriteSingleCoilRequest.fromBuffer(buffer)
            // }
            // if (fc === 0x06) {
            //     const WriteSingleRegisterRequest = require('./write-single-register.js')
            //     return WriteSingleRegisterRequest.fromBuffer(buffer)
            // }
            // if (fc === 0x0f) {
            //     const WriteMultipleCoilsResponse = require('./write-multiple-coils.js')
            //     return WriteMultipleCoilsResponse.fromBuffer(buffer)
            // }
            if (fc === 0x10) {
                return WriteMultipleRegistersRequestBody.fromBuffer(buffer)
            }
            //
            // if (fc <= 0x2B) {
            //     debug('Illegal Function (fc %d)', fc)
            //     const ExceptionRequest = require('./exception.js')
            //     return new ExceptionRequest(fc, 0x01)
            // }
        } catch (e) {
            // debug('Exception while reading function code', e)

        }
        return null;
    }
}
