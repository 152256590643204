import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonPage,
    IonRow,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {bugOutline, logoChrome} from "ionicons/icons";
import Utils from "../service/Utils";
import { inject } from "mobx-react";

@inject("lang")
export default class DownloadApp extends React.Component<any, any> {

    state = {installable: false}
    deferredPrompt:any = null;
    componentDidMount(): void {
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            // Update UI to notify the user they can add to home screen
            // addBtn.style.display = 'block';
            this.setState({installable: true});
        });
    }

    handleInstall = (ev:any) => {

        this.deferredPrompt.prompt();
        this.deferredPrompt.userChoice.then((choiceResult:any) => {
            if (choiceResult.outcome === 'accepted') {
                Utils.log('User accepted the A2HS prompt');
            } else {
                Utils.log('User dismissed the A2HS prompt');
            }
            this.deferredPrompt = null;
        });

    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {lang} = this.props;
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/" />
                    </IonButtons>
                    <IonTitle>{lang?.l.main.download_app_title()}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={"ion-padding"}>
                <IonGrid>

                    <IonRow>
                        <IonCol>
                            <IonCard style={{padding: 16, background: '#ffffff'}}>
                                <IonCardHeader>
                                    <IonCardTitle><img alt={"CloudRTU"} src={"/assets/icon/icon.png"} width={25} height={25}/> {lang?.l.main.download_get_app()}</IonCardTitle>
                                    <IonCardSubtitle>{lang?.l.main.download_app_platforms()}</IonCardSubtitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    <IonGrid>
                                        <IonRow>
                                            <IonCol sizeLg={"4"} sizeXl={"2"} sizeMd={"6"} sizeSm={"12"} sizeXs={"12"}>
                                                <a href='https://play.google.com/store/apps/details?id=com.mmunoz.cloudrtu&hl=es'><img alt='Disponible en Google Play' style={{width: '100%'}}  src={"/assets/google-play-badge.png"} /></a>
                                            </IonCol>
                                            <IonCol sizeXl={"2"} sizeLg={"4"} sizeMd={"6"} sizeSm={"12"} sizeXs={"12"}>
                                                <a href={'https://apps.apple.com/us/app/cloudrtu/id1505511843'}><img src={'/assets/Download_on_the_App_Store_Badge_ES_RGB_blk_100217.svg'} style={{width: '100%'}} alt={"Disponible en App Store"} /></a>
                                            </IonCol>
                                        </IonRow>
                                    </IonGrid>



                                    <div><small>Google Play y el logotipo de Google Play son marcas comerciales de Google LLC.</small></div>
                                    <div><small>App Store® are trademarks of Apple Inc., registered in the U.S. and other countries.</small></div>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>
                        <IonCol>
                            <IonCard style={{padding: 16, background: '#ffffff'}}>
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon icon={logoChrome}/> {lang?.l.main.download_pc_mac()}</IonCardTitle>
                                    <IonCardSubtitle>{lang?.l.main.download_chrome()}</IonCardSubtitle>
                                </IonCardHeader>

                                <IonCardContent>
                                    {this.state.installable &&
                                    <IonButton expand={"block"} onClick={this.handleInstall}>{lang?.l.main.download_install()}</IonButton>}
                                    {this.state.installable ||
                                    <IonText>{lang?.l.main.download_incompatible()}</IonText>}
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                    <IonRow>

                        <IonCol>
                            <IonCard style={{padding: 16, background: '#ffffff'}}>
                                <IonCardHeader>
                                    <IonCardTitle><IonIcon icon={bugOutline}/> Beta</IonCardTitle>
                                    <IonCardSubtitle>{lang?.l.main.download_beta()}</IonCardSubtitle>
                                </IonCardHeader>

                                <IonCardContent>

                                    <IonButton href={"https://play.google.com/apps/testing/com.mmunoz.cloudrtu"} expand={"block"}>{lang?.l.main.download_beta_android()}</IonButton>
                                    <IonButton href={"https://testflight.apple.com/join/blIXJEM4"} expand={"block"}>{lang?.l.main.download_beta_ios()}</IonButton>
                                </IonCardContent>
                            </IonCard>
                        </IonCol>
                    </IonRow>
                </IonGrid>

            </IonContent>
        </IonPage>
    }

}
