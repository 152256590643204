import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRow,
    IonSearchbar, IonSelect, IonSelectOption,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { HelpContentForm, HelpContentList, HelpContentScreenshot, HelpContentText, HelpTopic } from "../service/API";
import { withRouter } from "react-router";
import { cameraOutline, helpOutline, linkOutline } from "ionicons/icons";
import { Link } from "react-router-dom";
import Utils from "../service/Utils";

export interface HelpState {
    loading: boolean;
    help?: HelpTopic[];
    topic?: HelpTopic;

    supportPin?: number,
    supportPinLoading?: boolean,
}

@inject("store", "lang")
class Help extends React.Component<any, HelpState> {
    componentDidMount(): void {
        this.loadHelp();
    }

    loadHelp() {
        this.setState({ loading: true });
        let store: Store = this.props.store;

        store.api.help().then(value => {
            this.setState({ loading: false });
            if (value.success) {
                this.setState({ help: value.data }, () => {

                    this.addLocalTopics();
                    this.checkTopic();
                });
            }
        });
    }

    addLocalTopics() {
        let store: Store = this.props.store;
        let topics = [{
            title: "Información general de la aplicación",
            name: "info",
            keywords: [],
            content: [
                "Versión: " + Utils.getVersion(),
                "Servicio prestado desde: " + store.api.url,
                "Canal de actualizaciones: " + store.updates + " [:widget=updater]"
            ]
        },
        {
            title: "Créditos",
            name: "credits",
            keywords: [],
            content: [
                "[:page=/licenses]Licéncias y créditos[:page]"
            ]
        }]

        if (!this.state.help) {
            this.setState({ help: topics });
        } else {
            this.setState({ help: [...this.state.help, ...topics] });
        }

    }

    checkTopic() {
        if (this.props.match.params) {
            this.setTopic(this.props.match.params.topic);
        }
    }

    setTopic(topic: string) {
        for (let i = 0; i < this.state.help?.length!; i++) {
            if (this.state.help![i].name === topic) {
                this.setState({ topic: this.state.help![i] });
            }
        }
    }

    findTopicByName(topic: string) {
        for (let i = 0; i < this.state.help?.length!; i++) {
            if (this.state.help![i].name === topic) {
                return this.state.help![i];
            }
        }
    }

    findNextTopic(topic: HelpTopic) {
        for (let i = 0; i < this.state.help?.length! - 1; i++) {
            if (this.state.help![i] === topic) {
                return this.state.help![i + 1];
            }
        }
    }

    renderListTopic(id: string, name: string) {
        let item = this.findTopicByName(id);
        if (item?.section) {
            let item2 = this.findTopicByName(item?.section);
            name = item2?.title + " > " + name
        }
        return <IonItem routerLink={"/help/" + id}>
            <IonLabel>{name}</IonLabel>
        </IonItem>

    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<HelpState>, snapshot?: any): void {
        if (prevProps.match.params !== this.props.match.params) {
            this.checkTopic();
        }
        if (prevProps.match.params.topic && !this.props.match.params.topic) {
            this.setState({ topic: undefined });
        }
    }

    tokenizer(content: string | any[], re: RegExp, callback: (match: any) => any, result: any[] = []) {
        if (typeof content === 'string') {
            if (re.test(content)) {
                re.lastIndex = 0;
                let match, tmp = 0;
                while ((match = re.exec(content)) != null) {
                    result.push(content.substring(tmp, match.index));
                    result.push(callback(match));
                    tmp = re.lastIndex;
                }
                result.push(content.substring(tmp));
                return result;
            } else {
                result.push(content);
            }
        } else if (content instanceof Array) {
            for (let i = 0; i < content.length; i++) {
                this.tokenizer(content[i], re, callback, result);
            }
        } else {
            result.push(content);
        }
        return result;
    }

    renderText(content: string) {
        let result = this.tokenizer(content, /(\[:topic=([a-z-]+)])/ig, match1 => {
            let help = this.findTopicByName(match1[2]);
            if (help) {
                return <Link to={"/help/" + help.name}>{help.title}</Link>;
            } else {
                return `(Topic not found: ${match1[2]})`;
            }
        });
        result = this.tokenizer(result, /(\[:icon=([a-z-]+)])/ig, match1 => {
            let icon = match1[2];
            if (icon === "camera") {
                return <IonIcon icon={cameraOutline} />
            }
            return <IonIcon icon={helpOutline} />
        });
        result = this.tokenizer(result, /(\[:widget=([a-z-]+)])/ig, match1 => {
            let widget = match1[2];
            if (widget === "updater") {
                return <IonItem><IonSelect value={this.props.store.updates} onIonChange={(ev: any) => {
                    this.props.store.setUpdater(ev.detail.value);
                    this.setTopic('info')
                }} >
                    <IonSelectOption value={"Production"}>Por defecto</IonSelectOption>
                    <IonSelectOption value={"Master"}>Pruebas</IonSelectOption>

                </IonSelect></IonItem>
            }
            return ""
        });
        result = this.tokenizer(result, /(\[:page=([a-z-/]+)](.*?)\[:page])/ig, match1 => {
            let href = match1[2];
            let text = match1[3];
            return <Link to={href}>{text}</Link>
        });
        result = this.tokenizer(result, /(\*([a-zA-Z0-9 ]+)\*)/ig, match1 => {
            return <b>{match1[2]}</b>
        });
        var url_pattern = /(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:(?!10(?:\.\d{1,3}){3})(?!127(?:\.\d{1,3}){3})(?!169\.254(?:\.\d{1,3}){2})(?!192\.168(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)(?:\.(?:[a-z\x{00a1}\-\x{ffff}0-9]+-?)*[a-z\x{00a1}\-\x{ffff}0-9]+)*(?:\.(?:[a-z\x{00a1}\-\x{ffff}]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?/ig;

        result = this.tokenizer(result, url_pattern, match1 => {
            let url = match1[0];
            let protocol_pattern = /^(?:(?:https?|ftp):\/\/)/i;
            let href = protocol_pattern.test(url) ? url : 'http://' + url;
            return <a href={href}>{href}</a>
        });
        result = this.tokenizer(result, /(\n)/ig, match1 => {
            return <br />
        });
        return result;
    }

    renderList(list: HelpContentList) {
        return <IonCard>
            <IonCardHeader>
                <IonCardTitle>{list.name}</IonCardTitle>
                <IonCardSubtitle>{this.renderText(list.description)}</IonCardSubtitle>
            </IonCardHeader>
            <IonCardContent>
                <IonGrid>
                    {list.list.map((value, index) => {
                        return <IonRow>
                            <IonCol size={"4"}>{value.name}</IonCol>
                            <IonCol>{this.renderText(value.description)}</IonCol>
                        </IonRow>
                    })}
                </IonGrid>
            </IonCardContent>
        </IonCard>
    }

    renderScreenshot(image: HelpContentScreenshot) {
        return <IonCard>
            <IonCardHeader>
                <IonCardTitle>{image.title}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
                <IonGrid>
                    <IonRow>
                        <IonCol size={"9"}>
                            <img src={image.src.desktop} alt={image.title} /><br />Version
                            Escritorio
                        </IonCol>
                        <IonCol size={"3"}>

                            <img src={image.src.mobile} alt={image.title} /><br />Version Móvil
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonCardContent>
        </IonCard>
    }

    renderForm(form: HelpContentForm) {
        return <>
            <IonCard>
                <IonCardHeader>
                    <IonCardTitle>{form.name}</IonCardTitle>
                    <IonCardSubtitle>{this.renderText(form.description)}</IonCardSubtitle>
                </IonCardHeader>
                <IonCardContent>
                    <IonGrid>
                        <IonRow>
                            <IonCol size={"3"}><IonLabel><b>Campo</b></IonLabel></IonCol>
                            <IonCol size={"3"}>
                                <IonLabel><b>Tipo</b></IonLabel>
                            </IonCol>
                            <IonCol><IonLabel><b>Descripción</b></IonLabel></IonCol>
                        </IonRow>
                        {form.fields.map((value, index) => {
                            return <IonRow>
                                <IonCol size={"3"}>{value.name}</IonCol>
                                <IonCol size={"3"}>
                                    {value.type === 'text' && "Texto"}
                                    {value.type === 'button' && "Botón"}
                                    {value.type === 'checkbox' && "Casilla de verificación"}
                                    {value.type === 'password' && "Contraseña"}
                                    {value.type === 'select' && "Selección"}

                                    {value.list && value.list.map((value1, index1) => {
                                        return <p>&bull; {this.renderText(value1)}</p>
                                    })}
                                </IonCol>
                                <IonCol>
                                    {this.renderText(value.description)}

                                </IonCol>
                            </IonRow>
                        })}
                    </IonGrid>
                </IonCardContent>
            </IonCard>


        </>
    }

    renderTopic(topic: HelpTopic) {
        let next = this.findNextTopic(topic);
        return <IonContent fullscreen scrollEvents={true}>
            <IonList lines={"none"}>
                <IonListHeader>
                    <IonLabel><a href={"/help/" + topic.name}><IonIcon icon={linkOutline} /></a> {topic.title}
                    </IonLabel>
                </IonListHeader>
                {topic.content.map((value, index) => {
                    if (typeof value === 'string') {
                        return <IonItem>
                            <IonText>{this.renderText(value)}</IonText>
                        </IonItem>
                    } else {
                        if ((value as HelpContentText).type === 'text') {
                            return <IonItem>
                                <IonText>{this.renderText((value as HelpContentText).text)}</IonText>
                            </IonItem>
                        }
                        if ((value as HelpContentForm).type === 'form') {
                            return this.renderForm(value as HelpContentForm);
                        }
                        if ((value as HelpContentScreenshot).type === 'screenshot') {
                            return this.renderScreenshot(value as HelpContentScreenshot);
                        }
                        if ((value as HelpContentList).type === 'list') {
                            return this.renderList(value as HelpContentList);
                        }
                    }
                    return "";
                })}
                {topic.url && <IonItem button detail routerLink={topic.url}>
                    <IonLabel>Visitar {topic.title}</IonLabel>
                </IonItem>}
                {next && <IonItem button detail routerLink={"/help/" + next.name}>
                    <IonLabel>Siguiente: {next.title}</IonLabel>
                </IonItem>}
            </IonList>
        </IonContent>
    }


    handleGetPin = () => {
        let store: Store = this.props.store;
        if (store.remoteSupport) {
            this.setState({ supportPinLoading: true })
            store.remoteSupport.start().then(pin => {
                this.setState({ supportPin: pin, supportPinLoading: false });
            }).catch(err => {
                alert("Lamentamos informarle que no se ha podido establecer una conexión. Motivo: " + err.toString())
            });
        } else {
            alert("Error de aplicación. El Servicio no está activo");
        }

    }

    renderSupportCard() {
        return <IonCard>
            <IonCardHeader>
                PIN de Soporte
            </IonCardHeader>
            <IonCardContent>

                {this.state.supportPin && <h1>{this.state.supportPin.toString().split("").join(" ")}</h1>}
                {!!(this.state.supportPin) || <IonButton onClick={this.handleGetPin}>Obtener PIN</IonButton>}
                {this.state.supportPinLoading && <p><IonSpinner /> Espere un momento...</p>}

                <br />
                <p>Comparta este código para obtener soporte oficial de CloudRTU. Mantenga la aplicación abierta.</p>
            </IonCardContent>
        </IonCard>
    }

    renderMain() {
        // let stote = this.props.store as Store;
        let store: Store = this.props.store;
        return <IonContent className={"ion-padding"}>

            {/* TODO: Soporte remoto */}
            {store.login.isLogin() && this.renderSupportCard()}
            <IonCard>
                <IonCardHeader>
                    ¿En qué podemos ayudarle?
                </IonCardHeader>
                <IonCardContent>

                    <IonList>
                        {this.state.help && this.state.help.map((value, key: number) => this.renderListTopic(value.name, value.title))}
                        <IonItem>
                            <Link to={"https://api.cloudrtu.com/viewHelp"} target={"_blank"}>Ver en modo página única.</Link>
                        </IonItem>
                    </IonList>
                </IonCardContent>
            </IonCard>
        </IonContent>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        if (!this.state) return "";
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>

                    <IonButtons slot="start">
                        <IonBackButton defaultHref={this.state.topic ? "/help" : "/"} />
                    </IonButtons>
                    <IonTitle>Centro de Ayuda</IonTitle>
                </IonToolbar>
                <IonToolbar color={"primary"}>
                    <IonSearchbar placeholder={"Buscar"}></IonSearchbar>

                </IonToolbar>
            </IonHeader>
            {!!this.state.topic || this.renderMain()}
            {!!this.state.topic && this.renderTopic(this.state.topic)}

            <IonSpinner hidden={!this.state.loading} />
        </IonPage>
    }
}

export default withRouter(Help);
