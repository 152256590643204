import { IonBadge, IonButton, IonButtons, IonCol, IonContent, IonDatetime, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonRow, IonSelect, IonSelectOption, IonSpinner, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import moment from "moment-timezone";
import React, { RefObject } from "react";
import { match } from "react-router";
import Lang from "../../service/Lang";
import { Store } from "../../service/Store";
import * as H from "history";
import { inject } from "mobx-react";
import Utils from "../../service/Utils";
import { APIBase, Device, ReportType, ReportTypeList } from "../../service/API";
import CSV from "../../service/CSV";
import { DeviceInputs } from "../../service/API";

export interface DeviceGraphInputTimeTableProps {
    store?: Store,
    lang?: Lang,
    location?: H.Location,
    history?: H.History,

    match?: match<{ id: string, input?: string }>,
}
export interface DeviceGraphInputTimeTableState {
    dateInputStart?: string;
    dateInputEnd?: string;
    device?: Device;
    input?: DeviceInputs;
    loading: boolean;
    // timetable ? : any;
    // total?: any;
    reportName: string,
    report?: ReportType,


}
@inject("store", "lang")
export default class DeviceGraphInputTimeTable extends React.Component<DeviceGraphInputTimeTableProps, DeviceGraphInputTimeTableState> {

    dateStart: RefObject<any> = React.createRef();
    dateEnd: RefObject<any> = React.createRef();
    itemList: RefObject<any> = React.createRef();
    itemsPerPage = 100;

    state = {
        loading: false,
        reportName: 'list',
        dateInputStart: moment().format("YYYY-MM-DD"),
        dateInputEnd: moment().format("YYYY-MM-DD"),
    } as DeviceGraphInputTimeTableState;

    private handleChange = (key: string) => (ev: any) => {
        this.setState({ ...this.state, [key]: ev.detail.value });
    }

    componentDidMount(): void {
        this.loadDevice();
    }

    componentDidUpdate(prevProps: Readonly<DeviceGraphInputTimeTableProps>, prevState: Readonly<DeviceGraphInputTimeTableState>, snapshot?: any): void {
        if (prevProps.match?.params.id !== this.props.match?.params.id) {
            this.loadDevice();
        } else if (prevProps.match?.params.input !== this.props.match?.params.input) {
            this.loadDevice();
        }
    }

    loadDevice() {
        let id = this.props.match?.params.id!;
        let input_id = parseInt(this.props.match?.params.input!);
        this.setState({ loading: true })
        this.props.store?.api.deviceGet(parseInt(id), 'input').then((value: APIBase<Device>) => {
            this.setState({ device: value.data, loading: false });
            if (this.props.match?.params.input) {
                value.data.inputGroups.forEach(a => {
                    let find = a.inputs.find(b => b.id === input_id);
                    if (find) {
                        this.setState({ input: find });
                    }
                })
            }

            // if (value.success) {
            //     let id = parseInt(this.props.match?.params.input!);
            //     this.props.store?.subscribeToDevice(id);
            //     for (let i = 0; i < value.data.inputGroups.length; i++) {
            //         for (let j = 0; j < value.data.inputGroups[i].inputs.length; j++) {
            //             let input = value.data.inputGroups[i].inputs[j];
            //             if (input.id === id) {
            //                 this.setState({ input: input, inputGroup: value.data.inputGroups[i] });
            //             }
            //         }
            //     }
            // }
        });
    }

    handleLoad = (ev: any) => {
        let dateStart = (this.state.dateInputStart)!;
        let dateEnd = (this.state.dateInputEnd)!;
        let device_id = parseInt(this.props.match?.params.id!);
        if (this.state.input) {
            let input_id = (this.state.input.id);
            this.setState({ loading: true });
            this.props.store?.api.deviceInputReport(device_id, input_id, dateStart, dateEnd, this.state.reportName).then(res => {
                if (res.data) {
                    this.setState({ report: (res.data as any) });
                }
            }).finally(() => this.setState({ loading: false }));
    }
    }

    handleReportChange = (ev: any) => {
        this.setState({ reportName: ev.detail.value });
    }

    handleInputChange = (ev: any) => {
        this.setState({ input: ev.detail.value });
    }

    handleDownload = (ev: any) => {
        if (!this.state.report) {
            return;
        }
        if (!this.state.report.results) {
            return;
        }
        let { report } = this.state;
        let csv = new CSV();
        let keys = Object.keys(report.results[0]);
        csv.row(keys);
        report.results.forEach(value => {
            csv.row(Object.values(value).map((value2:any, index) => {
                if (keys[index] === 'time'||keys[index] === 'finish'||keys[index] === 'start') {
                    return moment(value2, 'X').format("YYYY-MM-DD HH:mm");
                }
                if (keys[index] === 'duration') {
                    return Utils.toTimeDuration(value2);
                }
                if (keys[index] === 'data') {
                    return this.displayData(value2);
                }
                return value2;
            }));
        });
        csv.download("CloudRTU_" + report.input.name + "_report.csv");
    }

    displayData(data: number) {
        if (this.state.report) {
            return Utils.inputDataToValue(data, this.state.report.input);
        }
        return data;
    }

    renderReportList(report: ReportTypeList) {
        return <IonList>

            <IonItem>
                <IonLabel>Estado</IonLabel>
                <IonLabel>Fecha</IonLabel>
                <IonLabel>Diff</IonLabel>
            </IonItem>
            {report.results.map(a => {
                return <IonRow>
                    <IonCol><IonBadge>{this.displayData(a.data)}</IonBadge></IonCol>
                    <IonCol>{moment(a.time, 'X').format("YYYY-MM-DD HH:mm")}</IonCol>
                    <IonCol>{Math.round(a.diff * 100) / 100}</IonCol>
                </IonRow>
            })}
        </IonList>

    }
    renderReportDaily(report: ReportType) {
        return <IonList>
            <IonItem>
                <IonLabel>Estado</IonLabel>
                <IonLabel>Fecha</IonLabel>
                <IonLabel>Duracion</IonLabel>
            </IonItem>
            {report.results.map(value => {
                return <IonRow>
                    <IonCol><IonBadge>{this.displayData(value.data)}</IonBadge></IonCol>
                    <IonCol>{value.day}</IonCol>
                    <IonCol>{Utils.toTimeDuration(value.duration)}</IonCol>
                </IonRow>
            })}
        </IonList>
    }
    renderReportTimetable(report: ReportType) {
        return <IonList>
            <IonItem>
                <IonLabel>Estado</IonLabel>
                <IonLabel>Inicio</IonLabel>
                <IonLabel>Fin</IonLabel>
                <IonLabel>Duracion</IonLabel>
            </IonItem>
            {report.results.map(value => {
                return <IonRow>
                    <IonCol><IonBadge>{this.displayData(value.data)}</IonBadge></IonCol>
                    <IonCol>{moment(value.start, 'X').format("YYYY-MM-DD HH:mm")}</IonCol>
                    <IonCol>{moment(value.finish, 'X').format("YYYY-MM-DD HH:mm")}</IonCol>
                    <IonCol>{Utils.toTimeDuration(moment(value.finish, 'X').diff(moment(value.start, 'X')) / 1000)}</IonCol>

                </IonRow>
            })}
        </IonList>
    }
    renderReportTotal(report: ReportType) {
        return <IonList>
            <IonItem>
                <IonLabel>Estado</IonLabel>
                <IonLabel>Duracion</IonLabel>
            </IonItem>
            {report.results.map(value => {
                return <IonRow>
                    <IonCol><IonBadge>{this.displayData(value.data)}</IonBadge></IonCol>
                    <IonCol>{Utils.toTimeDuration(value.total)}</IonCol>

                </IonRow>
            })}
        </IonList>
    }
    renderReport(report: ReportType) {
        switch (report.report) {
            case 'list':
                return this.renderReportList(report as ReportTypeList)
            case 'daily':
                return this.renderReportDaily(report as ReportType)
            case 'timetable':
                return this.renderReportTimetable(report as ReportType)
            case 'total':
                return this.renderReportTotal(report as ReportType)
        }

    }

    render(): React.ReactNode {
        const { lang } = this.props;
        let route = (this.props.match?.params.input) ? "/device/" + this.props.match?.params.id + "/status/input/" + this.props.match?.params.input : "/device/" + this.props.match?.params.id + "/status"

        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">

                        <IonButton routerDirection={"back"} routerLink={route}><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>Generar Informe detallado</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                <IonList>
                    <IonListHeader>
                        <IonLabel>{lang?.l.main.input_search_registers()}</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel position="stacked">Entrada</IonLabel>
                        <IonSelect interface="action-sheet" value={this.state.input} onIonChange={this.handleInputChange}>
                            {this.state.device && this.state.device.inputGroups.map(a => {
                                return a.inputs.map(input => {
                                    return <IonSelectOption value={input}>{a.name} - { input.name }</IonSelectOption>
                                })
                            })}
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">{lang?.l.main.start_date()}</IonLabel>
                        <IonDatetime firstDayOfWeek={1} presentation="date" value={this.state.dateInputStart} onIonChange={this.handleChange('dateInputStart')} lang="es-ES" />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">{lang?.l.main.end_date()}</IonLabel>
                        <IonDatetime firstDayOfWeek={1} presentation="date" value={this.state.dateInputEnd} onIonChange={this.handleChange('dateInputEnd')} lang="es-ES"  />
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Tipo de Informe</IonLabel>
                        <IonSelect value={this.state.reportName} onIonChange={this.handleReportChange}>
                            <IonSelectOption value={"list"}>Lista</IonSelectOption>
                            <IonSelectOption value={"daily"}>Diario</IonSelectOption>
                            <IonSelectOption value={"timetable"}>Periodos</IonSelectOption>
                            <IonSelectOption value={"total"}>Total</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonText>
                            Detalle de los informes:<br />
                            - Lista: Listado con la informacion de la entrada sin procesar.<br />
                            - Diario: Muestra diariamente el tiempo que ha transcurrido en el estado.<br />
                            - Periodos: Muestra el tiempo que ha permanecido en un estado indicando la hora de inicio y de fin.<br />
                            - Total: Sumatorio total del tiempo total que la entrada ha permanecido en un estado determinado.<br />

                        </IonText>
                    </IonItem>
                    <IonItem>
                        {this.state.loading && <IonSpinner />}
                        <IonButton onClick={this.handleLoad} expand={"block"}>{lang?.l.main.search()}</IonButton>
                        {this.state.report && <IonButton onClick={this.handleDownload} expand={"block"}>Descargar</IonButton>}
                    </IonItem>
                </IonList>
                {this.state.report && this.renderReport(this.state.report)}
                {/* {this.state.timetable && <IonList>
                    <IonItem>
                        <IonLabel>Estado</IonLabel>
                        <IonLabel>Día</IonLabel>
                        <IonLabel></IonLabel>
                        <IonLabel>Duracion</IonLabel>

                    </IonItem>
                    {this.renderTotalTable(this.state.total)}
                    <IonItem>
                        <IonLabel>Estado</IonLabel>
                        <IonLabel>Inicio</IonLabel>
                        <IonLabel>Fin</IonLabel>
                        <IonLabel>Duracion</IonLabel>
                    </IonItem>
                    {this.renderTimetable(this.state.timetable)}
                </IonList>} */}


                <IonLoading
                    hidden={!this.state.loading!}
                    message={lang?.l.main.loading()}
                />

            </IonContent>
        </IonPage>
    }

}