import ReadCoilsResponseBody from "./ReadCoilsResponseBody";
import ReadHoldingRegistersResponseBody from "./ReadHoldingRegistersResponseBody";
import WriteMultipleRegistersResponseBody from "./WriteMultipleRegistersResponseBody";
import ReadInputRegistersResponseBody from "./ReadInputRegistersResponseBody";
import ExceptionResponseBody from "./ExceptionResponseBody";

export default class ResponseFactory {
    static fromBuffer (buffer:Buffer) {
        try {
            const fc = buffer.readUInt8(0)

            // debug('fc', fc, 'payload', buffer)

            /* Exception Response */
            if (fc > 0x80) {
                return ExceptionResponseBody.fromBuffer(buffer)
            }

            /* Read Coils Response */
            if (fc === 0x01) {
                return ReadCoilsResponseBody.fromBuffer(buffer)
            }

            // /* Read Discrete Inputs Response */
            // if (fc === 0x02) {
            //     return ReadDiscreteInputsBody.fromBuffer(buffer)
            // }
            //
            /* Read Holding Registers Response */
            if (fc === 0x03) {
                return ReadHoldingRegistersResponseBody.fromBuffer(buffer)
            }
            //
            // /* Read Input Registers Response */
            if (fc === 0x04) {
                return ReadInputRegistersResponseBody.fromBuffer(buffer)
            }
            //
            // /* Write Single Coil Response */
            // if (fc === 0x05) {
            //     return WriteSingleCoilBody.fromBuffer(buffer)
            // }
            //
            // /* Write Single Register Response */
            // if (fc === 0x06) {
            //     return WriteSingleRegisterBody.fromBuffer(buffer)
            // }
            //
            // /* Write Multiple Coils Response */
            // if (fc === 0x0F) {
            //     return WriteMultipleCoilsBody.fromBuffer(buffer)
            // }
            //
            /* Write Multiple Registers Response */
            if (fc === 0x10) {
                return WriteMultipleRegistersResponseBody.fromBuffer(buffer)
            }

            return null
        } catch (e) {
            // debug('when NoSuchIndex Exception, the buffer does not contain a complete message')
            // debug(e)
            return null
        }
    }
}
