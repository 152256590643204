import React from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";

export default class TestPage extends React.Component<any, any> {


    // render() {
    //     return <div><HighchartsReact
    //         highcharts={Highcharts}
    //         options={{
    //             chart: {
    //                 scrollablePlotArea: {
    //                     minWidth: 10000,
    //                     scrollPositionX: 0
    //                 }
    //             },
    //             title: {
    //                 text: 'My chart'
    //             },
    //             series: [{
    //                 type: 'line',
    //                 data: [0.2, 0.8, 0.8, 0.8, 1, 1.3, 1.5, 2.9, 1.9, 2.6, 1.6, 3, 4, 3.6,
    //                     5.5, 6.2, 5.5, 4.5, 4, 3.1, 2.7, 4, 2.7, 2.3, 2.3, 4.1, 7.7, 7.1,
    //                     5.6, 6.1, 5.8, 8.6, 7.2, 9, 10.9, 11.5, 11.6, 11.1, 12, 12.3, 10.7,
    //                     9.4, 9.8, 9.6, 9.8, 9.5, 8.5, 7.4, 7.6]
    //             }]
    //         }}
    //     /></div>
    // }

}
