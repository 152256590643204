import { Position } from "@capacitor/geolocation";
import { API } from "./API";
import Geolocation from "./Geolocation";

export default class LocationService {
    inputs: number[] = [];
    
    

    private api: API;
    constructor(api: API) {
        this.api = api;
    }


    isEnabled(input: number) {
        return this.inputs.indexOf(input) >= 0;
    }
    

    private enableService() {
        Geolocation.watchPosition({}, this.handlePosition)

    }
    private disableService() {
        Geolocation.watchPosition({}, this.handlePosition)

    }

    private handlePosition = (position: Position | null, err?: any) => {
        if(position) {
            this.inputs.map(a => {
                return this.api.sendInputLatLng(String(a), {lat: position.coords.latitude, lng: position.coords.longitude});
            })
        }
    }
    setLocation(input: number, enable: boolean) {
        if(enable) {
            if(this.inputs.indexOf(input) === -1) {
                this.inputs.push(input);
                this.enableService();
            }
        } else {
            let item = this.inputs.indexOf(input);
            if(item >= 0) {
                this.inputs.splice(item, 1);
                if(this.inputs.length === 0) {
                    this.disableService();
                }
            }
        }
    }
}