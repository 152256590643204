import { IonButton, IonButtons, IonContent, IonDatetime, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonListHeader, IonLoading, IonPage, IonSpinner, IonText, IonTitle, IonToolbar } from "@ionic/react";
import { arrowBack } from "ionicons/icons";
import React, { RefObject } from "react";
import { match } from "react-router";
import { LogInput } from "../../service/API";
import Lang from "../../service/Lang";
import { Store } from "../../service/Store";
import * as H from "history";
import { inject } from "mobx-react";

export interface DeviceGraphInputSearchProps {
    store?: Store,
    lang?: Lang,
    location?: H.Location,
    history?: H.History,

    match?: match<{ id: string, input: string }>,
}
export interface DeviceGraphInputSearchState {
    dateInputStart?: string;
    loading: boolean;
    inputLog: LogInput[];
    page: number,
    selected: number,
    pageBottom: number,
    pageTop: number,
    scrollTo: number,
    unique: boolean,

}

@inject("store", "lang")
export default class DeviceGraphInputSearch extends React.Component<DeviceGraphInputSearchProps, DeviceGraphInputSearchState> {

    dateStart: RefObject<any> = React.createRef();
    dateEnd: RefObject<any> = React.createRef();
    itemList: RefObject<any> = React.createRef();
    itemsPerPage = 100;

    state = { scrollTo: 0, selected: 0, loading: false, page: 0, inputLog: [], pageBottom: 0, pageTop: 0, unique: true, } as DeviceGraphInputSearchState;

    private handleChange = (key: string) => (ev: any) => {
        this.setState({ ...this.state, [key]: ev.detail.value });
    }

    componentDidMount(): void {
        this.handleClickInputLog(null);
    }
    // private loadInputLog(): Promise<LogInput[]> {
    //     return new Promise(resolve => {
    //         this.setState({ loading: true });
    //         let input = parseInt(this.props.match?.params.input!);
    //         let device_id = parseInt(this.props.match?.params.id!);
    //         // let iindex = this.state.iindex as number;
    //         let dateStart = (this.state.dateInputStart)!;
    //         let dateEnd = (this.state.dateInputEnd)!;
    //         // let unique = this.state.unique!

    //         this.props.store?.api.deviceInputTimeline(device_id, input, dateStart, dateEnd, true).then(value => {

    //             if (value.success) {
    //                 this.setState({ inputLog: value.data.log }, () => {
    //                     this.setState({ loading: false });
    //                 });
    //             } else {
    //                 this.setState({ loading: false });
    //             }
    //             resolve(value.data.log);
    //         })
    //     })
    // }
    private handleClickInputLog = (ev: any) => {
        // this.loadInputLog();
        let device_id = parseInt(this.props.match?.params.id!);
        let input = parseInt(this.props.match?.params.input!);
        let dateStart = (this.state.dateInputStart)!;

        this.setState({ inputLog: [], loading: true }, () => {

            this.props.store?.api.deviceInputTimeline(device_id, input, dateStart, this.itemsPerPage, 0).then(value => {
                if (value.success) {
                    this.setState({
                        page: value.data.page,
                        inputLog: this.filterList(value.data.log),
                        pageBottom: value.data.page,
                        pageTop: value.data.page,
                        loading: false,
                        selected: value.data.first,
                        scrollTo: value.data.first,
                    });
                }
            });
        });


    }

    filterList(log: LogInput[]) {
        // let uniqueValues = true;
        // let lastUniqueValue: any = undefined;
        return log;
        // return log.filter((value, index) => {
        //     if (index === 0) {
        //         lastUniqueValue = value.data;
        //         return true;
        //     }
        //     if (lastUniqueValue !== value.data) {
        //         lastUniqueValue = value.data;
        //         return true;
        //     } else {
        //         return false;
        //     }

        // });
    }

    handleClickBottom = (ev: any) => {

        let device_id = parseInt(this.props.match?.params.id!);
        let input = parseInt(this.props.match?.params.input!);
        // this.setState({ loading: true });
        let lastId = 0;
        if (this.state.inputLog.length > 0) {
            lastId = this.state.inputLog[this.state.inputLog.length - 1].id;
        }
        this.props.store?.api.deviceInputTimeline(device_id, input, null, this.itemsPerPage, this.state.pageBottom + 1).then(value => {
            if (value.success) {
                let list = this.filterList(value.data.log);
                for (let index = 0; index < list.length; index++) {
                    const element = list[index];

                    this.state.inputLog.push(element);
                }
                this.setState({ pageBottom: value.data.page, scrollTo: lastId });
            } else {
                alert("Error!");
            }
        }).finally(() => this.setState({ loading: false }));
    }

    handleClickTop = (ev: any) => {
        if (this.state.pageTop <= 0) return;

        let device_id = parseInt(this.props.match?.params.id!);
        let input = parseInt(this.props.match?.params.input!);
        // this.setState({ loading: true });
        let lastId = 0;
        if (this.state.inputLog.length > 0) {
            lastId = this.state.inputLog[0].id;
        }
        this.setState({loading: true, 
            selected: lastId})

        this.props.store?.api.deviceInputTimeline(device_id, input, null, this.itemsPerPage, this.state.pageTop - 1).then(value => {
            if (value.success) {
                let list = this.filterList(value.data.log);
                let array = list.reverse();
                for (let index = 0; index < array.length; index++) {
                    const element = array[index];
                    this.state.inputLog.unshift(element);
                }
                this.setState({ pageTop: value.data.page, scrollTo: lastId });
            } else {
                alert("Error!");
            }
        }).finally(() => this.setState({ loading: false }));
    }

    scrollToItem(id: string | number) {
        this.setState({ scrollTo: parseInt(String(id)) });

    }

    handleReference = (value: LogInput) => (a: HTMLIonItemElement) => {
        if (this.state.scrollTo && this.state.scrollTo === value.id) {
            this.setState({ scrollTo: 0 });
            setTimeout(() => {
                a.scrollIntoView();
            }, 500)

        }

    }

    private renderLog(inputLog: LogInput[]) {
        return inputLog.map((value, index) => {
            return <IonItem ref={this.handleReference(value)} onClick={() => this.setState({ selected: value.id })} key={index} id={"log-input-" + value.id} color={value.id === this.state.selected ? "primary" : ""}>
                <IonLabel>{this.props.store?.moment(value.time, 'X').format("DD/MM/YYYY HH:mm")}</IonLabel>
                <IonText slot={"end"}>{value.data}</IonText>
            </IonItem>
        })
    }

    private handleChangeFilter = (ev: any) => {
        this.setState({ unique: ev.detail.checked });
    }

    componentDidUpdate(prevProps: Readonly<DeviceGraphInputSearchProps>, prevState: Readonly<DeviceGraphInputSearchState>, snapshot?: any): void {
        if (this.state.scrollTo > 0) {
            let item = document.getElementById("log-input-" + this.state.scrollTo);
            this.setState({ scrollTo: 0 });
            if (item) {
                setTimeout(() => {
                    item!.scrollIntoView();
                }, 500)
            }
        }
    }

    render(): React.ReactNode {
        const { lang } = this.props;
        console.log("Loading Search: ", this.state.loading);

        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">

                        <IonButton routerDirection={"back"} routerLink={"/device/" + this.props.match?.params.id + "/status/input/" + this.props.match?.params.input}><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>Búsqueda de registros</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                <IonList>
                    <IonListHeader>
                        <IonLabel>{lang?.l.main.input_search_registers()}</IonLabel>
                    </IonListHeader>
                    <IonItem>
                        <IonLabel position="stacked">{lang?.l.main.start_date()}</IonLabel>
                        <IonDatetime firstDayOfWeek={1} presentation="date" onIonChange={this.handleChange('dateInputStart')} value={this.state.dateInputStart} locale="es-ES" />
                    </IonItem>
                    {/* <IonItem>
                        <IonCheckbox slot="start" checked={this.state.unique} color="primary" onIonChange={this.handleChangeFilter} />
                        <IonLabel>{lang?.l.main.input_unique()}</IonLabel>
                    </IonItem> */}
                    <IonItem>
                        {this.state.loading && <IonSpinner />}
                        <IonButton onClick={this.handleClickInputLog} expand={"block"}>{lang?.l.main.search()}</IonButton>
                    </IonItem>
                </IonList>
                {/* {this.state.timeTable && this.renderTimeTable()} */}
                {this.state.inputLog.length > 0 && <IonList>
                    <IonItem>
                        <IonLabel>{lang?.l.main.date()}</IonLabel>
                        <IonText>{lang?.l.main.map_value()} </IonText>
                    </IonItem>
                    {this.state.pageTop > 0 && <IonItem onClick={this.handleClickTop}>
                        <IonButton>Cargar mas reciente</IonButton>
                    </IonItem>}
                    {this.state.inputLog && this.renderLog(this.state.inputLog!)}
                    <IonItem>
                        <IonButton onClick={this.handleClickBottom}>Cargar anterior</IonButton>
                    </IonItem>
                </IonList>}


                <IonLoading
                    hidden={!this.state.loading}
                    // isOpen={!!this.state.loading}
                    message={lang?.l.main.loading()}
                    duration={5000}
                />

            </IonContent>
        </IonPage>
    }

}