import Utils from "./Utils";

export type CacheType = {
    expire?: number;
    value: any ;
}
export enum TimeType {
    SECONDS,
    MINUTES,
    HOURS,
    DAYS,
    CUSTOM,
}
export default class Cache {

    private _key:string;
    constructor(key:string) {
        if (!window.localStorage) {
            throw new Error('Cache is not supported ..');
        }

        if (!key) {
            throw new Error('Key was not provided ..');
        }

        this._key = key.toUpperCase() + '.';
    }

    private _prepareKey(key:string) {
        return this._key + key;
    }

    get(key:string) {
        key = this._prepareKey(key);

        let result = localStorage.getItem(key) as string;

        if (result) {
            let cache = JSON.parse(result) as CacheType;

            if (!cache.expire) {
                return cache.value;
            }

            if (cache.expire >= new Date().getTime()) {
                return cache.value;
            }

            localStorage.removeItem(key);
        }

        return null;
    }

    add(key:string, value:any, time : number | {type: TimeType, value: number} | null = null) {
        key = this._prepareKey(key);

        // object to store
        let item = {
            key,
            value,
            expire: _fromTime(time)
        };

        localStorage.setItem(key, JSON.stringify(item));

        // time logic
        function _fromTime(time:any) {
            if (!time) {
                return null;
            }

            let date = new Date();

            if (typeof time === 'number') {
                date.setMinutes(date.getMinutes() + time);
            } else {
                switch (time.type) {
                    case 4:
                        date.setTime(time.value);
                        break;
                    case 3:
                        date.setDate(date.getDate() + time.value);
                        break;
                    case 2:
                        date.setHours(date.getHours() + time.value);
                        break;
                    case 1:
                        date.setMinutes(date.getMinutes() + time.value);
                        break;
                    case 0:
                        date.setSeconds(date.getSeconds() + time.value);
                        break;
                }
            }

            Utils.log(date);
            Utils.log(date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds());

            return date.getTime();
        }
    }

    clear() {
        for (let key in localStorage) {
            if (key.includes(this._key)) {
                localStorage.removeItem(key);
            }
        }
    }

    delete(key:string) {
        key = this._prepareKey(key);
        localStorage.removeItem(key);
    }

}
