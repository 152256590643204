import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar,
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device } from "../../service/API";
import Utils, { DeviceFlags } from "../../service/Utils";
import Confirm from "../../components/Confirm";
import { RouteComponentProps } from "react-router-dom";

export interface DeviceSettingsNotificationsProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}
export type DeviceSettingsNotificationsState = {
    loading?: boolean,
    device?: Device,
}


@inject("store", "lang")
export default class DeviceSettingsNotifications extends React.Component<DeviceSettingsNotificationsProps, DeviceSettingsNotificationsState> {

    public static NOTIFICATIONS = [
        {
            flag: DeviceFlags.NOTIFY_OUTPUTS,
            name: 'Cambio de salidas',
        },
        {
            flag: DeviceFlags.NOTIFY_INPUTS,
            name: 'Cambio de entradas',
        },
        {
            flag: DeviceFlags.NOTIFY_ONLINE,
            name: 'Notificar cuando se encuentre conectado / desconectado',
        },
        {
            flag: DeviceFlags.NOTIFY_POWER,
            name: 'Notificar cuando cambie el estado de la alimentación',
        },
        {
            flag: DeviceFlags.NOTIFY_SCHEDULER,
            name: 'Notificaciones del planificador',
        },
        {
            flag: DeviceFlags.NOTIFY_POWER_SCHEDULER,
            name: 'Notificaciones del programador',
        },
    ];
    private confirm: React.RefObject<Confirm>;

    state: DeviceSettingsNotificationsState = {};

    constructor(props: DeviceSettingsNotificationsProps, context: any) {
        super(props, context);
        this.confirm = React.createRef();
    }

    componentDidMount(): void {
        this.loadDevice();

    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsNotificationsProps>, prevState: Readonly<DeviceSettingsNotificationsState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    loadDevice() {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if (value.success) {
                this.setState({ device: value.data, loading: false });
            }
        });
    }

    handleChange = (flag: number) => (ev: any) => {
        this.setState({ device: { ...this.state.device!, flags: Utils.setFlag(this.state.device?.flags!, flag, ev.currentTarget.checked) } });
    };

    handleChangeName = (ev: any) => {
        this.setState({ device: { ...this.state.device!, name: ev.currentTarget.value } });
    };

    handleGalleryChange = (image: string) => {
        this.setState({ device: { ...this.state.device!, image: image } });

    }
    handleChangeDataInterval = (ev: any) => {
        this.setState({ device: { ...this.state.device!, dataInterval: ev.currentTarget.value } });
    };

    handleSave = (ev: any) => {
        this.setState({ loading: true });
        const { flags } = this.state.device!;
        this.props.store?.api.devicePost(this.state.device!.id, { flags })
            .then(value => {
                this.props.history.goBack();
            })
            .finally(() => this.setState({ loading: false }));
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;
        let enabled = Utils.haveFlag(this.state.device?.flags!, DeviceFlags.NOTIFY_EVENTS);

        // const zoom = 9;
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>General</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItemDivider>
                        <IonLabel>
                            Notificaciones
                        </IonLabel>
                    </IonItemDivider>
                    <IonItem>
                        <IonToggle checked={Utils.haveFlag(this.state.device?.flags!, DeviceFlags.NOTIFY_EVENTS)}
                            onIonChange={this.handleChange(DeviceFlags.NOTIFY_EVENTS)} slot="end">Activar las notificaciones</IonToggle>
                    </IonItem>
                    {DeviceSettingsNotifications.NOTIFICATIONS.map((v, k) => {
                        return <IonItem key={k} disabled={!enabled}>
                            <IonToggle checked={Utils.haveFlag(this.state.device?.flags!, v.flag)}
                                onIonChange={this.handleChange(v.flag)} slot="end">
                                {v.name}
                            </IonToggle>
                        </IonItem>
                    })}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
                <Confirm ref={this.confirm} />
            </IonContent>
        </IonPage>
    }

}
