import React, { RefObject } from "react";
import {
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonText,
    IonToast,
    IonToolbar
} from "@ionic/react";
import { bugOutline, cameraOutline, hardwareChipOutline } from "ionicons/icons";
import QRScan from "./QRScan";

import { BarcodeScanner } from '@ionic-native/barcode-scanner';
// import { BarcodeScanner } from '@awesome-cordova-plugins/barcode-scanner';
import { Capacitor } from "@capacitor/core";
import Payments from "./Payments";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { Contract, Model, ProductSuscription } from "../service/API";
import Lang from "../service/Lang";

export type AddDeviceType = {
    onAddDevice: () => void,
    store?: Store,
    lang?: Lang,

    token?: string,

    routerOutlet: HTMLIonRouterOutletElement | undefined,
}

export type AddDeviceState = {
    scan: boolean,
    error: string,
    payment: string,
    loading: boolean,
    adminPanel?: boolean,
    model?: any,
    suscriptions?: ProductSuscription[],
    contract?: Contract,

    deviceName?: string,
    suscription?: string,
    payment_method?: string,
    code?: string,
    promotion?: string,
    coupon?: any,
    segment: string,

    simulatorModels?: Model[],
    simulatorModel?: number,
    simulatorProtocol?: number,
}

class AddDevice extends React.Component<AddDeviceType, AddDeviceState> {

    suscription: RefObject<HTMLIonSelectElement> = React.createRef();
    state: AddDeviceState = {
        scan: false,
        error: "",
        payment: '',
        loading: false,
        code: '',
        deviceName: '',
        promotion: '',
        adminPanel: false,
        segment: 'device',
        payment_method: 'stripe',
    }

    handleSubmit = (ev: any) => {
        ev.preventDefault();
    };

    handleClickScan = (ev: any) => {
        if (Capacitor.getPlatform() === "web") {
            this.setState({ scan: true });
        } else {

            BarcodeScanner.scan({ showTorchButton: true, }).then((value: any) => {
                if (value && value.format && value.format === "QR_CODE") {
                    this.handleScanResult(value.text);
                }
            }).catch((err: any) => {
                console.log('Error', err);
            })
        }

    }

    handleScanResult = (result: string | boolean) => {
        this.setState({ scan: false });
        if (result) {
            var matches = String(result).trim().match(/https:\/\/cloudrtu.com\/d\/([0-9]+)/)
            if (matches) {
                result = matches[1];
            }
            this.setState({ code: String(result) });
        }

    };

    componentDidMount(): void {
        if (this.props.token) {
            this.setState({ code: this.props.token });
        }
        if (this.props.store?.user?.privileges! > 100) {
            this.setState({ adminPanel: true });
        }

        if (this.props.store) {
            this.props.store.api.getSimulatorModels().then(result => {
                if (result.success) {
                    this.setState({ simulatorModels: result.data });
                }
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<AddDeviceType>, prevState: Readonly<any>, snapshot?: any): void {

        if (this.props.token !== prevProps.token) {
            this.setState({ code: this.props.token });
        }
    }

    handleAdd = async (ev: any) => {
        if (this.state.segment === 'device') {
            await this.handleAddDevice(ev);
        } else {
            this.handleAddSimulator();
        }
    };

    async handleAddDevice(ev: any) {
        const { lang } = this.props;
        await this.handleValidateDiscount(ev);
        let code: string = String(this.state.code);
        let name = String(this.state.deviceName);
        this.setState({ error: '' });
        if (this.state.suscription && this.state.suscription) {
            if (this.state.payment) {
                if (code) {
                    if (name) {
                        this.setState({ loading: true });

                        let result = await this.props.store?.api.deviceAdd(name, code, this.state.payment_method!, this.state.suscription, this.state.payment, this.state.coupon ? this.state.coupon.id : "");
                        this.setState({ loading: false });

                        if (result) {
                            if (result.success) {
                                this.props.onAddDevice();
                            } else {
                                this.setState({ error: result.message });
                            }
                        } else {
                            this.setState({ error: lang?.l.main.add_device_error_internet()! });
                        }
                    } else {
                        this.setState({ error: lang?.l.main.add_device_error_name()! })
                    }
                } else {
                    this.setState({ error: lang?.l.main.add_device_error_code()! })
                }
            } else {
                this.setState({ error: lang?.l.main.add_device_error_payment()! })
            }
        } else {
            this.setState({ error: lang?.l.main.add_device_error_subscription()! })

        }
    }

    handleAddSimulator() {
        let name = String(this.state.deviceName);
        let model = (this.state.simulatorModel!);
        let protocol = (this.state.simulatorProtocol!);

        this.props.store?.api.createSimulator(name, model, protocol).then(result => {
            if (result.success) {
                this.props.onAddDevice();
            } else {
                this.setState({ error: result.message });
            }
        })
    }

    handleLoadSubscription = (ev: any) => {
        if (this.state.code) {
            this.setState({ loading: true });
            this.props.store?.api.deviceQr(this.state.code).then(value => {
                this.setState({ loading: false });
                if (value.success) {
                    this.setState({ suscriptions: value.data.suscriptions, model: value.data.model, contract: value.data.contract }, () => {
                        this.suscription.current!.open(ev);
                    });
                }

            })
        } else {
            this.setState({ suscriptions: [], model: undefined });
        }
    }

    handleValidateDiscount = async (ev: any) => {
        if (this.state.promotion) {
            await this.setState({ loading: true });
            let value = await this.props.store?.api.getPromotionCode(this.state.promotion);
            if (value) {
                await this.setState({ loading: false });
                if (value.success && value.data.length > 0) {
                    await this.setState({ coupon: value.data[0].coupon });
                } else {
                    await this.setState({ coupon: undefined });
                }
            }
        }
    }

    renderSubscriptions() {
        const { lang } = this.props;
        return <>
            <IonItem>
                <IonSelect label="Forma de pago" okText={lang?.l.main.ok()} cancelText={lang?.l.main.cancel()} interface={"action-sheet"} onIonChange={(ev) => this.setState({ payment_method: ev.detail.value })} value={this.state.payment_method}>
                    <IonSelectOption value={'stripe'}>Pasarela Stripe</IonSelectOption>
                    <IonSelectOption value={'sepa'}>Domiciliación SEPA</IonSelectOption>
                    <IonSelectOption value={'transferencia'}>Transferencia Manual</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonSelect label="Suscripción" okText={lang?.l.main.ok()} cancelText={lang?.l.main.cancel()} ref={this.suscription} interface={"action-sheet"} onIonChange={(ev) => this.setState({ suscription: ev.detail.value })} value={this.state.suscription}>
                    {this.state.suscriptions?.map((value, index) => {
                        return <IonSelectOption value={value.id}>{value.title}</IonSelectOption>
                    })}
                </IonSelect>
            </IonItem>


            <IonItem>
                <IonLabel>Contrato</IonLabel>
                <IonText>{this.state.contract?.title!}</IonText>
            </IonItem>
        </>
    }

    renderCoupon(coupon: any) {

        const { lang } = this.props;
        return <IonItem>
            <IonLabel>{coupon.name}</IonLabel>
            <IonLabel slot={"end"} color={"success"}>{lang?.l.main.add_device_coupon_charged()}</IonLabel>
        </IonItem>
    }

    renderDevice() {
        const { lang } = this.props;
        return <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <IonText dangerouslySetInnerHTML={{ __html: lang?.l.main.add_device_help()! }}>

            </IonText>
            <IonList>
                {this.state.adminPanel && this.state.code && <IonItem color={"primary"} button href={"https://admin.cloudrtu.com/deviceAdd/" + this.state.code}>
                    <IonLabel>{lang?.l.main.add_device_admin()}</IonLabel>
                </IonItem>}
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.add_device_name()} onIonInput={(e) => this.setState({ deviceName: String(e.detail.value) })} value={this.state.deviceName} required={true} placeholder={lang?.l.main.add_device_example_name()}></IonInput>
                </IonItem>
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.add_device_qrcode()} onIonInput={(e) => this.setState({ code: String(e.detail.value), suscriptions: undefined, suscription: undefined })} value={this.state.code} required={true}></IonInput>
                    <IonButton onClick={this.handleClickScan} fill={"clear"} slot={"end"}><IonIcon
                        size={"large"} icon={cameraOutline} /></IonButton>
                </IonItem>
                {!!this.state.suscriptions && this.renderSubscriptions()}
                {!!this.state.suscriptions ||
                    <IonItem button detail detailIcon={""} onClick={this.handleLoadSubscription}><IonLabel>{lang?.l.main.add_device_subscriptions_load()}</IonLabel></IonItem>}
                <IonItem>
                    <IonLabel>{lang?.l.main.add_device_payment_method()}</IonLabel>
                    <Payments onAuthorize={(payment: string) => {
                        this.setState({ payment })
                    }} paylater={true} routerOutlet={this.props.routerOutlet} />
                </IonItem>
                <IonItem>
                    <IonInput label={lang?.l.main.add_device_coupon()} onIonInput={(e) => this.setState({ promotion: String(e.detail.value) })} value={this.state.promotion}></IonInput>
                    <IonButton onClick={this.handleValidateDiscount} fill={"clear"} slot={"end"}>{lang?.l.main.add_device_coupon_check()}</IonButton>
                </IonItem>
                {!!this.state.coupon && this.renderCoupon(this.state.coupon)}

            </IonList>
            {/*<IonButton onClick={() => this.setState({modal: false})}>Close Modal</IonButton>*/}

        </form>
    }

    renderSimulator() {
        const { lang } = this.props;

        return <form noValidate autoComplete="off" onSubmit={this.handleSubmit}>
            <IonText>
                {lang?.l.main.add_simulator_title()}
            </IonText>
            <IonList>
                <IonItem>
                    <IonInput labelPlacement="stacked" label={lang?.l.main.add_device_name()} onIonInput={(e) => this.setState({ deviceName: String(e.detail.value) })} value={this.state.deviceName} required={true} placeholder={lang?.l.main.add_device_example_name()}></IonInput>
                </IonItem>
                <IonItem>
                    <IonSelect label={lang?.l.main.add_simulator_model()} labelPlacement="stacked" onIonChange={(e) => this.setState({ simulatorModel: e.detail.value })}>
                        {this.state.simulatorModels && this.state.simulatorModels.map(e => <IonSelectOption value={e.id}>{e.name}</IonSelectOption>)}

                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect label={lang?.l.main.add_simulator_protocol()} labelPlacement="stacked" onIonChange={(e) => this.setState({ simulatorProtocol: e.detail.value })}>
                        <IonSelectOption value={0}>IotModbus (4R, 4IO)</IonSelectOption>
                        <IonSelectOption value={1}>Remotic (8R, 4IO)</IonSelectOption>
                        <IonSelectOption value={2}>Modbus</IonSelectOption>

                    </IonSelect>
                </IonItem>
            </IonList>
            {/*<IonButton onClick={() => this.setState({modal: false})}>Close Modal</IonButton>*/}

        </form>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const { lang } = this.props;
        return <>
            <IonHeader>
                <IonToolbar color="primary">
                    <IonSegment value={this.state.segment} onIonChange={(e) => this.setState({ segment: e.detail.value as string })}>
                        <IonSegmentButton value="device">
                            <IonIcon icon={hardwareChipOutline}></IonIcon> {lang?.l.main.add_device_device()}
                        </IonSegmentButton>
                        <IonSegmentButton value="simulator">

                            <IonIcon icon={bugOutline}></IonIcon>
                            {lang?.l.main.add_device_simulator()}</IonSegmentButton>
                    </IonSegment>
                </IonToolbar>

            </IonHeader>

            <IonContent fullscreen className={"ion-padding"}>

                <IonToast
                    htmlAttributes={{ tabindex: undefined }}
                    isOpen={this.state.error !== ""}
                    message={this.state.error}
                    onDidDismiss={() => {
                        this.setState({ error: '' })
                    }}
                    color={"danger"}
                    position="top"
                    duration={5000}
                />
                <IonSpinner
                    hidden={!this.state.loading} />
                {this.state.segment === 'device' && this.renderDevice()}
                {this.state.segment === 'simulator' && this.renderSimulator()}

                <QRScan open={this.state.scan} onClose={this.handleScanResult} />
            </IonContent>
        </>
    }
}


export default inject("store", "lang")(AddDevice);
