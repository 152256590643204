import { Capacitor } from "@capacitor/core";
import { RangeChangeEventDetail } from "@ionic/core";
import { IonAlert, IonItem, IonRange, UseIonAlertResult } from "@ionic/react";
import { inject } from "mobx-react";
import React, { RefObject } from "react";
import ReactDOM from "react-dom";
import { DeviceInputs, HMIPanel } from "../service/API";
import EventBox from "../service/EventBox";
import { Store } from "../service/Store";
import Utils from "../service/Utils";


export interface HMIDisplayProps {
    store?: Store,
    panel: HMIPanel,
    onInputClick?: (input: DeviceInputs) => void

}
export interface HMIDisplayState {
    objects?: any,
    inputs: { [key: number]: DeviceInputs },
    loading: boolean,
    confim: boolean,
    confimMessage: string,
    action: any,
    error: string,
}

type HTMLBaseElement = Element;
type HTMLRenderElement = (element: HTMLBaseElement, data: DeviceInputs, rendered?: any) => void;
type InputData = DeviceInputs //{value: number, time: number};

@inject("store", "lang")
export default class HMIDisplay extends React.Component<HMIDisplayProps, HMIDisplayState> {


    svgRender: RefObject<HTMLDivElement> = React.createRef();
    elements: Element[] = [];
    // model : PanelModel[] = [];

    inputs: { input_name: string, input_id?: number, data?: any, elements: { render: HTMLRenderElement, element: HTMLBaseElement, rendered?: any }[] }[] = [];
    inputMap: any = {};
    state: HMIDisplayState = {
        objects: [],
        loading: false,
        confim: false,
        action: undefined,
        error: '',
        confimMessage: '',
        inputs: {},
    };
    present?: UseIonAlertResult[0];

    timer: any;
    zoom = 50;
    eventBox?: EventBox;

    componentDidMount() {

        this.eventBox = new EventBox(this.props.store?.events!);
        this.eventBox.on('input-data', this.handleInput);
        this.eventBox.on('reconnect', () => {
            console.log('reconnected!');
            this.pollInputs();

        })

        this.loadData().then(() => {

            this.pollInputs();
        })
        this.timer = setInterval(() => {
            // this.pollInputs();
        }, 5000);

    }

    componentWillUnmount() {
        clearInterval(this.timer);
        this.eventBox?.off();
    }

    pollInputs() {
        if (this.inputs.length > 0) {
            this.props.store?.api.getInputsData(this.inputs.map(a => a.input_name)).then(a => {
                if (a && a.data) {
                    a.data.map(b => {
                        this.setInputID(b.input_name, b.id, b);
                        this.props.store?.socket!.emit('input', b.id);
                        return this.setInputData(b.id, b);
                    })
                }
            });
        }
    }
    setInputID(input_name: string, input_id: number, data: any) {
        let input = this.inputs.find(a => a.input_name === input_name);
        if (input) {
            input.input_id = input_id;
            input.data = data;
        }
    }

    componentDidUpdate(prevProps: Readonly<HMIDisplayProps>, prevState: Readonly<HMIDisplayState>, snapshot?: any): void {
        if (prevProps.panel.id !== this.props.panel.id) {

            this.loadData().then(() => {

                this.pollInputs();
            })
        }
        this.updateView();
    }

    handleInput = (inputId: any, value: any, time: any, input: any) => {
        // console.log("inputId = " + inputId + " value = " + value, new Date(time).toLocaleString());
        this.setInputData(inputId, input);
    }

    setInputValue(inputId: number, input: number) {
        if (this.state.inputs![inputId]) {
            this.state.inputs![inputId].value = String(input);
        }
        this.updateView();
    }
    setInputData(inputId: number, input: InputData) {
        let inputs = this.state.inputs;
        /// eslint-disable
        inputs[inputId] = input;


        this.setState({});

        //this.setState({inputs: {...this.state.inputs, [inputId]: {value, time}}});
        this.updateView();
    }



    updateView() {
        if (!this.state.inputs) return;
        for (let i = 0; i < this.inputs.length; i++) {
            const element1 = this.inputs[i];
            let inputData = this.state.inputs[element1.input_id!];
            if (inputData) {
                //let div = this.svgRender.current!;
                for (let j = 0; j < element1.elements.length; j++) {
                    const element2 = element1.elements[j];
                    element2.rendered = element2.render(element2.element, inputData, element2.rendered);
                }
            }
        }
    }

    registerInput(input_name: string, element: HTMLBaseElement, render: HTMLRenderElement, click?: any) {
        let item = this.inputs.find(a => a.input_name === input_name);
        if (!item) {
            this.inputs.push({ input_name, elements: [{ render, element }] });
        } else {
            item.elements.push({ render, element });
        }
        (element as HTMLDivElement).addEventListener('click', (ev) => this.handleElementInputClick(input_name)(ev))
    }

    registerClick(element: Element, callback: any) {
        (element as HTMLDivElement).addEventListener('click', (ev) => callback(ev))
    }

    async loadData() {

        try {
            console.log("Loading: ", this.props.panel.url);
            let data = await this.props.store?.api!.getRequest(this.props.panel.url);
            if (!data) {
                this.setState({ error: 'Invalid URL File' });
                return;
            }

            if (data.headers.get('content-type') === 'image/svg+xml') {
                let text = await data?.text();
                this.svgRender.current!.innerHTML = text;
            }
            else if (data.headers.get('content-type')?.indexOf('image/') === 0) {
                this.svgRender.current!.innerHTML = '<img src="' + this.props.panel.url + '" />';
                return;
            }

            // this.svgRender.current!.innerHTML = this.props.panel.svg_data;
            let items = this.svgRender.current!.querySelectorAll("[data-input_text]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const input_name = (element.getAttribute('data-input_text')!);
                this.registerInput(input_name, element, this.renderInputText)
            }
            items = this.svgRender.current!.querySelectorAll("[data-input_color]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const input_name = (element.getAttribute('data-input_color')!);
                this.registerInput(input_name, element, this.renderInputColor)
            }
            items = this.svgRender.current!.querySelectorAll("[data-input_flow]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                let inverse = false;
                if (element.hasAttribute("data-flow_inverse")) {
                    inverse = true;
                }
                const input_name = (element.getAttribute('data-input_flow')!);
                this.registerInput(input_name, element, this.renderFlow(inverse))
            }
            items = this.svgRender.current!.querySelectorAll("[data-input_height]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const input_name = (element.getAttribute('data-input_height')!);
                const max = parseInt(element.getAttribute('data-height_max')!);
                const min = parseInt(element.getAttribute('data-height_min')!);

                const y = parseInt(element.children[0].getAttribute('y')!);
                const height = parseInt(element.children[0].getAttribute('height')!);
                this.registerInput(input_name, element, this.renderHeight(max, min, y, height))
            }
            items = this.svgRender.current!.querySelectorAll("[data-input_spin]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const input_name = (element.getAttribute('data-input_spin')!);

                this.registerInput(input_name, element, this.renderSpin(false));
            }
            items = this.svgRender.current!.querySelectorAll("[data-output_slider]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const [device_id, output_id, input_name] = (element.getAttribute('data-output_slider')!).split(":");

                this.registerInput((input_name), element, this.renderSlider(parseInt(device_id), parseInt(output_id)))
                // this.renderSlider(element, )
            }
            items = this.svgRender.current!.querySelectorAll("[data-output_on]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const [device_id, output_id] = (element.getAttribute('data-output_on')!).split(":");
                this.registerClick(element, this.handleOutputOn(parseInt(device_id), parseInt(output_id)));
            }
            items = this.svgRender.current!.querySelectorAll("[data-output_off]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const [device_id, output_id] = (element.getAttribute('data-output_off')!).split(":");
                this.registerClick(element, this.handleOutputOff(parseInt(device_id), parseInt(output_id)));

            }
            items = this.svgRender.current!.querySelectorAll("[data-output_toggle]");
            for (let i = 0; i < items.length; i++) {
                const element = items[i];
                const [device_id, output_id] = (element.getAttribute('data-output_toggle')!).split(":");
                this.registerClick(element, this.handleOutputToggle(parseInt(device_id), parseInt(output_id)));

            }
            if (Capacitor.getPlatform() === 'web') {
                // this.handleViewBox()
            }

            let hrefs = this.svgRender.current!.querySelectorAll('a');


            console.log(hrefs);
            console.log(this.props.panel.name, " - Inputs: ", this.inputs);

        } catch (ex: any) {
            this.setState({ error: 'Error: ' + ex.toString() });
            return;
        }
    }

    renderElement(element: Element, child: any) {
        if (element.childNodes.length > 0) {
            let base = element.childNodes.item(0) as Element;
            let el = document.createElement("div");
            let x = base.getAttribute('x')!;
            let y = parseInt(base.getAttribute('y')!) + 57;
            let width = base.getAttribute('width')!;
            let height = base.getAttribute('height')!;
            el.style.position = 'absolute';
            el.style.left = x + "px";
            el.style.top = y + "px";
            el.style.width = width + "px";
            el.style.height = height + "px";

            ReactDOM.render(child, el);

            if (this.svgRender.current!) {
                (this.svgRender.current! as Element).appendChild(el);
            }
            this.elements.push(el);
            return el;
            // el.setAttribute("x", x);
            // el.setAttribute("y", y);
            // el.setAttribute("width", width);
            // el.setAttribute("height", height);
            // el.innerHTML = '<switch><foreignObject x="' + x + '" y="' + y + '" width="' + width + '" height="' + height + '" pointer-events="none" width="100%" height="100%" requiredFeatures="http://www.w3.org/TR/SVG11/feature#Extensibility" ><text>test1234</text></foreignObject></switch>';
            // element.appendChild(el);
        }
    }
    renderSlider = (device: number, output: number) => (el: Element, data: InputData, rendered: any) => {
        if (!rendered) {
            rendered = <IonRange onIonChange={this.handleSliderChange(device, output, data.id)} value={{ lower: 0, upper: parseInt(data.value) }} max={parseInt(data.max)} pin={true} />;
            this.renderElement(el, rendered);
        } else {
            (rendered as any).props.value.upper = parseInt(data.value);
        }
        return rendered
    }

    renderInputText = (el: Element, data: InputData) => {
        let postfix = el.getAttribute('data-text_postfix') ?? "";
        let prefix = el.getAttribute('data-text_prefix') ?? "";
        if (postfix === undefined) {
            postfix = "";
        }

        if (prefix === undefined) {
            prefix = "";
        }
        let on_value = el.getAttribute('data-text_on') ?? "";
        let off_value = el.getAttribute('data-text_off') ?? "";

        let value = String(data.value);

        if (on_value) {
            if (data.value) {
                value = on_value;
            }
        }
        if (off_value) {
            if (!data.value) {
                value = off_value;
            }
        }


        [...this.getTextNodes(el)].forEach(a => a.textContent = prefix + " " + value + " " + postfix);
        // el.textContent = String(data.value);
    }
    renderInputColor = (el: Element, data: InputData) => {
        let color = (parseInt(data.value) === 1) ? el.getAttribute('data-color_on') : el.getAttribute('data-color_off');
        [...el.childNodes].filter((a: any) => (a.hasAttribute && a.hasAttribute('fill') && a.getAttribute('fill') !== 'none')).forEach((a: any) => (a.setAttribute ? a.setAttribute('fill', color!) : a))
        // el.textContent = String(data.value);
    }
    renderFlow = (inverse: boolean) => (el: Element, data: InputData) => {
        let class1 = (Utils.toBoolean(data.value)) ? "fluid1 " + (inverse ? "fluid-inverse" : "") : "";
        [...el.childNodes].filter(a => a.nodeName === 'path').forEach((a: any) => (a.setAttribute ? a.setAttribute('class', class1!) : a))
        // el.textContent = String(data.value);
    }

    renderHeight = (max: number, min: number, y: number, height: number) => (el: Element, data: InputData) => {
        // let class1 = (Utils.toBoolean(data.value)) ? "fluid1 " + (inverse ? "fluid-inverse" : "") : "";
        let newHeight = Utils.scale(parseFloat(data.value), [min, max], [0, height]);
        let newY = (height - newHeight) + y;
        [...el.childNodes]
            .filter((a: any) => (a.hasAttribute && a.hasAttribute('height')))
            .forEach((a: any) => {
                a.setAttribute('height', newHeight);
                a.setAttribute('y', newY);
            })
        // el.textContent = String(data.value);
    }

    renderSpin = (inverse: boolean) => (el: Element, data: InputData) => {
        let class1 = (Utils.toBoolean(data.value)) ? "spin " + (inverse ? "spin-inverse" : "") : "";
        [...el.childNodes].filter((a: any) => a.setAttribute).forEach((a: any) => (a.setAttribute ? a.setAttribute('class', class1!) : a))
        // el.textContent = String(data.value);
    }
    getTextNodes(node: Element, results: Element[] = []) {
        let list = [...node.childNodes];
        for (const e of list) {
            if (e.hasChildNodes()) {
                this.getTextNodes(e as Element, results);
            } else {
                if (e.nodeValue?.trim()) {
                    results.push(e as Element);
                }
            }
        }
        return results;

    }
    delay: any;
    handleSliderChange = (device: number, output: number, input: number) => (ev: CustomEvent<RangeChangeEventDetail>) => {
        console.log(ev.type);
        if (this.delay) {
            clearTimeout(this.delay);
        }
        this.setInputValue(input, ev.detail.value as number);
        this.delay = setTimeout(() => {
            this.props.store?.api.deviceOutputState(device, output, 0, ev.detail.value as number);
        }, 1000);
        // 

    }
    handleOutputOn = (device: number, output: number) => (ev: any) => {
        this.handleOutputChange(device, output, 1)(ev);
    }

    handleOutputOff = (device: number, output: number) => (ev: any) => {
        this.handleOutputChange(device, output, 0)(ev);
    }
    handleOutputToggle = (device: number, output: number) => (ev: any) => {
        this.getDevice(device).then(res => {
            if (res.success) {
                let outputData = Utils.getOutputByIndex(res.data.outputs, output);

                let inputData = Utils.getInputForOutput(res.data, output);
                // let outputData = res.data.outputs.find(a => a.oindex == output);
                if (inputData) {
                    let val = !Utils.toBoolean(inputData.value);
                    this.setState({
                        confimMessage: '¿Realmente deseas cambiar la salida: ' + outputData?.name + " al estado: " + (val ? "ON" : "OFF") + "?",
                        confim: true, action: () => {
                            this.props.store?.api.deviceOutputState(device, output, 0, val ? 1 : 0)
                        }
                    });
                }
            }
        })

    }
    handleOutputChange = (device: number, output: number, state: number) => (ev: any) => {
        this.getDevice(device).then(res => {
            if (res.success) {
                let outputData = Utils.getOutputByIndex(res.data.outputs, output);

                // let inputData = Utils.getInputForOutput(res.data, output);
                // let outputData = res.data.outputs.find(a => a.oindex == output);
                let stateName = "";
                if (outputData?.type === "toggle") {
                    stateName = (state === outputData.on_value) ? outputData.on_name : outputData.off_name;
                } else {
                    stateName = String(state);
                }
                if (outputData) {
                    this.setState({
                        confimMessage: '¿Realmente deseas cambiar la salida: ' + outputData?.name + " al estado: " + stateName + "?",
                        confim: true, action: () => {
                            this.props.store?.api.deviceOutputState(device, output, 0, state)
                        }
                    });
                } else {
                    alert("La salida seleccionada (" + output + ") no existe en el dispositivo");
                }
            }
        })
    }

    getDevice(device: number) {
        this.setState({ loading: true });
        return this.props.store!.api.deviceGet(device).then(res => {
            this.setState({ loading: false });
            return res;
        });
    }

    handleElementInputClick = (input_name: string) => (ev: any) => {
        console.log(ev);
        if (ev.metaKey) {

            let item = this.inputs.find(a => a.input_name === input_name);
            if (item && item.data) {
                if (this.props.onInputClick) {
                    this.props.onInputClick(item.data);
                }

            }
            ev.preventDefault();
        }
    }
    handleViewBox() {
        let svgImage = this.svgRender.current!.querySelector("svg")!;
        let svgContainer = this.svgRender.current!;
        if (!svgImage || !svgContainer) {
            return;
        }
        var viewBox = { x: 0, y: 0, w: svgImage.clientWidth, h: svgImage.clientHeight };
        svgImage.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
        const svgSize = { w: svgImage.clientWidth, h: svgImage.clientHeight };
        var isPanning = false;
        var startPoint = { x: 0, y: 0 };
        var endPoint = { x: 0, y: 0 };;
        var scale = 1;

        svgContainer.onwheel = function (e) {
            e.preventDefault();
            var w = viewBox.w;
            var h = viewBox.h;
            var mx = e.offsetX;//mouse x  
            var my = e.offsetY;
            var dw = w * Math.sign(e.deltaY) * 0.05;
            var dh = h * Math.sign(e.deltaY) * 0.05;
            var dx = dw * mx / svgSize.w;
            var dy = dh * my / svgSize.h;
            viewBox = { x: viewBox.x + dx, y: viewBox.y + dy, w: viewBox.w - dw, h: viewBox.h - dh };
            scale = svgSize.w / viewBox.w;
            // zoomValue.innerText = `${Math.round(scale*100)/100}`;
            svgImage.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
        }


        svgContainer.onmousedown = function (e) {
            isPanning = true;
            startPoint = { x: e.x, y: e.y };
        }

        svgContainer.onmousemove = function (e) {
            if (isPanning) {
                endPoint = { x: e.x, y: e.y };
                var dx = (startPoint.x - endPoint.x) / scale;
                var dy = (startPoint.y - endPoint.y) / scale;
                var movedViewBox = { x: viewBox.x + dx, y: viewBox.y + dy, w: viewBox.w, h: viewBox.h };
                svgImage.setAttribute('viewBox', `${movedViewBox.x} ${movedViewBox.y} ${movedViewBox.w} ${movedViewBox.h}`);
            }
        }

        svgContainer.onmouseup = function (e) {
            if (isPanning) {
                endPoint = { x: e.x, y: e.y };
                var dx = (startPoint.x - endPoint.x) / scale;
                var dy = (startPoint.y - endPoint.y) / scale;
                viewBox = { x: viewBox.x + dx, y: viewBox.y + dy, w: viewBox.w, h: viewBox.h };
                svgImage.setAttribute('viewBox', `${viewBox.x} ${viewBox.y} ${viewBox.w} ${viewBox.h}`);
                isPanning = false;
            }
        }

        svgContainer.onmouseleave = function (e) {
            isPanning = false;
        }


    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <>
            {this.state.error && <IonItem>{ this.state.error }</IonItem>}
            <div style={{ 'overflow': 'auto' }} >
                <div className="cloudrtu-hmi" ref={this.svgRender}></div>
            </div>

            <IonAlert
                isOpen={this.state.confim}
                onDidDismiss={() => this.setState({ confim: false })}
                cssClass='my-custom-class'
                header={'Confirmar'}
                subHeader={'Está a punto de cambiar la salida'}
                message={this.state.confimMessage}
                buttons={[
                    {
                        text: 'Cancelar',
                        role: 'cancel',
                        cssClass: 'secondary',
                        handler: blah => {

                        }
                    },
                    {
                        text: 'Okay',
                        handler: () => {
                            if (this.state.action) { this.state.action() };
                        }
                    }
                ]}
            />


        </>
    }
}