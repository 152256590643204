import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../../service/Store";
import {Device} from "../../service/API";
import {RouteComponentProps} from "react-router-dom";

export interface DeviceSettingsModiconTimerProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsModiconTimerState = {
    timePlc : string,
    loading?: boolean,
    events: any[],
    actions : string[],
    device ?:Device,
    error: boolean | string,
    edit: boolean,
    index?: number,
}

@inject("store", "lang")
export default class DeviceSettingsModiconTimer extends React.Component<DeviceSettingsModiconTimerProps, DeviceSettingsModiconTimerState> {

    state : DeviceSettingsModiconTimerState = {
        actions: [],
        loading: false,
        events: [],
        error: false,
        edit: false,
        index: undefined,
        timePlc: "-",
    };
    // ACTIONS : string[] = [];
    componentDidMount(): void {
        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;

        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
                // for (let i = 0; i < 4; i++) {
                //     this.ACTIONS[9 + i] = value.data.outputs[i].name + " ON"
                //     this.ACTIONS[13 + i] = value.data.outputs[i].name + " OFF"
                // }
            }

            this.props.store?.api.modiconRequestActions(Number(id)).then(value => {
                if(value.success) {
                    this.setState({actions: value.data.actions});
                    this.props.store?.api.modiconRequestGetTimers(Number(id)).then(value => {
                        if(value.success) {
                            this.setState({events: value.data.t, timePlc: value.data.time});
                            
                        } else {
                            this.setState({error: 'El dispositivo no está conectado'});
                        }
                    })
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });

    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsModiconTimerProps>, prevState: Readonly<DeviceSettingsModiconTimerState>, snapshot?: any): void {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        // const {history} = this.props;

        this.props.store?.api.modiconRequestSetTimers(Number(id), this.state.events).then(value => {
            if(value.success) {
                if(value.data) {
                    this.setState({events: value.data.t});
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleClear = (ev: any) => {
        if(this.state.events) {
            for (let i = 0; i < this.state.events.length; i++) {
                let item = this.state.events[i];
                item.week = 0;
                item.hour = 0;
                item.min = 0;
                item.action = 0;
            }
            this.setState({});
        }
    }

    handleChange = (item:any, name:string) => (ev:any) => {
        console.log(ev.currentTarget.value);
        item[name] = ev.currentTarget.value;
        let events = this.state.events;
        events[this.state.index!] = this.buildItem(item);
        this.setState({events: events});
    }

    handleCheckChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.currentTarget.checked;

        let events = this.state.events;
        events[this.state.index!] = this.buildItem(item);
        this.setState({events: events})
    }

    handleSync = (ev:any) => {        
        let id = this.props.match?.params.id!;
        this.setState({loading: true});
        this.props.store?.api.modiconRequest(Number(id), {'action': 'sync-rtc', 'server': '150.214.5.121'}).then(value => {
            this.loadDevice();
        });
    }

    parseItem(value:any) {
        /*
               "e": false,
                "h": 0,
                "m": 0,
                "a": 4,
                "w": 2
         */

        let enable = value.e;
        let day = [];
        for (let index = 0; index < 7; index++) {
            const element = ((value.w & (1 << index)) !== 0);
            if(element) {
                day.push(index)
            }
        }
        // let sunday = !!((value.week >> 0) & 1);
        // let monday = !!((value.week >> 1) & 1);
        // let tuesday = !!((value.week >> 2) & 1);
        // let wednesday = !!((value.week >> 3) & 1);
        // let thursday = !!((value.week >> 4) & 1);
        // let friday = !!((value.week >> 5) & 1);
        // let saturday = !!((value.week >> 6) & 1);
        let time = (value.h > 9?value.h:"0" + value.h) + ":" + (value.m > 9?value.m:"0" + value.m);
        let action = value.a;
        console.log(day);

        return {enable, day, time, action};
    }

    buildItem(item:any) {
        let result = {w: 0, h: 0, m: 0, a: item.action, e: item.enable};
        // let result = {week: 0, hour: 0, min: 0, action: item.action};
        // if(item.enable) {
        //     result.week = (1 << 7);
        //     result.week += item.day & 0b111;
        // }
        result.w = 0;
        
        for (let index = 0; index < 7; index++) {
            const element = item.day.indexOf(index);
            if(element > -1) {
                result.w += ((1 << index));
            }
        }
        result.h = parseInt(item.time.substring(0, 2));
        result.m = parseInt(item.time.substring(3, 5));
        return result;
    }


    weekName(index: number) {
        switch (index + 1) {
            case 1: return "L";
            case 2: return "M";
            case 3: return "X";
            case 4: return "J";
            case 5: return "V";
            case 6: return "S";
            case 7: return "D";
        }
        return "";
    }
    renderWeek(item:any) {
        let week = item.day;
        let string = "";
        for (let index = 0; index < 7; index++) {
            string += (week.indexOf(index) >= 0 ) ? this.weekName(index) : "";
        }
        return string;
    }

    renderItem(value:any, index:number) {
        if(!this.state.device) return "";
        let item = this.parseItem(value);

        return <>
            <IonItem detail button onClick={() => this.setState({edit: true, index: index})}>
                <IonLabel>Evento programado {index + 1}</IonLabel>

                <IonBadge slot={"end"} color={"primary"} hidden={!item.enable}>{item.time}</IonBadge>
                <IonBadge slot={"end"} color={"primary"} hidden={!item.enable}>{this.renderWeek(item)}</IonBadge>
                <IonBadge slot={"end"} color={"warning"} hidden={!item.enable}>{this.state.actions[item.action]} ({item.action})</IonBadge>
                <IonBadge slot={"end"} color={"danger"} hidden={item.enable}>Desactivado</IonBadge>
            </IonItem>
        </>
    }

    renderEditor() {
        if(!this.state.device) return "";
        if(this.state.index === undefined) return "";
        let value = this.parseItem(this.state.events[this.state.index]);

        return <>
            <IonItem>
                <IonToggle checked={value.enable} onIonChange={this.handleCheckChange(value, 'enable')}>Activar</IonToggle>
            </IonItem>
            <div hidden={!value.enable}>

                <IonItem>
                    <IonLabel>Hora</IonLabel>
                    <IonDatetime firstDayOfWeek={1}  locale="es-ES" presentation="time" value={value.time} onIonChange={this.handleChange(value, 'time')} />
                </IonItem>
                <IonItem>
                    <IonSelect label="Acción" value={value.action} interface={"action-sheet"} onIonChange={this.handleChange(value, 'action')}>
                        {Object.keys(this.state.actions).map((value1:string) => <IonSelectOption value={parseInt(value1)}>{this.state.actions[Number(value1)]}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect label="Día" value={value.day} multiple={true} interface={"action-sheet"} onIonChange={this.handleChange(value, 'day')}>
                        <IonSelectOption value={0}>Lunes (L)</IonSelectOption>
                        <IonSelectOption value={1}>Martes (M)</IonSelectOption>
                        <IonSelectOption value={2}>Miércoles (X)</IonSelectOption>
                        <IonSelectOption value={3}>Jueves (J)</IonSelectOption>
                        <IonSelectOption value={4}>Viernes (V)</IonSelectOption>
                        <IonSelectOption value={5}>Sábado (S)</IonSelectOption>
                        <IonSelectOption value={6}>Domingo (D)</IonSelectOption>
                    </IonSelect>
                </IonItem>
            </div>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if(!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"}/>
                    </IonButtons>
                    <IonTitle>Acciones programadas</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleClear}>Limpiar</IonButton>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}
                    <IonItem>
                        Fecha PLC: {this.state.timePlc}
                        <IonButton slot="end" onClick={this.handleSync}>Sincronizar</IonButton>
                    </IonItem>

                    {this.state.events && this.state.events.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>

            <IonModal isOpen={this.state.edit! ? true: false} onDidDismiss={() => this.setState({edit: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Acción</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({edit: false})}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
