import React from "react";
import { Device, DeviceInputGroup, DeviceInputs } from "../service/API";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { Line } from 'react-chartjs-2';
import { IonChip, IonIcon, IonLabel, IonSelect, IonSelectOption, IonSpinner } from "@ionic/react";
import { Chart as ChartJS, CategoryScale, LineController, LineElement, PointElement, LinearScale, Title, TimeScale, ChartDataset, CoreChartOptions, ElementChartOptions, PluginChartOptions, DatasetChartOptions, ScaleChartOptions, LineControllerChartOptions, Tooltip, Legend } from 'chart.js';
import 'chartjs-adapter-moment';
import { _DeepPartialObject } from "chart.js/types/utils";
import { chevronBack, chevronForward, timeOutline } from "ionicons/icons";
import moment from "moment-timezone";
import DatetimeSelect from "./DatetimeSelect";
import Utils from "../service/Utils";
import EventBox from "../service/EventBox";


export type ChartRange = {
    value: number,
    name: string,
    interval: string,
}
export type InputChartProps = {
    input: DeviceInputs,
    title: string,
    store?: Store,
    number: number,
    time?: number,
    ranges: ChartRange[],
    location: string,
    fullSize?: boolean,
    device: Device,
}
export type InputChartState = {

    series?: any,
    data?: any,
    loading: boolean,
    range: number,
    start: number,
    realtime: boolean,

    selectStartDateVisible: boolean,
    selectEndDateVisible: boolean,

    interval: string,
}
ChartJS.register(CategoryScale, LineController, LineElement, PointElement, LinearScale, Title, TimeScale, Tooltip, Legend);
type ChartOptionType = _DeepPartialObject<CoreChartOptions<"line"> & ElementChartOptions<"line"> & PluginChartOptions<"line"> & DatasetChartOptions<"line"> & ScaleChartOptions<"line"> & LineControllerChartOptions> | undefined
const Colors = ['#2f7ed8', '#0d233a', '#8bbc21', '#910000', '#1aadce',
    '#492970', '#f28f43', '#77a1e5', '#c42525', '#a6c96a'];
export const ShortRanges: ChartRange[] = [

    {
        value: 2 * 24 * 60 * 60 * 1000,
        name: '2d',
        interval: "hour",
    },
    {
        value: 24 * 60 * 60 * 1000,
        name: '1d',
        interval: "hour",
    },
    {
        value: 12 * 60 * 60 * 1000,
        name: '12h',
        interval: "hour",
    },
    {
        value: 6 * 60 * 60 * 1000,
        name: '6h',
        interval: "900",
    },
    {
        value: 2 * 60 * 60 * 1000,
        name: '2h',
        interval: "1",
    }
].reverse();

export const LongRanges: ChartRange[] = [

    {
        value: 14 * 24 * 60 * 60 * 1000,
        name: '14d',
        interval: "hour",
    },
    {
        value: 7 * 24 * 60 * 60 * 1000,
        name: '7d',
        interval: "hour",
    },
    {
        value: 2 * 24 * 60 * 60 * 1000,
        name: '2d',
        interval: "hour",
    },
    {
        value: 24 * 60 * 60 * 1000,
        name: '1d',
        interval: "hour",
    },
    {
        value: 12 * 60 * 60 * 1000,
        name: '12h',
        interval: "hour",
    },
    {
        value: 6 * 60 * 60 * 1000,
        name: '6h',
        interval: "900",
    },
    {
        value: 2 * 60 * 60 * 1000,
        name: '2h',
        interval: "minute",
    }
].reverse();
@inject("store", "lang")
export default class InputChart extends React.Component<InputChartProps, InputChartState> {


    state: InputChartState = {
        loading: false,
        series: {},
        data: { labels: [], datasets: [] },
        range: 24 * 60 * 60 * 1000,
        interval: 'hour',
        start: new Date().getTime(),
        selectStartDateVisible: false,
        selectEndDateVisible: false,
        realtime: true,
        // data: {
        //     label: 'Temperature',
        //     data: [{ x: 1, y: 2 }],
        //     tension: 0,
        //     borderColor: "rgb(248,169,113)",
        //     backgroundColor: "rgba(0,0,0,0)",
        //     borderWidth: 1,
        //     pointHitRadius: 5
        // }
    }

    container: string = "container-" + Math.floor(Math.random() * 10000000000);
    events?: EventBox;
    getKeyName() {
        return "graph-" + this.props.location + "-" + this.props.input.id;
    }
    componentDidMount() {

        let range = parseInt(this.props.store?.getItem(this.getKeyName()) || "0");
        if (range) {
            let interval = this.props.ranges.find(value => value.value === range);
            if (interval) {
                this.setState({ interval: interval.interval, range: interval.value });
            }
        }
        this.loadData();


        this.events = new EventBox(this.props.store?.events!);
        this.events.on('inputs', (device: any, inputs: any[]) => {
            for (let i = 0; i < inputs.length; i++) {
                this.updateInput(inputs[i].id, inputs[i]);
            }
        })
    }
    componentWillUnmount(): void {
        this.events?.off();
    }

    updateInput(id: number, data: DeviceInputGroup) {
        for (let index = 0; index < data.inputs.length; index++) {
            const element = data.inputs[index];
            if (element.id === this.props.input.id) {
                // this.loadData();
                if (this.state.realtime) {
                    this.setState({ start: new Date().getTime() + 1000 })

                }

            }

        }
    }


    ensureLoaded(start: number, ensuredTimeMs: number = 570) {
        let duration = (new Date().getTime() - start);
        return Math.max(ensuredTimeMs - duration, 0);
    }

    isDiscrete() {
        if (this.props.input.widget === "onoff") {
            return true;
        }
        if (this.props.input.type === "output") {
            let output = Utils.getOutputByIndex(this.props.device.outputs!, this.props.input.index);
            if (output && output.type === 'toggle') {
                return true;
            }
        }
        return false;
    }

    getDiscreteValueMapping() {
        if (this.props.input.type === "output") {
            let output = Utils.getOutputByIndex(this.props.device.outputs!, this.props.input.index);
            if (output) {
                if (output.type === 'toggle') {
                    return { [output.on_value]: output.on_name, [output.off_value]: output.off_name, }
                }
            }
            return { 1: 'ON', 0: 'OFF', }
        } else {
            return { 1: this.props.input.on_value, 0: this.props.input.off_value }
        }
    }

    getValue(mapping: any, value: number) {
        return mapping[value];
    }



    loadData() {
        this.setState({ loading: true });
        let timeStart = new Date().getTime();

        let start = this.state.start - ((this.state.range));
        let end = this.state.start;
        let fullSize = this.props.fullSize;
        let onoff = (this.isDiscrete());
        let mapping = this.getDiscreteValueMapping();

        this.props.store?.api.deviceInputChart(this.props.input.id, start / 1000, end / 1000, this.state.interval).then(value => {
            setTimeout(() => {
                this.setState({ loading: false });
            }, this.ensureLoaded(timeStart));
            let datasets: ChartDataset[] = [];
            let series: any[] = [];
            let labels: any[] = [];

            let ds: ChartDataset;
            if (value.success) {
                let data = value.data;
                for (let i = 0; i < data.length; i++) {
                    let item = data[i];
                    labels.push(new Date((item.time) * 1000));
                    if (onoff) {
                        let val = this.getValue(mapping, item.data as number);
                        series.push(val);
                    } else {
                        series.push(item.data as number);
                    }
                    // 

                }

                if (fullSize) {
                    ds = {
                        data: series as number[],
                        label: this.props.input.name,
                        borderColor: Colors[this.props.number % Colors.length],
                        fill: false,
                        // pointHoverBorderWidth: 2,
                        // pointRadius: 3, 
                        // pointHoverRadius: 6,
                        tension: 0.1,
                        yAxisID: onoff ? 'y2' : 'y',
                        stepped: onoff,

                    } as ChartDataset;
                } else {
                    ds = {
                        data: series as number[],
                        label: this.props.input.name,
                        borderColor: Colors[this.props.number % Colors.length],
                        fill: false,
                        pointRadius: 0,
                        yAxisID: onoff ? 'y2' : 'y',
                        stepped: onoff
                    }
                }
                datasets.push(ds);
                this.setState({ data: { labels, datasets } });
                setTimeout(() => {

                    this.setState({});
                }, 100);
            }
        })


    }

    componentDidUpdate(prevProps: Readonly<InputChartProps>, prevState: Readonly<InputChartState>, snapshot?: any) {
        if (this.props.input.id !== prevProps.input.id) {
            this.loadData();
        }

        if (this.state.range !== prevState.range ||
            this.state.start !== prevState.start) {
            this.loadData();
        }
    }

    handleResize(el: any) {
        window.dispatchEvent(new Event('resize'));
    }

    handleClick = (value: any) => (ev: any) => {
        this.props.store?.setItem(this.getKeyName(), value.value);
        this.setState({ range: value.value, interval: value.interval });
    }

    handleNavigateBackward = (ev: any) => {
        let back = this.state.start - this.state.range;

        this.setState({ start: back, realtime: false });
    }

    handleSetStartDateClick = (ev: any) => {
        this.setState({ selectStartDateVisible: !this.state.selectStartDateVisible })
    }
    handleSetStartDate = (date: number) => {

        this.setState({ selectStartDateVisible: false, start: date + this.state.range });
    }
    handleSetEndDateClick = (ev: any) => {
        this.setState({ selectEndDateVisible: !this.state.selectEndDateVisible })
    }
    handleSetEndDate = (ev: number) => {
        //let back = this.state.start - this.state.range;
        // this.setState({ start: back });
    }
    handleSetRealtime = () => {
        //let back = this.state.start - this.state.range;
        this.setState({ realtime: true, start: new Date().getTime() });
    }

    handleNavigateForward = (ev: any) => {
        let back = this.state.start + this.state.range;

        this.setState({ start: back, realtime: false });
    }

    handleSelectChange = (ev: any) => {
        let selectedValue = ev.detail.value;
        let item = this.props.ranges.find(a => a.value === selectedValue)
        if (item) {
            this.props.store?.setItem(this.getKeyName(), item.value);
            this.setState({ range: item.value, interval: item.interval });
        }
    }

    getOptions(): ChartOptionType {
        let mapping = Object.values(this.getDiscreteValueMapping()).reverse();

        if (this.props.fullSize) {
            return {
                responsive: true,
                plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    title: {
                        display: false,
                        // text: 'Stacked scales',
                    },
                },
                scales: {
                    x: {
                        type: 'time',
                        display: true,

                        title: {

                            display: true,
                            text: 'Fecha',

                        },
                        ticks: {
                            autoSkip: true,
                            maxRotation: 90,
                            major: {
                                enabled: true
                            },

                            // color: function(context) {
                            //   return context.tick && context.tick.major ? '#FF0000' : 'rgba(0,0,0,0.1)';
                            // },

                        },

                        time: {
                            // Luxon format string
                            tooltipFormat: 'HH:mm:ss DD / MM / YY '
                        },
                    },
                    y: {
                        type: 'linear',
                        display: "auto",
                        title: {
                            display: true,
                            text: 'Valor'
                        },
                        


                    },
                    y2: {
                        display: "auto",
                        type: 'category',
                        labels: mapping,
                        offset: true,

                        position: 'left',
                        stack: 'demo',
                        stackWeight: 1,
                        grid: {
                            //borderColor: Utils.CHART_COLORS.blue
                        }
                    }
                },


            } as ChartOptionType;
        } else {
            return {
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {

                    x: {

                        type: 'time'
                    },

                    y2: {
                        display: "auto",
                        type: 'category',
                        labels: mapping,
                        offset: true,

                        position: 'left',
                        stack: 'demo',
                        stackWeight: 1,
                        grid: {
                            //borderColor: Utils.CHART_COLORS.blue
                        }
                    }
                }
            } as ChartOptionType;
        }
    }
    renderNavigation() {
        return <>
            <div>
                <IonChip onClick={this.handleNavigateBackward}>
                    <IonLabel><IonIcon icon={chevronBack} /></IonLabel>
                </IonChip>
                <IonChip onClick={this.handleSetStartDateClick}>
                    <IonLabel>{moment(this.state.start - this.state.range).format("DD/MM/YY HH:mm")}</IonLabel>
                </IonChip>
                <IonChip onClick={this.handleSetEndDateClick}>
                    <IonLabel>{moment(this.state.start).format("DD/MM/YY HH:mm")}</IonLabel>
                </IonChip>
                <IonChip onClick={this.handleNavigateForward}>
                    <IonLabel><IonIcon icon={chevronForward} /></IonLabel>
                </IonChip>
                <IonChip onClick={this.handleSetRealtime} outline={this.state.realtime} color={this.state.realtime ? "primary" : ""}  >
                    <IonLabel><IonIcon icon={timeOutline} /></IonLabel>
                </IonChip>
            </div>
            <div>
                <DatetimeSelect date={this.state.start - this.state.range} visible={this.state.selectStartDateVisible} onCancel={() => this.setState({ selectStartDateVisible: false, realtime: false })} onSelectDatetime={this.handleSetStartDate} />
            </div>
        </>

    }

    render() {
        return <div style={{ minWidth: '270px' }}>
            {this.props.fullSize && this.renderNavigation()}

            <IonChip>
                <IonSelect interfaceOptions={{message: 'Ventana de tiempo', }}  value={this.state.range} onIonChange={this.handleSelectChange}>
                    {this.props.ranges.map(value => {
                        return <IonSelectOption value={value.value}>{value.name}</IonSelectOption>
                    })}

                </IonSelect>
            </IonChip>
            <IonSpinner color={"primary"} hidden={!this.state.loading} style={{ display: 'relative', marginLeft: '8px' }} />
            {/* {this.props.ranges.map(value => {
                return <IonChip color={value.value === this.state.range ? "primary" : ""}
                    outline={value.value === this.state.range} onClick={this.handleClick(value)}>
                    <IonLabel>{value.name}</IonLabel>
                    {value.value === this.state.range &&}

                </IonChip>
            })} */}
            {/* <IonSpinner hidden={!this.state.loading} /> */}
            <Line options={this.getOptions()} data={this.state.data!} />
        </div>
    }
}
