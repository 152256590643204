import React from "react";
import {
    IonButtons,
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonMenuButton,
    IonPage, IonSelect, IonSelectOption, IonItemDivider,
    IonTitle,
    IonToolbar,
    IonText
} from "@ionic/react";
import { inject } from "mobx-react";
import { InputChangeEventDetail } from "@ionic/core";
import { Store } from "../service/Store";
import moment from "moment";

@inject("store", "lang")
export default class Settings extends React.Component<any, any> {
    handleChangeSerial = (ev: CustomEvent<InputChangeEventDetail>) => {
        let store: Store = this.props.store;
        store.setItem("serialId", ev.detail.value);
    }
    handleChangeChart = (ev: CustomEvent<InputChangeEventDetail>) => {
        let store: Store = this.props.store;
        store.setItem("chartEngine", ev.detail.value);
    }
    handleChangeTimeZone = (ev: CustomEvent<any>) => {
        let store: Store = this.props.store;
        store.timeZone = ev.detail.value;
        store.setItem("timeZone", ev.detail.value);
        this.setState({});
    }
    handleResetSiri = (ev: any) => {
        let store: Store = this.props.store;
        store.siri!.deleteAll().then(() => {
            alert("OK");
        });
    }

    renderAdmin() {
        let store: Store = this.props.store;

        return <>

            <IonItemDivider>
                <IonLabel>
                    Admin Features
                </IonLabel>
            </IonItemDivider>
            <IonItem>
                <IonLabel onClick={() => store.socket?.emit('resetMQTT')}>Reiniciar MQTT</IonLabel>
            </IonItem>
        </>;
    }
    render() {
        let store: Store = this.props.store;
        const { lang } = this.props;
        // let id = store.getItem("serialId");
        // let chart = store.getItem("chartEngine") || 0;
        let timeZones = moment.tz.names();
        let currentTimeZone = store.timeZone;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot={"start"}><IonMenuButton /></IonButtons>
                    <IonTitle>{lang?.l.main.app_settings_title()}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    <IonItem>
                        <IonSelect label={lang?.l.main.app_settings_timezone()} interface="action-sheet" value={currentTimeZone} onIonChange={this.handleChangeTimeZone}>
                            {timeZones.map(timeZone => <IonSelectOption value={timeZone}>{timeZone} {moment().tz(timeZone).format('Z')}</IonSelectOption>)}
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonLabel>{lang?.l.main.app_settings_current_time()}</IonLabel>
                        <IonText>{store.moment(new Date()).format()}</IonText>
                    </IonItem>
                    <IonItem hidden={!store.siri || !store.siri.available} onClick={this.handleResetSiri}>
                        <IonLabel>Reiniciar Siri</IonLabel>
                    </IonItem>
                    {this.props.store.user.privileges >= 100 && this.renderAdmin()}
                </IonList>


            </IonContent>
        </IonPage>
    }
}
