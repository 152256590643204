
const config = {
    // API: "http://192.168.1.133:3000/",
    // @ts-ignore
    API: window.WEBSERVER,
    // @ts-ignore
    STRIPE: window.STRIPE,
    // @ts-ignore
    DEBUG: window.DEBUG,

    FORECAST_API_KEY: "9f579c8bc5d33cf4792dbce0998938f0",

    // @ts-ignore
    THEME: window.APP_THEME || "cloudrtu",
};

export default config
