import {
    IonAvatar,
    IonBadge,
    IonButton,
    IonContent,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonRouterOutlet,
    IonSplitPane,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { Redirect, Route, RouteComponentProps, withRouter } from "react-router-dom";
import DeviceDetails from "./Device/DeviceDetails";
import { analyticsOutline, apps, cogOutline, documentOutline } from "ionicons/icons";
import React, { RefObject } from "react";
import { inject } from "mobx-react";
import DeviceStatus from "./Device/DeviceStatus";
import DeviceSettingsHardwareInterlock from "./DeviceSettings/DeviceSettingsHardwareInterlock";
import DeviceLog from "./Device/DeviceLog";
import DeviceSettings from "./DeviceSettings/DeviceSettings";
import DeviceSettingsGeneral from "./DeviceSettings/DeviceSettingsGeneral";
import DeviceSettingsOutput from "./DeviceSettings/DeviceSettingsOutput";
import DeviceSettingsInputGroup from "./DeviceSettings/DeviceSettingsInputGroup";
import DeviceSettingsShare from "./DeviceSettings/DeviceSettingsShare";
import DeviceSettingsHardwareRelays from "./DeviceSettings/DeviceSettingsHardwareRelays";
import DeviceSettingsHardwareAIN from "./DeviceSettings/DeviceSettingsHardwareAIN";
import DeviceSettingsHardwareDIN from "./DeviceSettings/DeviceSettingsHardwareDIN";
import DeviceSettingsHardwareTimer from "./DeviceSettings/DeviceSettingsHardwareTimer";
import { Store } from "../service/Store";
import { ClientDevices } from "../service/API";
import DeviceSettingsHardwareTemp from "./DeviceSettings/DeviceSettingsHardwareTemp";
import EventBox from "../service/EventBox";
import Utils from "../service/Utils";
import DeviceSettingsRemoticAnalog from "./DeviceSettings/Remotic/DeviceSettingsRemoticAnalog";
import DeviceGraphInput from "./Device/DeviceGraphInput";
import DeviceSettingsModbus from "./DeviceSettings/DeviceSettingsModbus";
import DeviceSettingsRemoticAutomation from "./DeviceSettings/Remotic/DeviceSettingsRemoticAutomation";
import DeviceSettingsRemoticTimer from "./DeviceSettings/Remotic/DeviceSettingsRemoticTimer";
import Lang from "../service/Lang";
import DeviceSettingsNotifications from "./DeviceSettings/DeviceSettingsNotifications";
import DeviceSettingsInput from "./DeviceSettings/DeviceSettingsInput";
import DeviceSettingsInputGroupList from "./DeviceSettings/DeviceSettingsInputGroupList";
import DeviceSettingsPlugins from "./DeviceSettings/DeviceSettingsPlugins";
import DeviceSettingsSuscription from "./DeviceSettings/DeviceSettingsSuscription";
import DeviceGraphInputSearch from "./Device/DeviceGraphInputSearch";
import DeviceGraphInputTimeTable from "./Device/DeviceGraphInputTimeTable";
import DeviceOutputService from "./Device/DeviceOutputService";
import DeviceSettingsService from "./DeviceSettings/DeviceSettingsService";
import DeviceSettingsHardwareBackup from "./DeviceSettings/DeviceSettingsHardwareBackup";
import DeviceSettingsModiconTimer from "./DeviceSettings/DeviceSettingsModiconTimer";
import DeviceSettingsHardwareModbus from "./DeviceSettings/DeviceSettingsHardwareModbus";

export interface DeviceSplitPaneProps extends RouteComponentProps<{ id: string, tab: string }> {
    store?: Store,
    lang?: Lang,
}
export interface DeviceSplitPaneState {
    // devices?:SimpleDevice[],

    clients?: ClientDevices[],
}
@inject("store", "lang")
class DeviceSplitPane extends React.Component<DeviceSplitPaneProps, DeviceSplitPaneState> {

    state: DeviceSplitPaneState = {};
    events?: EventBox;

    routerOutlet: RefObject<HTMLIonRouterOutletElement>;
    constructor(props: any, context: any) {
        super(props, context);
        this.routerOutlet = React.createRef();

    }
    componentDidMount(): void {

        this.events = new EventBox(this.props.store?.events!);

        this.events.on('online', () => {
            this.updateData();
        });
        this.events.on('offline', () => {
            this.updateData();
        });
        this.updateData();
    }

    componentWillUnmount() {
        this.events?.off();
    }

    updateData() {
        this.props.store?.api.getDevicesClients().then(value => {
            if (value.success) {
                this.setState({ clients: value.data.clients });
            }
        });
    }

    handleNav = (tab: string) => (ev: any) => {

        let location = "/device/" + this.props.match.params.id + "/" + tab;
        this.props.history.replace({ pathname: location })

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const { lang } = this.props;
        let id = this.props.match.params.id;
        Utils.log(id);
        return <IonSplitPane contentId="menuContent">
            {/*--  our side menu  --*/}
            <IonMenu contentId="menuContent" >
                <IonHeader>
                    <IonToolbar color={"primary"}>
                        <IonTitle>{lang?.l.main.menu()}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>

                        <IonItem routerLink={"/"}>
                            <IonLabel>{lang?.l.main.menu_home()}</IonLabel>
                        </IonItem>
                        {this.state.clients && this.state.clients.map((value2, key2) => {
                            return <div key={key2}>
                                <IonListHeader>
                                    <IonLabel>{value2.user.fullname}</IonLabel>

                                </IonListHeader>
                                {value2.devices.map((value, index) => {
                                    return <IonItem routerLink={"/device/" + value.id + "/" + this.props.match.params.tab} detail={true} button key={index} color={id === String(value.id) ? "light" : ""}>
                                        <IonAvatar slot={"start"}><img alt={value.name} src={value.image || value.model.image} /> </IonAvatar>
                                        <IonLabel>
                                            <h3> {value.name}</h3>
                                            <p>{value.model.name} - {value.protocol}</p>
                                            <IonBadge color={value.online ? "primary" : "danger"}> {value.online ? "Conectado" : "Desconectado"}</IonBadge>
                                        </IonLabel>
                                    </IonItem>
                                })}
                            </div>
                        })}


                        <IonItem routerLink={"/help"}>
                            <IonLabel>{lang?.l.main.menu_help()}</IonLabel>
                        </IonItem>

                        <IonList>
                        </IonList>
                    </IonList>
                </IonContent>
            </IonMenu>

            {/*-- the main content --*/}
            <div className="ion-page" id="menuContent">
                <IonContent>
                    <IonTabs>
                        <IonRouterOutlet ref={this.routerOutlet}>
                            <Route path="/device/:id/settings/general" render={(props) => <DeviceSettingsGeneral {...props} routerOutlet={this.routerOutlet.current!}  />} />
                            
                            <Route path="/device/:id/settings/suscription" component={DeviceSettingsSuscription} />
                            <Route path="/device/:id/settings/output" render={(props) => <DeviceSettingsOutput {...props} routerOutlet={this.routerOutlet.current!} />} />
                            <Route path="/device/:id/settings/inputGroup/:inputGroup/input/:input" exact render={(props) => <DeviceSettingsInput {...props} routerOutlet={this.routerOutlet.current!} />}/>
                            <Route path="/device/:id/settings/inputGroup/:inputGroup" exact render={(props) => <DeviceSettingsInputGroup {...props} routerOutlet={this.routerOutlet.current!} />}/>
                            <Route path="/device/:id/settings/input" exact render={(props) => <DeviceSettingsInputGroupList {...props} routerOutlet={this.routerOutlet.current!} />}/>
                            <Route path="/device/:id/settings/share" component={DeviceSettingsShare} />
                            <Route path="/device/:id/settings/plugins" component={DeviceSettingsPlugins} />
                            <Route path="/device/:id/settings/services" component={DeviceSettingsService} />

                            <Route path="/device/:id/settings/hardware_relay" component={DeviceSettingsHardwareRelays} />
                            <Route path="/device/:id/settings/hardware_analog" component={DeviceSettingsHardwareAIN} />
                            <Route path="/device/:id/settings/hardware_temp" component={DeviceSettingsHardwareTemp} />
                            <Route path="/device/:id/settings/hardware_digital" component={DeviceSettingsHardwareDIN} />
                            <Route path="/device/:id/settings/hardware_timer" component={DeviceSettingsHardwareTimer} />
                            <Route path="/device/:id/settings/hardware_interlock" component={DeviceSettingsHardwareInterlock} />
                            <Route path="/device/:id/settings/hardware_modbus" component={DeviceSettingsHardwareModbus} />
                            <Route path="/device/:id/settings/hardware_backup" component={DeviceSettingsHardwareBackup} />

                            <Route path="/device/:id/settings/modicon_timer" component={DeviceSettingsModiconTimer} />

                            <Route path="/device/:id/settings/remotic_inputs" component={DeviceSettingsRemoticAnalog} />
                            <Route path="/device/:id/settings/remotic_automation" component={DeviceSettingsRemoticAutomation} />
                            <Route path="/device/:id/settings/remotic_timer" component={DeviceSettingsRemoticTimer} />
                            <Route path="/device/:id/settings/modbus" component={DeviceSettingsModbus} />
                            <Route path="/device/:id/settings/notifications" component={DeviceSettingsNotifications} />
                            
                            <Route path="/device/:id/output/:output/servicePanel" component={DeviceOutputService} />
                            
                            <Route path="/device/:id/status/input/:input" component={DeviceGraphInput} exact />
                            <Route path="/device/:id/status/input/:input/search" component={DeviceGraphInputSearch} exact />
                            <Route path="/device/:id/status/input/:input/timetable" component={DeviceGraphInputTimeTable} exact />

                            <Route path="/device/:id/:tab(details)" render={(props) => <DeviceDetails {...props} routerOutlet={this.routerOutlet.current!} />} />
                            <Route path="/device/:id/:tab(status)" component={DeviceStatus} exact />
                            <Route path="/device/:id/:tab(log)" component={DeviceLog} />
                            <Route path="/device/:id/:tab(settings)" component={DeviceSettings} exact />


                            
                            <Route path="/" exact={true} render={() => <Redirect to="/dashboard" />} />
                            <Route>
                                <IonButton routerLink={"/"} >{lang?.l.main.tab_back()}</IonButton>
                            </Route>
                        </IonRouterOutlet>
                        <IonTabBar slot={"bottom"}>
                            <IonTabButton href={"/device/" + this.props.match.params.id + "/details"} tab="details">
                                <IonIcon icon={apps} />
                                <IonLabel>{lang?.l.main.tab_control()}</IonLabel>
                            </IonTabButton>
                            <IonTabButton href={"/device/" + this.props.match.params.id + "/status"} tab="status">
                                <IonIcon icon={analyticsOutline} />
                                <IonLabel>{lang?.l.main.tab_status()}</IonLabel>
                            </IonTabButton>
                            <IonTabButton href={"/device/" + this.props.match.params.id + "/log"} tab="log">
                                <IonIcon icon={documentOutline} />
                                <IonLabel>{lang?.l.main.tab_log()}</IonLabel>
                            </IonTabButton>
                            <IonTabButton href={"/device/" + this.props.match.params.id + "/settings"} tab="settings">
                                <IonIcon icon={cogOutline} />
                                <IonLabel>{lang?.l.main.tab_settings()}</IonLabel>
                            </IonTabButton>
                        </IonTabBar>
                    </IonTabs>
                </IonContent>
            </div>


        </IonSplitPane>
    }
}

export default withRouter(DeviceSplitPane);
