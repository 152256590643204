import {API, Device, getAPI, User} from "./API";
import LoginService from "./LoginService";
import {observable} from "mobx";
import {loadStripe, Stripe} from "@stripe/stripe-js";
import socketIOClient, { Socket } from "socket.io-client";
import EventsPool from "./EventsPool";
import Push from "./Push";
import config from "../config";
import SerialPort from "./SerialPort";
import APICache from "./APICache";
import {IonReactRouter} from "@ionic/react-router";
import Utils from "./Utils";
import moment, { Moment } from "moment-timezone";
import { Capacitor } from "@capacitor/core";
import WatchExtension from "./WatchExtension";
import { initializeApp } from 'firebase/app';

import LocationService from "./LocationService";
import OpenWeatherMap from 'openweathermap-ts';
import Siri from "./Siri";
import LocationRouteTrackingService from "./LocationRouteTrackingService";
import AppActionsManager from "./AppActionsManager";
import RemoteSupportService from "./RemoteSupportService";

export type SocketCallback = (event:string, data?: any) => void;

const firebaseConfig = {
    apiKey: "AIzaSyB3-sS89ajHwoHlrO8XJS4d-rSz2RX7hzE",
    authDomain: "cloudrtu-661bb.firebaseapp.com",
    databaseURL: "https://cloudrtu-661bb.firebaseio.com",
    projectId: "cloudrtu-661bb",
    storageBucket: "cloudrtu-661bb.appspot.com",
    messagingSenderId: "1024774355658",
    appId: "1:1024774355658:web:13298f843e9befbe7dd233",
    measurementId: "G-Z615N0YVV4"
  };
initializeApp(firebaseConfig);

export class Store {
    api:API = getAPI();
    login:LoginService = LoginService.newInstance();
    board:{device?: Device|null} = observable({
        device: null,
    });
    uiPref:{darkMode: boolean} = observable({
        darkMode: false,
    })
    stripe:Promise<Stripe | null>;
    private _user?:User;
    socket?:Socket;

    events:EventsPool = new EventsPool();
    currentDevice?:any;
    push:Push;

    updates = "Production"
    _cache = new APICache(this);
    location:LocationService = new LocationService(this.api);
    locationRouteTracking:LocationRouteTrackingService = new LocationRouteTrackingService(this.api);

    weatherMap: OpenWeatherMap;
    siri ?: Siri;
    appActions: AppActionsManager;
    remoteSupport?: RemoteSupportService;
    private _router?:IonReactRouter;
    private clientId : string;

    private _timeZone = "Europe/Madrid";

    private watch!: WatchExtension;
    public get timeZone() {
        return this._timeZone;
    }
    public set timeZone(value) {
        this._timeZone = value;
    }

    constructor() {
        // @ts-ignore
        window.Store = this;

        this.api.token = this.login.token();
        if(!this.getItem("clientId")) {
            let uuid = Utils.generateUUID();
            this.setItem("clientId", uuid);
        }
        this.clientId = this.getItem("clientId")!;
        this.api.clientId = this.clientId;
        this.push = new Push(this.api);
        this.weatherMap = new OpenWeatherMap({apiKey: config.FORECAST_API_KEY, units: 'metric', language: 'es'});

        if(this.login.isLogin()) {
            this.setupSocket();
            this.getUser(true).then(value => {
                if(value) {
                    this.events.emit('user', value);
                }
            })
        }
        this.stripe = loadStripe(config.STRIPE);

        // if(window.matchMedia) {
        //     const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        //     prefersDark.addListener(this.handleThemePrefers);
        //     this.uiPref.darkMode = prefersDark.matches;
        //     if(this.getItem('dark') === 'true') {
        //         this.uiPref.darkMode = true;
        //     }
        //     this.updateUiPrefs();
        // }

        this.updates = this.getItem("updates")??"Production";
        this.timeZone = this.getItem("timeZone")??this.timeZone;


        if(Capacitor.getPlatform() === 'ios') {
            this.watch = new WatchExtension(this);
            this.watch.init();
        }

        this.siri = new Siri(this);
        this.appActions = new AppActionsManager(this);
        this.remoteSupport = new RemoteSupportService(this);
    }

    startRemoteSupport(dom: any) {
        
        // this.remoteSupport = new RemoteSupportService(dom, this.socket);
    }
    handleThemePrefers = (ev: any) => {
        // this.setDarkMode(ev.matches);
    };

    getRemoteSupportPin() {
        let pin = this.getItem("remotePin");
        if(!pin) {
            pin = ((new Date()).getTime() % 9999).toString();
        }
        this.setItem("remotePin", pin);
        return pin;
    }

    async getUser(update:boolean) : Promise<User | undefined> {
        if(update) this._user = undefined;
        if(!this._user) {
            let result = await this.api.user();
            if(result.success) {
                this._user = result.data;
            }
        }
        return this._user;
    }

    async auth(user:string, pass:string, remember:boolean) {
        let result = await this.api.usersAuth(user, pass, this.clientId);
        try {
            if(result.success) {
                this.setToken(result.data.token, remember);
            }
            return result;
        } catch (e) {
            console.log(e);
            throw e;
        }
    }


    private setupSocket() {
        let url = this.api.url;
        // if(url.startsWith("http://")) {
        //     url = url.substring(5);

        // }
        // if(url.startsWith("https://")) {
        //     url = url.substring(6);
        // }
        this.socket = socketIOClient(url + "?token=" + this.login.token(), {transports: ["websocket", "xhr-polling", "htmlfile", "jsonp-polling"],secure: true });
        this.socket.on('alarm', (data:any) => this.events.emit('alarm', data));
        this.socket.on('automation', (data:any) => this.events.emit('automation', data));
        this.socket.on('schedulers', (data:any) => this.events.emit('schedulers', data));
        this.socket.on('online', (data:any) => this.events.emit('online', data));
        this.socket.on('offline', (data:any) => this.events.emit('offline', data));
        this.socket.on('dataX', (data:any) => this.events.emit('dataX', data));
        this.socket.on('update', (data:any) => this.events.emit('update', data));
        this.socket.on('verify', (data:any) => this.events.emit('verify', data));
        this.socket.on('permissions', (data:any) => this.events.emit('permissions', data));
        this.socket.on('inputs', (data:any) => this.events.emit('inputs', data));
        this.socket.on('location', (data:any) => this.events.emit('location', data));
        this.socket.on('notification', (data:any) => this.events.emit('notification', data));
        this.socket.on('alert', (data:any) => this.events.emit('alert', data));
        this.socket.on('input-data', (data:any) => this.events.emit('input-data', data));
        this.socket.on('support', (data: any) => {
            if (this.remoteSupport) {
                this.remoteSupport.handleCommand(data);
            }
        });
        this.socket.on('connect', () => {
            this.socket?.emit('ready');
        });
        this.socket.io.on('reconnect', () => {
            // this.socket?.emit('ready');
            this.events.emit('reconnect', []);
            if(this.currentDevice) {
                this.subscribeToDevice(this.currentDevice);
            }
        });
    }

    public setToken(token:string, persist: boolean = false) {
        this.login = LoginService.newInstance(persist);
        this.api.token = token;
        this.login.login(token);
        if(this.watch) {
            this.watch.setToken(token);
        }
        this.setupSocket();
    }

    subscribeToDevice(id:number) {
        if(this.socket) {
            this.currentDevice = id;
            this.socket.emit('device', id);
        } else {
            throw new Error("Socket not ready!");
        }
    }

    unsubscribeDevice() {
        if(this.socket && this.currentDevice) {
            // this.socket.emit('leave-device', this.currentDevice.id);
            this.currentDevice = false;
        }
    }

    logout() {
        this.login.logout();
        this.cache.clear();
        this._user = undefined;
        if(this.watch) {
            this.watch.setToken("");
        }
    }

    setItem(key:string, value:any) {
        if(value === undefined) {
            value = "";
        }
        if(value === "undefined") {
            value = "";
        }
        localStorage.setItem(key, value);
    }

    removeItem(key:string) {
        localStorage.removeItem(key);
    }

    getItem(key:string) {
        return localStorage.getItem(key);
    }



    setSessionItem(key:string, value:any) {
        sessionStorage.setItem(key, value);
    }

    removeSessionItem(key:string) {
        sessionStorage.removeItem(key);
    }

    getSessionItem(key:string) {
        return sessionStorage.getItem(key);
    }

    migrate() {
        let token = this.getItem("token");
        if(token) {
            this.setToken(token);
            this.removeItem("token");
            this.setItem("migrated", true);
            return true;
        }
        return false;
    }

    toggleDarkMode() {
        this.setDarkMode(!this.isDarkMode());
    }
    
    setDarkMode(state:boolean) {
        this.setItem('dark', state?'true':'false');
        this.uiPref.darkMode = state;
        this.updateUiPrefs();
    }

    isDarkMode() {
        return this.uiPref.darkMode;
    }

    updateUiPrefs() {
        document.body.classList.toggle('dark', this.uiPref.darkMode);
    }

    setUpdater(name:string) {
        this.updates = name;
        this.setItem('updates', name);
    }

    getSerialPort() {
        return new SerialPort(this.getItem("serialId")!);
    }

    get cache() {
        return this._cache;
    }


    get user(): User | undefined {
        return this._user;
    }

    set user(value: User| undefined) {
        this._user = value;
    }


    get router(): IonReactRouter {
        return this._router!;
    }

    set router(value: IonReactRouter) {
        this._router = value;
    }

    moment(updatedAt: Date | number | undefined | string, arg1 ?: string): Moment {
        return moment(updatedAt, arg1).tz(this.timeZone).locale('es');
    }

    formatLongDate(updatedAt: Date | number | undefined | string) {
        // L - M - X - J - V - S - D
        // 14 Abril 2024
        return moment(updatedAt).format("DD W MM/YY HH:mm");
    }

    getUploadsURL() {
        
    }
}
