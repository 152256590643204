import { Device, DeviceInputGroup, DeviceInputs, DeviceOutput, InputItemSettings, ModbusType, SchedulerOutput } from "./API";
import config from "../config";
import { ReferenceFormat, ReferenceInterpretation } from "../components/ModbusExplorer";
import { Capacitor } from "@capacitor/core";
import { Filesystem, FilesystemDirectory, FilesystemEncoding, WriteFileResult } from "@capacitor/filesystem";
import { Share } from "@capacitor/share";
import { alert, analytics, calendar, chevronUp, cloudOutline, eyeOff, information, map, moonOutline, rainyOutline, snowOutline, speedometer, stopwatch, sunnyOutline, text, thunderstormOutline, toggle } from "ionicons/icons";



export enum DeviceFlags {

    LOG_CONNECTION_EVENTS = 0b0000000001,
    LOG_AC_EVENTS = 0b0000000010,
    NOTIFY_EVENTS = 0b0000000100,
    NOTIFY_OUTPUTS = 0b0000001000,
    LOG_ERRORS = 0b0000010000,
    NOTIFY_ONLINE = 0b0000100000,
    NOTIFY_POWER = 0b0001000000,
    NOTIFY_SCHEDULER = 0b0010000000,
    NOTIFY_POWER_SCHEDULER = 0b0100000000,
    LOG_ALARMS = 0b1000000000,
    NOTIFY_INPUTS = 0b10000000000,

}

export default class Utils {
    static getInputIcon(input : DeviceInputs) {
        /*

                    <IonSelectOption value="hide">(Oculto)</IonSelectOption>
                    <IonSelectOption value="text">Texto</IonSelectOption>
                    <IonSelectOption value="perc">Porcentaje</IonSelectOption>
                    <IonSelectOption value="gaug">Manómetro</IonSelectOption>
                    <IonSelectOption value="onoff">ON/OFF</IonSelectOption>
                    <IonSelectOption value="pulses">Contador Pulsos</IonSelectOption>
                    <IonSelectOption value="latlng">Ubicación</IonSelectOption>
                    <IonSelectOption value="distance">Distancia</IonSelectOption>
                    <IonSelectOption value="flow">Flujo</IonSelectOption>
                    <IonSelectOption value="cierre">Cierre</IonSelectOption>
        */
        if(input.widget === 'hide') {
            return eyeOff;
        }
        if(input.widget === 'text') {
            return text;
        }
        if(input.widget === 'perc') {
            return information;
        }
        if(input.widget === 'gaug') {
            return speedometer;
        }
        if(input.widget === 'onoff') {
            return toggle;
        }
        if(input.widget === 'pulses') {
            return chevronUp;
        }
        if(input.widget === 'latlng') {
            return map;
        }
        if(input.widget === 'distance') {
            return analytics;
        }
        if(input.widget === 'flow') {
            return stopwatch;
        }
        if(input.widget === 'cierre') {
            return calendar;
        }
        return alert

        
    }
    static toTimeDuration(seconds: number, days : boolean = true, hour : boolean = true, minutes : boolean = true, dseconds : boolean = true) {
        var d = Math.floor(seconds / (3600 * 24));
        var h = Math.floor(seconds % (3600 * 24) / 3600);
        var m = Math.floor(seconds % 3600 / 60);
        var s = Math.floor(seconds % 60);

        var dDisplay = d > 0 && days ? d + (d === 1 ? " dia, " : " dias, ") : "";
        var hDisplay = h > 0 && hour ? h + (h === 1 ? " hora, " : " horas, ") : "";
        var mDisplay = m > 0 && minutes ? m + (m === 1 ? " minuto, " : " minutos, ") : "";
        var sDisplay = s > 0 && dseconds ? s + (s === 1 ? " segundo" : " segundos") : "";
        return dDisplay + hDisplay + mDisplay + sDisplay;
    }
    static modbusToString(v: ModbusType): string {
        return v.station + " fc " + v.functionCode + " 0x" + v.address.toString(16) + "[" + v.registers + "]";
    }
    static getOutputTitleByValue(output: DeviceOutput | SchedulerOutput, state: string): string {
        if (!output || !output.values) return state;
        for (let i = 0; i < output.values!.length; i++) {
            const element = output.values![i];
            if (element.value === parseFloat(state)) return element.title;
        }

        return state;
    }

    static log(...args: any[]) {
        if (config.DEBUG) {
        }
        console.log(...args);
        // console.trace.call(this, args);
    }

    static isDesktop() {
        return window.innerWidth > 700;
    }
    static getInputGroupByIndex(inputs: DeviceInputGroup[], iindex: number) {
        if (!inputs) return false;
        for (let i = 0; i < inputs.length; i++) {
            if (Number(inputs[i].iindex) === iindex) return inputs[i];
        }
        return false;
    }
    static getInputByIndex(inputs: DeviceInputs[], iindex: number) {
        if (!inputs) return false;
        for (let i = 0; i < inputs.length; i++) {
            if (Number(inputs[i].index) === iindex) return inputs[i];
        }
        return false;
    }
    static getInputById(inputs: DeviceInputGroup[], id: number): boolean | DeviceInputGroup {
        for (let i = 0; i < inputs.length; i++) {
            if (Number(inputs[i].id) === id) return inputs[i];
        }
        return false;
    }

    static getSubInputById(inputs: DeviceInputGroup[], id: number): DeviceInputs | undefined {
        for (let i = 0; i < inputs.length; i++) {
            for (let j = 0; j < inputs[i].inputs.length; j++) {
                const element = inputs[i].inputs[j];
                if (element.id === id) return element;
            }
        }

    }

    static getSubOutputByIndex(data: any[], index2: number) {
        for (let i = 0; i < data.length; i++) {
            if (Number(data[i].output) === index2) return data[i];
        }
        return false;
    }

    // static getInputSettings(input: DeviceInputs) : InputSettings {
    //     let settings:any = [];
    //     if(input.settings) {
    //         settings = (input.settings!)
    //     }
    //     if((!(settings instanceof Array) || settings.length === 0) && (settings as ServiceSettings).name === undefined) {
    //         settings = Utils.createDefaultSettings(input, Utils.fromJSON(input.data!));
    //     }
    //     return settings;
    // }
    // static getSubInputSettings(inputs: DeviceInputs[], iindex : number, index: number) : InputItemSettings | undefined {
    //     let input = this.getInputByIndex(inputs, iindex);
    //     if(input) {
    //         let settings:any = Utils.getInputSettings(input);
    //         if(settings.inputs) return settings.inputs[index]
    //         return settings[index];
    //     }
    // }

    static getInputName(device: Device, input: DeviceInputs) {
        if (input.type === 'output') {
            let output = Utils.getOutputByIndex(device.outputs, input.index);
            if (output) {
                return output.name;
            }
        }
        return input.name;
    }
    /**
     *
     * @deprecated
     * @param inputs
     * @param iindex
     * @param index
     */
    static getSubInputName(inputs: DeviceInputGroup[], iindex: number, index: number) {
        if (inputs[iindex] && inputs[iindex].inputs && inputs[iindex].inputs[index]) {
            return inputs[iindex].inputs[index].name;
        }
        return "Entrada " + (index + 1);

    }

    static getInputBySubInputGroup(inputs: DeviceInputGroup[], iindex: number, index: number) {
        for (let i = 0; i < inputs.length; i++) {
            if (inputs[i].iindex === iindex) {
                for (let j = 0; j < inputs[i].inputs.length; j++) {
                    if (inputs[i].inputs[j].index === index) {
                        return inputs[i].inputs[j];
                    }
                }
            }
        }
        return null;
    }
    static getNameBySubInputGroup(inputs: DeviceInputGroup[], iindex: number, index: number) {
        let input = this.getInputBySubInputGroup(inputs, iindex, index);
        if (input) {
            return input.name;
        }
        return "Entrada " + (iindex + 1) + "/" + (index + 1);
    }

    static getOutputByIndex(outputs: DeviceOutput[], oindex: number) {
        for (let i = 0; i < outputs.length; i++) {
            if (Number(outputs[i].oindex) === oindex) return outputs[i];
        }
    }
    static getOutputById(outputs: DeviceOutput[], id: number) {
        for (let i = 0; i < outputs.length; i++) {
            if (Number(outputs[i].id) === id) return outputs[i];
        }
    }
    static getOutputNameByIndex(outputs: DeviceOutput[], oindex: number, fallback: string = "Salida " + (oindex + 1)) {
        for (let i = 0; i < outputs.length; i++) {
            if (Number(outputs[i].oindex) === oindex) return outputs[i].name;
        }
        return fallback;
    }

    static translateWeek(week: string) {
        let w = ['L', 'M', 'X', 'J', 'V', 'S', 'D'];
        let r = ""
        for (let i = 0; i < 7; i++) {
            r += (week[i] === "1" ? w[i] : "-");
        }
        return r;
    }

    static haveFlag(flags: number, flag: number): boolean {
        return !!(flags & flag);
    }

    static setFlag(flags: number, flag: number, enable: boolean): number {
        if (enable) {
            return flags | flag;
        } else {
            return flags & ~flag;
        }
    }

    static fromJSON(json: string) {
        if (!(typeof json === "string")) return json;
        try {
            return JSON.parse(json);
        } catch (e) {
            return {};
        }
    }
    //
    // static createDefaultSettings(input : DeviceInputs, data:any[]):InputSettings {
    //     if(!data) return [];
    //     if(input.type === 'service') {
    //         return {name: '', user: '', pass: '', interval: 60};
    //     }
    //     return data.map((value, index) => {
    //         if(input.type === "analog" && value.input >= 0) return {type: 'text', name: 'Entrada analógica ' + (index + 1)};
    //         if(input.type === "digital" && value.input >= 0) return {type: 'text', name: 'Entrada digital ' + (index + 1)};
    //         if(input.type === "status" && value.output >= 0) return {type: 'text', name: 'Salida ' + (index + 1)};
    //         if(input.type === "status" && value.voltage !== undefined) return {type: 'text', name: 'Voltage'};
    //         if(input.type === "status" && value.signal !== undefined) return {type: 'text', name: 'Cobertura'};
    //         if(input.type === "temp" && Number(value.input) === 0) return {type: 'text', name: 'Temperatura'};
    //         if(input.type === "temp" && Number(value.input) === 1) return {type: 'text', name: 'Humedad'};
    //         return {type: "text", name: "Desconocido " + (index + 1)};
    //     });
    // }

    static toBoolean(value: string | boolean | number) {
        switch (value) {
            case true:
            case "true":
            case 1:
            case "1":
            case "on":
            case "yes":
            case "1.00":
                return true;
            default:
                return false;
        }
    }

    static sortInputsGroup(list: DeviceInputGroup[]) {
        return list.sort((a: DeviceInputGroup, b: DeviceInputGroup) => (a.iindex - b.iindex)).sort((a: DeviceInputGroup, b: DeviceInputGroup) => (a.display_order - b.display_order));
    }
    static sortInputs(list: DeviceInputs[]) {
         return list.sort((a: DeviceInputs, b: DeviceInputs) => (a.display_order - b.display_order));
    }
    static sortOutputs() {
        return (a: DeviceOutput, b: DeviceOutput) => a.oindex - b.oindex;
    }

    static getVersion(): string {
        // @ts-ignore
        return window.APP_VERSION;
    }

    static update() {
        if (navigator.serviceWorker && navigator.serviceWorker.getRegistration) {

            navigator.serviceWorker.getRegistration().then(function (reg) {
                if (reg) {
                    reg.unregister().then(function () { window.location.reload(); });
                } else {
                    window.location.reload();
                }
            });
        }
    }

    static translateSubscriptionStatus(status: string) {
        /*

    UNCONFIGURED: 'unconfigured',
    CONFIGURED: 'configured',
    CANCELLED: 'cancelled',
    PAYMENT_ERROR: 'payment_error',
         */
        if (status === 'unconfigured') {
            return "Pendiente de configurar";
        }
        if (status === 'configured') {
            return "Activa";
        }
        if (status === 'cancelled') {
            return "Cancelada";
        }
        if (status === 'payment_error') {
            return "Error en el pago";
        }
        return "?"
    }

    static getStatus(device: Device, iindex: number): { value: string } | undefined {
        if (device.inputGroups) {
            let inputGroup = Utils.getInputGroupByIndex(device.inputGroups, 0);
            if (inputGroup && inputGroup.inputs) {
                let input = Utils.getInputByIndex(inputGroup.inputs, iindex);
                if (input) {
                    return { value: String(input.value) };
                }
            }
        }
        return { value: '' };
    }

    static getInputForOutput(device: Device, oindex: number): DeviceInputs | undefined {
        let inputGroup = Utils.getInputGroupByIndex(device.inputGroups, 0);
        if (inputGroup && inputGroup.inputs) {
            return Utils.getInputByIndex(inputGroup.inputs, oindex) || undefined;
            
        }
        return undefined;
        
    }

    static parseDeviceInputs(device: Device): InputInfoData[] {
        // TODO:
        return [];
        // return device.inputs.map((value, index) => {
        //     let data = Utils.fromJSON(value.data!);
        //     let current: InputData[] = [];
        //     if(data) {
        //         for (let i = 0; i < data.length; i++) {
        //             let subItem = data[i];
        //             if(subItem.text !== undefined) {
        //                 current.push({
        //                     title: Utils.getSubInputName(device.inputs, value.iindex, i),
        //                     value: subItem.text,
        //                 })
        //             }
        //             if(subItem.output !== undefined) {
        //                 let out = Utils.getOutputByIndex(device.outputs, subItem.output);
        //                 if(out) {
        //                     current.push({
        //                         title: out.name,
        //                         value: subItem.value,
        //                     })
        //                 }
        //             }
        //             if(subItem.input !== undefined) {
        //                 let settings = Utils.getSubInputSettings(device.inputs!, value.iindex, subItem.input);
        //                 current.push({
        //                     title: settings!.name,
        //                     value: subItem.value + " " + (settings!.unit??""),
        //                 })
        //             }
        //             if(subItem.signal !== undefined) {
        //                 current.push({
        //                     title: "GSM",
        //                     value: subItem.signal,
        //                 })
        //             }
        //             if(subItem.voltage !== undefined) {
        //                 current.push({
        //                     title: "Voltage",
        //                     value: subItem.voltage,
        //                 })
        //             }
        //         }
        //     }
        //     return {input: value, data: current};
        // });

    }

    static readAsset(name: string) {
        return fetch("assets/" + name);
    }

    static getGraphProps(input: DeviceInputGroup, i: number): InputItemSettings {
        let colors = ["#40ad3a", "#32adbc", "#ffce00", "#f25454", "#ffffff"]
        let props = Utils.fromJSON(input.graphProps!) || [];
        if (props[i]) {
            return props[i];
        }
        return { color: colors[i % colors.length] };
    }

    static clearUrl(url: string) {
        if (url.indexOf("http") >= 0) {
            url = url.substring(url.indexOf("://") + 3);
            url = url.substring(url.indexOf("/"));
        }
        return url;
    }


    static generateUUID() {
        var d = new Date().getTime();
        if (window.performance && typeof window.performance.now === "function") {
            d += performance.now(); //use high-precision timer if available
        }
        var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (d + Math.random() * 16) % 16 | 0;
            d = Math.floor(d / 16);
            return (c === 'x' ? r : ((r & 0x3) | 0x8)).toString(16);
        });
        return uuid;
    }

    static convertReference(format: string, reference: any): number {
        if (reference && reference.meta && reference.meta.addrFormat) {
            let addrFormat = reference.meta.addrFormat;
            if (format.length === 3) {
                format = " " + format;
            }
            let result = 0;
            for (let i = 0; i < addrFormat.length; i++) {
                const element = addrFormat[i];
                let s = format.substr(format.length - (element.offset + element.size), element.size);
                if (element.base) {
                    // Convert to base
                    let n = parseInt(s, element.base);
                    result += (n << (element.offset * 4));
                } else if (element.map) {
                    let item = element.map[s.trim()];
                    result += (item << (element.offset * 4));
                }
            }

            return result;
        } else {
            return parseInt(format);
        }
    }

    static getFormatById(id: number, format: any[]) {
        for (let i = 0; i < format.length; i++) {
            const element = format[i];
            if (element.id === id) return element;
        }
        return;
    }

    static interpretateFormat(value: number, format: ReferenceFormat): ReferenceInterpretation[] {
        if (!format || !format.variables) {
            return [];
        }
        let result: ReferenceInterpretation[] = [];
        for (let i = 0; i < format.variables.length; i++) {
            const element = format.variables[i];
            let op = (value >> element.offset) & element.bits;

            if (element.type === 'map') {
                if (element.map) {
                    result.push({ name: element.name, value: element.map[op], format: element });
                } else {
                    throw new Error("Reference invalid. Element defined as map but no definiiton found for format.id = " + format.id);
                }
            } else if (element.type === 'integer') {
                result.push({ name: element.name, value: op, format: element });
            } else if (element.type === 'function') {
                // eslint-disable-next-line
                op = eval('(function(value) {' + element.fromDecimal + '})(' + op + ')');

                result.push({ name: element.name, value: op, format: element });
            }
        }


        return result;
    }

    static formatFromInterpretation(interpretation: ReferenceInterpretation[]) {
        let result: number = 0;

        for (let i = 0; i < interpretation.length; i++) {
            let n: number = 0;
            const element = interpretation[i];
            if (element.format.type === 'map') {
                let value = element.value;
                for (const key in element.format.map) {
                    if (Object.prototype.hasOwnProperty.call(element.format.map, key)) {
                        const element2 = element.format.map[key as any];
                        if (element2 === value) {
                            n = parseInt(key);
                            break;
                        }
                    }
                }
            } else if (element.format.type === 'integer') {
                n = parseInt(element.value);
            } else if (element.format.type === 'function') {
                // eslint-disable-next-line
                n = eval('(function(value) {' + element.format.toDecimal + '})(' + element.value + ')');
            }

            result += ((n & element.format.bits) << element.format.offset);
        }
        return result;

    }

    static millisecondsToStr(seconds: number) {
        var levels = [
            [Math.floor(seconds / 31536000), 'años'],
            [Math.floor((seconds % 31536000) / 86400), 'días'],
            [Math.floor(((seconds % 31536000) % 86400) / 3600), 'horas'],
            [Math.floor((((seconds % 31536000) % 86400) % 3600) / 60), 'minutos'],
            [(((seconds % 31536000) % 86400) % 3600) % 60, 'segundos'],
        ];
        var returntext = '';

        for (var i = 0, max = levels.length; i < max; i++) {
            if (levels[i][0] === 0) continue;
            returntext += ' ' + levels[i][0] + ' ' + (levels[i][0] === 1 ? String(levels[i][1]).substr(0, String(levels[i][1]).length - 1) : levels[i][1]);
        };
        return returntext.trim();
    }

    static downloadFile(contents: string, name: string, mimeType: string = "text/plain;charset=utf-8") {
        if (Capacitor.getPlatform() === "web") {
            let meta = "data:" + mimeType + ",";
            var encodedUri = encodeURI(meta + contents).replace(/#/g, '%23');
            var link = document.createElement("a");
            link.setAttribute("href", encodedUri);
            link.setAttribute("download", name);
            document.body.appendChild(link); // Required for FF
            link.click();
            return new Promise(resolve => { resolve(true) });
        } else {


            return new Promise((resolve, reject) => {
                Filesystem.writeFile({
                    path: name,
                    data: contents,
                    directory: FilesystemDirectory.Documents,
                    encoding: FilesystemEncoding.UTF8,
                }).then(async (value: WriteFileResult) => {
                    let file = value.uri;
                    if (!value.uri) {
                        let uri = (await Filesystem.getUri({
                            directory: FilesystemDirectory.Documents,
                            path: name,
                        }));
                        if (uri) {
                            file = uri.uri;
                        }
                    }
                    return Share.share({
                        title: name,
                        url: file,
                        dialogTitle: 'Compartir',
                    }).then(() => resolve(true)).catch(error => {
                        reject(error);
                    });
                })

            })
        }
    }

    static getIconById(id: number, day: boolean) {
        if (id >= 200 && id <= 232) {
            return thunderstormOutline;
        }
        if (id >= 300 && id <= 321) {
            return rainyOutline;
        }
        if (id >= 500 && id <= 531) {
            return rainyOutline;
        }
        if (id >= 600 && id <= 622) {
            return snowOutline;
        }
        if (id >= 701 && id <= 781) {
            return cloudOutline;
        }
        if (id === 800) {
            if (day) {
                return sunnyOutline
            } else {
                return moonOutline
            }
        }
        if (id >= 801) {
            return cloudOutline
        }
    }

    static uploadFile(mimeType: string = "*"): Promise<{ data: string, file: File }> {
        return new Promise((resolve, reject) => {

            if (Capacitor.getPlatform() === 'web') {
                let input = document.createElement("input");
                input.setAttribute("type", "file");
                input.setAttribute("accept", mimeType);
                input.addEventListener('change', (ev) => {
                    if (input.files) {
                        let f = input.files[0];
                        var fr = new FileReader();
                        fr.onload = function () {
                            resolve({ data: fr.result!.toString(), file: f });
                        }
                        fr.onerror = (er) => {
                            reject(er);
                        }
                        fr.readAsText(f);
                    }
                    console.log("change", ev, input.files![0]);
                });
                input.style.display = 'none';
                input.click();

            } else {
                reject("Only allowed on Website");
            }
        })
    }


    static scale(number: number, [inMin, inMax]: number[], [outMin, outMax]: number[]) {
        // if you need an integer value use Math.floor or Math.ceil here
        return (number - inMin) / (inMax - inMin) * (outMax - outMin) + outMin;
    }

    static getPaymentMethods(flag: number) {
        let array : any = {
            'stripe':           0b0001,
            'sepa':             0b0010,
            'transferencia':    0b0100,
        };
        let text : any = {
            'stripe': 'Pasarela de pagos Stripe',
            'sepa': 'Adeudo SEPA',
            'transferencia': 'Transferencia Manual',
        };
        let res = [];
        for (const key in array) {
            if (Object.prototype.hasOwnProperty.call(array, key)) {
                const element = array[key as any];
                if ((flag & element) === element) {
                    res.push({
                        title: text[key],
                        value : key,
                    })
                }
                
            }
        }
        return res;

    }
    
    static b64toBlob(base64: string, type = 'application/octet-stream') {
        return fetch(`data:${type};base64,${base64}`).then(res => res.blob());
    }

    static inputDataToValue(data: number, input: DeviceInputs) : string {
        if (input.widget === 'onoff') {
            return data ? input.on_value! : input.off_value!;
        }
        return String(data);
    }
}

export type InputInfoData = { input: DeviceInputs, data: InputData[] }
export type InputData = { title: string, value: any }
