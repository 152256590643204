import ModbusClient from "./ModbusClient";
import ModbusRTUClientRequestHandler from "./ModbusRTUClientRequestHandler";
import ModbusSocket from "./ModbusSocket";
import ModbusRTUClientResponseHandler from "./ModbusRTUClientResponseHandler";

export default class ModbusRTUClient extends ModbusClient {

    constructor(socket:ModbusSocket, address: number = 1) {
        super(socket);
        this.requestHandler = new ModbusRTUClientRequestHandler(this, address);
        this.responseHandler = new ModbusRTUClientResponseHandler()
    }
}
