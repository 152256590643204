import React from "react";
import {
    IonContent,
    IonHeader,
    IonItem,
    IonLabel,
    IonPage,
    IonSpinner,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../service/Store";

@inject("store", "lang")
export default class Invitation extends React.Component<any, any> {

    componentDidMount(): void {
        let token = this.props.match.params.token;
        this.setState({loading: true});
        let store:Store = this.props.store;
        store.api.invitation(token).then(value => {
            this.setState({loading: false, invitation: value});
        })

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        if(!this.state) return "";
        const {lang} = this.props;
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle>{lang?.l.main.invitation()}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent className={"ion-padding"}>
                <IonText>
                    <div hidden={!this.state.loading}><IonSpinner /> {lang?.l.main.loading()} </div>
                    <div hidden={!this.state.invitation}>
                        <IonItem color={"success"}>
                            <IonLabel>{lang?.l.main.invitation_accepted()}</IonLabel>
                        </IonItem>
                        <IonItem button routerLink={"/devices"}>
                            <IonLabel>{lang?.l.main.see_devices()}</IonLabel>
                        </IonItem>
                    </div>
                </IonText>
            </IonContent>
        </IonPage>
    }
}
