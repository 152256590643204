import Registers from "../Registers";
import {MemoryAddress} from "./MemoryMapper";

export default class RS485V1 extends Registers {

    RS232_Baud_Rate:number = 0;
    RS232_Data_bits:number = 0; // Constante = 3
    RS232_Stop_bits:number = 0; // 0 , 1
    RS232_Check_bits:number = 0; // 0 - none, 1 - even, 2 - odd
    RS232_setting:number = 0;

    RS485_Interval_Time:number = 0; // Intervalo
    RS485_Over_Time:number = 0; // Min value 200
    RS485_NoData_Time:number = 0;  // 60 s
    RS485_Baud_Rate:number = 0;
    RS485_Data_bits:number = 0; // Constante = 3
    RS485_Stop_bits:number = 0;  // 0 , 1
    RS485_Check_bits:number = 0; // 0 - none, 1 - even, 2 - odd
    RS485_setting:number = 0;  // 0 - None, 1 - Modbus RTU Master, 2 - Modbus RTU Slave, 3 - Transparent Transmission
    RS485_time:number = 0; // Constante 0

    async Read(): Promise<any> {

        let read = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.RS485), 16);
        this.RS232_Baud_Rate = (read[0] << 16) + read[1];
        this.RS232_Data_bits = read[2];
        this.RS232_Stop_bits = read[3];
        this.RS232_Check_bits = read[4];

        this.RS485_Interval_Time = read[5];
        this.RS485_Over_Time = read[6];
        this.RS485_NoData_Time = read[7];
        this.RS485_Baud_Rate = (read[8] << 16) + read[9];
        this.RS485_Data_bits = read[10];
        this.RS485_Stop_bits = read[11];
        this.RS485_Check_bits = read[12];
        this.RS485_setting = read[13];
        this.RS485_time = read[14];

        return true;
    }

    async Write(): Promise<any> {
        let data = [];
        data.push((this.RS232_Baud_Rate >> 16) & 0xFFFF);
        data.push((this.RS232_Baud_Rate) & 0xFFFF);
        data.push(this.RS232_Data_bits);
        data.push(this.RS232_Stop_bits);
        data.push(this.RS232_Check_bits);
        data.push(this.RS485_Interval_Time);
        data.push(this.RS485_Over_Time);
        data.push(this.RS485_NoData_Time);

        data.push((this.RS485_Baud_Rate >> 16) & 0xFFFF);
        data.push((this.RS485_Baud_Rate) & 0xFFFF);
        data.push(this.RS485_Data_bits);
        data.push(this.RS485_Stop_bits);
        data.push(this.RS485_Check_bits);
        data.push(this.RS485_setting);
        data.push(this.RS485_time);

        this.writeMultipleRegisters(this.mapper.get(MemoryAddress.RS485), data);
        return this.writeMultipleRegisters(79, [1]);
    }
}
