import React from "react";
import { IonBackButton, IonButton, IonButtons, IonContent, IonFab, IonFabButton, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonPage, IonReorder, IonReorderGroup, IonTitle, IonToolbar, ItemReorderEventDetail } from "@ionic/react";
import { add, checkmark } from "ionicons/icons";
import { inject } from "mobx-react";
import { RouteComponentProps } from "react-router";
import { DeviceInputGroup } from "../../service/API";
import { Store } from "../../service/Store";
import Utils from "../../service/Utils";
export interface DeviceSettingsInputGroupListProps extends RouteComponentProps<{ id: string, input?: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}

export type DeviceSettingsInputGroupListState = {
    loading?: boolean,
    inputGroups?: DeviceInputGroup[],
    device_id?: number,

    order: boolean
}

@inject("store", "lang")
export default class DeviceSettingsInputGroupList extends React.Component<DeviceSettingsInputGroupListProps, DeviceSettingsInputGroupListState> {

    state: DeviceSettingsInputGroupListState = {
        order: false
    };

    unregister: any;
    componentDidMount(): void {
        this.loadDevice();
        this.unregister = this.props.history.listen(location => {
            if (this.props.history.action === 'POP') {
                this.loadDevice();
            }
        })
    }

    componentWillUnmount() {
        if (this.unregister) {
            this.unregister();
        }

    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsInputGroupListProps>, prevState: Readonly<DeviceSettingsInputGroupListState>, snapshot?: any): void {
        if(prevProps.match.params.id !== this.props.match.params.id) {
            this.loadDevice();
        }
    }

    loadDevice() {
        this.updateData()!.then(() => {

            // if (this.props.match.params.input !== undefined) {
            //     let iindex = parseInt(this.props.match.params.input);
            //     let input = Utils.getInputGroupByIndex(this.state.inputGroups!, iindex);
            //     if (input) {
            //         this.setState({ inputGroup: input });
            //     }
            // }
        });
    }


    handleAdd = (ev: any) => {
        this.setState({ loading: true });

        this.props.store?.api.deviceInputGroupAdd(this.state.device_id!, "modbus").then(value => {
            this.setState({ loading: false });

            this.updateData();
            if (value.success) {
                this.handleEdit(value.data);
            }
        });
    }
    async updateData() {
        let id = this.props.match?.params.id!;
        this.setState({ loading: true, device_id: parseInt(id) });
        let value = await this.props.store?.api.deviceSettingsInputGroupGet(Number(id));
        if (value && value.success) {
            this.setState({ inputGroups: Utils.sortInputsGroup(value.data) });
        }
        this.setState({ loading: false });
    }

    handleEdit = (input: DeviceInputGroup) => {
        let id = this.props.match?.params.id!;
        this.props.history.push("/device/" + id + "/settings/inputGroup/" + input.id)

    }

    handleReorderButton = () => {
        if (this.state.order) {
            this.handleSaveOrder();
        }
        this.setState({ order: !this.state.order })
    };

    handleSaveOrder() {
        let id = parseInt(this.props.match?.params.id!);

        this.setState({ loading: true });
        this.props.store?.api.deviceSettingsInputGroup(id, this.state.inputGroups!).finally(() => this.setState({ loading: false }));

    }

    doReorder = (event: CustomEvent<ItemReorderEventDetail>) => {
        // The `from` and `to` properties contain the index of the item
        // when the drag started and ended, respectively
        console.log('Dragged from index', event.detail.from, 'to', event.detail.to);
        let from = event.detail.from;
        let to = event.detail.to;

        let inputs = Utils.sortInputsGroup(this.state.inputGroups!);
        for (let index = 0; index < inputs.length; index++) {
            const element = inputs[index];
            element.display_order = index;
        }

        inputs[event.detail.from].display_order = to;

        if (to > from) {
            for (let index = from + 1; index < to + 1; index++) {
                inputs[index].display_order--;

            }

        } else {

            for (let index = to; index < from; index++) {
                inputs[index].display_order++;
                
            }
        }

        inputs = Utils.sortInputsGroup(inputs);
        // Finish the reorder and position the item in the DOM based on
        // where the gesture ended. This method can also be called directly
        // by the reorder group
        event.detail.complete();
        this.setState({ inputGroups: inputs });
    }

    render(): React.ReactNode {
        let id = this.props.match?.params.id!;
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Grupos de Entradas</IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={this.handleReorderButton}><IonIcon hidden={!this.state.order} icon={ checkmark } /> Ordenar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonReorderGroup disabled={!this.state.order} onIonItemReorder={this.doReorder}>
                    {this.state && this.state.inputGroups && this.state.inputGroups!.map((value, index) => {
                        return <IonItem key={value.id} button detail={!this.state.order} onClick={() => !this.state.order && this.handleEdit(value)}>
                            <IonLabel>{value.name}</IonLabel>
                            <IonReorder slot="end" />
                        </IonItem>

                    })}

                </IonReorderGroup>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton onClick={this.handleAdd}>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
        </IonPage>
    }
}