import React from "react";
import { IonAlert, IonButton, IonCol, IonGrid, IonIcon, IonLabel, IonListHeader, IonRow, IonSpinner, IonText } from "@ionic/react";
import { APIBase, Device, DeviceInputs, DeviceOutput, DeviceOutputGroup, LogOutput } from "../service/API";
import Utils from "../service/Utils";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import moment from "moment";
import Lang from "../service/Lang";
import { RouteComponentProps } from "react-router";
import { DefaultOutputGroup } from "../pages/DeviceSettings/DeviceSettingsOutput";
import { add, linkOutline, remove } from "ionicons/icons";
import DeviceLogActions from "./DeviceLogActions";
import DeviceStatus from "../pages/Device/DeviceStatus";

export interface DeviceOutputsProps extends RouteComponentProps<{}> {
    device: Device,
    store?: Store,
    lang?: Lang,
}
export type DeviceOutputsState = {
    output?: DeviceOutput,
    state: boolean,
    confirm: boolean,
    loading: any,
    set: boolean,
    select: boolean,
    fixed: boolean,
    process: boolean,
    setValue?: number,
}
@inject("store", "lang")
export default class DeviceOutputs extends React.Component<DeviceOutputsProps, DeviceOutputsState> {


    state: DeviceOutputsState = {
        state: false,
        confirm: false,
        select: false,
        fixed: false,
        process: false,
        set: false,
        output: undefined,
    } as DeviceOutputsState;
    componentDidMount(): void {
        // setInterval(() => {
        //     this.setState({});
        // }, 1000)
    }

    componentDidUpdate(prevProps: Readonly<DeviceOutputsProps>, prevState: Readonly<DeviceOutputsState>, snapshot?: any) {
        if (this.props.device !== prevProps.device) {
            this.setState({});
        }
    }


    handleClick = (output: DeviceOutput, state: boolean) => (ev: any) => {

        this.setState({
            output, state, confirm: true,
        })

    }
    handleSetValue = (output: DeviceOutput) => (ev: any) => {
        let status = Utils.getStatus(this.props.device, output.oindex);
        this.setState({
            set: true,
            setValue: parseFloat(status?.value!),
            output: output,
        })

    }
    handleSelectValue = (output: DeviceOutput) => (ev: any) => {
        let status = Utils.getStatus(this.props.device, output.oindex);

        this.setState({
            select: true,
            setValue: parseFloat(status?.value!),
            output: output,
        })

    }
    handleFixedValue = (output: DeviceOutput) => (ev: any) => {
        this.setState({
            fixed: true,
            output: output,
        })

    }

    handleServicePanel = (output: DeviceOutput) => (ev:any) => {
        this.props.history.push("/device/" + this.props.device.id + "/output/" + output.id + "/servicePanel");

    }

    handleResponse(response: APIBase<any>) {
        if (response.error) {
            if (response.error.code === "DEVICE_NOT_SUBSCRIBED") {
                //this.props.
                this.props.history.push("/device/" + this.props.device.id + "/settings/suscription")
            }
        }

    }


    handleConfirm = (ev: any) => {
        this.setState({ loading: { output: this.state.output, state: this.state.state } });

        this.props.store?.api.deviceOutputState(this.props.device.id, this.state.output?.oindex!, 0, this.state.state ? 1 : 0).then((response) => {
            this.handleResponse(response);
            this.setState({ loading: false });

        });

    }
    handleConfirmValue = (ev: any) => {
        this.setState({ loading: { output: this.state.output, state: this.state.state } });

        this.props.store?.api.deviceOutputState(this.props.device.id, this.state.output?.oindex!, 0, ev.input).then((response) => {
            this.handleResponse(response);
            this.setState({ loading: false });

        });

    }
    handleConfirmSelect = (ev: any) => {
        this.setState({ loading: { output: this.state.output, state: this.state.state } });

        this.props.store?.api.deviceOutputState(this.props.device.id, this.state.output?.oindex!, 0, ev).then((response) => {
            this.handleResponse(response);
            this.setState({ loading: false });

        });

    }

    handleConfirmFixed = (ev: any) => {
        this.setState({ loading: { output: this.state.output, state: this.state.state } });

        this.props.store?.api.deviceOutputState(this.props.device.id, this.state.output?.oindex!, 0, this.state.output?.fixed_value!).then((response) => {
            this.handleResponse(response);
            this.setState({ loading: false });

        });

    }
    handleConfirmProcess = (ev: any) => {
        this.setState({ loading: { output: this.state.output, state: this.state.state } });

        this.props.store?.api.deviceOutputState(this.props.device.id, this.state.output?.oindex!, 0, 0).then((response) => {
            this.handleResponse(response);
            this.setState({ loading: false });

        });

    }

    handleGroupExpand = (group: DeviceOutputGroup) => (ev: any) => {
        group.expanded = !group.expanded;
        this.props.store?.api.deviceSettingsOutputGroup(this.props.device.id, group);
        this.setState({});
    }

    handleInputClick = (input ? : DeviceInputs) => (ev:any) => {
        if(input) {
            this.props.history.push("/device/" + this.props.device.id + "/status/input/" + input.id);
        }
    }

    isLoading(output: DeviceOutput, state: boolean) {
        return (this.state && this.state.loading && this.state.loading.output === output && this.state.loading.state === state) || (output.sending && Utils.toBoolean(output.sending.state) === state);
    }

    timeRemaining(log: LogOutput) {
        let date = log.date + log.timeout;
        return moment(date * 1000).fromNow();
    }

    renderWait(log: LogOutput) {
        return <><IonText>Esperando al dispositivo para cambiar salida... Tiempo
            restante: {this.timeRemaining(log)}</IonText></>
    }

    renderOutputWait(output: DeviceOutput) {
        let log = output.sending!;
        return <IonRow>
            <IonCol size="12">{this.renderWait(log)}. Emitido desde {DeviceLogActions.getTriggerName(this.props.lang!, log.trigger)}</IonCol>
        </IonRow>
    }

    renderToggle(value: DeviceOutput) {
        let status = Utils.getStatus(this.props.device, value.oindex);
        let onoff = parseInt(status?.value!) === value.on_value;
        return <IonRow>
            <IonCol size="6"><IonButton color={"medium"} expand={"block"}
                fill={onoff ? "solid" : "outline"}
                onClick={this.handleClick(value, true)}>{value.name} {value.on_name || "ON"} <IonSpinner
                
                    hidden={!this.isLoading(value, true)} /> </IonButton> </IonCol>
            <IonCol size="6"><IonButton color={"medium"} expand={"block"}
                fill={!onoff ? "solid" : "outline"}
                onClick={this.handleClick(value, false)}>{value.name}  {value.off_name || "OFF"} <IonSpinner
                    slot={"end"} hidden={!this.isLoading(value, false)} /></IonButton></IonCol>
        </IonRow>
    }
    renderToggle2(value: DeviceOutput) {
        // let status = Utils.getStatus(this.props.device, value.oindex);
        // let onoff = Utils.toBoolean(status?.value!);

        return <IonRow>

        </IonRow>
        // return <IonRow>
        //     <IonCol size="6"><IonButton color={"medium"} expand={"block"}
        //                                 fill={onoff ? "solid" : "outline"}
        //                                 onClick={this.handleClick(value, true)}>{value.name} ON <IonSpinner
        //         hidden={!this.isLoading(value, true)}/> </IonButton> </IonCol>
        //     <IonCol size="6"><IonButton color={"medium"} expand={"block"}
        //                                 fill={!onoff ? "solid" : "outline"}
        //                                 onClick={this.handleClick(value, false)}>{value.name} OFF <IonSpinner
        //         slot={"end"} hidden={!this.isLoading(value, false)}/></IonButton></IonCol>
        // </IonRow>
    }

    renderValue(value: DeviceOutput) {
        let status = Utils.getStatus(this.props.device, value.oindex);
        return <IonRow>
            <IonCol size="12">
                <IonButton color={"medium"} expand={"block"}
                    fill={"solid"}
                    onClick={this.handleSetValue(value)}>{value.name} - {status?.value} {value.units ? value.units : ""}<IonSpinner
                        slot={"end"} hidden={!this.isLoading(value, false)} /></IonButton>
            </IonCol>
        </IonRow>
    }

    renderSelect(value: DeviceOutput) {
        let status = Utils.getStatus(this.props.device, value.oindex);
        let selected = value.values?.find(e => (e.value) === parseFloat(status?.value!));
        return <IonRow>
            <IonCol size="12">
                <IonButton color={"medium"} expand={"block"}
                    fill={"solid"}
                    onClick={this.handleSelectValue(value)}>{value.name} - {selected?.title} {value.units ? value.units : ""}<IonSpinner
                        slot={"end"} hidden={!this.isLoading(value, false)} /></IonButton>
            </IonCol>
        </IonRow>
    }

    renderProcess(value: DeviceOutput) {
        //let status = Utils.getStatus(this.props.device, value.oindex);
        return <IonRow>
            <IonCol size="12">
                <IonButton color={"medium"} expand={"block"}
                    fill={"solid"}
                    onClick={this.handleFixedValue(value)}>{value.name}<IonSpinner
                        slot={"end"} hidden={!this.isLoading(value, false)} /></IonButton>
            </IonCol>
        </IonRow>
    }
    renderServicePanel(value: DeviceOutput) {
        //let status = Utils.getStatus(this.props.device, value.oindex);
        return <IonRow>
            <IonCol size="12">
                <IonButton color={"medium"} expand={"block"}
                    fill={"solid"}
                    onClick={this.handleServicePanel(value)}>{value.name} <IonIcon icon={linkOutline} /> </IonButton>
            </IonCol>
        </IonRow>
    }

    renderFixed(value: DeviceOutput) {
        //let status = Utils.getStatus(this.props.device, value.oindex);
        return <IonRow>
            <IonCol size="12">
                <IonButton color={"medium"} expand={"block"}
                    fill={"solid"}
                    onClick={this.handleFixedValue(value)}>{value.name}<IonSpinner
                        slot={"end"} hidden={!this.isLoading(value, false)} /></IonButton>
            </IonCol>
        </IonRow>
    }

    renderInputType(value: DeviceOutput) {
        //let status = Utils.getStatus(this.props.device, value.oindex);
        let input = Utils.getSubInputById(this.props.device.inputGroups, value.input_id!);
        return <IonRow>
            <IonCol size="6">
                {value.name}
            </IonCol>
            <IonCol size="6" onClick={this.handleInputClick(input)}>
                {(input ? DeviceStatus.renderItemInput(this.props.lang!, input!) : "(ERROR)")}
            </IonCol>
        </IonRow>
    }

    renderOutputs() {
        if (!this.props.device.outputGroups) return;
        let groups = [DefaultOutputGroup, ...this.props.device.outputGroups];
        return groups.filter(a => a.visible).map((group, key) => {
            let outputs = this.props.device.outputs.filter(a => (group.id ? group.id === a.group_id : !a.group_id));
            return <>

                {!!group.id &&
                    <IonListHeader>
                        {group.expandable && <>
                            <IonButton onClick={this.handleGroupExpand(group)}><IonIcon icon={group.expanded ? remove : add}></IonIcon> &nbsp; {group.name}</IonButton>
                        </>}
                        {!group.expandable && <>
                            <IonLabel>{group.name}</IonLabel>
                        </>}

                    </IonListHeader>}

                <IonGrid className={"collapse-content " + (group.expanded ? "active" : "")}>
                    {outputs.filter(a => a.visible).map((value, index) => {

                        return <div key={index}>
                            {value.type === 'toggle' && this.renderToggle(value)}
                            {value.type === 'toggle2' && this.renderToggle2(value)}
                            {value.type === 'value' && this.renderValue(value)}
                            {value.type === 'select' && this.renderSelect(value)}
                            {value.type === 'fixed' && this.renderFixed(value)}
                            {value.type === 'process' && this.renderProcess(value)}
                            {value.type === 'panel' && this.renderServicePanel(value)}
                            {value.type === 'input' && this.renderInputType(value)}
                            {value.sending && this.renderOutputWait(value)}
                        </div>
                    })
                    }
                </IonGrid>
            </>
        })


    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <>
            {this.props.device && this.props.device.outputs && this.renderOutputs()}

            <IonAlert
                isOpen={this.state && this.state.confirm}
                onDidDismiss={() => {
                    this.setState({ confirm: false })
                }}
                header={'Confirmar'}
                subHeader={'Esta a punto de cambiar el estado de la salida ' + (this.state && this.state.output ? this.state.output!.name : "")}
                message={'¿Realmente desea cambiar el estado de esta salida a ' + (this.state.state ? "ON" : "OFF") + '?'}
                buttons={[{
                    text: 'OK',
                    role: 'primary',
                    handler: this.handleConfirm
                },
                {
                    text: 'Cancelar',
                    role: 'secondary',
                }]}
            />
            <IonAlert
                isOpen={this.state && this.state.set}
                onDidDismiss={() => {
                    this.setState({ set: false })
                }}
                header={'Establecer Valor'}
                subHeader={'Esta a punto de cambiar el valor de la salida ' + (this.state && this.state.output ? this.state.output!.name : "")}
                message={'Introduzca el valor para la salida ' + (this.state.output?.units ? "(" + this.state.output?.units + ")" : "")}
                inputs={[
                    {
                        name: 'input',
                        type: 'number',
                        id: 'name2-id',
                        value: this.state.setValue!,
                        label: this.state.output?.name + " " + this.state.output?.units,

                    },
                ]}
                buttons={[{
                    text: 'OK',
                    role: 'primary',
                    handler: this.handleConfirmValue
                },
                {
                    text: 'Cancelar',
                    role: 'secondary',
                }]}
            />

            {this.state.output && this.state.output.values && <IonAlert
                isOpen={this.state && this.state.select}
                onDidDismiss={() => {
                    this.setState({ select: false })
                }}
                header={'Establecer Valor'}
                subHeader={'Esta a punto de cambiar el valor de la salida ' + (this.state && this.state.output ? this.state.output!.name : "")}
                message={'Seleccione el valor para la salida'}
                inputs={this.state.output.values.map((item, key) => ({
                    name: item.title,
                    type: 'radio',
                    label: item.title + " " + this.state.output?.units,
                    value: item.value,
                    checked: (item.value === this.state.setValue),

                }))}

                buttons={[{
                    text: 'OK',
                    role: 'primary',
                    handler: this.handleConfirmSelect
                },
                {
                    text: 'Cancelar',
                    role: 'secondary',
                }]}
            /> }


            <IonAlert
                isOpen={this.state && this.state.fixed}
                onDidDismiss={() => {
                    this.setState({ fixed: false })
                }}
                header={'Confirmar'}
                subHeader={'Esta a punto de cambiar el estado de la salida ' + (this.state && this.state.output ? this.state.output!.name : "")}
                message={'¿Realmente desea cambiar el estado de esta salida?'}
                buttons={[{
                    text: 'OK',
                    role: 'primary',
                    handler: this.handleConfirmFixed
                },
                {
                    text: 'Cancelar',
                    role: 'secondary',
                }]}
            />

            <IonAlert
                isOpen={this.state && this.state.process}
                onDidDismiss={() => {
                    this.setState({ process: false })
                }}
                header={'Confirmar'}
                subHeader={'Esta a punto de ejecutar el proceso ' + (this.state && this.state.output ? this.state.output!.name : "")}
                message={'¿Realmente desea ejecutar los cambios de este proceso?'}
                buttons={[{
                    text: 'OK',
                    role: 'primary',
                    handler: this.handleConfirmProcess
                },
                {
                    text: 'Cancelar',
                    role: 'secondary',
                }]}
            />
        </>
    }

}
