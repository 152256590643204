import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../../service/Store";
import {Device} from "../../service/API";
import {RouteComponentProps} from "react-router-dom";

export interface DeviceSettingsHardwareModbusProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsHardwareModbusState = {
    loading?: boolean,
    modbus: {
        station: number,
        address: number,
        length: number,
        functionCode: number,
        interval: number,
        config: number,}[],
    device:Device,
    error: boolean | string,
    edit: boolean,
    index: number,
}

@inject("store", "lang")
export default class DeviceSettingsHardwareModbus extends React.Component<DeviceSettingsHardwareModbusProps, DeviceSettingsHardwareModbusState> {

    componentDidMount(): void {
        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;

        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
                // for (let i = 0; i < 4; i++) {
                //     this.ACTIONS[9 + i] = value.data.outputs[i].name + " ON"
                //     this.ACTIONS[13 + i] = value.data.outputs[i].name + " OFF"
                // }
            }

            this.props.store?.api.getHardwareModbus(Number(id)).then(value => {
                if(value.success) {
                    this.setState({modbus: value.data.modbus})
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });

    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsHardwareModbusProps>, prevState: Readonly<DeviceSettingsHardwareModbusState>, snapshot?: any): void {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        const {history} = this.props;

        this.props.store?.api.setHardwareModbus(Number(id), this.state.modbus).then(value => {
            if(value.success) {
                if(value.data.result) {
                    history.goBack();
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleClear = (ev: any) => {
        if(this.state.modbus) {
            for (let i = 0; i < this.state.modbus.length; i++) {
                let item = this.state.modbus[i];
                item.station = 0;
                item.address = 0;
                item.length = 0;
                item.functionCode = 0;
                item.interval = 0;
                item.config = 0;
            }
            this.setState({});
        }
    }

    handleChange = (item: any, name: string) => (ev: any) => {
        item[name] = parseInt(ev.detail.value);
        this.setState({});
    }

    handleCheckChange = (item: any, name: string) => (ev: any) => {
        item[name] = ev.currentTarget.checked;
    }
    handleCheckChangeFlag = (item: any, name: string, flag: number) => (ev: any) => {
        if(ev.currentTarget.checked) {
            item[name] |= (flag);
        } else {
            item[name] &= ~(flag);

        }
    }

    renderItem(value: any, index: number) {
        if (!this.state.device) return "";

        return <>
            <IonItem detail button onClick={() => this.setState({ edit: true, index: index })}>
                <IonLabel>Modbus {index + 1}</IonLabel>
                <IonLabel hidden={value.station === 0}>Estacion: {value.station}</IonLabel>
            </IonItem>
        </>
    }

    renderEditor() {
        if (!this.state.device) return "";
        if (this.state.index === undefined) return "";
        let value = this.state.modbus[this.state.index];

        return <>
            <IonItem>
                <IonInput label="Dirección de Estación" value={value.station} type="number" max="255"  onIonInput={this.handleChange(value, 'station')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Dirección Lectura" value={value.address} type="number" max="65535"  onIonInput={this.handleChange(value, 'address')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Número de Datos (Max: 16)" value={value.length} max={"16"} type="number" onIonInput={this.handleChange(value, 'length')}></IonInput>
            </IonItem>
            <IonItem>
                <IonSelect label="Función" value={value.functionCode} interface={"action-sheet"} onIonChange={this.handleChange(value, 'functionCode')}>
                    <IonSelectOption value={3}>0x03 - ReadHoldingRegisters</IonSelectOption>
                    <IonSelectOption value={0x10}>0x10 - WriteMultipleRegisters</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonInput label="Intervalo (x100ms)" value={value.interval} max="255" type="number" onIonInput={this.handleChange(value, 'interval')}></IonInput>
            </IonItem>
            <IonItem>
                <IonLabel>Configuración</IonLabel>
            </IonItem>
            <IonItem>
                <IonToggle checked={(value.config & 0b1) === 0b1} onIonChange={this.handleCheckChangeFlag(value, 'config', 0b1)}>Si hay un error establecer a cero</IonToggle>
            </IonItem>
            <IonItem>
                <IonToggle checked={(value.config & 0b10) === 0b10} onIonChange={this.handleCheckChangeFlag(value, 'config', 0b10)}>Publicar como datos actuales</IonToggle>
            </IonItem>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Conmutador</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleClear}>Limpiar</IonButton>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}

                    {this.state.modbus && this.state.modbus.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>

            <IonModal isOpen={this.state.edit! ? true : false} onDidDismiss={() => this.setState({ edit: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Eventos</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ edit: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
