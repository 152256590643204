export default class Theme {
    name: string;

    constructor(name: string) {
        this.name = name;
    }

    static detectFromHost() {
        if (document.location.hostname.startsWith("mreal.")) {
            return new Theme("omron");
        }
        if (document.location.hostname.startsWith("app.cloudrtu.com")) {
            return new Theme("cloudrtu");
        }
        return new Theme("cloudrtu");
    }

}