import {observable} from "mobx";

import { Locales, Translation, TranslationFunctions } from "../i18n/i18n-types";
// import { Capacitor } from "@capacitor/core";
import { i18n } from "../i18n/i18n-util";
import { LocaleTranslationFunctions } from "typesafe-i18n";


export default class Lang {
    private translations : any;
    //private _language:string;
    private fallbackLang = "en-GB"
    private ll : any;

    private L:LocaleTranslationFunctions<Locales, Translation, TranslationFunctions>;
    @observable
    public location:{language: Locales} = {
        language: "es-ES",
    }
    constructor() {
        this.L = i18n();

    }

    // constructor() {
    //     // this._language = language;
    //     // Capacitor.Plugins.Device.getLanguageCode().then((a: {value: string}) => {
    //     //     const detectedLocale = detectLocale(this.fallbackLang, locales, () => {
    //     //         return [a.value];
    //     //     });
    //     //     this.language = detectedLocale as Locales;
            
    //     // })
    // }
    get l() {
        return this.L[this.language];
    }
    get language(): Locales {
        return this.location.language;
    }

    set language(value: Locales) {
        this.location.language = value;
    }
}
