import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {RouteComponentProps} from "react-router-dom";
import {Store} from "../../../service/Store";
import {Device, RemoticHardwareSettings} from "../../../service/API";
import Utils from "../../../service/Utils";

export interface DeviceSettingsRemoticTimerProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsRemoticTimerState = {
    loading?: boolean,
    settings: RemoticHardwareSettings,
    index: number,
    device:Device,
    error: boolean | string,
    edit: boolean,
}

@inject("store", "lang")
export default class DeviceSettingsRemoticTimer extends React.Component<DeviceSettingsRemoticTimerProps, DeviceSettingsRemoticTimerState> {

    componentDidMount(): void {

        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
            }
            this.props.store?.api.getRemoticHardwareSettings(Number(id), 3).then(value => {
                if(value.success) {
                    this.setState({settings: value.data});
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });
    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsRemoticTimerProps>, prevState: Readonly<DeviceSettingsRemoticTimerState>, snapshot?: any) {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        const {history} = this.props;

        this.props.store?.api.setRemoticHardwareSettings(Number(id), this.state.settings).then(value => {
            this.setState({loading: false});
            if(value.success) {
                if(value.data.result) {
                    history.goBack();
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.currentTarget.value;
        let events = this.state.settings.timers;
        events[this.state.index] = this.buildItem(item);
        this.setState({})
    }

    handleCheckChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.currentTarget.checked;

        let events = this.state.settings.timers;
        events[this.state.index] = this.buildItem(item);
        this.setState({})
    }

    parseItem(value:any) {
        /*
        week

         */

        let enable = !!((value.week >> 7) & 1);
        let sunday = !!((value.week >> 6) & 1);
        let monday = !!((value.week >> 5) & 1);
        let tuesday = !!((value.week >> 4) & 1);
        let wednesday = !!((value.week >> 3) & 1);
        let thursday = !!((value.week >> 2) & 1);
        let friday = !!((value.week >> 1) & 1);
        let saturday = !!((value.week >> 0) & 1);

        let hour = value.hourMinute >> 8;
        let min = value.hourMinute  & 0xFF;

        let time = (hour > 9?hour:"0" + hour) + ":" + (min > 9?min:"0" + min);
        let output = value.output;
        let state = value.state;
        let index = value.index;
        return {enable, sunday, monday, tuesday, wednesday, thursday, friday, saturday, time, output, state, index};
    }

    buildItem(item:any) {
        let result = {week: 0, hourMinute: 0, output: item.output, state: item.state, index: item.index};
        result.week = ((item.enable?1:0) << 7);
        result.week += ((item.sunday?1:0) << 6);
        result.week += ((item.monday?1:0) << 5);
        result.week += ((item.tuesday?1:0) << 4);
        result.week += ((item.wednesday?1:0) << 3);
        result.week += ((item.thursday?1:0) << 2);
        result.week += ((item.friday?1:0) << 1);
        result.week += ((item.saturday?1:0) << 0);
        result.hourMinute = parseInt(item.time.substring(0, 2)) << 8;
        result.hourMinute += parseInt(item.time.substring(3, 5));
        return result;
    }


    renderWeek(item:any) {
        let string = "";
        string += (item.monday?"L":"-");
        string += (item.tuesday?"M":"-");
        string += (item.wednesday?"X":"-");
        string += (item.thursday?"J":"-");
        string += (item.friday?"V":"-");
        string += (item.saturday?"S":"-");
        string += (item.sunday?"D":"-");
        return string;
    }

    renderItem(value:any, index:number) {
        if(!this.state.device) return "";
        let item = this.parseItem(value);

        return <>
            <IonItem detail button onClick={() => this.setState({edit: true, index: index})}>
                <IonLabel>Evento programado {index + 1}</IonLabel>

                <IonBadge slot={"end"} color={"primary"} hidden={!item.enable}>{item.time}</IonBadge>
                <IonBadge slot={"end"} color={"primary"} hidden={!item.enable}>{this.renderWeek(item)}</IonBadge>
                <IonBadge slot={"end"} color={"warning"} hidden={!item.enable}>{Utils.getOutputNameByIndex(this.state.device.outputs, item.output)} {item.state?" ON":" OFF"}</IonBadge>
                <IonBadge slot={"end"} color={"danger"} hidden={item.enable}>Desactivado</IonBadge>
            </IonItem>
        </>
    }

    renderEditor() {
        if(!this.state.device) return "";
        if(this.state.index === undefined) return "";
        let value = this.parseItem(this.state.settings.timers[this.state.index]);

        return <>
            <IonItem>
                <IonToggle checked={value.enable} onIonChange={this.handleCheckChange(value, 'enable')} >
                    Activar
                </IonToggle>
            </IonItem>
            <div hidden={!value.enable}>

                <IonItem>
                    <IonLabel>Hora</IonLabel>
                    <IonDatetime firstDayOfWeek={1}  presentation="time" value={value.time} onIonChange={this.handleChange(value, 'time')} />
                </IonItem>
                <IonItem>
                    <IonSelect label="Salida" value={value.output} interface={"action-sheet"} onIonChange={this.handleChange(value, 'output')}>
                        {this.state.device.outputs.map((value1, index1) => <IonSelectOption value={value1.oindex}>{value1.name}</IonSelectOption>)}
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonSelect label="Salida" value={value.state} interface={"action-sheet"} onIonChange={this.handleChange(value, 'state')}>
                        <IonSelectOption value={0}>{"OFF"}</IonSelectOption>
                        <IonSelectOption value={1}>{"ON"}</IonSelectOption>
                    </IonSelect>
                </IonItem>
                <IonItem>
                    <IonToggle checked={value.monday} onIonChange={this.handleCheckChange(value, 'monday')} >
                        Lunes
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={value.tuesday} onIonChange={this.handleCheckChange(value, 'tuesday')}>
                        Martes
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={value.wednesday} onIonChange={this.handleCheckChange(value, 'wednesday')} >
                        Miércoles
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={value.thursday} onIonChange={this.handleCheckChange(value, 'thursday')} >
                    Jueves
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={value.friday} onIonChange={this.handleCheckChange(value, 'friday')}>
                    Viernes
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={value.saturday} onIonChange={this.handleCheckChange(value, 'saturday')}>
                    Sábado
                    </IonToggle>
                </IonItem>
                <IonItem>
                    <IonToggle checked={value.sunday} onIonChange={this.handleCheckChange(value, 'sunday')}>
                    Domingo
                    </IonToggle>
                </IonItem>
            </div>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if(!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"}/>
                    </IonButtons>
                    <IonTitle>Acciones programadas</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}

                    {this.state.settings && this.state.settings.timers.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>

            <IonModal isOpen={this.state.edit! ? true: false} onDidDismiss={() => this.setState({edit: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Acción</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({edit: false})}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
