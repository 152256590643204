import React from 'react';
import {IonIcon} from "@ionic/react";

const SwitchState = (props:any) => {
  let selected = props.value !== props.defaultValue;
  let newValue = selected? props.defaultValue: props.nextState;
  return (
    <IonIcon icon={props.icon} color={selected?"success":"muted"}
          onClick={() => props.onChange(newValue)} />
  );
}

export default SwitchState;
