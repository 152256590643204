import React from "react";
import {Store} from "../service/Store";
import {DeviceInputGroup} from "../service/API";
import {inject} from "mobx-react";
import {Chart} from "react-google-charts";
import HighchartsChart from "./chart/HighchartsChart";

export type InputGroupGraphProps = {
    store?: Store,
    input: DeviceInputGroup,
}
export type InputGroupGraphState = {
    chartData?: any,
    recreate?: any,
    time?: any,
}
@inject("store", "lang")
export default class InputGroupGraph extends React.Component<InputGroupGraphProps, InputGroupGraphState> {

    private lineChart?: Chart;
    state: InputGroupGraphState = {};
    // chartEngine:any = GoogleChart;
    // chartEngines = {
    //     'GoogleCharts': {
    //         render: (chartData:any) => {
    //
    //         },
    //         update(input: DeviceInputGroup, data:any, recreate:boolean) {
    //
    //         }
    //     },
    //     'amCharts4': {
    //
    //     }
    // };


    componentDidMount(): void {
        this.loadChart();

    }

    componentDidUpdate(prevProps: Readonly<InputGroupGraphProps>, prevState: Readonly<InputGroupGraphState>, snapshot?: any): void {
        if(prevProps.input.id !== this.props.input.id || prevProps.input.graph !== this.props.input.graph || prevProps.input.graphRange !== this.props.input.graphRange || prevProps.input.graphInterval !== this.props.input.graphInterval) {
            this.loadChart(true);
        } else if(prevProps.input.inputs !== this.props.input.inputs) {
            // TODO: Check chart updates
            this.loadChart();
        }
    }

    loadChart(recreate:boolean = false) {
        let id = this.props.input.id;
        let start = new Date().getTime() - (this.props.input.graphRange * 60 * 60 * 1000);
        let end = new Date().getTime();

        // let items:InputItemSettings[] = Utils.fromJSON(this.props.input.graphProps!) as InputItemSettings[];
        // if(!items) return;

        this.props.store?.api.deviceInputGroupChart(id, start / 1000, end / 1000, this.props.input.graphInterval).then(response => {
            if(response.success) {
                this.setState({chartData: response.data, recreate, time: new Date().getTime()})
            }
        });
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // let Component = this.chartEngine;
        // return <Component ref={this.setChartRef} input={this.props.input} data={this.state.chartData} />
        return <HighchartsChart input={this.props.input} chartData={this.state.chartData} time={this.state.time} recreate={this.state.recreate} />
    }

}
