import EventsPool from "./EventsPool";

export type EventName = 'alarm' | 'automation' | 'schedulers' | 'online' | 'offline' | 'dataX' | 'update' | 'verify' | 'permissions' | 'inputs' | 'location' | 'reconnect' | 'input-data';
export default class EventBox {
    private events:any[] = [];

    private pool:EventsPool;


    constructor(pool: EventsPool) {
        this.pool = pool;
    }

    public on(name:EventName, fn:any) {
        this.events.push({name, fn});
        this.pool.on(name, fn);
    }

    public off() {
        for (let i = 0; i < this.events.length; i++) {
            this.pool.off(this.events[i].name, this.events[i].fn);
        }
    }
}
