import React from "react";
import {Device} from "../service/API";
import {IonButton, IonButtons, IonContent, IonHeader, IonModal, IonText, IonTitle, IonToolbar} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../service/Store";
import moment from "moment-timezone";

export interface PaymentState {
    open:boolean;
    trial?:boolean;
    trialEnd?:boolean;
    cancelled?:boolean;
    paymentError?:boolean;
    lastTime?: number;
}
export interface PaymentProps {
    device:Device;
    store?:Store;
    routerOutlet:HTMLIonRouterOutletElement;
}
@inject("store", "lang")
export default class Payment extends React.Component<PaymentProps, PaymentState> {
    state = {open: false, trial: false, trialEnd: false, cancelled: false, paymentError: false, dismissed: false, lastTime: 0};

    checkProps() {
        this.setState({ open: false, trialEnd: false, cancelled: false, paymentError: false });
        if (this.props.device.suscription) {
            if (new Date().getTime() - this.state.lastTime > 120000) {
                if (!this.props.device.suscription.active) {
                    this.setState({open: true, cancelled: true, trial: false});
                } else {
                    if (this.props.device.suscription.stripe_payment === 'paylater') {
                        if((Math.floor(new Date().getTime() / 1000) - moment(this.props.device.suscription.date_start!).unix()) > (30 * 24 * 60 * 60)) {
                            this.setState({open: true, trial: true, trialEnd: true});
                        } else {
                            this.setState({open: true, trial: true, trialEnd: false});
                        }
                    } else {
                        if (this.props.device.suscription.payment_error) {
                            this.setState({open: true, paymentError: true, trial: false});
                        }
                    }
                }
            }
            
        }
    }

    getTrialEnd() {
        return moment(this.props.device.suscription.date_start).unix() + (30 * 24 * 60 * 60);
    }

    componentDidMount(): void {
        this.checkProps();
    }

    componentDidUpdate(prevProps: Readonly<PaymentProps>, prevState: Readonly<PaymentState>, snapshot?: any): void {
        if(this.props.device !== prevProps.device || (this.props.device && prevProps.device && this.props.device.suscription !== prevProps.device.suscription)) {
            this.checkProps();
        }
    }

    handleDismiss = (ev:any) => {
        ev.preventDefault();
    }

    canClose() {
        return (!this.state.trialEnd && !this.state.cancelled && !this.state.paymentError);
    }

    renderPaymentError() {
        return <>
            <IonHeader translucent={true}>
                <IonToolbar>
                    <IonTitle>Error al procesar el pago</IonTitle>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={() => this.setState({open: false, lastTime: new Date().getTime()})}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-padding"}>
                <IonText>Se ha producido un error al procesar el último pago de la subscripción, revise la forma de pago para continuar usando el dispositivo.</IonText>
                <IonButton expand={"full"} onClick={() => this.setState({open: false})} routerLink={"/device/" + this.props.device.id + "/settings/suscription"}>Configurar el pago ahora</IonButton>
            </IonContent>
        </>
    }

    renderCancelled() {
        return <>
            <IonHeader translucent={true}>
                <IonToolbar>
                    <IonTitle>Subscripción cancelada</IonTitle>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={() => {this.setState({open: false, lastTime: new Date().getTime()})}}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            
            <IonContent fullscreen className={"ion-padding"}>
                <IonText>Ha cancelado su subscripción al servicio. Si desea continuar usando este dispositivo, vuelva a configurar la forma de pago.</IonText>
                <IonButton expand={"full"} onClick={() => this.setState({open: false})} routerLink={"/device/" + this.props.device.id + "/settings/suscription"}>Configurar el pago ahora</IonButton>
            </IonContent>
        </>
    }

    renderTrialEnds() {
        return <>
            <IonHeader translucent={true}>
                <IonToolbar>
                    <IonTitle>Periodo de prueba finalizado</IonTitle>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={() => this.setState({open: false, lastTime: new Date().getTime()})}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-padding"}>
                <IonText>Ha finalizado su periodo de prueba, configure ahora el método de pago.</IonText>
                <IonButton expand={"full"} onClick={() => this.setState({open: false})} routerLink={"/device/" + this.props.device.id + "/settings/suscription"}>Configurar el pago ahora</IonButton>
            </IonContent>
        </>
    }

    renderTrialAboutToEnds() {
        let date = this.props.store?.moment(this.getTrialEnd(), "X").format("DD/MM/YYYY HH:mm");
        let remaining = (this.getTrialEnd() * 1000 - (new Date().getTime())) / (1000 * 60 * 60 * 24);
        remaining = Math.round(remaining);

        return <>
            <IonHeader translucent={true}>
                <IonToolbar>
                    <IonTitle>Periodo de prueba</IonTitle>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={() => this.setState({open: false, lastTime: new Date().getTime()})}>Cerrar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-padding"}>
                <IonText>Este dispositivo disfruta de un periodo de prueba de 30 días.
                    Durante esté periodo podrá utilizar de forma completa todas las funcionalidades disponibles.</IonText>
                <br />
                <IonText> Antes de que finalice este periodo puede suscribirse al servicio para no perder el control de su dispositivo.</IonText>
                <div>

                    <IonText>Su periodo de prueba finaliza el día <b>{date}</b></IonText>
                </div>
                <div style={{'textAlign': 'center', 'margin': '35px'}}>
                    <IonText style={{'fontSize': '22px', }}>Quedan {remaining} días para finalizar</IonText>
                </div>
                <div>

                <IonButton expand={"full"} onClick={() => this.setState({open: false})} routerLink={"/device/" + this.props.device.id + "/settings/suscription"}>Configurar el pago ahora</IonButton>
                </div>

            </IonContent>
        </>

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        let canClose = this.canClose()
        return <>
            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state.open ? true: false} onDidDismiss={this.handleDismiss} backdropDismiss={canClose} keyboardClose={canClose}>
                {this.state.paymentError && this.renderPaymentError()}
                {this.state.cancelled && this.renderCancelled()}
                {this.state.trial && (this.state.trialEnd ? this.renderTrialEnds() : this.renderTrialAboutToEnds())}
            </IonModal>
        </>
    }
}
