import React, {Component, CSSProperties} from 'react';
// import {SketchPicker} from 'react-color';

import styles from './styles';

class ColorInput extends Component<any, any> {
    state = {
        show: false
    };

    toggleVisibility = (event: any) => {
        if (event.preventDefault) {
            event.preventDefault();
        }

        let {show} = this.state;
        this.setState({
            show: !show
        })
    }

    handleChange = (color: any) => {
        let {r, g, b, a} = color.rgb;
        this.props.onChange(`rgba(${r}, ${g}, ${b}, ${a})`);
    }

    handleClose = (event: any) => {
        if (event.preventDefault) {
            event.preventDefault();
        }

        this.setState({
            show: false
        })
    }

    render() {
        let {show} = this.state;
        let {value} = this.props;

        return (
            <div>
                <div>
                    <button
                        style={styles.colorInput}
                        onClick={this.toggleVisibility.bind(this)}>
                        <span style={{...styles.color, backgroundColor: value}}/>
                    </button>
                    {/*{show && <div style={styles.colorPopover as CSSProperties}>*/}

                    {/*</div>}*/}
                </div>
                {show && <>
                <div style={styles.colorCover as CSSProperties} onClick={this.handleClose}/>
                {/* <SketchPicker

                    color={value}
                    onChange={this.handleChange}
                /> */}
                 </>
                }
            </div>
        );
    }
}

export default ColorInput;
