import React from "react";
import {DeviceInputs, ModbusType} from "../service/API";
import ModbusEdit from "./ModbusEdit";

export type ModbusInputEditorProps = {
    input: DeviceInputs
    routerOutlet:HTMLElement;
}
export default class ModbusInputEditor extends React.Component<ModbusInputEditorProps, any> {


    handleInputChange = (prop: string) => (ev:any) => {
        // @ts-ignore
        this.props.input[prop] = ev.detail.value;
    }

    handleSaveModbus = (modbus?:ModbusType) => {
        this.props.input.modbus = modbus;
        this.setState({});
    }

    render() {
        return <>
            {/* <IonItem>
                <IonLabel>Nombre</IonLabel>
                <IonInput onIonInput={this.handleInputChange('name')}
                          value={this.props.input.name}/>
            </IonItem> */}
            <ModbusEdit routerOutlet={this.props.routerOutlet} modbus={this.props.input.modbus} type={'input'} onSave={this.handleSaveModbus}>

            </ModbusEdit>
        </>
    }
}
