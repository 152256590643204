import type { BaseTranslation } from 'typesafe-i18n'

const es: BaseTranslation = {
    "errors": {
        "INTERNAL_ERROR": "Error interno",
        "DEVICE_ADD_ALREADY": "El dispositivo que intentas añadir ya está en tu cuenta",
        "DEVICE_ADD_ANOTHER": "El dispositivo ya se encuentra en otra cuenta",
        "DEVICE_ADD_GENERIC": "Error interno, contacte con soporte",
        "DEVICE_ADD_CODE": "Código QR no válido",
        "DEVICE_ADD_NOT_SUPPORTED": "Este modelo de dispositivo no está soportado",
        "DEVICE_UPDATE_GENERIC": "Error en la aplicación",
        "DEVICE_UPDATE_ACCOUNT": "Dispositivo no encontrado",
        "DEVICE_DELETE_ACCOUNT": "Dispositivo no encontrado",
        "DEVICE_DELETE_FILED": "Compruebe que ha enviado el identificador de dispositivo",
        "DEVICE_DATA_NOT_ONLINE": "El dispositivo no está online",
        "DEVICE_DATA_NOT_SUPPORTED": "El dispositivo no soporta datos",
        "DEVICE_DATA_NOT_FOUND": "Dispositivo no encontrado",
        "DEVICE_SETTINGS_NOT_FOUND": "Dispositivo no encontrado",
        "DEVICE_AIN_INVALID": "Respuesta inválida del dispositivo",
        "DEVICE_AIN_NOT_ONLINE": "Dispositivo no está en línea",
        "DEVICE_AIN_NOT_SUPPORTED": "El dispositivo no soporta entradas",
        "DEVICE_AIN_NOT_ACCOUNT": "Dispositivo no encontrado",
        "DEVICE_OUTPUT_NOT_FOUND": "No existe la salida solicitada",
        "DEVICE_OUTPUT_ERROR": "Error al controlar la salida",
        "DEMO_NOT_ALLOWED": "EL usuario demo no puede hacer eso",
        "INPUT_NOT_FOUND": "Entrada no encontrada",
        "DEVICE_NOT_FOUND": "Dispositivo no encontrado",
        "DEVICE_INVALID_RESPONSE": "Respuesta del dispositivo no válida",
        "DEVICE_UNDEFINED_RESPONSE": "Respuesta del dispositivo no definida",
        "QTHING_NOT_FOUND": "Cosa QR no encontrada",
        "QR_NOT_FOUND": "El código QR no se ha encontrado",
        "USER_NOT_ALLOWED": "Usuario no se le permite hacer esto",
        "SCHEDULER_NOT_FOUND": "Planificador no encontrado",
        "AUTOMATION_NOT_FOUND": "Automatización no encontrada",
        "USER_EMAIL_TAKEN": "Email ya en uso",
        "USER_USERNAME_TAKEN": "Nombre de usuario ya en uso",
        "USER_LOGIN_INCORRECT": "Nombre de usuario o contraseña incorrecta",
        "USER_LOGIN_EMPTY": "Nombre de usuario o contraseña vacía",
        "USER_RECOVERY_INVALID": "Código de recuperación no válido",
        "USER_RECOVERY_EXPIRED": "Código de recuperación expirado",
        "USER_TOKEN_NOT_VALID": "Token no válido",
        "USER_PASSWORD_INVALID_OLD": "Contraseña anterior no válida",
        "USER_PASSWORD_SHORT": "Contraseña demasiado corta.",
        "USER_INVALID_VERIFICATION_CODE": "Código de recuperación no válido",
        "USER_ALREADY_VERIFIED": "El usuario ya está registrado",
        "SMS_PROVIDER_ERROR": "Error en el envío, pruebe más tarde",
        "PERMISSION_NOT_ALLOWED": "No tienes permisos para realizar esta accion en el dispositivo",
        "PERMISSION_NOT_FOUND": "No existe el permiso para este usuario/dispositivo",
        "PERMISSION_REMOVE_YOURSELF": "No puedes eliminarte a ti mismo",
        "PERMISSION_ADD_OWNER": "No se puede añadir porque esa cuenta es el propietario del dispositivo",
        "PERMISSION_USER_NOT_FOUND": "No existe el usuario",
        "PERMISSION_NOT_VALID": "Permisos asignados no válidos",
        "PERMISSION_USER_ALREADY": "La invitación ya está enviada a este usuario",
        "PERMISSION_RESEND_ERROR": "Error al enviar el código de invitación",
        "NOTIFICATIONS_PROVIDER_NOT_VALID": "Este proveedor de notificaciones no está disponible",
        "NOT_IMPLEMENTED_ERROR": "Esta lógica no está implementada todavía",
        "PAYMENT_METHOD_ERROR": "Error procesando este método de pago",
        "PAYMENT_ERROR": "Error procesando el pago",
        "PAYMENT_LIST_ERROR": "Error al procesas tus métodos de pago",
        "PAYMENT_NOT_ENABLED_ERROR": "Porfavor configure el pago primero",
        "DEVICE_DELETE_PAYMENT": "Error eliminando el método de pago",
        "SIM_ACTIVATION_ERROR": "Error al activar la sim del dispositivo",
        "DEVICE_NOT_ONLINE": "El dispositivo no está conectado",
        "DEVICE_NOT_COMPATIBLE": "Este modelo no es compatible con estos ajustes",
        "INVITATION_NOT_VALID": "Código de invitación no válido",
        "INVITATION_REVOKED": "Esta invitación ha sido revocada",
        "INVITATION_USED": "La invitación ya ha sido usada por otro usuario",
        "INVITATION_YOU": "Esta invitación es tuya.",
        "NOT_FOUND": "Ruta no encontrada",
        "PANEL_FOUND": "Panel no encontrado",
        "CLIENT_UUID_REQUIRED": "Se necesita un UUID de cliente para esta acción",
        "DEVICE_TRANSFER_USER_NOT_FOUND": "No se encuentra al usuario receptor",
        "DEVICE_TRANSFER_OWNER": "Solo el propietario de este dispositivo puede hacer esta acción",
        "DEVICE_NOT_VALID": "No es un dispositivo válido",
        "INVALID_PARAMETERS": "Parámetros no válidos",
        "CREATE_USER_EMAIL_USED": "El email ya está en uso",
        "CREATE_USER_USERNAME_USED": "El nombre de usuario ya está en uso",
        "FIREBASE_NOT_ENABLED": "Firebase no esta activo para este proyecto",
        "CREATE_USER_EMAIL_EMPTY": "Porfavor rellene el email",
        "CREATE_USER_PASSWORD_EMPTY": "Porfavor rellene la contraseña",
        "CREATE_USER_USERNAME_EMPTY": "Porfavor rellene el nombre de usuario",
        "CREATE_USER_USERNAME_NOT_VALID": "Porfavor introduzca un nombre de usuario válido, solo letras, números, guión bajo y guión medios",
        "CREATE_USER_PHONE_EMPTY": "Porfavor introduzca el número de teléfono",
        "DEVICE_NOT_SUBSCRIBED": "Este dispositivo no está suscrito a CloudRTU",
        "PAYMENT_USER_NOT_ALLOWED": "Solo el propietario puede modificar los datos de suscripcion"
    },
    "main": {
        "login": "Acceder",
        "language": "Idioma",
        "software_demo": "¿Estas interesado en probar el Software? Pulse aquí para comenzar la version de demostración de CloudRTU",
        "start_demo": "Comenzar Demo",
        "demo_version": "Versión Demo",
        "login_header": "Acceder al panel de control",
        "login_user_email": "Email / Usuario",
        "logn_password": "Contraseña",
        "login_remember": "Recordar acceso",
        "login_remember_me": "Recordarme",
        "login_button": "Acceder",
        "login_new_account": "Crear nueva cuenta",
        "login_recovery": "Recuperar cuenta",
        "login_download": "Descargar APP",
        "menu": "Menu",
        "menu_home": "Inicio",
        "menu_hmi": "HMI",
        "menu_account": "Cuenta",
        "menu_settings": "Ajustes",
        "menu_usb": "Configurador USB",
        "menu_help": "Ayuda",
        "menu_logout": "Cerrar Sesión",
        "menu_theme": "Tema Oscuro",
        "tab_back": "Volver",
        "tab_dashboard": "Dashboard",
        "tab_devices": "Dispositivos",
        "tab_map": "Mapa",
        "tab_control": "Control",
        "tab_status": "Estado",
        "tab_log": "Registros",
        "tab_settings": "Ajustes",
        "dashboard_verify_title": "Verificar cuenta",
        "dashboard_button": "Verificar",
        "dashboard_content": "Está a un solo paso de completar la configuración de la cuenta",
        "dashboard_email_sent": "Le hemos enviado un email a su cuenta de email: <b>{email}</b>",
        "dashboard_code_input": "Código de verificación",
        "dashboard_resend": "Volver a enviar",
        "dasbhoard_head": "Para comenzar",
        "dashboard_welcome": "Bienvenido a CloudRTU",
        "dashboard_welcome_message": "Desde aquí tendrás el control de tus dispositivos.",
        "dashboard_understood": "Entendido",
        "dashboard_title": "Dashboard",
        "dashboard_panels": "Paneles",
        "dashboard_devices": "Dispositivos",
        "online": "Conectado",
        "offline": "Desconectado",
        "dashboard_error": "Error al cargar. Compruebe su conexión e inténtelo de nuevo.",
        "reload": "Volver a cargar",
        "Loading": "Cargando...",
        "loading": "Cargando...",
        "dasbhoard_button_control": "Controlar",
        "devices_title": "Dispositivos",
        "search": "Buscar",
        "devices_error": "Error consultando sus dispositivos!",
        "devices_retry": "Volver a intentar",
        "map_access_error": "No se ha podido acceder al servicio de localización",
        "map_title": "Mapa de Dispositivos",
        "map_current_location": "Posicion Actual",
        "map_set_device_location": "Asignar ubicación a dispositivo",
        "cancel": "Cancelar",
        "map_device_status": "Estado del Dispositivo",
        "close": "Cerrar",
        "map_name": "Nombre",
        "map_status": "Estado",
        "map_control": "Controlar el dispositivo",
        "map_value": "Valor",
        "add_device_error_internet": "Internet error!",
        "add_device_error_name": "Tiene que introducir un nombre para el dispositivo",
        "add_device_error_code": "Tiene que introducir el código de identificación",
        "add_device_error_payment": "Tiene que seleccionar una forma de pago",
        "add_device_error_subscription": "Tiene que seleccionar una suscripción",
        "ok": "Aceptar",
        "add_device_coupon_charged": "Aplicado",
        "add_device_help": "Antes de añadir un dispositivo en la APP, siga estas instrucciones para inicializar el dispositivo: <a href=\"https://cloudrtu.com/inicio-rapido/\" rel=\"noopener noreferrer\" target=\"_blank\">Guía de inicio Rápido</a>",
        "add_device_admin": "Añadir en el panel de Admin",
        "add_device_name": "Nombre del dispositivo",
        "add_device_example_name": "Riego goteo",
        "add_device_qrcode": "Código QR",
        "add_device_subscriptions_load": "Subscripciones. Pulsa para cargar",
        "add_device_payment_method": "Forma de Pago",
        "add_device_coupon": "Cupón",
        "add_device_coupon_check": "Comprobar",
        "add_simulator_title": "Desde aquí puedes crear un dispositivo simulado.",
        "add_simulator_model": "Modelo a Simular",
        "add_simulator_protocol": "Protocolo",
        "add_device_header": "Añadir dispositivo",
        "add_device_device": "Dispositivo",
        "add_device_simulator": "Simulador",
        "edit": "Editar",
        "panel_create_new": "Crear nuevo",
        "title": "Título",
        "size": "Tamaño",
        "remove": "Eliminar",
        "add_payment_error": "Error al configurar el pago",
        "add_payment_not_implemented": "Método de pago no implementado",
        "add_payment_error_internet": "Error al configurar el pago, compruebe su conexión a internet",
        "add_payment_holder_name": "Titular Tarjeta",
        "add_payment_credit_message": "Al añadir una forma de pago permites a CloudRTU realizar los cargos pertinentes en su cuenta bancaria.",
        "add_payment_sepa_holder": "Nombre completo Titular",
        "add_payment_sepa_message": "Al proporcionar su IBAN y confirmar este pago, usted está autorizando CloudRTU y Stripe, nuestro proveedor de servicios de pago, para enviar instrucciones a su banco para debitar su cuenta de acuerdo con esas instrucciones. Tiene derecho a un reembolso de su banco bajo los términos y condiciones de su acuerdo con su banco. Se debe reclamar un reembolso dentro de las ocho semanas a partir de la fecha de débito en su cuenta.",
        "add_payment_title": "Añadir nueva forma de pago",
        "add_payment_help_message": "Puede añadir una forma de pago para suscribirse a varios productos.",
        "add_payment_method": "Método de pago",
        "add_payment_credit_card": "Tarjeta de crédito",
        "add_payment_sepa": "Adeudo SEPA",
        "add_panel_success": "Añadido correctamente",
        "add_panel_select": "Seleccionar el panel",
        "add_panel_title": "Añadir nuevo panel",
        "add_panel_help": "Los paneles son cajas que se muestran en el inicio de la aplicación. Estas cajas pueden contener los elementos de diferentes entradas de múltiples dispositivos.",
        "password_short": "La nueva contraseña es muy corta",
        "password_not_match": "Las contraseñas no coinciden",
        "error": "Error",
        "password_current": "Contraseña Actual",
        "password_current_help": "Introduzca aquí su contraseña anterior",
        "password_new": "Contraseña Nueva",
        "password_new_help": "Introduzca aquí su nueva contraseña",
        "password_repeat": "Repetir Contraseña",
        "password_repeat_help": "Repita aquí su nueva contraseña",
        "change": "Cambiar",
        "demo_conditions": "Acepte las condiciones",
        "demo_header": "Un paso más antes de continuar",
        "demo_subtitle": "Para comenzar la version demo es necesario que nos facilites la siguiente información:",
        "demo_fullname": "Nombre Completo",
        "demo_business": "Nombre de empresa",
        "demo_phone": "Teléfono de contacto",
        "email": "Email",
        "legal_terms": "Terminos legales",
        "legal_terms_url": "<span>Acepto los <a href=\"/legal#tos\" rel=\"noopener noreferrer\" target=\"_blank\"> términos y condiciones del servicio</a></span>",
        "privacy_terms": "Terminos legales",
        "privacy_terms_url": "<span>Acepto y entiendo la <a href=\"/legal#privacy\" rel=\"noopener noreferrer\" target=\"_blank\">política de privacidad</a></span>",
        "automation_confirm": "Realmente deseas eliminar esta automatización?",
        "atomation_output_name": "Nombre de salida",
        "automation_change_state": "Cambiar al estado",
        "automation_on": "ON / Encendido",
        "automation_off": "OFF / Apagado",
        "automation_set_value": "Establecer valor",
        "automation_select_value": "Seleccionar Valor",
        "automation_destination_email": "Email Destino",
        "automation_email_message": "Mensaje",
        "automation_send_email_to": "Enviar email a {email}",
        "automation_show_notification": "Mostrar notificación en el dispositivo",
        "automation_action_type": "Tipo de acción",
        "automation_set_output": "Establecer Salida",
        "automation_action_send_email": "Enviar Email",
        "automation_action_notify": "Enviar Notificación",
        "automation_condition_title": "Operación personalizada (Escriba $ para ver sugerencias)",
        "automation_boolean": "Conector Boleeano",
        "and": "Y",
        "or": "O",
        "automation_if": "Si (Valor de entrada)",
        "automation_is": "Es",
        "automation_to_value": "A (Valor)",
        "automation_if_short": "Si",
        "automation_to_short": "a",
        "automation_operation": "Operación",
        "automation_enable": "Habilitar",
        "automation_wait_title": "Cuando se active, esperar estos segundos antes de ejecutar las acciones",
        "automation_rearm": "Tras ejecutar las acciones, volver a habilitar la automatización transcurridos estos segundos",
        "add": "Añadir",
        "automation_actions": "Acciones",
        "notes": "Notas",
        "automation_executed": "Ejecutado",
        "automation_enabled": "Habilitado",
        "automation_disabled": "Deshabilitado",
        "automation_automations": "Automatizaciones",
        "automation_automation": "Automatización",
        "save": "Guardar",
        "automation_edit_condition": "Editar Condición",
        "automation_edit_action": "Editar Acción",
        "scheduler_output": "Salida",
        "enable": "Activar",
        "scheduler_time": "Hora",
        "scheduler_repeat_week": "Repetir durante la semana",
        "monday": "Lunes",
        "thursday": "Jueves",
        "wednesday": "Miércoles",
        "tuesday": "Martes",
        "friday": "Viernes",
        "saturday": "Sábado",
        "sunday": "Domingo",
        "scheduler_fixed_date": "Ejecutar en una fecha fija",
        "date": "Fecha",
        "scheduler_multiple_dates": "Ejecutar en multiples fechas",
        "scheduler_enable": "Activar planificador",
        "scheduler_disable_warning": "Atención! El programador se encuentra desactivado. No se ejecutará ninguna acción programada.",
        "scheduler_title_modal": "Temporizador",
        "galery_error": "Error durante la carga de la galería",
        "tab_scheduler": "Planificador",
        "tab_automation": "Programador",
        "loading_device": "Cargando dispositivo...",
        "error_loading_device": "Se ha producido un error al cargar el dispositivo",
        "output_off": "Apagado",
        "output_on": "Encendido",
        "status_no_data": "No se ha recolectado ningun dato desde la unidad",
        "status_inputs_hidden": "Todas las entradas están ocultas",
        "status_download_registers": "Descargar Registros",
        "start_date": "Fecha Inicio",
        "end_date": "Fecha Fin",
        "download": "Descargar",
        "status_input_details": "Detalles de entrada",
        "status_display_dashboard": "Ver este control en Dashboard (Inicio)",
        "input_details": "Detalles de la Entrada",
        "input_search_registers": "Buscar registros",
        "input_unique": "Mostrar valores únicos",
        "diff": "Differencia",
        "log_load_error": "No se ha podido cargar el dispositivo, compruebe la conexión a internet.",
        "event": "Evento",
        "details": "Detalles",
        "user": "Usuario",
        "offline_long": "Fuera de línea",
        "log_power_recovery": "Alimentación Correcta",
        "log_power_faillure": "Fallo de Alimentación",
        "power_supply": "Alimentación",
        "sent": "Enviado",
        "sent_error": "No enviado",
        "power_supply_recovery": "Conectada alimentación",
        "hardware_timer": "Temporizador Hardware",
        "log_register_details": "Detalles del Registro",
        "log_register_id": "Identificador de registro",
        "log_register_date": "Fecha del registro",
        "log_register_output_name": "Nombre salida",
        "log_register_event": "Tipo de Evento",
        "log_register_description": "Descripción del Evento",
        "log_register_manual": "Cambio de salida manual",
        "log_register_scheduler": "Cambio de salida por planificador",
        "log_register_automation": "Cambio de salida por programador",
        "log_register_online": "Dispositivo conectado",
        "log_register_offline": "Dispositivo desconectado",
        "log_register_power": "Cambio en alimentación",
        "log_register_error": "Error interno",
        "no_error": "Sin errores",
        "log_register_username": "Nombre Usuario",
        "log_register_filter": "Filtrar registros por Tipo, Usuario, Detalles",
        "filter": "Filtrar",
        "type": "Tipo",
        "load_more": "Cargar más elementos",
        "loading_more": "Cargando más elementos...",
        "log_total": "Total elementos: {total}",
        "reles": "Relés",
        "analog_inputs": "Entradas analógicas",
        "temp_and_humd": "Temperatura & Humedad",
        "digital_inputs": "Entradas digitales",
        "interlock": "Conmutador de Eventos",
        "modbus": "Modbus",
        "inputs": "Entradas",
        "hardware_automation": "Automatismos",
        "device_not_support_settings": "Su dispositivo no soporta estas configuraciones",
        "device_offline": "Su dispositivo no se encuentra online",
        "settings_general": "General",
        "settings_subscription": "Subscripción",
        "settings_outputs": "Salidas",
        "share": "Compartir",
        "download_app_title": "Descarga Aplicaciones Móvil",
        "download_get_app": "Obtener CloudRTU",
        "download_app_platforms": "Descarga CloudRTU en una de estas plataformas",
        "download_pc_mac": "PC y Mac",
        "download_chrome": "Descargar para Google Chrome",
        "download_install": "Instalar en este equipo",
        "download_incompatible": "Este dispositivo no es compatible con PWA.",
        "download_beta": "Formar parte de la Beta Abierta de CloudRTU",
        "download_beta_android": "Beta de Android",
        "download_beta_ios": "Beta de iOS",
        "invitation": "Invitación",
        "invitation_accepted": "Invitación aceptada",
        "see_devices": "Ver dispositivos",
        "app_settings_title": "Configuración de la aplicación",
        "app_settings_timezone": "Zona Horaria",
        "app_settings_current_time": "Hora actual",
        "see_bills": "Ver facturas",
        "modify": "Modificar",
        "enable_billing": "Habilitar facturación",
        "payment_configure": "Configurar forma de pago",
        "username": "Nombre de usuario",
        "billing": "Facturación",
        "change_password": "Cambiar Contraseña",
        "draft": "Borrador",
        "bill_open": "Abierta",
        "bill_paid": "Pagada",
        "bill_void": "Cancelada",
        "bill_unpaid": "Sin pagar",
        "bills_list": "Listado de Facturas",
        "bill_info_header": "Estas son las facturas que hemos emitido. Pulse sobre un elemento para ver en PDF.",
        "problem_ocurrurs": "Se ha producido un problema",
        "password_too_short": "La contraseña es muy corta. Más de 6 caracteres.",
        "problem_occurred": "Se ha producido un error.",
        "error_occurred": "Se ha producido un error.",
        "password_recovery": "Recuperar acceso",
        "password_recovery_email": "Hemos verificado su identidad, ahora puede establecer una contraseña nueva.",
        "new_password_set": "Se ha establecido una nueva contraseña.",
        "back_home": "Volver al Inicio",
        "recovery_password_title": "Si no recuerda la contraseña.",
        "email_address": "Dirección de Email",
        "email_was_sent": "Se ha enviado un email.",
        "contact_info": "Datos de Contacto",
        "postal_address": "Dirección (Calle, num, ciudad, provincia, código postal)",
        "authentication_data": "Datos de Acceso",
        "join_newsletter": "Me gustaría recibir información relevante acerca de noticias y novedades de CloudRTU",
        "create_account": "Crear cuenta"
    }
};

export default es