import React from "react";
import { MapContainer } from "react-leaflet";
import ReactLeafletGoogleLayer from 'react-leaflet-google-layer';
export type MapsMapType = 'roadmap' | 'satellite' | 'terrain' | 'hybrid';

export interface MapsProps {
    mapType: MapsMapType,

    onMap?: (map: L.Map) => void,
    onMapClick?: (click: L.LeafletMouseEvent) => void,
    
}

export interface MapsState {
    map ? : L.Map

    lat: number,
    lng: number,
    zoom: number,
}
export default class Maps extends React.Component<MapsProps, MapsState> {

    map ? : L.Map;
    state: MapsState = { 
        lat: 40.33817045213394,
        lng: -3.6932795243169863,
        zoom: 6.4,
    };

    handleSetMap = (map: L.Map | null) => {
        if(map == null) {
            return;
        }
        if (!this.map) {
            this.map = map;
            map.off('click').on('click', (click) => {
                if (this.props.onMapClick) {
                    this.props.onMapClick(click);
                }
            });
            // TODO: Handle Map click by parent using props.onMap(map);
            if (this.props.onMap) {
                this.props.onMap(map);
            }

            
            setTimeout(() => {
                window.dispatchEvent(new Event('resize'));
            }, 1000);
        }
        
        return null;
    }

    render(): React.ReactNode {
        const position = {lat: this.state.lat, lng: this.state.lng};
        return <MapContainer 
        ref={(map) => this.handleSetMap(map)}
                center={position} zoom={this.state.zoom}
                style={{ width: '100%', height: '100%' }}>
                {/* <MapConsumer>{this.handleSetMap}</MapConsumer> */}

                {this.props.mapType === 'hybrid' && <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'hybrid'} />}
                {this.props.mapType === 'terrain' && <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'terrain'} />}
                {this.props.mapType === 'satellite' && <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'satellite'} />}
                {this.props.mapType === 'roadmap' && <ReactLeafletGoogleLayer useGoogMapsLoader={false} type={'roadmap'} />}


                {this.props.children}
            </MapContainer>
    }

}