import React from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {IonContent, IonHeader, IonItem, IonLoading, IonPage, IonText, IonTitle, IonToolbar} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../service/Store";

export interface LinkShortenProps extends RouteComponentProps < { token: string } > {
    store?: Store,
    
}
@inject("store", "lang")
class LinkShorten extends React.Component<LinkShortenProps, any> {

    state = {loading: false, url: '', error: false};

    componentDidMount(): void {
        this.update();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        if (this.props.match.params.token !== prevProps.match.params.token) {
            this.update();
        }
    }

    update() {
        let store: Store = this.props.store!;
        this.setState({loading: true});
        store.api.link(this.props.match.params.token).then(value => {
            this.setState({loading: false});
            if (value.success) {
                let url = value.data.url.replace("https://app.cloudrtu.com", "");
                url = url.replace("http://localhost", "");
                this.setState({url: url});
            } else {
                this.setState({error: true});
            }

        });

    }

    getLocation() {
        // eslint-disable-next-line no-restricted-globals
        return location;
    }

    handleClick = (ev: any) => {
        // this.getLocation().href = this.state.url;
        this.props.history.push(this.state.url);

    };

    isSafe(url: string) {
        return url.indexOf("app.cloudrtu.com") >= 0 || url.indexOf("localhost") >= 0 || url.indexOf(this.getLocation().host) >= 0;
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle>Redirección corta</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonLoading isOpen={this.state.loading}></IonLoading>
                {this.state.url && <IonItem button onClick={this.handleClick}><IonText>Continuar a {this.state.url}</IonText></IonItem>}
                {this.state.error &&
                <IonItem><IonText color={"danger"}>Se ha producido un error, inténtentelo de nuevo más tarde.</IonText></IonItem>}
                <IonItem routerLink={"/"}><>Volver al inicio</></IonItem>
            </IonContent>

        </IonPage>;
    }
}

export default withRouter(LinkShorten)
