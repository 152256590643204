import React, {RefObject} from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonCard,
    IonCardContent,
    IonCardHeader,
    IonCardSubtitle,
    IonCardTitle,
    IonCol,
    IonContent,
    IonGrid,
    IonHeader,
    IonIcon,
    IonInput,
    IonInputPasswordToggle,
    IonItem,
    IonItemGroup,
    IonLabel,
    IonPage,
    IonRow,
    IonSpinner,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";
import {checkmarkOutline, personOutline} from "ionicons/icons";
import {inject} from "mobx-react";
import {withRouter} from "react-router";
import {Store} from "../service/Store";
import Lang from "../service/Lang";

interface UserRecoveryProps {
    store?: Store,
    lang?: Lang,
    match?: any,
}

@inject("store", "lang")
class UserRecovery extends React.Component<UserRecoveryProps, any> {

    state = {
        error: false,
        success: false,
        loading: false,
        password: false,
    }
    email: RefObject<any>;
    password1: RefObject<any>;
    password2: RefObject<any>;

    constructor(props: any, context: any) {
        super(props, context);
        this.email = React.createRef();
        this.password1 = React.createRef();
        this.password2 = React.createRef();
    }

    componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any): void {
        //this.checkToken();

    }

    componentDidMount(): void {
        this.checkToken();
    }

    checkToken() {
        if (this.props.match.params.token) {
            this.setState({password: true});
        } else {
            this.setState({password: false});
        }

    }

    handleSubmitPassword = (ev: any) => {
        const {lang} = this.props;
        ev.preventDefault();
        let p1 = this.password1.current.value;
        let p2 = this.password2.current.value;
        let token = this.props.match.params.token;
        if (p1.length >= 6) {
            if (p1 === p2) {
                this.setState({loading: true});
                this.props.store?.api.usersRecoveryPassword(token, p1).then(value => {
                    this.setState({loading: false});
                    if (value.success) {
                        this.setState({success: true});
                    } else {
                        this.setState({error: lang?.l.main.problem_occurred()});

                    }
                })
            } else {
                this.setState({error: lang?.l.main.password_not_match()});
            }
        } else {
            this.setState({error: lang?.l.main.password_too_short()});
        }
    }

    handleSubmit = (ev: any) => {
        const {lang} = this.props;
        ev.preventDefault();
        let account = this.email.current.value;
        if (account.length > 0) {
            this.setState({loading: true, success: false, error: false});
            this.props.store?.api.usersRecovery(account).then(result => {
                this.setState({loading: false});
                if (result.success) {
                    this.setState({success: true});
                } else {
                    this.setState({error: lang?.l.main.error_occurred()})
                }

            })

        } else {
            this.setState({error: 'Por favor rellene el formulario'});

        }
    }

    renderFormPassword() {
        const {lang} = this.props;
        return <IonCard style={{padding: 16, background: '#ffffff'}}>
            <IonCardHeader>
                <IonCardTitle><IonIcon icon={checkmarkOutline}/> {lang?.l.main.password_recovery()}</IonCardTitle>
                <IonCardSubtitle>{lang?.l.main.password_recovery_email()}</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                <form autoComplete={"off"} onSubmit={this.handleSubmitPassword}>
                    {this.state.success || <IonItemGroup>
                        {/*<IonList lines={"full"}>*/}
                        <IonItem>
                            <IonInput labelPlacement="stacked" label={lang?.l.main.logn_password()} ref={this.password1} type={"password"} required={true}>
                                <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                            </IonInput>
                        </IonItem>
                        <IonItem>
                            <IonInput labelPlacement="stacked" label={lang?.l.main.password_repeat()} ref={this.password2} type={"password"} required={true}>
                                <IonInputPasswordToggle slot="end"></IonInputPasswordToggle>
                            </IonInput>
                        </IonItem>
                        <IonButton type={"submit"} expand={"block"}>
                            <IonSpinner hidden={!this.state.loading}/>
                            {lang?.l.main.change_password()}</IonButton>

                        {/*</IonList>*/}
                    </IonItemGroup>}
                    {this.state.success && <IonItemGroup>
                        {/*<IonList lines={"full"}>*/}
                        <IonItem color={"success"}>
                            <IonLabel><IonIcon icon={checkmarkOutline} /> {lang?.l.main.new_password_set()}</IonLabel>
                        </IonItem>
                        <IonButton routerLink={"/login"} expand={"block"}>
                            {lang?.l.main.back_home()}</IonButton>

                        {/*</IonList>*/}
                    </IonItemGroup>}


                    <IonToast
                        htmlAttributes={{tabindex: undefined}}
                        isOpen={!!this.state.error}
                        onDidDismiss={() => this.setState({error: false})}
                        message={String(this.state.error)}
                        duration={5000}
                        position="top"
                    />
                </form>
            </IonCardContent>
        </IonCard>
    }

    renderForm() {

        const {lang} = this.props;
        return <IonCard style={{padding: 16, background: '#ffffff'}}>
            <IonCardHeader>
                <IonCardTitle><IonIcon icon={personOutline}/> {lang?.l.main.password_recovery()}</IonCardTitle>
                <IonCardSubtitle>{lang?.l.main.recovery_password_title()}</IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
                <form autoComplete={"off"} onSubmit={this.handleSubmit}>
                    <IonItemGroup>
                        {/*<IonList lines={"full"}>*/}
                        <IonItem>
                            <IonLabel position="stacked">{lang?.l.main.email_address()}</IonLabel>
                            <IonInput ref={this.email} required={true}></IonInput>
                        </IonItem>
                        {this.state.success && <IonItem color={"success"}>
                            <IonLabel><IonIcon icon={checkmarkOutline}/> {lang?.l.main.email_was_sent()}</IonLabel>
                        </IonItem>}
                        <IonButton type={"submit"} expand={"block"}>
                            <IonSpinner hidden={!this.state.loading}/>
                            {lang?.l.main.login_recovery()}</IonButton>

                        {/*</IonList>*/}
                    </IonItemGroup>


                    <IonToast
                        htmlAttributes={{tabindex: undefined}}
                        isOpen={!!this.state.error}
                        onDidDismiss={() => this.setState({error: false})}
                        message={String(this.state.error)}
                        duration={5000}
                        position="top"
                    />
                </form>
            </IonCardContent>
        </IonCard>
    }


    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref="/"/>
                    </IonButtons>
                    <IonTitle>CloudRTU</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                <div className={"register-background"}>

                    <IonGrid style={{maxWidth: '600px'}}>
                        <IonRow>
                            <IonCol size="12">


                                {!this.state.password && this.renderForm()}
                                {this.state.password && this.renderFormPassword()}
                            </IonCol>
                        </IonRow>
                    </IonGrid>

                </div>
            </IonContent>
        </IonPage>
    }
}

export default withRouter(UserRecovery);
