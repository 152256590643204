import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../../service/Store";
import {Device} from "../../service/API";
import Utils from "../../service/Utils";
import {RouteComponentProps} from "react-router-dom";

export interface DeviceSettingsHardwareAINProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsHardwareAINState = {
    loading?: boolean,
    ains: any[],
    index: number,
    device:Device,
    error: boolean | string,
    edit: boolean,
}

@inject("store", "lang")
export default class DeviceSettingsHardwareAIN extends React.Component<DeviceSettingsHardwareAINProps, DeviceSettingsHardwareAINState> {

    componentDidMount(): void {

        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;
        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
            }
            this.props.store?.api.getHardwareAIN(Number(id)).then(value => {
                if(value.success) {
                    this.setState({ains: value.data.ains});
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });
    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsHardwareAINProps>, prevState: Readonly<DeviceSettingsHardwareAINState>, snapshot?: any): void {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        const {history} = this.props;

        this.props.store?.api.setHardwareAIN(Number(id), this.state.ains).then(value => {
            this.setState({loading: false});
            if(value.success) {
                if(value.data.result) {
                    history.goBack();
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.detail.value;
        this.setState({});
    }

    renderItem(value:any, index:number) {

        let name = Utils.getNameBySubInputGroup(this.state.device.inputGroups, 1, index);
        return <IonItem detail button onClick={() => this.setState({edit: true, index: index})}>
            <IonLabel>{name}</IonLabel>
        </IonItem>

    }

    renderEditor() {
        if(!this.state.device) return "";
        if(!this.state.ains) return "";
        if(this.state.index === undefined) return "";
        let index = this.state.index;
        let value = this.state.ains[index];
        let name = Utils.getNameBySubInputGroup(this.state.device.inputGroups, 1, index);

        return <>

            <IonItemDivider>
                <IonLabel>
                    {name}
                </IonLabel>
            </IonItemDivider>
            <IonItem>

                <IonSelect label="Tipo" value={value.type} onIonChange={this.handleChange(value, 'type')}>
                    <IonSelectOption value={0}>Desactivar</IonSelectOption>
                    <IonSelectOption value={1}>0-5V</IonSelectOption>
                    <IonSelectOption value={2}>0-20mA</IonSelectOption>
                    <IonSelectOption value={3}>4-20mA</IonSelectOption>
                    <IonSelectOption value={4}>PTC Probe</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem hidden={value.type > 3}>
                <IonInput label="Valor máximo" labelPlacement="stacked" value={value.maxValue} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'maxValue')}></IonInput>
            </IonItem>
            <IonItem hidden={value.type > 3}>
                <IonInput label="Valor mínimo" labelPlacement="stacked" value={value.minValue} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'minValue')}></IonInput>
            </IonItem>
            <IonItem hidden={value.type !== 4}>
                <IonInput label="R1 (x1000 ohm)" labelPlacement="stacked" value={value.maxValue} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'maxValue')}></IonInput>
            </IonItem>
            <IonItem hidden={value.type !== 4}>
                <IonInput label="R(25º) (x1000ohm)" labelPlacement="stacked" value={value.minValue} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'minValue')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Límite alto" labelPlacement="stacked" value={value.highLimit} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'highLimit')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Límite bajo" labelPlacement="stacked" value={value.lowLimit} step={"0.01"} max={"9999.99"} min={"-9999.99"} onIonInput={this.handleChange(value, 'lowLimit')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Tiempo confirmación" labelPlacement="stacked" value={value.confirmTime} step={"1"} max={"9999"} min={"0"} onIonInput={this.handleChange(value, 'confirmTime')}></IonInput>
            </IonItem>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if(!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"}/>
                    </IonButtons>
                    <IonTitle>Entradas analógicas</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}
                    {this.state.ains && this.state.ains.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>


            <IonModal isOpen={this.state.edit! ? true: false} onDidDismiss={() => this.setState({edit: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Entrada</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({edit: false})}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
