import ModbusSocket from "./ModbusSocket";
import ReadCoilsRequestBody from "./request/ReadCoilsRequestBody";
import ModbusClientResponseHandler from "./ModbusClientResponseHandler";
import ModbusRTUClientRequestHandler from "./ModbusRTUClientRequestHandler";
import ReadHoldingRegistersRequestBody from "./request/ReadHoldingRegistersRequestBody";
import WriteMultipleRegistersRequestBody from "./request/WriteMultipleRegistersRequestBody";
import ReadInputRegistersRequestBody from "./request/ReadInputRegistersRequestBody";

export default abstract class ModbusClient {

    private socket:ModbusSocket;
    private unitId:number = 1;
    private _responseHandler ?: ModbusClientResponseHandler;
    private _requestHandler ?: ModbusRTUClientRequestHandler;


    protected constructor(socket:ModbusSocket) {
        this.socket = socket;

        this.socket.on('data', (data:any) => this.onData(data));
    }

    public write(data:Buffer) {
        this.socket.write(data);
    };

    protected onData(data:Buffer) {

        this._responseHandler!.handleData(data);

        /* get latest message from message handler */
        do {
            const response = this._responseHandler!.shift();

            /* no message was parsed by now, come back later */
            if(!response) {
                return;
            }

            /* process the response in the request handler if unitId is a match */
            // if(this.unitId === response.unitId) {
                this._requestHandler!.handle(response);

            // }
        } while (1);

    }

    readCoils(start:number, count:number) {

        let request;

        request = new ReadCoilsRequestBody(start, count);

        return this._requestHandler!.registerBody(request);
    }



    /** Execute ReadHoldingRegisters Request (Function Code 0x03)
     * @param {Number} start Start Address.
     * @param {Number} count Coil Quantity.
     * @returns {Promise}
     * @example
     * client.readHoldingRegisters(0, 10).then(function (res) {
     *   console.log(res.response, res.request)
     * }).catch(function (err) {
     *   ...
     * })
     */
    readHoldingRegisters (start:number, count:number) {
        // debug('issuing new read holding registers request')
        let request
        try {
            request = new ReadHoldingRegistersRequestBody(start, count)
        } catch (e) {
            return Promise.reject(e)
        }

        return this._requestHandler!.registerBody(request);
    }


    /** Execute ReadInputRegisters Request (Function Code 0x04)
     * @param {number} start Start Address.
     * @param {number} count Coil Quantity.
     * @example
     * client.readInputRegisters(0, 10).then(function (res) {
     *   console.log(res.response, res.request)
     * }).catch(function (err) {
     *   ...
     * })
     */
    public readInputRegisters (start: number, count: number) {
        // debug('issuing new read input registers request')

        let request
        try {
            request = new ReadInputRegistersRequestBody(start, count)
        } catch (e) {
            // debug('unknown request error occurred')
            return Promise.reject(e)
        }

        return this._requestHandler!.registerBody(request)
    }

    /** Execute WriteMultipleRegisters Request (Function Code 0x10)
     * @param {Number} address Address.
     * @param {Array|Buffer} values Values either as an Array[UInt16] or a Buffer.
     * @returns {Promise}
     * @example
     * client.writeMultipleRegisters(10, [0x1234, 0x5678, 0x9ABC, 0xDEF0]).then(function (res) {
     *   console.log(res.response, res.request)
     * }).catch(function (err) {
     *   ...
     * })
     * @example
     * client.writeMultipleRegisters(10, Buffer.from([0x12, 0x34, 0x56, 0x78, 0x9A, 0xBC, 0xDE, 0xF0])).then(function (res) {
     *   console.log(res.response, res.request)
     * }).catch(function (err) {
     *   ...
     * })
     */
    writeMultipleRegisters (start:number, values:Array<number> | Buffer) {
        // debug('issuing new write multiple registers request')

        let request
        try {
            request = new WriteMultipleRegistersRequestBody(start, values)
        } catch (e) {
            return Promise.reject(e)
        }

        return this._requestHandler?.registerBody(request)
    }

    get responseHandler(): ModbusClientResponseHandler {
        return this._responseHandler!;
    }

    set responseHandler(value: ModbusClientResponseHandler) {
        this._responseHandler = value;
    }

    get requestHandler(): ModbusRTUClientRequestHandler {
        return this._requestHandler!;
    }

    set requestHandler(value: ModbusRTUClientRequestHandler) {
        this._requestHandler = value;
    }
}
