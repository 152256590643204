import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonItem, IonList, IonModal, IonSelect, IonSelectOption, IonTitle, IonToolbar } from "@ionic/react";
import React from "react";

export default class OutputServiceModal extends React.Component<any, any> {

    handleChangeGroupValue = (group: any, key: string) => (ev: any) => {
        if (ev.detail.checked !== undefined) {
            group[key] = ev.detail.checked;

        } else {
            group[key] = ev.detail.value;
        }
    }

    handleClose = (result: boolean) => {
        this.props.onClose(result);
    }
    render() {
        const { editOutputService } = this.props;

        return <IonModal isOpen={this.props.editOutputService! ? true : false}
            onDidDismiss={() => this.handleClose(false)}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>Nuevo Servicio Externo</IonTitle>
                    <IonButtons slot={"secondary"}>
                        <IonButton onClick={() => this.handleClose(false)}>Cancelar</IonButton>
                    </IonButtons>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={() => this.handleClose(true)}>OK</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>

            <IonContent>
                {editOutputService && <IonList>
                    <IonItem>
                        <IonInput label="Nombre (lora)" value={editOutputService.name} placeholder="lora" onIonInput={this.handleChangeGroupValue(editOutputService, 'name')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Título" value={editOutputService.title} onIonInput={this.handleChangeGroupValue(editOutputService, 'title')}></IonInput>
                    </IonItem>

                    <IonItem>
                        <IonSelect label="Tipo" value={editOutputService.service_type} onIonChange={this.handleChangeGroupValue(editOutputService, 'service_type')}>
                            <IonSelectOption value={'FieldStation2'}>FieldStation2</IonSelectOption>
                            <IonSelectOption value={'SenseCAP'}>SenseCAP</IonSelectOption>
                        </IonSelect>
                    </IonItem>
                    <IonItem>
                        <IonInput label="Ajustes" value={editOutputService.settings} placeholder="TTN Device ID" onIonInput={this.handleChangeGroupValue(editOutputService, 'settings')}></IonInput>
                    </IonItem>
                    <IonItem>
                        <IonSelect label="Entrada Asociada" value={editOutputService.input_group} onIonChange={this.handleChangeGroupValue(editOutputService, 'input_group')} >
                            {this.props.inputGroups && this.props.inputGroups.map((v: any, k: any) => {
                                return <IonSelectOption key={k} value={v.iindex}>{v.name}</IonSelectOption>
                            })}
                        </IonSelect>
                    </IonItem>
                </IonList>}
            </IonContent>

        </IonModal>
    }
}