import ModbusSocket from "./rtu/ModbusSocket";
import WebSerialPort from "../service/WebSerialPort";

export default class SerialWrapper implements ModbusSocket{
    private serial:WebSerialPort;


    constructor(serial: WebSerialPort) {
        this.serial = serial;
    }

    write(data: Buffer): void {
        this.serial.write(data);
    }

    on(event: string, callback: (args: any) => any): void {
        if(event === 'data') {
            this.serial.setOnDataReceivedCallback((data:ArrayBuffer) => {
                // console.log(data);
                callback(Buffer.from(data));
            });
        }
    }
}
