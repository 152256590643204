import React from "react";
import {
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import AddDevice from "../components/AddDevice";

export interface AddDevicesProps extends RouteComponentProps<{ token?: string }> {
    store?: Store;
}
export type AddDevicesState = {
    token: string
}

@inject("store", "lang")
export default class AddDevices extends React.Component<AddDevicesProps, AddDevicesState> {

    state = {
        token: ''
    } as AddDevicesState;

    ref : React.RefObject<any> = React.createRef();
    componentDidMount(): void {
        if (this.props.match?.params.token !== "add") {
            this.setState({ token: this.props.match?.params.token! });
        }
    }

    componentDidUpdate(prevProps: Readonly<AddDevicesProps>, prevState: Readonly<AddDevicesState>, snapshot?: any): void {
        if (this.props.match?.params.token !== prevProps.match?.params.token) {
            if (this.props.match?.params.token !== "add") {
                this.setState({ token: this.props.match?.params.token! });
            }
        }
    }

    handleOnAddDevice = () => {
        this.props.history.replace("/devices?reload=true");
        
    }

    handleAdd = (ev:any) => {
        this.ref.current.handleAdd(ev)
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle>Añadir dispositivo</IonTitle>


                    <IonButtons slot={"secondary"}>
                        <IonButton routerLink="/devices">Cancelar</IonButton>
                    </IonButtons>
                    <IonButtons slot={"primary"}>
                        <IonButton onClick={this.handleAdd}>Añadir</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <AddDevice ref={this.ref} onAddDevice={this.handleOnAddDevice} token={this.state.token} routerOutlet={undefined} />
            </IonContent>
        </IonPage>
    }

}
