import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device } from "../../service/API";
import Utils from "../../service/Utils";
import { RouteComponentProps } from "react-router-dom";

export interface DeviceSettingsHardwareDINProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
}

type DINType = {
    type: number,
    confirmTime: number,
    hr24: number,
    startValue: number,
    interval: number,
    total: number,

}
export type DeviceSettingsHardwareDINState = {
    loading?: boolean,
    dins: DINType[],
    index: number,
    device: Device,
    error: boolean | string,
    edit: boolean,
}

@inject("store", "lang")
export default class DeviceSettingsHardwareDIN extends React.Component<DeviceSettingsHardwareDINProps, DeviceSettingsHardwareDINState> {

    componentDidMount(): void {

        this.loadDevice();
    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsHardwareDINProps>, prevState: Readonly<DeviceSettingsHardwareDINState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    private loadDevice() {

        this.setState({ loading: true });
        let id = this.props.match?.params.id!;

        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if (value.success) {
                this.setState({ device: value.data })
            }
            this.props.store?.api.getHardwareDIN(Number(id)).then(value => {
                if (value.success) {
                    this.setState({ dins: value.data.dins });
                } else {
                    this.setState({ error: 'El dispositivo no está conectado' });
                }
            }).finally(() => this.setState({ loading: false }));
        });
    }
    handleSave = (ev: any) => {
        this.setState({ loading: true, error: false });
        let id = this.props.match?.params.id!;
        const { history } = this.props;

        this.props.store?.api.setHardwareDIN(Number(id), this.state.dins).then(value => {
            this.setState({ loading: false });
            if (value.success) {
                if (value.data.result) {
                    history.goBack();
                } else {
                    this.setState({ error: 'No se puede configurar el dispositivo' });
                }
            }
        });

    };

    handleChange = (item: any, name: string) => (ev: any) => {
        item[name] = ev.detail.value;
        this.setState({});
    }
    handleCheckChange = (item: any, name: string) => (ev: any) => {
        item[name] = ev.currentTarget.checked;
        this.setState({});
    }

    handleReset = () => {

        for (let i = 0; i < this.state.dins.length; i++) {
            let item = this.state.dins[i];
            item.type = 0;
            item.confirmTime = 0;
            item.hr24 = 0;
            item.startValue = 0;
            item.interval = 0;
            item.total = 0;
        }
        this.setState({});
    }
    renderItem(value: DINType, index: number) {

        let name = Utils.getNameBySubInputGroup(this.state.device.inputGroups, 2, index);
        return <IonItem key={index} detail button onClick={() => this.setState({ edit: true, index: index })}>
            <IonLabel>{name}</IonLabel>
        </IonItem>

    }

    renderEditor() {
        if (!this.state.device) return "";
        if (!this.state.dins) return "";
        if (this.state.index === undefined) return "";
        let index = this.state.index;
        let value = this.state.dins[index];
        let name = Utils.getNameBySubInputGroup(this.state.device.inputGroups, 1, index);

        return <>

            <IonItemDivider>
                <IonLabel>
                    {name}
                </IonLabel>
            </IonItemDivider>
            <IonItem>
                <IonSelect label="Tipo" value={value.type} onIonChange={this.handleChange(value, 'type')}>
                    <IonSelectOption value={0}>Desactivar</IonSelectOption>
                    <IonSelectOption value={1}>Abierto Normalmente</IonSelectOption>
                    <IonSelectOption value={2}>Cerrado Normalmente</IonSelectOption>
                    <IonSelectOption value={3}>Cambio</IonSelectOption>
                    <IonSelectOption value={5}>Contador pulsos</IonSelectOption>
                    <IonSelectOption value={6}>Contador parcial y total</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonInput label="Tiempo de confirmación" labelPlacement="stacked" value={value.confirmTime} step={"1"} max={"9999"} min={"0"}
                    onIonInput={this.handleChange(value, 'confirmTime')} />
            </IonItem>
            <IonItem>
                <IonToggle slot={"end"} checked={!!value.hr24} onIonChange={this.handleCheckChange(value, 'hr24')}>
                    Valor 24H
                </IonToggle>
            </IonItem>
            <div hidden={value.type < 5}>
                <IonItem>
                    <IonInput label="Valor inicial" labelPlacement="stacked" value={value.startValue} step={"1"} max={"999999"} min={"0"}
                        onIonInput={this.handleChange(value, 'startValue')} />
                </IonItem>
                <IonItem>
                    <IonInput label="Intervalo" labelPlacement="stacked" value={value.interval} step={"1"} max={"999999"} min={"0"}
                        onIonInput={this.handleChange(value, 'interval')} />
                </IonItem>
                <IonItem>
                    <IonInput label="Total" labelPlacement="stacked" value={value.total} step={"1"} max={"999999"} min={"0"}
                        onIonInput={this.handleChange(value, 'total')} />
                </IonItem>
            </div>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Entradas digitales</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleReset}>Reiniciar</IonButton>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}
                    {this.state.dins && this.state.dins.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>


            <IonModal isOpen={this.state.edit! ? true : false} onDidDismiss={() => this.setState({ edit: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Entrada</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ edit: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
