import { Capacitor } from '@capacitor/core';
import { Store } from './Store';

class SiriShortcutsMonk {

    addListener(key: string, callback: any) { };

}

const SiriShortcuts = (Capacitor.getPlatform() === 'web') ? (new SiriShortcutsMonk()) : require('capacitor-plugin-siri-shorts').SiriShortcuts;


export default class Siri {
    store: Store;
    readonly available: boolean = Capacitor.getPlatform() === 'ios';

    constructor(store: Store) {
        this.store = store;
        SiriShortcuts.addListener('appLaunchBySiriShortcuts', (res:any) => {
            // do something with the response of the shortcut here
            this.handleAction(res.persistentIdentifier);
        });

    }

    async addToSiri(action: string, message: string, userInfo : any = undefined) {
        try {

            await SiriShortcuts.donate({
                persistentIdentifier: action,
                title: message,
                userInfo
            });

            await SiriShortcuts.present({
                persistentIdentifier: action,
                title: message,
                userInfo
            })
        } catch (ex: any) {

        }
    }

    private convertState(string: string) {
        if (string === 'on') return 1;
        if (string === 'off') return 0;
        return parseFloat(string);
    }

    async handleAction(action: string) {
        if (!action) return;

        let items = action.split("-");
        if (items[0] === "output") {
            let device_id = parseInt(items[1]);
            let output_index = parseInt(items[2]);
            let state = this.convertState(items[3]);
            

            await this.store.api.deviceOutputState(device_id, output_index, 0, state);
            if (this.store.router) {
                this.store.router.history.push("/device/" + device_id + "/details")
                
            }
        }

    }

    async delete(action: string) {
        await SiriShortcuts.delete({ identifiers: [action] });
    }

    async deleteAll() {
        await SiriShortcuts.deleteAll();
    }
}