import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonFab,
    IonFabButton,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonTitle,
    IonToggle,
    IonToolbar
} from "@ionic/react";
import { RouteComponentProps } from "react-router";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device } from "../../service/API";
import { add } from "ionicons/icons";


export interface DeviceSettingsPluginsProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
    routerOutlet: HTMLIonRouterOutletElement,
}
export type DeviceSettingsPluginsState = {
    loading?: boolean,
    device?: Device,
    pluginConfigure: boolean,
    plugins: DevicePlugin[],
}

type DevicePlugin = {
    name: string,
    title: string,
    description: string,
    price: number,
    enabled: boolean,
}

@inject("store", "lang")
export default class DeviceSettingsPlugins extends React.Component<DeviceSettingsPluginsProps, DeviceSettingsPluginsState> {

    state: DeviceSettingsPluginsState = {
        pluginConfigure: false,
        plugins: [
            { name: 'fert0', title: 'Fertirrigacion', description: 'Ayuda para abonar', enabled: false, price: 0 }
        ],

    }

    componentDidUpdate(prevProps: Readonly<DeviceSettingsPluginsProps>, prevState: Readonly<DeviceSettingsPluginsState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    async loadDevice() {
        let id = this.props.match?.params.id!;
        this.setState({ loading: true });
        let device = await this.props.store?.api.deviceGet(Number(id), 'all');
        if (device) {
            this.setState({ device: device.data });
        }
        this.setState({ loading: false });
    }

    handleAdd = () => {

    }

    renderPlugin(plugin: DevicePlugin) {
        return <IonItem>
            <IonToggle checked={plugin.enabled}></IonToggle>
            <IonLabel>{plugin.title}</IonLabel>
            <IonButton slot="end">Configurar</IonButton>
        </IonItem>

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Extensiones</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.plugins.map((v, k) => this.renderPlugin(v))}
                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />

                <IonFab vertical="bottom" horizontal="end" slot="fixed" onClick={this.handleAdd}>
                    <IonFabButton>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
            </IonContent>
            <IonModal presentingElement={this.props.routerOutlet} isOpen={this.state.pluginConfigure! ? true : false} onDidDismiss={() => this.setState({ pluginConfigure: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Establecer ubicación</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ pluginConfigure: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent fullscreen>

                </IonContent>

            </IonModal>
        </IonPage>
    }

}
