import ModbusRTUResponse from "./ModbusRTUResponse";

export default class ModbusClientResponseHandler {

    protected buffer : Buffer = Buffer.alloc(0);
    protected messages:ModbusRTUResponse[] = [];
    handleData(data: Buffer): void {
        throw new Error('Not implemented yet.')
    }

    shift(): ModbusRTUResponse | undefined {
        return this.messages.shift();
    }

}
