import React from "react";
import {
    IonBackButton,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import {inject} from "mobx-react";
import {Store} from "../../service/Store";
import {Device} from "../../service/API";
import Utils from "../../service/Utils";
import {RouteComponentProps} from "react-router-dom";

export interface DeviceSettingsHardwareRelaysProps extends RouteComponentProps<{id: string}> {
    store?:Store,
}
export type DeviceSettingsHardwareRelaysState = {
    loading?: boolean,
    relays: any[],
    device:Device,
    error: boolean | string,
    edit: boolean,
    index: number,
}

@inject("store", "lang")
export default class DeviceSettingsHardwareRelays extends React.Component<DeviceSettingsHardwareRelaysProps, DeviceSettingsHardwareRelaysState> {

    componentDidMount(): void {
        this.loadDevice();
    }

    loadDevice() {
        this.setState({loading: true});
        let id = this.props.match?.params.id!;

        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if(value.success) {
                this.setState({device: value.data})
            }
            this.props.store?.api.getHardwareRelay(Number(id)).then(value => {
                if(value.success) {
                    this.setState({relays: value.data.relays})
                } else {
                    this.setState({error: 'El dispositivo no está conectado'});
                }
            }).finally(() => this.setState({loading: false}));
        });

    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsHardwareRelaysProps>, prevState: Readonly<DeviceSettingsHardwareRelaysState>, snapshot?: any): void {
        if(this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    handleSave = (ev: any) => {
        this.setState({loading: true, error: false});
        let id = this.props.match?.params.id!;
        const {history} = this.props;

        this.props.store?.api.setHardwareRelay(Number(id), this.state.relays).then(value => {
            if(value.success) {
                if(value.data.result) {
                    history.goBack();
                } else {
                    this.setState({error: 'No se puede configurar el dispositivo'});
                }
            }
        });

    };

    handleChange = (item:any, name:string) => (ev:any) => {
        item[name] = ev.detail.value;
    }

    renderItem(value:any, index:number) {
        if(!this.state.device) return "";
        let output = Utils.getOutputByIndex(this.state.device.outputs, index);

        return <>
            <IonItem detail button onClick={() => this.setState({edit: true, index: index})}>
                <IonLabel>{output ? output!.name : "?"}</IonLabel>
            </IonItem>
        </>
    }

    renderEditor() {
        if(!this.state.device) return "";
        if(this.state.index === undefined) return "";
        let output = Utils.getOutputByIndex(this.state.device.outputs, this.state.index);
        let value = this.state.relays[this.state.index];

        return <>

            <IonItemDivider>
                <IonLabel>
                    {output!.name}
                </IonLabel>
            </IonItemDivider>
            <IonItem>
                <IonInput label="Tiempo en cerrar (s)" value={value.closeTime} onIonInput={this.handleChange(value, 'closeTime')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Tiempo en abrir (s)" value={value.openTime} onIonInput={this.handleChange(value, 'openTime')}></IonInput>
            </IonItem>
            <IonItem>
                <IonInput label="Frecuencia (pulsos)" value={value.freq} onIonInput={this.handleChange(value, 'freq')}></IonInput>
            </IonItem>
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if(!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"}/>
                    </IonButtons>
                    <IonTitle>Relés</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}
                    {this.state.relays && this.state.relays.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>

            <IonModal isOpen={this.state.edit! ? true: false} onDidDismiss={() => this.setState({edit: false})}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Salida</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({edit: false})}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
