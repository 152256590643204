import React, { RefObject } from "react";
import {
    IonBadge,
    IonButton,
    IonButtons,
    IonCard,
    IonCheckbox,
    IonCol,
    IonContent,
    IonDatetime,
    IonGrid,
    IonHeader,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonLoading,
    IonModal,
    IonPage,
    IonRow,
    IonSearchbar,
    IonSpinner,
    IonText,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";
import { inject, observer } from "mobx-react";
import { Store } from "../../service/Store";
import { APIBase, Device, DeviceInputGroup, DeviceInputs, DeviceOutput } from "../../service/API";
import EventBox from "../../service/EventBox";
import {
    arrowBack,
    downloadOutline,
    filterCircle,
    filterCircleOutline,
    informationCircle,
    notifications,
    refreshOutline,
    saveOutline,
    settingsOutline
} from "ionicons/icons";
import Utils from "../../service/Utils";
import InputGroupGraph from "../../components/InputGroupGraph";
import * as H from 'history';
import { match } from "react-router";
import CSV from "../../service/CSV";
import moment from "moment-timezone";
import 'moment/locale/es'

import InputChart, { ShortRanges } from "../../components/InputChart";
import Chart from "react-google-charts";
import Lang from "../../service/Lang";

export interface DeviceStatusProps {
    store?: Store,
    lang?: Lang,
    location?: H.Location,
    history?: H.History,

    match?: match<{ id: string }>,

    routerOutlet: HTMLIonRouterOutletElement,
}

export type DeviceStatusState = {
    device?: Device, segment?: string,
    loading?: boolean,
    loadingData: boolean,
    errorToastOpen: boolean,
    errorToastMessage: string,
    download: number,
    inputs?: DeviceInputs,
    // iindex?: number | boolean,
    dateInputStart?: string;
    dateInputEnd?: string;
    modalFilter: boolean,
    hideInputGroups: number[],
    filterByName: string,
}

@inject("store", "lang")
@observer
export default class DeviceStatus extends React.Component<DeviceStatusProps, DeviceStatusState> {
    options = {
        width: 400,
        height: 120,
        redFrom: 90,
        redTo: 100,
        yellowFrom: 75,
        yellowTo: 90,
        minorTicks: 5
    };
    events?: EventBox;
    dateStart: RefObject<any>;
    dateEnd: RefObject<any>;

    state: DeviceStatusState = {
        download: 0,
        modalFilter: false,
        loadingData: false,
        errorToastOpen: false,
        hideInputGroups: [],
        filterByName: "",
        errorToastMessage: "",
    };


    constructor(props: DeviceStatusProps, context: any) {
        super(props, context);
        this.dateStart = React.createRef();
        this.dateEnd = React.createRef();
    }

    componentDidMount(): void {
        this.loadDevice();
        // this.props.history?.listen((location: H.Location<{ direction?: string, } | null | undefined>) => {
        //     Utils.log(location);
        //     if (location.state && location.state?.direction === "back") {
        //         this.loadDevice();
        //     }
        // });
        this.setState({
            dateInputStart: moment().subtract(7, 'days').format("YYYY-MM-DD"),
            dateInputEnd: moment().format("YYYY-MM-DD"),
        });
        this.events = new EventBox(this.props.store?.events!);

        this.events.on('inputs', (device: any, inputs: any[]) => {
            Utils.log(device, inputs);
            for (let i = 0; i < inputs.length; i++) {
                this.updateInput(inputs[i].id, inputs[i]);
            }
        });
        this.events.on('input-data', (inputId: any, value: any, time: any, input: any) => {
            if (this.state.device) {
                let groups = this.state.device.inputGroups;
                for (let i = 0; groups && i < groups.length; i++) {
                    for (let index2 = 0; index2 < groups[i].inputs.length; index2++) {
                        const element = groups[i].inputs[index2];
                        if (inputId === groups[i].id) {
                            element.value = value;
                            this.setState({});
                            return true;
                        }
                    }

                }
            }
        });
        setTimeout(() => {
            this.setState({});
        }, 3000);
    }

    componentDidUpdate(prevProps: Readonly<DeviceStatusProps>, prevState: Readonly<DeviceStatusState>, snapshot?: any): void {
        if (this.props.match?.params.id !== prevProps.match?.params.id) {
            this.loadDevice()
        }
    }

    componentWillUnmount(): void {
        let store: Store = this.props.store!;
        store.unsubscribeDevice();
        this.events?.off();
    }

    updateInput(id: number, data: any) {
        if (this.state.device) {
            let inputs = this.state.device.inputGroups;
            for (let i = 0; inputs && i < inputs.length; i++) {
                if (id === inputs[i].id) {
                    inputs[i] = data;
                    this.setState({ device: { ...this.state.device, inputGroups: inputs } });
                    return true;
                }

            }
        }
        return false;
    }

    loadDevice() {
        let id = this.props.match?.params.id!;
        this.setState({ loading: true })
        this.props.store?.api.deviceGet(parseInt(id), 'input').then((value: APIBase<Device>) => {
            this.setState({ device: value.data, loading: false });
        });
        this.props.store?.subscribeToDevice(parseInt(id));

        if (id) {

            try {
                let json = JSON.parse(localStorage.getItem('filter-input-groups-' + id)!);
                if (Array.isArray(json)) {
                    this.setState({ hideInputGroups: json });
                }

            } catch (ex: any) {

            }
        }
    }

    loadData() {
        this.loadDevice();
        this.setState({ loadingData: true })
        this.props.store?.api.deviceInputData(parseInt(this.props.match?.params.id!)).then((data) => {
            this.setState({ loadingData: false })
            if(!data.success) {
                this.setState({errorToastMessage: String(JSON.stringify(data.error)), errorToastOpen: true});
            }
            
            // this.loadDevice();
        }).catch(err => {
        })
    }


    handleDownload = (id: any) => (ev: any) => {
        const { lang } = this.props;
        let dateStart = new Date(this.dateStart.current.value);
        let dateEnd = new Date(this.dateEnd.current.value);
        dateEnd.setHours(23, 23, 59);

        let inputGroup = this.state.device?.inputGroups.find(v => v.id === id);
        this.setState({ loading: true });
        if (!inputGroup) return;
        let inputMap: any = {};
        inputGroup.inputs.map(v => inputMap[v.id] = v);
        return this.props.store?.api.deviceInputGroupChart((inputGroup.id), dateStart.getTime() / 1000, dateEnd.getTime() / 1000, "minute").then(value => {
            if (value.success) {
                let csv = new CSV();
                csv.row(["#", lang?.l.main.map_value(), lang?.l.main.scheduler_output(), lang?.l.main.date()]);
                let inputs = Utils.getInputById(this.state.device?.inputGroups!, Number(this.state.download!));
                if (inputs) {
                    value.data.map(value1 => {
                        let name = Utils.getInputName(this.state.device!, inputMap[value1['input_id']]);
                        csv.row([value1['id'], value1['data'], name, this.props.store?.moment(new Date(value1.time * 1000)).format("DD/MM/YYYY HH:mm:ss")])
                        return value1;
                    })
                }


                csv.download("CloudRTU_" + this.state.device!.name + "_log.csv").then(value1 => {
                    this.setState({ download: 0 });
                }).catch(() => {

                })
            }
            this.setState({ loading: false });

        })
    };

    private handleClickDetails = (value: DeviceInputs) => (ev: any) => {
        this.props.history?.push("/device/" + this.state.device?.id! + "/status/input/" + value.id);

        // this.setState({inputs: value, inputLog: []}, () => {
        //     // this.handleClickInputLog(ev)
        // });


    }

    handleAnimationEnd = (ev: any) => {
        ev.currentTarget.classList.remove("item-updated");
    }

    handleSearchChange = (ev: any) => {
        this.setState({ filterByName: ev.detail.value });
    }


    static renderItemText(text: string): any {
        return text
    }

    static renderItemOutput(lang: Lang, outputs: DeviceOutput[], item: DeviceInputs): any {
        let output = Utils.getOutputByIndex(outputs, item.index);
        if (!output) return null;
        let content;
        if (output.type === 'toggle') {
            if (parseInt(item.value!) === output.off_value) {
                content = <IonBadge color="danger">{lang?.l.main.output_off()}</IonBadge>
            } else {
                content = <IonBadge color="success">{lang?.l.main.output_on()}</IonBadge>
            }
        } else {
            content = <IonBadge color="tertiary">{item.value} {output.units!}</IonBadge>
        }
        return content
    }

    static renderGsmSignal(item: DeviceInputs): any {
        return item.value;
    }

    static renderVoltage(item: DeviceInputs): any {
        return item.value + " V";
    }

    static renderOnOff(lang: Lang, input: DeviceInputs): any {
        if (Utils.toBoolean(input.value)) {
            return <IonBadge color="success">{input.on_value ?? lang?.l.main.output_on()}</IonBadge>
        } else {
            return <IonBadge color="danger">{input.off_value ?? lang?.l.main.output_off()}</IonBadge>
        }
    }

    static renderGauge(item: DeviceInputs) {
        let input = parseFloat(item.value);
        let unit = item.unit || "";
        let max = parseFloat(item.max || "100");
        let min = parseFloat(item.min || "0");
        // let percent = ((input - min)) / (max - min);
        return <Chart
            chartType="Gauge"
            width={"180px"}
            height={"180px"}
            loader={<div>Loading Chart</div>}
            data={[
                ['Label', 'Value'],
                [unit, input],
            ]}
            options={{
                redFrom: max - (max * 0.10),
                redTo: max,
                yellowFrom: max - (max * 0.25),
                yellowTo: max - (max * 0.10),
                minorTicks: 5,
                max: max,
                min: min,
            }}
        />
        // return <GaugeChart style={{width: '220px', height: '100%'}} id={"gauge-chart-" + item.id}
        //                    nrOfLevels={20}
        //                    percent={percent}
        //                    formatTextValue={value => input + " " + unit}
        //
        //
        // />
        //
        // return <div><CustomGauge id={item.id!} label={item.unit!} value={parseFloat(item.value!)} height={240} width={240} max={parseFloat(item.max!)}
        //                          min={parseFloat(item.min!)}></CustomGauge></div>
    }

    static renderItemInput(lang: Lang, input: DeviceInputs): any {
        if (input.widget === "text") {
            return <IonBadge color="">{input.value + " " + (input.unit ?? "")}</IonBadge>;
        } else if (input.widget === "gaug") {
            return this.renderGauge(input);
        } else if (input.widget === "onoff") {
            return this.renderOnOff(lang, input);
        } else if (input.widget === "perc") {
            let val = parseFloat(input.value);
            let max = parseFloat(input.max!);
            let min = parseFloat(input.min!);
            if (val > max) {
                val = max;
            }
            let percent = ((val - min) * 100) / (max - min);
            return <IonBadge color="">{percent + " % " + (input.unit ?? "")}</IonBadge>;
        } else if (input.widget === "pulses") {
            return <IonBadge color="">{input.value + " " + (input.unit ?? "")}</IonBadge>;
        } else if (input.widget === "distance") {
            return <IonBadge color="">{input.value + " " + (input.unit ?? "")}</IonBadge>;
        } else if (input.widget === "pulses") {
            return <IonBadge color="">{input.value + " " + (input.unit ?? "")}</IonBadge>;
        } else if (input.widget === "flow") {
            return <IonBadge color="">{input.value + " " + (input.unit ?? "")}</IonBadge>;
        } else if (input.widget === "datetime") {
            return <IonBadge color="">{moment(input.value, "X").tz('UTC').format("HH:mm DD/MM/YYYY")}</IonBadge>;
        }
    }

    static renderItemTitle(device: Device, input: DeviceInputs): string {
        return Utils.getInputName(device, input);
    }

    static renderItemContent(lang: Lang, device: Device, input: DeviceInputs): any {
        if (input.type === 'text') {
            return this.renderItemText(input.value);
        }
        if (input.type === 'output') {
            return this.renderItemOutput(lang, device.outputs, input);
        }
        if (input.type === 'input') {
            return this.renderItemInput(lang, input);
        }
        if (input.type === 'signal') {
            return this.renderGsmSignal(input);
        }
        if (input.type === 'voltage') {
            return this.renderVoltage(input);
        }
        if (input.type === 'distance') {
            return this.renderItemInput(lang, input);
        }

        if (input.type === 'datetime') {
            return this.renderItemInput(lang, input);
        }

        return "(No implementado)";
    }

    renderInputGroupGraph(input: DeviceInputGroup) {
        return <InputGroupGraph input={input} />
    }

    renderInputChart(input: DeviceInputs) {


    }

    handleChangeFilter = (inputGroup: DeviceInputGroup) => (ev: any) => {
        let checked = ev.detail.checked;

        let id = inputGroup.id;
        if (!checked) {
            if (this.state.hideInputGroups.indexOf(id) === -1) {

                this.state.hideInputGroups.push(id);
                this.setState({ hideInputGroups: [...this.state.hideInputGroups] });
            }
        } else {
            let index = this.state.hideInputGroups.indexOf(id);
            if (index !== -1) {
                this.state.hideInputGroups.splice(index, 1);
                this.setState({ hideInputGroups: [...this.state.hideInputGroups] });
            }
        }


        if (this.state.device) {

            localStorage.setItem('filter-input-groups-' + this.state.device.id, JSON.stringify(this.state.hideInputGroups));
        }

    }

    filterInputs = (a: DeviceInputs) => {
        return a.name.toLocaleLowerCase().indexOf(this.state.filterByName.toLocaleLowerCase()) !== -1;
    }

    handleClickAll = (ev:any) => {

        if (this.state.device) {
            let all : number[] = [];
            this.setState({ hideInputGroups: [...all] });
            localStorage.setItem('filter-input-groups-' + this.state.device.id, JSON.stringify(all));
        }

    }

    handleClickNone = (ev:any) => {

        if (this.state.device) {
            let all : number[] = [];
            let list = this.state.device?.inputGroups!;
            for (const iterator of list) {
                all.push(iterator.id)
            }
            this.setState({ hideInputGroups: [...all] });
            localStorage.setItem('filter-input-groups-' + this.state.device.id, JSON.stringify(all));
        }

    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        // const id = this.props.match?.params.id;
        const { lang } = this.props;
        const { id } = this.props.match!.params;
        const inputGroups = (this.state.device ? this.state.device.inputGroups : null);
        // const hideInputGroups = [];
        return <IonPage>
            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">

                        <IonButton routerDirection={"back"} routerLink={"/device/" + id + "/details"}><IonIcon icon={arrowBack} /></IonButton>
                    </IonButtons>
                    <IonTitle>{lang?.l.main.map_status()}</IonTitle>
                    <IonButtons slot="end">
                        <IonSpinner color={"light"} hidden={!this.state.loadingData} />
                        <IonButton onClick={() => this.loadData()}>
                            <IonIcon slot="icon-only" icon={refreshOutline} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>

                <IonToolbar color={"primary"}>
                    <IonSearchbar onIonChange={this.handleSearchChange} placeholder="Buscar por nombre..."></IonSearchbar>

                    <IonButtons slot="end">
                        <IonButton onClick={() => this.setState({ modalFilter: true })}>
                            <IonIcon slot="icon-only" icon={(this.state.hideInputGroups.length === 0 ? filterCircleOutline : filterCircle)} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent scrollEvents={true}>
                {this.state.device && this.state.device?.inputGroups && Utils.sortInputsGroup(this.state.device?.inputGroups).map((value, index) => {


                    if (!value.visible) return "";
                    if (this.state.hideInputGroups.indexOf(value.id) !== -1) {
                        return "";
                    }
                    let inputs = value.inputs ? value.inputs.filter(value1 => value1.widget !== 'hide') : null;

                    if (this.state.filterByName && inputs) {
                        inputs = inputs.filter(this.filterInputs);
                    }


                    return <div key={index}>
                        <IonListHeader>
                            <IonLabel>{value.name}</IonLabel>
                            <IonButton onClick={() => this.setState({ download: value.id })}><IonIcon
                                icon={downloadOutline} /></IonButton>
                            <IonButton
                                routerLink={"/device/" + this.state.device!.id + "/settings/inputGroup/" + value.id}><IonIcon
                                    icon={settingsOutline} /></IonButton>

                        </IonListHeader>
                        {/*<IonItem lines="none">*/}
                        {/*    <IonLabel color="primary">*/}
                        {/*        {value.updatedAt && <>Actualizado {moment(value.updatedAt).locale('es').fromNow()}</>}*/}
                        {/*    </IonLabel>*/}
                        {/*</IonItem>*/}
                        <IonGrid>
                            <IonRow>
                                {inputs && inputs.map((item, key: number) => {
                                    let update = this.checkChange(item);
                                    let title = DeviceStatus.renderItemTitle(this.state.device!, item);
                                    // let text = typeof render?.content === 'string';
                                    return <IonCol sizeSm={"6"} sizeLg={"3"} key={key}>

                                        <IonCard color={""} onAnimationEnd={this.handleAnimationEnd}
                                            className={update ? "item-updated" : ""}>
                                            {!!value.graph && <InputChart device={this.state.device!} location={"deviceGraph"} ranges={ShortRanges} number={key} input={item} title={title} time={item.updatedAt} />}
                                            <IonItem>
                                                <IonLabel>{title}</IonLabel>
                                                <IonText>{DeviceStatus.renderItemContent(this.props.lang!, this.state.device!, item)}</IonText>
                                            </IonItem>
                                            {item.error && <IonItem lines="inset">
                                                <IonText color={"danger"}>{item.error}</IonText>
                                            </IonItem>}
                                            {item.description && <IonItem lines="inset">
                                                <IonText>{item.description}</IonText>
                                            </IonItem>}
                                            <IonItem button detail detailIcon={(item.notification & 1) === 1 ? notifications : informationCircle}
                                                onClick={this.handleClickDetails(item)}>
                                                <IonText style={{ textAlign: 'center', fontSize: '14px' }}
                                                    color={"medium"}>{this.props.store?.moment(item.updatedAt).format('DD/MM/YYYY HH:mm:ss')}</IonText>
                                            </IonItem>
                                        </IonCard>
                                    </IonCol>
                                })}
                            </IonRow>
                        </IonGrid>

                        {/*{!!value.graph && this.renderInputGroupGraph(value)}*/}
                        {/**/}
                        {(!value.inputs || value.inputs.length === 0) &&
                            <IonItem><IonLabel>{lang?.l.main.status_no_data()}</IonLabel></IonItem>}
                        {(value.inputs && inputs && inputs.length === 0) &&
                            <IonItem><IonLabel>{lang?.l.main.status_inputs_hidden()}</IonLabel></IonItem>}

                    </div>
                })}
            </IonContent>

            <IonModal isOpen={this.state.download! > 0} onDidDismiss={() => this.setState({ download: 0 })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{lang?.l.main.status_download_registers()}</IonTitle>
                        <IonButtons slot={"end"}>
                            <IonButton onClick={() => this.setState({ download: 0 })}>Cerrar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList>
                        <IonItem>
                            <IonLabel position="stacked">{lang?.l.main.start_date()}</IonLabel>
                            <IonDatetime presentation="date" lang="es-ES" firstDayOfWeek={1} ref={this.dateStart} />
                        </IonItem>
                        <IonItem>
                            <IonLabel position="stacked">{lang?.l.main.end_date()}</IonLabel>
                            <IonDatetime presentation="date" lang="es-ES" firstDayOfWeek={1} ref={this.dateEnd} />
                        </IonItem>

                        <IonItem button detail={false} color={"primary"}
                            onClick={this.handleDownload(this.state.download!)}>
                            <IonSpinner hidden={!this.state.loading} slot={"start"} />
                            <IonIcon icon={saveOutline} slot={"start"} />
                            <IonText slot={"start"}>{lang?.l.main.download()}</IonText>
                        </IonItem>
                    </IonList>
                </IonContent>
            </IonModal>
            <IonModal isOpen={this.state.modalFilter} onDidDismiss={() => this.setState({ modalFilter: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Filtrar Grupos de Entradas</IonTitle>

                        <IonButtons slot={"end"}>
                            <IonButton onClick={() => this.setState({ modalFilter: false })}>Cerrar</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    <IonList>
                        <IonItem>
                            <IonButton slot="end" onClick={this.handleClickAll}>Todo</IonButton>
                            <IonButton slot="end" onClick={this.handleClickNone}>Ninguno</IonButton>
                        </IonItem>
                        {inputGroups && <>
                            {inputGroups.map((v1, k1) => {
                                return <IonItem>
                                    <IonCheckbox checked={this.state.hideInputGroups.indexOf(v1.id) === -1} onIonChange={this.handleChangeFilter(v1)}>{v1.name}</IonCheckbox>
                                </IonItem>
                            })}
                        </>}
                    </IonList>
                </IonContent>
            </IonModal>
            <IonLoading
                isOpen={this.state.loading!}
                message={lang?.l.main.loading()}
            />
            <IonToast duration={5000} color={"danger"} position="top" isOpen={this.state.errorToastOpen} message={this.state.errorToastMessage} onDidDismiss={() => this.setState({errorToastMessage: "", errorToastOpen: false})} />

        </IonPage>

    }


    private cache: any = {};
    private checkChange(value: DeviceInputs) {
        let key = value.id;
        if (!this.cache[key]) {
            this.cache[key] = { time: new Date(2000), data: value.value };
            return false;
        }
        if (this.cache[key].data !== value.value) {

            this.cache[key] = { time: new Date(), data: value.value };
            return true;
        }
        if (this.checkCacheTime(this.cache[key].time)) {
            return true;
        }
        return false;
    }
    private checkCacheTime(time: Date) {
        return (new Date().getTime() - time.getTime() < 500);
    }

}
