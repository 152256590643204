export type Callback = (data:any) => void;
export default class EventsPool {
    private listeners:{[key: string]: Callback[]} = {};

    public on(name: string, fn:Callback) {
        if(!this.listeners[name]) {
            this.listeners[name] = [];
        }
        this.listeners[name].push(fn);
    }

    public off(name: string, fn?:Callback) {
        if(!fn) {
            this.listeners[name] = [];
        } else {
            let i = this.listeners[name].indexOf(fn);
            if(i >= 0) this.listeners[name].splice(i, 1);
        }
    }

    public emit(name: string, data:any) {
        if(this.listeners[name]) {
            this.listeners[name].map(value => value.apply(this, data));
        }
    }
}