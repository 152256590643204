import {API, APIBase} from "./API";
import {Store} from "./Store";
import Cache, {TimeType} from "./Cache";

export default class APICache {
    private api: API;
    private store: Store;
    private cache: Cache;

    constructor(store: Store) {
        this.store = store;
        this.api = store.api;
        this.cache = new Cache("apiCache");
    }

    clear() {
        this.cache.clear();
        return this;
    }

    getDevice(id: number) {
        return this.cached('devices', id, () => this.api.deviceGet(id));
    }

    private cached(masterKey: string, key: number, fn:() => Promise<APIBase<any>>):Promise<APIBase<any>> {
        return new Promise((resolve, reject) => {

            let k = masterKey + "." + key;
            let data = this.cache.get(k);
            if (data) {
                resolve({
                    success: true,
                    data: data,
                    statusCode: 200,
                    cache: true,
                    message: 'Data retrieved from cache'
                });
                return;
            }
            fn().then((data) => {
                if(data.success) {
                    this.cache.add(k, data.data, {type: TimeType.MINUTES, value: 10});
                }
                resolve(data);
            });
        });
    }
}
