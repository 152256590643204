import React from "react";
import {IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonModal} from "@ionic/react";
// import { QrReader } from "react-qr-reader";

export type QRScanProps = {
    open: boolean,
    onClose: (result:string|boolean) => void;
}

export default class QRScan extends React.Component<QRScanProps, any> {

    // private cameraDiv: RefObject<any> = React.createRef();
    handleScan = (data:string|null) => {
        if (data) {
            this.handleClose(data);
        }
    }
    handleError = (err:any) => {
        alert("Error: " + err + ". No se ha podido iniciar la cámara!");
        this.handleClose("");
    }

    handleCancel = (ev:any) => {
        this.handleClose("");
    }

    handleClose = (result:string) => {
        this.props.onClose(result);
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <IonModal isOpen={this.props.open ? true: false} canDismiss={true} onDidDismiss={() => this.props.onClose(false)}>

            <IonCard>
                <IonCardHeader>
                    <IonCardSubtitle>Enfoque la pegatina de la parte trasera del modem en el recuadro rojo</IonCardSubtitle>
                    <IonCardTitle>Escanear QR</IonCardTitle>
                </IonCardHeader>

                <IonCardContent>
                    {/* {this.props.open && <QrReader
                        constraints={{facingMode: 'user'}}
                        // delay={300}
                        // onError={this.handleError}
                        // onScan={this.handleScan}

                    />} */}
                    <IonButton onClick={this.handleCancel}>Cancelar</IonButton>
                </IonCardContent>
            </IonCard>

        </IonModal>;
    }
}
