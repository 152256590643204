import Registers from "../Registers";
import {MemoryAddress} from "./MemoryMapper";

export type PackedType = {
    type: number,
    data: string,
}
export default class NetConfig extends Registers {

    public CommProtocol : number = 0;
    public Protocol : number = 0;
    public ConnWays : number = 0;
    public ResendTime : number = 0;
    public RegeditMode : number = 0;
    public ReconnTime : number = 0;
    public HeartTime : number = 0;

    public GprsIp1:string = "";
    public GprsPort1:number = 0;
    public GprsIp2:string = "";
    public GprsPort2:number = 0;

    public GprsApn:string = "";
    public GprsApnUser:string = "";
    public GprsPwd:string = "";
    public LoginMessage:PackedType = {data: '', type: 0};
    public LoginACKMessage:PackedType = {data: '', type: 0};
    public LogoutMessage:PackedType = {data: '', type: 0};
    public HeartbeatMessage:PackedType = {data: '', type: 0};
    public HeartbeatACKMessage:PackedType = {data: '', type: 0};

    // constructor(rtu: ModbusRTUClient) {
    //     super(rtu);
    //     // this.defineCustomAddress(0x3D800, {
    //     //     CommProtocol: Model.byte(), // 0 = Disable, 1 = Modbus RTU, 2 = IoT Modbus, 3 = Modbus TCP
    //     //     Protocol: Model.byte(), // 0 = TCP, 1 = UDP
    //     //     ConnWays: Model.byte(),
    //     //     ResendTime: Model.byte(),
    //     //     RegeditMode: Model.byte(),
    //     //     reserve: Model.byte(),
    //     //     ReconnTime: Model.value(),
    //     //     HeartTime: Model.value(),
    //     // });
    //     //
    //     // this.defineCustomAddress(0x3D840, {
    //     //     GprsIp1: Model.byteString(60),
    //     //     GprsPort1: Model.value(),
    //     //     GprsIp2: Model.byteString(60),
    //     //     GprsPort2: Model.value(),
    //     // })
    //     //
    //     // this.defineCustomAddress(0x3D8C0, {
    //     //     GprsApn: Model.byteString(60),
    //     // })
    //     // this.defineCustomAddress(0x3D900, {
    //     //     GprsApnUser: Model.byteString(60),
    //     // })
    //     // this.defineCustomAddress(0x3D940, {
    //     //     GprsPwd: Model.byteString(60),
    //     // })
    //     // this.defineCustomAddress(0x3E000, {
    //     //     LoginMessage: Model.packedString(60),
    //     // })
    //     // this.defineCustomAddress(0x3E040, {
    //     //     LoginACKMessage: Model.packedString(60),
    //     // })
    //     // this.defineCustomAddress(0x3E080, {
    //     //     LogoutMessage: Model.packedString(60),
    //     // })
    //     // this.defineCustomAddress(0x3E0C0, {
    //     //     HeartbeatMessage: Model.packedString(60),
    //     // })
    //     // this.defineCustomAddress(0x3E100, {
    //     //     HeartbeatACKMessage: Model.packedString(60),
    //     // })
    // }

    async Read(): Promise<any> {
        let buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSINFO_ADDRESS), 10);
        if(buffer) {
            this.CommProtocol = buffer.readUInt8(0);
            this.Protocol = buffer.readUInt8(1);
            this.ConnWays = buffer.readUInt8(2);
            this.ResendTime = buffer.readUInt8(3);
            this.RegeditMode = buffer.readUInt8(4);
            this.ReconnTime = buffer.readUInt16LE(6);
            this.HeartTime = buffer.readUInt16LE(8);

            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSIP_ADDRESS), 128);
            if(!buffer) return false;
            this.GprsIp1 = "";
            for (let i = 0; i < 60; i++) {
                if(buffer.readUInt8(i) !== 0) {
                    this.GprsIp1 += String.fromCharCode(buffer.readUInt8(i));
                }
            }
            this.GprsPort1 = buffer.readUInt16LE(60);
            this.GprsIp2 = "";
            for (let i = 64; i < 124; i++) {
                if(buffer.readUInt8(i) !== 0) {
                    this.GprsIp2 += String.fromCharCode(buffer.readUInt8(i));
                }
            }
            this.GprsPort2 = buffer.readUInt16LE(124);

            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSAPN_ADDRESS), 60);
            if(!buffer) return false;

            this.GprsApn = "";
            for (let i = 0; i < 60; i++) {
                if(buffer.readUInt8(i) !== 0) {
                    this.GprsApn += String.fromCharCode(buffer.readUInt8(i));
                }
            }
            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSUSER_ADDRESS), 60);
            if(!buffer) return false;

            this.GprsApnUser = "";
            for (let i = 0; i < 60; i++) {
                if(buffer.readUInt8(i) !== 0) {
                    this.GprsApnUser += String.fromCharCode(buffer.readUInt8(i));
                }
            }
            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSPWD_ADDRESS), 60);
            if(!buffer) return false;

            this.GprsPwd = "";
            for (let i = 0; i < 60; i++) {
                if(buffer.readUInt8(i) !== 0) {
                    this.GprsPwd += String.fromCharCode(buffer.readUInt8(i));
                }
            }

            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSDATAPACK_ADDRESS), 64);
            if(!buffer) return false;

            this.LoginMessage = this.readPackedType(buffer);

            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKREGRESP_ADDRESS), 64);
            if(!buffer) return false;

            this.LoginACKMessage = this.readPackedType(buffer);

            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKDISCONN_ADDRESS), 64);
            if(!buffer) return false;

            this.LogoutMessage = this.readPackedType(buffer);

            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKHEART_ADDRESS), 64);
            if(!buffer) return false;

            this.HeartbeatMessage = this.readPackedType(buffer);

            buffer = await this.readCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKHEARTRESP_ADDRESS), 64);
            if(!buffer) return false;

            this.HeartbeatACKMessage = this.readPackedType(buffer);


            return true;
        }
        return false;
    }

    async Write(): Promise<any> {
        let buffer: Buffer = Buffer.alloc(10);
        buffer.writeUInt8(this.CommProtocol, 0);
        buffer.writeUInt8(this.Protocol, 1);
        buffer.writeUInt8(this.ConnWays, 2);
        buffer.writeUInt8(this.ResendTime, 3);
        buffer.writeUInt8(this.RegeditMode, 4);
        buffer.writeUInt16LE(this.ReconnTime, 6);
        buffer.writeUInt16LE(this.HeartTime, 8);
        this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSINFO_ADDRESS), buffer)

        buffer = Buffer.alloc(128);
        for (let i = 0; i < this.GprsIp1.length; i++) {
            buffer.writeUInt8(this.GprsIp1.charCodeAt(i), i);
        }
        buffer.writeUInt16LE(this.GprsPort1, 60);

        for (let i = 0; i < this.GprsIp2.length; i++) {
            buffer.writeUInt8(this.GprsIp2.charCodeAt(i), i + 64);
        }
        buffer.writeUInt16LE(this.GprsPort2, 124);
        this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSIP_ADDRESS), buffer);

        buffer = Buffer.alloc(60);
        for (let i = 0; i < this.GprsApn.length; i++) {
            buffer.writeUInt8(this.GprsApn.charCodeAt(i), i);
        }
        this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSAPN_ADDRESS), buffer);
        buffer = Buffer.alloc(60);
        for (let i = 0; i < this.GprsApnUser.length; i++) {
            buffer.writeUInt8(this.GprsApnUser.charCodeAt(i), i);
        }
        this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSUSER_ADDRESS), buffer);
        buffer = Buffer.alloc(60);
        for (let i = 0; i < this.GprsPwd.length; i++) {
            buffer.writeUInt8(this.GprsPwd.charCodeAt(i), i);
        }
        this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSPWD_ADDRESS), buffer);

        await this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSDATAPACK_ADDRESS), this.writePackedType(this.LoginMessage));
        await this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKREGRESP_ADDRESS), this.writePackedType(this.LoginACKMessage));
        await this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKDISCONN_ADDRESS), this.writePackedType(this.LogoutMessage));
        await this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKHEART_ADDRESS), this.writePackedType(this.HeartbeatMessage));
        await this.writeCustomRegisters(this.mapper.get(MemoryAddress.GPRSPACKHEARTRESP_ADDRESS), this.writePackedType(this.HeartbeatACKMessage));

        await this.writeMultipleRegisters(79, [1]);
    }

    private readPackedType(buffer:Buffer) {
        let result : PackedType = {data: '', type: 0};
        result.type = buffer.readInt8(0);
        let length = buffer.readInt8(1);
        for (let i = 0; i < length; i++) {
            /// if (type != 1) {
            ///   str += ((char) Convert.ToByte(Modbus.GetByteValue(data21, StartReg, 1), 16)).ToString()
            /// } else {
            ///     str += Convert.ToByte(Modbus.GetByteValue(data21, StartReg, 1), 16).ToString("X2")
            ///
            //             str = (this._GprsPackReg.type != (byte) 1) ? ( ) : (  );
            if(result.type !== 1) {
                result.data += String.fromCharCode(buffer.readInt8(i + 2));
            } else {
                let f = buffer.readInt8(i + 2).toString(16);
                if(f.length === 1) {
                    f = "0" + f;
                } else if(f.length === 0) {
                    f = "00";
                }
                result.data += f;
            }

        }
        return result;  
    }

    private writePackedType(packed: PackedType) {

        let buffer = Buffer.alloc(64);
        if(packed.type !== 1) {
            // string
            buffer.writeUInt8(0, 0);
            buffer.writeUInt8(packed.data.length, 1);
            for (let i = 0; i < packed.data.length; i++) {
                buffer.writeUInt8(packed.data.charCodeAt(i), i + 2);
            }
        } else {
            // hex
            buffer.writeUInt8(1, 0);
            buffer.writeUInt8(packed.data.length / 2, 1);
            for (let i = 0; i < packed.data.length / 2; i++) {
                buffer.writeUInt8(parseInt(packed.data.substring( i * 2, i * 2 + 2), 16), i + 2);
            }
        }
        return buffer;
    }

}
