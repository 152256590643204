import React from "react";
import {
    IonBackButton,
    IonBadge,
    IonButton,
    IonButtons,
    IonContent,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonLoading,
    IonModal,
    IonPage,
    IonSelect,
    IonSelectOption,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { inject } from "mobx-react";
import { Store } from "../../service/Store";
import { Device } from "../../service/API";
import { RouteComponentProps } from "react-router-dom";

export interface DeviceSettingsHardwareInterlockProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
}
export type DeviceSettingsHardwareInterlockState = {
    loading?: boolean,
    events: any[],
    device: Device,
    error: boolean | string,
    edit: boolean,
    index: number,
}

@inject("store", "lang")
export default class DeviceSettingsHardwareInterlock extends React.Component<DeviceSettingsHardwareInterlockProps, DeviceSettingsHardwareInterlockState> {
    EVENTS_S275: { [key: string]: string } = {
        0: "(Desactivar)",
        1: "Conectado",
        2: "Desarmado",

        4: "DI1 Alama digital",
        5: "DI2 Alama digital",
        6: "DI3 Alama digital",
        7: "DI4 Alama digital",
        8: "DI5 Alama digital",
        9: "DI6 Alama digital",
        10: "DI7 Alama digital",
        11: "DI8 Alama digital",

        13: "DI1 Recuperado",
        14: "DI2 Recuperado",
        15: "DI3 Recuperado",
        16: "DI4 Recuperado",
        17: "DI5 Recuperado",
        18: "DI6 Recuperado",
        19: "DI7 Recuperado",
        20: "DI8 Recuperado",


        21: "DI1 Alarma de pulsos intervalo",
        22: "DI1 Alarma de pulsos total",

        // 24: "Alarma Entrada analógica 1",
        // 25: "Alarma Entrada analógica 2",
        // 26: "Alarma Entrada analógica 3",
        // 27: "Alarma Entrada analógica 4",
        // 28: "Alarma Entrada analógica 5",
        // 29: "Alarma Entrada analógica 6",


        31: "AI1 Recuperado",
        32: "AI2 Recuperado",
        33: "AI3 Recuperado",
        34: "AI4 Recuperado",
        35: "AI5 Recuperado",
        36: "AI6 Recuperado",

        // 37: "Alarma temperatura",
        // 38: "Alarma humedad",
        //
        39: "Recuperar temperatura",
        // 40: "Recuperar humedad",

        // 41: "Al cerrar todos los relés",

        // 42: "Relé 1 ON",
        // 43: "Relé 2 ON",
        // 44: "Relé 3 ON",
        // 45: "Relé 4 ON",
        //
        // 47  : "Relé 1 Abierto",
        // 48  : "Relé 2 Abierto",
        // 49  : "Relé 3 Abierto",
        // 50  : "Relé 4 Abierto",
        //
        //
        // 51  : "Alarma RS232",
        // 52  : "Alarma RS485",
        // 53  : "Señal GSM Baja",
        // 54  : "Fallo de conexión GPRS",

        60: "AI1 Limite alto",
        61: "AI1 Limite Bajo",
        62: "AI2 Limite alto",
        63: "AI2 Limite Bajo",
        64: "AI3 Limite alto",
        65: "AI3 Limite Bajo",
        66: "AI4 Limite alto",
        67: "AI4 Limite Bajo",
        68: "AI5 Limite alto",
        69: "AI5 Limite Bajo",
        70: "AI6 Limite alto",
        71: "AI6 Limite Bajo",

        72: "Temperatura alta",
        73: "Temperatura baja",

        74: "Humedad alta",
        75: "Humedad baja",

        76: "DI2 Alarma de pulsos intervalo",
        77: "DI3 Alarma de pulsos intervalo",
        78: "DI4 Alarma de pulsos intervalo",

        83: "DI2 Alarma de pulsos total",
        84: "DI3 Alarma de pulsos total",
        85: "DI4 Alarma de pulsos total",
    }
    
    EVENTS: { [key: string]: string } = this.EVENTS_S275;
    ACTIONS_S275: { [key: string]: string } = {

        0: "(Nada)",//
        1: "Reiniciar", //
        2: "Cerrar todos los Relés", //
        3: "Abrir todos los Relés", //
        4: "Abrir Relé 1", //
        5: "Cerrar Relé 1", //
        6: "Abrir Relé 2", //
        7: "Cerrar Relé 2", //
        8: "Abrir Relé 3",//
        9: "Cerrar Relé 3", //
        10: "Abrir Relé 4", //
        11: "Cerrar Relé 4", //
        12: "Armar",
        13: "Desarmar",
        14: "GPRS Online",
        15: "Abrir puerta (ON/OFF)",
        16: "Sirena",
        255: "Acción Extendida",
    }
    ACTIONS_S271: { [key: string]: string } = {

        0: "(Nada)",
        1: "Reiniciar",
        2: "Cerrar todos los Relés",
        3: "Abrir todos los Relés",
        4: "Relé 1 OFF",
        5: "Relé 1 ON",
        6: "Relé 2 OFF",
        7: "Relé 2 ON",
        8: "Relé 3 OFF",
        9: "Relé 3 ON",
        10: "Relé 4 OFF",
        11: "Relé 4 ON",
        14: "GPRS Online",
        15: "Cambiar ON/OFF",
    }
    ACTIONS: { [key: string]: string } = this.ACTIONS_S275;

    componentDidMount(): void {
        this.loadDevice();
    }
    componentDidUpdate(prevProps: Readonly<DeviceSettingsHardwareInterlockProps>, prevState: Readonly<DeviceSettingsHardwareInterlockState>, snapshot?: any): void {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.loadDevice();
        }
    }

    loadDevice() {
        this.setState({ loading: true });
        let id = this.props.match?.params.id!;

        this.props.store?.api.deviceGet(Number(id)).then(value => {
            if (value.success) {
                // if(value.data.model.name === "BM272") {
                //     this.EVENTS = this.EVENTS_S271;
                //     this.ACTIONS = this.ACTIONS_S271;
                // } else {
                // }

                this.EVENTS = this.EVENTS_S275;
                this.ACTIONS = this.ACTIONS_S275;
                this.setState({ device: value.data })
            }
            this.props.store?.api.getHardwareEvents(Number(id)).then(value => {
                if (value.success) {
                    this.setState({ events: value.data.events })
                } else {
                    this.setState({ error: 'El dispositivo no está conectado' });
                }
            }).finally(() => this.setState({ loading: false }));
        });
    }

    handleSave = (ev: any) => {
        this.setState({ loading: true, error: false });
        let id = this.props.match?.params.id!;
        const { history } = this.props;

        this.props.store?.api.setHardwareEvents(Number(id), this.state.events).then(value => {
            if (value.success) {
                if (value.data.result) {
                    history.goBack();
                } else {
                    this.setState({ error: 'No se puede configurar el dispositivo' });
                }
            }
        });

    };
    handleClear = (ev: any) => {
        for (let i = 0; i < this.state.events.length; i++) {
            let item = this.state.events[i];
            item.event = 0;
            item.action = 0;
        }
        this.setState({});

    }

    handleChange = (item: any, name: string) => (ev: any) => {
        item[name] = ev.detail.value;
        this.setState({});
    }

    handleCheckChange = (item: any, name: string) => (ev: any) => {
        item[name] = ev.currentTarget.checked;
    }

    renderItem(value: any, index: number) {
        if (!this.state.device) return "";

        return <>
            <IonItem detail button onClick={() => this.setState({ edit: true, index: index })}>
                <IonLabel>Evento {index + 1}</IonLabel>
                <IonBadge color={"danger"} slot={"end"} hidden={value.event !== 0}>Desactivado</IonBadge>
                <div hidden={value.event === 0 || value.event === 255} slot={"end"}>
                    Si <IonBadge color={"warning"}>{this.EVENTS[value.event] ? this.EVENTS[value.event] : value.event}</IonBadge>&nbsp;
                    entonces <IonBadge color={"warning"}>{this.ACTIONS[value.action] ? this.ACTIONS[value.action] : value.action}</IonBadge>
                </div>
            </IonItem>
        </>
    }
    renderExtended(value: any) {
        return <>
            <IonItem>
                <IonSelect label="Acción extendida" value={value.action2} interface={"action-sheet"} onIonChange={this.handleChange(value, 'action2')}>
                    <IonSelectOption value={1}>Abrir Relé (OFF)</IonSelectOption>
                    <IonSelectOption value={2}>Cerrar Relé (ON)</IonSelectOption>
                    <IonSelectOption value={10}>Reiniciar pulsos 1</IonSelectOption>
                    <IonSelectOption value={11}>Reiniciar pulsos 2</IonSelectOption>
                    <IonSelectOption value={12}>Reiniciar pulsos 3</IonSelectOption>
                    <IonSelectOption value={13}>Reiniciar pulsos 4</IonSelectOption>
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonInput label="Valor" value={value.argument2} type="number" onIonInput={this.handleChange(value, 'argument2')}></IonInput>
            </IonItem>

        </>
    }

    renderEditor() {
        if (!this.state.device) return "";
        if (this.state.index === undefined) return "";
        let value = this.state.events[this.state.index];

        return <>
            <IonItem>
                <IonSelect label="Evento" value={value.event} interface={"action-sheet"} onIonChange={this.handleChange(value, 'event')}>
                    {Object.keys(this.EVENTS).map((value1: string) => <IonSelectOption value={parseInt(value1)}>{this.EVENTS[value1]}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
            <IonItem>
                <IonSelect label="Acción" value={value.action} interface={"action-sheet"} onIonChange={this.handleChange(value, 'action')}>
                    {Object.keys(this.ACTIONS).map((value1: string) => <IonSelectOption value={parseInt(value1)}>{this.ACTIONS[value1]}</IonSelectOption>)}
                </IonSelect>
            </IonItem>
            {value.action === 255 && this.renderExtended(value)}
        </>
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        let id = this.props.match?.params.id!;

        if (!this.state) return "";
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot="start">
                        <IonBackButton defaultHref={"/device/" + id + "/settings"} />
                    </IonButtons>
                    <IonTitle>Conmutador</IonTitle>

                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleClear}>Limpiar</IonButton>
                    </IonButtons>
                    <IonButtons slot="primary">
                        <IonButton onClick={this.handleSave}>Guardar</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent>
                <IonList>
                    {this.state.error && <IonItem color={"danger"}>
                        <IonLabel>{this.state.error}</IonLabel>
                    </IonItem>}

                    {this.state.events && this.state.events.map((value, index) => this.renderItem(value, index))}

                </IonList>
                <IonLoading
                    isOpen={this.state.loading!}
                    message={'Cargando...'}
                    duration={5000}
                />
            </IonContent>

            <IonModal isOpen={this.state.edit! ? true : false} onDidDismiss={() => this.setState({ edit: false })}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Editar Eventos</IonTitle>
                        <IonButtons slot={"primary"}>
                            <IonButton onClick={() => this.setState({ edit: false })}>OK</IonButton>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>

                <IonContent>
                    {this.renderEditor()}
                </IonContent>

            </IonModal>
        </IonPage>
    }

}
