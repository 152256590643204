export default abstract class ModbusResponseBody {

    private _fc:number;
    // static fromBuffer (buffer:Buffer) {
    //     try {
    //         const fc = buffer.readUInt8(0)
    //
    //         if (fc === 0x01) {
    //             return ReadCoilsResponseBody.fromBuffer(buffer)
    //         }
    //
    //         return null
    //     } catch (e) {
    //         return null
    //     }
    //     return null;
    // }

    /** Create new ModbusResponseBody
     * @param {Number} fc Function Code
     */
    constructor (fc:number) {
        this._fc = fc
    }

    /** Number of bytes for the payload.  */
    get byteCount () : number {
        throw new Error('Not implemented yet.')
    }

    /** Create payload to be send over a socket.
     * @returns {Buffer}
     */
    createPayload () : Buffer {
        throw new Error('Not implemented yet.')
    }

    get name () : string {
        throw new Error('No implemented yet.')
    }


    get fc(): number {
        return this._fc;
    }

    set fc(value: number) {
        this._fc = value;
    }
}
