import Registers from "../Registers";
import {MemoryAddress} from "./MemoryMapper";

export default class GPRS extends Registers {

    public GPRS_Enable : number = 0;

    public ServerIp1 : string = "";
    public ServerPort1 : number = 0;
    public ServerUdp1 : number = 0;
    public Heartbeat_Times1 : number = 0;
    public Reboot_Times1 : number = 0;
    public ServerIp2 : string = "";
    public ServerPort2 : number = 0;
    public ServerUdp2 : number = 0;


    public APN_Name : string = "";
    public APN_User : string = "";
    public APN_Pass : string = "";

    public APN_SET : number = 0;
    public DomainEnable : number = 0;
    public Domain : string = "";


    // constructor(rtu: ModbusRTUClient) {
    //     super(rtu);
    // }
    // Sacados de S272
    async Read(): Promise<any> {
        let result = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.GPRS_ENABLE), 16);
        if(!result) return false;
        this.GPRS_Enable = result[0];

        this.ServerIp1 = "";
        for (let i = 0; i < 4; i++) {
            this.ServerIp1 += (this.ServerIp1 === ""?"":".") + result[i + 1];
        }
        this.ServerPort1 = result[5];
        this.ServerUdp1 = result[6];
        this.Heartbeat_Times1 = result[7];
        this.Reboot_Times1 = result[8];
        this.ServerIp2 = "";
        for (let i = 0; i < 4; i++) {
            this.ServerIp2 += (this.ServerIp2 === ""?"":".") + result[i + 9];
        }
        this.ServerPort2 = result[13];
        this.ServerUdp2 = result[14];

        result = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.GPRS_APN_CONNECT), 82);
        if(!result) return false;
        this.APN_Name = Registers.toString16Array(result.slice(0, 40));
        this.APN_User = Registers.toString16Array(result.slice(40, 40 + 21));
        this.APN_Pass = Registers.toString16Array(result.slice(61, 82));
        result = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.GPRS_APN_SET), 1);
        if(!result) return false;
        this.APN_SET = result[0];

        result = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.GPRS_DOMAIN_ENABLE), 32);
        if(!result) return false;
        this.DomainEnable = result[0];
        this.Domain = Registers.toString16Array(result.slice(1, 31));
        return true;
    }

    async Write(): Promise<any> {
        let data : number[] = [];
        data.push(this.GPRS_Enable);
        Registers.IPStringToArray(this.ServerIp1, data);
        data.push(this.ServerPort1);
        data.push(this.ServerUdp1);
        data.push(this.Reboot_Times1);
        data.push(this.Heartbeat_Times1);
        Registers.IPStringToArray(this.ServerIp2, data);
        data.push(this.ServerPort2);
        data.push(this.ServerUdp2);
        data.push(0);
        data.push(0);
        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.GPRS_ENABLE), data);

        data = [];
        Registers.toArray16String(this.APN_Name, 40, data);
        Registers.toArray16String(this.APN_User, 21, data);
        Registers.toArray16String(this.APN_Pass, 21, data);

        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.GPRS_APN_CONNECT), data);
        data = [];
        data.push(this.APN_SET);
        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.GPRS_APN_SET), data);
        data = [];
        data.push(this.DomainEnable);
        Registers.toArray16String(this.Domain, 30, data);
        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.GPRS_DOMAIN_ENABLE), data);
        return this.writeMultipleRegisters(79, [1]);

    }

}
