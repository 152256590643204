import {
    IonButton,
    IonContent,
    IonHeader,
    IonPage,
    IonText,
    IonTitle,
    IonToolbar
} from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { SimpleDevice } from "../service/API";
import Utils from "../service/Utils";
import Rect from "../components/svg/objects/Rect";
import { mxGraph } from 'mxgraph';
import mx from '../service/mxGraph';      

export interface HMIDesignerProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
}
export interface HMIDesignerState {
    devices?: SimpleDevice[],
    objects: any[],
}
@inject("store", "lang")
class HMIDesigner extends React.Component<HMIDesignerProps, HMIDesignerState> {
    model?: mxGraph;
    graph?: mxGraph;
    state: HMIDesignerState = {
        objects: [],
    };

    componentDidMount(): void {
        this.props.store?.api.getDevices().then(value => {
            this.setState({ devices: value.data.devices })

        });
        let objects: any = [];
        this.setState({ objects });
        this.loadData();

    }

    loadData() {
        Utils.readAsset("hmi/template1.json").then(response => {
            response.json().then(json => {
                this.setState({ objects: json });
            })
        });
    }
    handleUpdate = (objects: any) => {
        this.setState({ objects });
    }

    handleSave = (ev: any) => {
        Utils.log(JSON.stringify(this.state.objects));
    }

    handleAdd = (type: 'rectangle' | 'text' | 'circle' | 'image') => (ev: any) => {
        let types = {
            'rectangle': Rect.meta.initial,
            'text': { type: "text", x: 10, y: 20, text: "Hello!", fill: "red", stroke: 'black' },
            'circle': { type: "circle", x: 10, y: 20, width: 30, height: 40, fill: "red", stroke: 'black' },
            'image': { type: "image", x: 10, y: 20, width: 30, height: 40, fill: "red", stroke: 'black' },
        }

        // this.state.objects.push(types[type]);
        // this.setState({objects: this.state.objects});
        this.setState({ objects: [...this.state.objects, types[type]] })
    }

    testLoadData() {
    }

    handleSetReference = (el: HTMLDivElement) => {

        console.log(mx.mxClient.VERSION);
        this.graph = new mx.mxGraph(el);
        this.loadStyle().then(() => {
            this.loadPanel();
        });
        // 
    }

    loadElement(url: string, into: any) {
        return new Promise(resolve => {
            Utils.readAsset(url).then(response => {
                response.text().then(xmlString => {
                    resolve(this.loadXml(xmlString, into));
                })
            });
        })

    }
    loadStyle() {
        return this.loadElement("hmi/default.xml", this.graph!.getStylesheet());
    }

    loadPanel() {
        return this.loadElement("hmi/mx.xml", this.graph!.getModel());
    }

    loadXml = (xmlString: string, into?: any) => {
        try {
            var doc = mx.mxUtils.parseXml(xmlString);
            var codec = new mx.mxCodec(doc);
            return codec.decode(doc.documentElement, into);
        }
        catch (ex) {
            console.error(ex);
        }
    }

    // render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {


    //     return <IonPage>

    //         <IonHeader>
    //             <IonToolbar color={"primary"}>
    //                 <IonTitle>Editor HMI</IonTitle>
    //                 <IonButtons slot="primary">
    //                     <IonButton  onClick={this.handleSave}>
    //                         <IonIcon slot="icon-only" icon={save}  />
    //                     </IonButton>
    //                 </IonButtons>
    //             </IonToolbar>
    //         </IonHeader>
    //         <IonContent style={{height: '100%'}}>
    //             <SVGDesigner objects={this.state.objects} height={'100%'} width={'100%'} onUpdate={this.handleUpdate} />

    //             <IonFab vertical="bottom" horizontal="end" slot="fixed">
    //                 <IonFabButton>
    //                     <IonIcon icon={add} />
    //                 </IonFabButton>
    //                 <IonFabList side="top">
    //                     <IonFabButton onClick={this.handleAdd('image')} ><IonIcon icon={text} /> IM</IonFabButton>
    //                     <IonFabButton onClick={this.handleAdd('rectangle')} ><IonIcon icon={square} /></IonFabButton>
    //                     <IonFabButton onClick={this.handleAdd('circle')} ><IonIcon icon={ellipse}/></IonFabButton>
    //                     <IonFabButton onClick={this.handleAdd('text')} ><IonIcon icon={text} /></IonFabButton>
    //                 </IonFabList>
    //             </IonFab>
    //         </IonContent>
    //     </IonPage>
    // }

    handleOpenHMI = () => {
        window.open("https://editor.cloudrtu.com/?token=" + this.props.store?.api.token);
    }

    renderTest() {
        return <div ref={this.handleSetReference}></div>

    }
    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {


        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonTitle>Editor HMI</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent style={{ height: '100%' }} className="ion-padding">
                <IonText>
                    <h1 id="hmi-tags">HMI Tags</h1>
                    
                    <IonButton onClick={this.handleOpenHMI}>Abrir editor HMI</IonButton>
                    <h2 id="inputs">Inputs</h2>
                    <p>Para referir entradas se puede utilizar alguna de las siguientes formas:</p>
                    <ul>
                        <li><code>[InputID]</code> ID de la entrada</li>
                        <li><code>input-[DeviceId]-[InputGroup]-[InputIndex]</code> Formato largo</li>
                    </ul>
                    <p>Donde:</p>
                    <ul>
                        <li><code>DeviceID</code>: Es el ID del dispositivo</li>
                        <li><code>InputGroup</code>: Es índice del grupo, comienza en 0.</li>
                        <li><code>InputIndex</code>: Es índice de la entrada en el grupo.</li>
                    </ul>
                    <p>Ejemplos:</p>
                    <ul>
                        <li><code>160</code></li>
                        <li><code>input-8-100-1</code></li>
                        <li><code>input-8-0-0</code></li>
                    </ul>
                    <ul>
                        <li>La parte &quot;input-&quot; es opcional</li>
                    </ul>
                    <h2 id="outputs">Outputs</h2>
                    <p>Para referirse a salidas se utiliza la siguiente forma:</p>
                    <p><code>[DeviceID]:[OutputIndex]</code></p>
                    <p>Donde:</p>
                    <ul>
                        <li><code>DeviceID</code>: Es el ID del dispositivo</li>
                        <li><code>OutputIndex</code> Es el índice de salida. Comienza en el 0</li>
                    </ul>
                    <h3 id="propiedades-svg">Propiedades SVG</h3>
                    <p>Estas propiedades se establecen en el editor svg. Seleccionamos la forma a la cual queremos que cambie
                        con la información de CloudRTU y pusamos &quot;Edit Data&quot; (Ctrl+M). En ese diálogo introduciremos las propiedades siguientes.</p>
                    <h3 id="establecer-texto">Establecer Texto</h3>
                    <p><strong>Syntax</strong></p>
                    <pre><code> input_text = <span className="hljs-string">[Input]</span>
                    </code></pre><p> Establecera la cadena de texto a los objetos con el valor de la entrada.</p>
                    <p><strong>Properties</strong></p>
                    <ul>
                        <li><code>text_prefix=String</code> : Establecer prefijo al texto.</li>
                        <li><code>text_postfix=String</code> : Establecer sufijo al texto.</li>
                    </ul>
                    <h3 id="establecer-color">Establecer Color</h3>
                    <p><strong>Syntax</strong></p>
                    <pre><code> input_color = <span className="hljs-string">[Input]</span>
                    </code></pre><p><strong>Properties</strong></p>
                    <ul>
                        <li><code>color_on = [string]</code></li>
                        <li><code>color_off = [string]</code></li>
                    </ul>
                    <h3 id="input-flow-animation">Input Flow Animation</h3>
                    <p><strong>Syntax</strong></p>
                    <pre><code> input_flow = <span className="hljs-string">[InputID]</span>
                    </code></pre><p>Mark section flow animation.</p>
                    <p><strong>Properties</strong></p>
                    <ul>
                        <li><code>flow_inverse</code> : Inverse animation</li>
                    </ul>
                    <h3 id="output_toggle-outputid-">output_toggle = [OutputID]</h3>
                    <h3 id="output_on-outputid-">output_on = [OutputID]</h3>
                    <h3 id="output_off-outputid-">output_off = [OutputID]</h3>

                </IonText>
            </IonContent>
        </IonPage>
    }
}

export default withRouter(HMIDesigner);
