import { IonButton, IonButtons, IonCard, IonCardHeader, IonCardTitle, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonMenuButton, IonPage, IonTitle, IonToolbar } from "@ionic/react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import { DeviceInputs, HMIPanel } from "../service/API";

import "./HMIView.css"
import HMIDisplay from "../components/HMIDisplay";
import { trash } from "ionicons/icons";
import Utils from "../service/Utils";

export type PanelModel = {
    input_id: number,
    behaviour: {
        element_id: string,
        value?: string | number,
        prop?: any,
        discrete?: boolean,
        text?: string,
    }[]
}

export interface HMIViewProps extends RouteComponentProps<{ id: string }> {
    store?: Store,
}
export interface HMIViewState {
    panels?: HMIPanel[],
    loading: boolean,
}

@inject("store", "lang")
class HMIView extends React.Component<HMIViewProps, HMIViewState> {
    state: HMIViewState = {
        loading: false,
    };
    handleInputClick = (input: DeviceInputs) => {
        let device_id = input.group!.device_id;
        let input_id = input.id;
        // eslint-disable-next-line no-restricted-globals
        if (confirm("¿Deseas ver la entrada asociada a este control (" + input.name + " )?")) {
            this.props.history.push("/device/" + device_id + "/status/input/" + input_id)
        }
    };

    componentDidMount(): void {
        this.loadData();

    }


    loadData() {
        this.props.store?.api.getHMIPanels().then(response => {
            if (response.success) {
                this.setState({ panels: response.data });
            }

        });
    }

    handleRemove = (id: string) => (ev:any) => {
        // eslint-disable-next-line no-restricted-globals
        if(confirm("¿Realmente deseas eliminar este panel?")) {
            this.props.store?.api.removeHMIPanel(id).then(() => this.loadData());
        }

    }

    handleAdd = (ev:any) => {
        Utils.uploadFile(".svg").then(data => {
            // eslint-disable-next-line no-restricted-globals
            if(data) {
                let name = prompt("Introducir un título de panel", data.file.name);
                if(name) {
                    this.props.store?.api.addHMIPanel(name, data.data).then(() => this.loadData());
                }
            }
            

        }).catch(err => {
            alert("ERROR: " + err.toString());
        })


    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <IonPage>

            <IonHeader>
                <IonToolbar color={"primary"}>
                    <IonButtons slot={"start"}><IonMenuButton /></IonButtons>
                    <IonTitle>Visualizacion HMI</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent >
                <IonGrid>
                    {this.state.panels && this.state.panels.map((value, index) => {
                        return <IonCol sizeSm={"12"} key={index}>
                            <IonCard className="welcome-card">
                                <IonCardHeader>
                                    <IonCardTitle>
                                        {value.name}
                                    </IonCardTitle>
                                </IonCardHeader>
                                <HMIDisplay panel={value} onInputClick={this.handleInputClick} />

                                <IonItem button color="danger" onClick={this.handleRemove(value.id)}>
                                    <IonIcon icon={trash} slot="start" />
                                    <IonLabel>Eliminar</IonLabel>
                                </IonItem>
                            </IonCard>
                        </IonCol>
                    })}
                    <IonButton onClick={this.handleAdd}>Añadir</IonButton>
                </IonGrid>

                {/* <SVGSimpleRender objects={this.state.objects}></SVGSimpleRender> */}
            </IonContent>
        </IonPage>
    }
}

export default withRouter(HMIView);
