import {ConfigurationSection, DeviceConfigurationState} from "../../../pages/Configurator";
import DeviceConfiguration from "../DeviceConfiguration";
import GPRS from "../GPRS";
import RS485V1 from "../RS485V1";
import MemoryMapperV2_7 from "../MemoryMapperV2_7";
import ModbusRTUClient from "../../rtu/ModbusRTUClient";

export default class S272 extends MemoryMapperV2_7 {

    async setDefaultConfig(rtu: ModbusRTUClient): Promise<any> {
        super.setDefaultConfig(rtu);

        let deviceConfig = new DeviceConfiguration(rtu, this);
        deviceConfig.Equipment_DES = "S272";
        deviceConfig.Basic_Settings = 0;
        deviceConfig.Equipment_ID = 1;
        await deviceConfig!.Write();


        let gprs = new GPRS(rtu, this);

        gprs.GPRS_Enable = 1;
        gprs.APN_Name = "TM";
        gprs.APN_Pass = "";
        gprs.APN_User = "";
        gprs.APN_SET = 1;
        gprs.DomainEnable = 1;
        gprs.Domain = "devices.cloudrtu.com";
        gprs.ServerPort1 = 8001;

        await gprs!.Write();

        let commv1 = new RS485V1(rtu, this);
        commv1!.RS232_setting = 0
        commv1!.RS485_setting = 3
        commv1!.RS485_Baud_Rate = 9600
        commv1!.RS485_Data_bits = 3
        commv1!.RS485_Stop_bits = 0
        commv1!.RS485_Check_bits = 0
        commv1!.RS485_time = 0
        await commv1!.Write();
    }

    private sections:ConfigurationSection[] = [
        {
            title: 'Configuración principal',
            type: 'group',
            rtu: {
                class: DeviceConfiguration,
                stateKey: 'deviceConfig'
            },
            settings: [
                {
                    title: 'Nombre Dispositivo (60 max)',
                    read: (state:DeviceConfigurationState) => state.deviceConfig!.Equipment_DES,
                    write: (state:DeviceConfigurationState, value:any) => state.deviceConfig!.Equipment_DES = value,
                    type: 'text',
                    controlProps: {maxLength: 60},
                },
                {
                    title: 'ID Dispositivo',
                    read: (state:DeviceConfigurationState) => state.deviceConfig!.Equipment_ID,
                    write: (state:DeviceConfigurationState, value:any) => state.deviceConfig!.Equipment_ID = value,
                    type: 'number',
                    controlProps: {maxValue: 276, minValue: 0,}

                }
            ]
        },
        {
            title: 'Configuración de red',
            type: 'group',
            rtu: {
                class: GPRS,
                stateKey: 'netConfigOld'
            },
            settings: [
                {
                    type: 'header',
                    title: 'Configuración de red'
                },
                {
                    title: 'Activar GPRS',
                    read: (state:DeviceConfigurationState) => state.netConfigOld!.GPRS_Enable,
                    write: (state:DeviceConfigurationState, value:any) => state.netConfigOld!.GPRS_Enable = value,
                    type: 'check',
                },
                {
                    title: 'Nombre APN',
                    read: (state:DeviceConfigurationState) => state.netConfigOld!.APN_Name,
                    write: (state:DeviceConfigurationState, value:any) => state.netConfigOld!.APN_Name = value,
                    type: 'text',
                    controlProps: {maxLength: 40},
                },
                {
                    title: 'Usuario APN',
                    read: (state:DeviceConfigurationState) => state.netConfigOld!.APN_User,
                    write: (state:DeviceConfigurationState, value:any) => state.netConfigOld!.APN_User = value,
                    type: 'text',
                    controlProps: {maxLength: 21},
                },
                {
                    title: 'Contraseña APN',
                    read: (state:DeviceConfigurationState) => state.netConfigOld!.APN_Pass,
                    write: (state:DeviceConfigurationState, value:any) => state.netConfigOld!.APN_Pass = value,
                    type: 'text',
                    controlProps: {maxLength: 21},
                },
                {
                    type: 'header',
                    title: 'Configuración de servidor'
                },
                {
                    title: 'Activar Dominio',
                    read: (state:DeviceConfigurationState) => state.netConfigOld!.DomainEnable,
                    write: (state:DeviceConfigurationState, value:any) => state.netConfigOld!.DomainEnable = value,
                    type: 'check',
                },
                {
                    title: 'Servidor',
                    read: (state:DeviceConfigurationState) => state.netConfigOld!.Domain,
                    write: (state:DeviceConfigurationState, value:any) => state.netConfigOld!.Domain = value,
                    type: 'text',
                    controlProps: {maxLength: 31},
                },
                {
                    title: 'Puerto',
                    read: (state:DeviceConfigurationState) => state.netConfigOld!.ServerPort1,
                    write: (state:DeviceConfigurationState, value:any) => state.netConfigOld!.ServerPort1 = value,
                    type: 'number',
                    controlProps: {maxValue: 65536},
                },

            ]
        },
        {
            title: 'Configuración RS485',
            type: 'group',
            rtu: {
                class: RS485V1,
                stateKey: 'commv1'
            },
            settings: [
                {
                    type: 'header',
                    title: 'Configuración de Comunicación'
                },
                {
                    title: 'RS485_setting',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_setting,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_setting = value,
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: '0 - Apagado'
                            },
                            {
                                value: 2,
                                name: '2 - Modbus RTU Slave'
                            },
                            {
                                value: 3,
                                name: '3 - Transparent Transmission'
                            },
                        ]
                    }
                },
                {
                    title: 'RS485_Baud_Rate',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Baud_Rate,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Baud_Rate = value,
                    type: 'number',
                },
                {
                    title: 'RS485_Stop_bits',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Stop_bits,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Stop_bits = value,
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: '1'
                            },
                            {
                                value: 1,
                                name: '2'
                            },
                        ]
                    }
                },
                {
                    title: 'RS485_Check_bits',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Check_bits,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Check_bits = value,
                    type: 'select',
                    controlProps: {
                        values: [
                            {
                                value: 0,
                                name: 'none'
                            },
                            {
                                value: 1,
                                name: 'even'
                            },
                            {
                                value: 2,
                                name: 'odd'
                            },
                        ]
                    }
                },
                {
                    title: 'RS485_Interval_Time',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Interval_Time,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Interval_Time = value,
                    type: 'number',
                    controlProps: {
                        minValue: 200
                    }
                },
                {
                    title: 'RS485_Over_Time',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_Interval_Time,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_Interval_Time = value,
                    type: 'number',
                    controlProps: {
                        minValue: 200
                    }
                },
                {
                    title: 'RS485_NoData_Time',
                    read: (state:DeviceConfigurationState) => state.commv1!.RS485_NoData_Time,
                    write: (state:DeviceConfigurationState, value:any) => state.commv1!.RS485_NoData_Time = value,
                    type: 'number'
                },
            ]
        }
    ];
    getSections() : ConfigurationSection[] {
        return this.sections;
    }

}
