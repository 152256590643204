import { IonCol, IonGrid, IonInput, IonItem, IonRow, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";

export type NumberInputProps = {
    value?: number,
    label?: string,
    onChange?: (value: number) => void,
}

type NumberInputState = {
    base: number,
    error : boolean,
}

export default class NumberInput extends React.Component<NumberInputProps, any> {

    state: NumberInputState = {
        base: 10,
        error: false,
    } as NumberInputState

    converStringToNumber(number: string): number {
        if (number === "") {
            return 0;
        }
        return parseInt(number, this.state.base);
    }

    convertNumberToString(number?: number): string {
        if (number === undefined) {
            return "";
        }
        return Number(number).toString(this.state.base).toUpperCase();
    }

    handleChange = (ev: any) => {
        let value = this.converStringToNumber(ev.detail.value);
        if (Number.isInteger(value)) {
            this.setState({ error: false });
            if (this.props.onChange) {
                this.props.onChange(value);
            }
        } else {
            this.setState({ error: true });

        }
    }


    handleChangeRadio = (ev: any) => {
        // let oldBase = this.state.base;
        // let newBase = ev.detail.value;
        this.setState({ base: ev.detail.value });
        if (this.props.onChange && this.props.value !== undefined) {
            this.props.onChange(this.props.value);
            this.setState({});
        }
    }

    render() {
        return <IonItem>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        <IonInput label={this.props.label + " b" + this.state.base} onIonInput={this.handleChange} color={this.state.error ? "danger" : ""} value={this.convertNumberToString(this.props.value)}></IonInput>
                    </IonCol>
                    <IonCol size="2">
                        <IonSelect interface={"action-sheet"} value={this.state.base} onIonChange={this.handleChangeRadio}>
                            <IonSelectOption value={10}>DEC</IonSelectOption>
                            <IonSelectOption value={16}>HEX</IonSelectOption>
                            <IonSelectOption value={2}>BIN</IonSelectOption>
                        </IonSelect>
                    </IonCol>
                </IonRow>

            </IonGrid>
        </IonItem>

    }

}