import React from "react";
import {ChartEngineProps} from "./ChartEngine";
// import Highcharts from 'highcharts'
// import HighchartsReact from 'highcharts-react-official'
export default class HighchartsChart extends React.Component<ChartEngineProps> {

    state = {
        data: undefined,
        series: undefined,
    }

    setData(data: {id: number, data: number, input_id: number, time: number}[], recreate: boolean) {
        let inputs = this.props.input.inputs!;
        let series:{type: string, data: any[], name: string}[] = [];
        // title.push({ type: 'date', label: 'Fecha' });
        for (let i = 0; i < inputs.length; i++) {
            // let graph = Utils.getGraphProps(this.props.input, i);

            series[inputs[i].id] = {type: 'line', data: [], name: inputs[i].name};
        }
        //
        // // let max = 0;
        for (let i = 0; i < data.length; i++) {
            let item = data[i];
            if(series[item.input_id]) {
                series[item.input_id].data.push([(item.time) * 1000, item.data]);
            }
        }

        if(Object.keys(series).length > 0) {
            this.setState({series: Object.values(series)});
        }
        // for (const time in row) {
        //     if(row[time].length !== inputs.length + 1) {
        //         for (let i = 0; i < inputs.length; i++) {
        //             if(!row[time][keys[inputs[i].id]]) {
        //                 row[time][keys[inputs[i].id]] = 0;
        //             }
        //         }
        //     }
        // }
        // if(Object.keys(row).length > 0) {
        //
        //     let result = Object.values(row);
        //     this.setState({data: result});
        // } else {
        //     this.setState({data: undefined});
        //
        // }
    }

    componentDidUpdate(prevProps: Readonly<ChartEngineProps>, prevState: Readonly<{}>, snapshot?: any) {
        if (prevProps.time !== this.props.time) {
            this.setData(this.props.chartData, this.props.recreate);
        }
    }

    // render() {
    //     return <HighchartsReact
    //         highcharts={Highcharts}
    //         options={{
    //             title: {
    //                 text: this.props.input.name,
    //             },
    //             time: {
    //                 timezone: 'Europe/Madrid',
    //                 useUTC: false
    //             },
    //             xAxis: {

    //                 type: 'datetime',
    //                 // tickWidth: 0,
    //                 // gridLineWidth: 1,
    //                 // labels: {
    //                 //     align: 'left',
    //                 //     x: 3,
    //                 //     y: -3
    //                 // }
    //             },
    //             //
    //             // yAxis: [{ // left y axis
    //             //     title: {
    //             //         text: null
    //             //     },
    //             //     labels: {
    //             //         align: 'left',
    //             //         x: 3,
    //             //         y: 16,
    //             //         format: '{value:.,0f}'
    //             //     },
    //             //     showFirstLabel: false
    //             // }, { // right y axis
    //             //     linkedTo: 0,
    //             //     gridLineWidth: 0,
    //             //     opposite: true,
    //             //     title: {
    //             //         text: null
    //             //     },
    //             //     labels: {
    //             //         align: 'right',
    //             //         x: -3,
    //             //         y: 16,
    //             //         format: '{value:.,0f}'
    //             //     },
    //             //     showFirstLabel: false
    //             // }],
    //             //
    //             legend: {
    //                 align: 'left',
    //                 verticalAlign: 'top',
    //                 borderWidth: 0
    //             },

    //             tooltip: {
    //                 shared: true,
    //                 crosshairs: true
    //             },
    //             plotOptions: {
    //                 series: {
    //                     cursor: 'pointer',
    //                     point: {
    //                         events: {
    //                             click: function (e:any) {
    //                                 // hs.htmlExpand(null, {
    //                                 //     pageOrigin: {
    //                                 //         x: e.pageX || e.clientX,
    //                                 //         y: e.pageY || e.clientY
    //                                 //     },
    //                                 //     headingText: this.series.name,
    //                                 //     maincontentText: Highcharts.dateFormat('%A, %b %e, %Y', this.x) + ':<br/> ' +
    //                                 //         this.y + ' sessions',
    //                                 //     width: 200
    //                                 // });
    //                             }
    //                         }
    //                     },
    //                     marker: {
    //                         lineWidth: 1
    //                     }
    //                 }
    //             },

    //             series: this.state.series,
    //         }}
    //     />
    // }
}
