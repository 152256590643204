import React from "react";
import {Redirect, Route} from "react-router";
import {inject} from "mobx-react";
import * as H from 'history';
import Utils from "../service/Utils";
import { Store } from "../service/Store";

@inject("store", "lang")
class PrivateRoute extends React.Component<any, any> {

    state = {info: ''};

    renderPrivateRoute(location: H.Location) {
        let store:Store = this.props.store;
        Utils.log(location.pathname);
        if (this.props.store.login.isLogin()) {
            if(location.pathname === "/devices") {
                store.setItem('home', "devices");
            }
            if(location.pathname === "/dashboard") {
                store.setItem('home', "dashboard");
            }
            return this.props.children;
        }
        if (location.pathname !== "/")
            this.props.store.setItem('redirect', location.pathname);
        if(!this.state.info) {
            //this.setState({info: 'Es necesario acceder con su cuenta para continuar'});
        }

        return <Redirect to={{pathname: "/login", state: {from: location}}}/>;
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return <>
            <Route render={({location}) => this.renderPrivateRoute(location)}/>
        </>
    }
}

export default inject("store", "lang")(PrivateRoute);
