import React, {Component} from 'react';
// import _ from 'lodash';

// import Icon from '../Icon';
// import styles from './styles';
import PropertyGroup from './PropertyGroup';
// import Button from './Button';
// import SwitchState from './SwitchState';
import Columns from './Columns';
import Column from './Column';

export default class SizePanel extends Component<any, any> {
  has(object:any, prop:string) {
    return Object.keys(object).indexOf(prop) >= 0;
  }
  render() {
    let {object} = this.props;
    return (
      <PropertyGroup object={object}>
        {this.has(object, 'width') && <Columns label="Size">
          <Column showIf={this.has(object, 'width')}
                  label="width" value={object.width}
                  onChange={this.props.onChange.bind(this, 'width')} />
          <Column showIf={this.has(object, 'height')} label="height"
                  value={object.height}
                  onChange={this.props.onChange.bind(this, 'height')} />
        </Columns>}
        <Columns label="Position">
          <Column showIf={this.has(object, 'x')}
                  label="top" value={object.x}
                  onChange={this.props.onChange.bind(this, 'x')} />
          <Column showIf={this.has(object, 'y')} label="left" value={object.y}
                  onChange={this.props.onChange.bind(this, 'y')} />
        </Columns>
        {this.has(object, 'rotate') && <Columns label="Rotation">
          <Column label="angle" value={object.rotate}
                  onChange={this.props.onChange.bind(this, 'rotate')} />
        </Columns>}
      </PropertyGroup>
    );
  }
}
