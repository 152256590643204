import React from "react";
import {
    IonButton,
    IonButtons,
    IonCheckbox,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonToast,
    IonToolbar
} from "@ionic/react";
import { checkmarkOutline, closeOutline } from "ionicons/icons";
import { DemoRequest } from "../service/API";
import { inject } from "mobx-react";
import { Store } from "../service/Store";
import Lang from "../service/Lang";

type DemoLoginProps = {
    onDemo: (demo: DemoRequest) => void,
    onClose: () => void,
    store?: Store,
    lang?: Lang,
}

type DemoLoginState = {
    fullname: string,
    business: string,
    phone: string,
    email: string,
    tos: boolean,
    privacy: boolean,
    error: string
}

class DemoLogin extends React.Component<DemoLoginProps, DemoLoginState> {

    state: DemoLoginState = { error: '', business: '', email: '', fullname: '', phone: '', privacy: false, tos: false }

    handleDemoSubmit = (ev: any) => {
        const { lang } = this.props;

        let name = this.state.fullname;
        let business = this.state.business;
        let phone = this.state.phone;
        let email = this.state.email;

        if (this.state.tos && this.state.privacy) {
            this.props.store?.api.usersDemo(name, business, phone, email).then(value => {
                if (value.success) {
                    this.props.onDemo(value.data);
                }
            })
        } else {
            this.setState({ error: lang?.l.main.demo_conditions()! })
        }
    }

    handleChangeText = (name: string) => (ev: any) => {
        this.setState({ ...this.state, [name]: ev.detail.value });
    }
    handleChangeCheck = (name: string) => (ev: any) => {
        this.setState({ ...this.state, [name]: ev.detail.checked });
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {

        const { lang } = this.props;
        return <>
            <IonToast
                htmlAttributes={{ tabindex: undefined }}
                isOpen={!!this.state.error}
                color={"danger"}
                onDidDismiss={() => this.setState({ error: '' })}
                message={this.state.error}
                duration={5000}
            />
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{lang?.l.main.demo_header()}</IonTitle>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen className={"ion-padding"}>
                <form noValidate autoComplete="off" onSubmit={this.handleDemoSubmit}>
                    {lang?.l.main.demo_subtitle()}
                    <IonList lines={"full"}>

                        <IonItem>
                            <IonLabel position="stacked">{lang?.l.main.demo_fullname()}</IonLabel>
                            <IonInput name={"fullname"} title={lang?.l.main.demo_fullname()!} onIonInput={this.handleChangeText("fullname")}
                                required={true} />
                        </IonItem>
                        <IonItem>
                            <IonInput labelPlacement="stacked" label={lang?.l.main.demo_business()} name={"business"} title={lang?.l.main.demo_business()!} onIonInput={this.handleChangeText("business")}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonInput labelPlacement="stacked" label={lang?.l.main.demo_phone()} name={"phone"} title={lang?.l.main.demo_phone()!} onIonInput={this.handleChangeText("phone")}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonInput labelPlacement="stacked" label={lang?.l.main.email()} name={"email"} title={lang?.l.main.email()!} onIonInput={this.handleChangeText("email")}></IonInput>
                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start" color="primary" title={lang?.l.main.legal_terms()!} name={"tos"} onIonChange={this.handleChangeCheck("tos")}>
                                {lang?.l.main.legal_terms_url()}
                            </IonCheckbox>

                        </IonItem>
                        <IonItem>
                            <IonCheckbox slot="start" color="primary" title={lang?.l.main.privacy_terms()!} name={"privacy"} onIonChange={this.handleChangeCheck("privacy")}>
                                {lang?.l.main.privacy_terms_url()}
                            </IonCheckbox>
                        </IonItem>
                    </IonList>
                    {/*<IonButton onClick={() => this.setState({modal: false})}>Close Modal</IonButton>*/}

                </form>
            </IonContent>

            <IonFooter>
                <IonToolbar>
                    <IonButtons slot={"start"}>
                        <IonButton onClick={() => this.props.onClose()}><IonIcon
                            icon={closeOutline} /> {lang?.l.main.cancel()}</IonButton>
                    </IonButtons>
                    <IonButtons slot={"end"}>
                        <IonButton title={lang?.l.main.start_demo() + " modal"} onClick={this.handleDemoSubmit}><IonIcon icon={checkmarkOutline} /> {lang?.l.main.start_demo()}</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonFooter>
        </>
    }
}

export default inject("store", "lang")(DemoLogin);
