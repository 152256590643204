import Registers from "../Registers";
import {MemoryAddress} from "./MemoryMapper";

export default class DeviceConfiguration extends Registers {

    public Basic_Settings:number=0;
    public Reporting_removal_settings:number=0;
    public Reporting_DIN_settings:number=0;
    public Reporting_AIN_settings:number=0;
    public Reporting_DOUT_settings:number=0;
    public ALM_SMS_removal_settings:number=0;
    public ALM_SMS_DIN_settings:number=0;
    public ALM_SMS_AIN_settings:number=0;
    public ALM_SMS_DOUT_settings:number=0;
    public Equipment_ID:number=0;
    public Cloth_removal:number=0;
    public Cloth_DISARM_TIME:number=0;
    public Equipment_DES:string="";
    public version_model:string="";
    public serial_number:string="";

    // constructor(rtu: ModbusRTUClient) {
    //     super(rtu);
    //     //
    //     // this.defineAddress(2, {
    //     //     Basic_Settings: Model.value(),
    //     //     Reporting_removal_settings: Model.value(),
    //     //     Reporting_DIN_settings: Model.value(),
    //     //     Reporting_AIN_settings: Model.value(),
    //     //     Reporting_DOUT_settings: Model.value(),
    //     //     ALM_SMS_removal_settings: Model.value(),
    //     //     ALM_SMS_DIN_settings: Model.value(),
    //     //     ALM_SMS_AIN_settings: Model.value(),
    //     //     ALM_SMS_DOUT_settings: Model.value(),
    //     //     Equipment_ID: Model.value(),
    //     //     Cloth_removal: Model.value(),
    //     //     Cloth_DISARM_TIME: Model.value(),
    //     //     _empty: Model.values(3),
    //     //     Equipment_DES: Model.byteString(60),
    //     // })
    //     // this.defineAddress(1496,{
    //     //     version_model: Model.byteString(5),
    //     // });
    //     // this.defineAddress(80,{
    //     //     HostTEL_Number: Model.byteString(21),
    //     // });
    // }

    async Read(): Promise<boolean> {
        let read = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.BASIC_SETTINGS), 74);
        if(read) {
            let buffer = read;
            this.Basic_Settings = buffer[0];
            this.Reporting_removal_settings = buffer[1];
            this.Reporting_DIN_settings = buffer[2];
            this.Reporting_AIN_settings = buffer[3];
            this.Reporting_DOUT_settings = buffer[4];
            this.ALM_SMS_removal_settings = buffer[5];
            this.ALM_SMS_DIN_settings = buffer[6];
            this.ALM_SMS_AIN_settings = buffer[7];
            this.ALM_SMS_DOUT_settings = buffer[8];
            this.Equipment_ID = buffer[9];
            this.Cloth_removal = buffer[10];
            this.Cloth_DISARM_TIME = buffer[11];
            this.Equipment_DES = Registers.toString16Array(buffer.slice(14, 14 + 60));
            read = await this.readHoldingRegistersArray(this.mapper.get(MemoryAddress.VERSION_MODEL), 5);
            if(!read) return false;
            this.version_model = Registers.toString16Array(read);

            // if(this.mapper.has(MemoryAddress.SERIAL_NUMBER)) {
            //     let read2 = await this.readInputRegisters(this.mapper.get(MemoryAddress.SERIAL_NUMBER), 8);
            //     if(!read2) return false;
            //     this.serial_number = read2.valuesAsBuffer.toString('ASCII');
            // }
            return true
        }
        return false;
    }

    async Write(): Promise<any> {
        let data : number[] = [];
        data.push(this.Basic_Settings);
        data.push(this.Reporting_removal_settings);
        data.push(this.Reporting_DIN_settings);
        data.push(this.Reporting_AIN_settings);
        data.push(this.Reporting_DOUT_settings);
        data.push(this.ALM_SMS_removal_settings);
        data.push(this.ALM_SMS_DIN_settings);
        data.push(this.ALM_SMS_AIN_settings);
        data.push(this.ALM_SMS_DOUT_settings);
        data.push(this.Equipment_ID);
        data.push(this.Cloth_removal);
        data.push(this.Cloth_DISARM_TIME);
        await this.writeMultipleRegisters(this.mapper.get(MemoryAddress.BASIC_SETTINGS), data);
        data = [];
        Registers.toArray16String(this.Equipment_DES, 60, data);
        await this.writeMultipleRegisters(16, data);
        await this.writeMultipleRegisters(79, [1]);

        return true;
    }

    static BasicSettings = function(smsOnPowerOn:boolean, timeOnSms:boolean, armOnPowerOn:boolean, rearm:boolean, addInfo1:boolean, addInfo2:boolean) {
        /*
          this.CheckTime.Checked = ((int) this._objMCU.DEVICE_COF.Basic_Settings & 1) > 0; // Send SMS \"Power on\" to users
          this.AlarmTime.Checked = ((int) this._objMCU.DEVICE_COF.Basic_Settings & 2) > 0; // Add timestamp to alarm SMS
          this.ArmedState.Checked = ((int) this._objMCU.DEVICE_COF.Basic_Settings & 4) > 0;
          this.AutoDeploy.Checked = ((int) this._objMCU.DEVICE_COF.Basic_Settings & 8) > 0; //Auto Arm after disarm
          this.AddInformation1.Checked = ((int) this._objMCU.DEVICE_COF.Basic_Settings & 16) > 0;
          this.AddInformation2.Checked = ((int) this._objMCU.DEVICE_COF.Basic_Settings & 32) > 0;
         */
        var res = 0;
        res += (smsOnPowerOn?1:0);
        res += (timeOnSms?1:0) >> 1;
        res += (armOnPowerOn?1:0) >> 2;
        res += (rearm?1:0) >> 3;
        res += (addInfo1?1:0) >> 4;
        res += (addInfo2?1:0) >> 5;
        return res;

    };
}
